/* eslint-disable react/jsx-no-target-blank */
import {SidebarMenuItem} from './SidebarMenuItem'
import {SideMenuItems} from './SideMenuItemCustom'
import {useTranslation} from 'react-i18next'
import {Stack} from '@mui/material'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../../app/store'
import MainItems from '../../header/MainItems'
import Order from '../../../../../app/svg/Order'
import {checkIsActive} from '../../../../helpers'
import {useLocation} from 'react-router-dom'
import Business from '../../../../../app/svg/Business'
import Payment from '../../../../../app/svg/Payment'
import PaymentRefund from '../../../../../app/svg/PaymentRefund'
import Request from '../../../../../app/svg/Request'
import Home from '../../../../../app/svg/Home'
import WallitIcon from '../../../../../app/svg/WallitIcon'
import TransationIcon from '../../../../../app/svg/TransationIcon'
import PurchasesIcon from '../../../../../app/svg/PurchasesIcon'
import TicketIcon from '../../../../../app/svg/TicketIcon'

const SidebarMenuMain = ({activeMenu, setIsOpen}: any) => {
  const {pathname} = useLocation()
  const store = useSelector((state: RootState) => state.vender)

  const links = [
    {
      name: 'home',
      to: '/dashboard',
      icon: <Home color={checkIsActive(pathname, '/dashboard') ? '#DDD' : ''} />,
      isSubMenu: false,
    },
    {
      name: 'wallet',
      to: '/wallet',
      icon: <WallitIcon color={checkIsActive(pathname, '/wallet') ? '#DDD' : ''} />,
      isSubMenu: false,
    },
    {
      name: 'transactions',
      to: '/transactions',
      icon: <TransationIcon color={checkIsActive(pathname, '/transactions') ? '#DDD' : ''} />,
      isSubMenu: false,
    },
    {
      name: 'purchases',
      to: '/purchases',
      icon: <PurchasesIcon color={checkIsActive(pathname, '/purchases') ? '#DDD' : ''} />,
      isSubMenu: false,
    },
    {
      name: 'tickets',
      to: '/ticket',
      icon: <TicketIcon color={checkIsActive(pathname, '/ticket') ? '#DDD' : ''} />,
      isSubMenu: false,
    },
  ]
  const linksSubMenu = [
    // {
    //   name: 'myBusiness',
    //   to: '/myBusiness',
    //   icon: <Business color={checkIsActive(pathname, '/myBusiness') ? '#DDD' : ''} />,
    //   isSubMenu: true,
    // },
    {
      name: 'paymentLog',
      to: '/paymentLog',
      icon: <Payment color={checkIsActive(pathname, '/paymentLog') ? '#DDD' : ''} />,
      isSubMenu: true,
    },
    {
      name: 'latestOrder',
      to: '/latestOrder',
      icon: <Order color={checkIsActive(pathname, '/latestOrder') ? '#DDD' : ''} />,
      isSubMenu: true,
    },
    {
      name: 'paymentRefund',
      to: '/paymentRefund',
      icon: <PaymentRefund color={checkIsActive(pathname, '/paymentRefund') ? '#DDD' : ''} />,
      isSubMenu: true,
    },
    {
      name: 'requestRefund',
      to: '/requestRefund',
      icon: <Request color={checkIsActive(pathname, '/requestRefund') ? '#DDD' : ''} />,
      isSubMenu: true,
    },
  ]
  return (
    <Stack gap='15px'>
      <MainItems isBorder links={links} />
      {store.user && store.user.type === 'Vendor' && <MainItems links={linksSubMenu} />}
    </Stack>
  )
}

export {SidebarMenuMain}
