import React from 'react'

export default function Error() {
  return (
    <svg width='80' height='80' viewBox='0 0 80 80' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle opacity='0.15' cx='40' cy='40' r='40' fill='#EF476F' />
      <circle opacity='0.2' cx='40' cy='40' r='30' fill='#EF476F' />
      <circle cx='40' cy='40' r='20' fill='#EF476F' />
      <path
        d='M34 46L46 34M34 34L46 46'
        stroke='white'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}
