import * as React from 'react'
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import PersonAdd from '@mui/icons-material/PersonAdd'
import Settings from '@mui/icons-material/Settings'
import Logout from '@mui/icons-material/Logout'
import TooltipContiner from '../../parts/TooltipContiner'
import {Badge, Stack} from '@mui/material'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import clsx from 'clsx'
import Get from '../../fubcations/mastars/Get'
import Label from '../../components/texts/Label'
import ReadAll from '../../svg/ReadAll'
import Item from './Item'
import {useSelector} from 'react-redux'
import {RootState} from '../../store'
import Items from './Items'

export default function Notifacations() {
  const itemClass = 'ms-1 ms-lg-3'

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const store = useSelector((state: RootState) => state.vender)
  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <React.Fragment>
      <Box sx={{display: 'flex', alignItems: 'center', textAlign: 'center'}}>
        <TooltipContiner text='notifications'>
          <Stack
            style={{margin: '0 20px', cursor: 'pointer'}}
            onClick={handleClick}
            className={clsx('app-navbar-item', itemClass)}
          >
            <Badge badgeContent={store && store.user && store.user.notification_count}>
              <NotificationsNoneIcon color='action' />
            </Badge>
          </Stack>
        </TooltipContiner>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
      >
        <Stack overflow={'auto'} maxHeight={'70vh'} sx={{minWidth: '316px'}}>
          {' '}
          <Stack p='12px 24px' direction={'row'} justifyContent={'space-between'}>
            <Label text='notifications' />
            {/* <Stack gap='8px' direction={'row'}>
              <Label text='markRead' />
              <ReadAll />
            </Stack> */}
          </Stack>
          <Divider color='#484848' />
          <Items />
        </Stack>
      </Menu>
    </React.Fragment>
  )
}
