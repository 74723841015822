import React, {useState} from 'react'
import {Link, useLocation} from 'react-router-dom'
import {checkIsActive} from '../../../helpers'
import {Box, Stack} from '@mui/material'
import {useTranslation} from 'react-i18next'
import Label from '../../../../app/components/texts/Label'

export default function MenuItem({item}: any) {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, item.to)
  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  const fillColor = isHovered ? 'var(--link-color)' : '#777777'
  const iconColor = isHovered ? 'var(--link-color)' : '#6D6D6D' // Change icon color on hover

  return (
    <Link style={{}} to={item.to}>
      <Box
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        sx={{
          background: {
            xs: isActive
              ? 'linear-gradient(rgb(103, 117, 226) -46.29%, rgb(68, 185, 234) 79.49%)'
              : 'none',
            lg:
              isActive && item.isSubMenu
                ? 'linear-gradient(rgb(103, 117, 226) -46.29%, rgb(68, 185, 234) 79.49%)'
                : isActive
                ? '#ff000000'
                : 'none',
          },
          padding: {xs: '11px 18px', lg: '8px 25px'},
          borderRadius: '8px',
          color: {xs: isActive ? '#467de1' : '#89898A', lg: isActive ? 'black' : '#89898A'},
        }}
        flexDirection={'column'}
        display={'flex'}
      >
        <Stack direction={'row'} alignItems={'center'} gap='18px'>
          <Stack display={{lg: item.isSubMenu ? 'block' : 'none'}}>
            {React.isValidElement(item.icon)
              ? React.cloneElement(item.icon, {fillColor: iconColor})
              : item.icon && React.cloneElement(item.icon, {fillColor: iconColor})}
          </Stack>
          <Label
            color={{
              lg: isActive && item.isSubMenu ? '#ffff' : isActive ? 'black' : fillColor,
              xs: isActive ? '#DDD' : '#828282',
            }}
            fw={isActive ? 600 : 400}
            text={item.name}
          />
        </Stack>
        {isActive && !item.isSubMenu && (
          <Stack
            display={{xs: 'none', lg: 'block'}}
            sx={{height: '4px', borderRadius: '8px', width: '100%', bgcolor: '#467de1'}}
          ></Stack>
        )}
      </Box>
    </Link>
  )
}
