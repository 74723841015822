import {Stack} from '@mui/material'
import React, {useState} from 'react'

import {LoginSchema} from '../../yup/SignSchema'

import Title from '../../components/texts/Title'
import Label from '../../components/texts/Label'
import InputWithLabel from '../../parts/InputWithLabel'
import MainBtn from '../../components/buttons/MainBtn'
import TextLink from '../../components/texts/TextLink'
import {LoginObj} from '../modules/post/SignsModule'
import Post from '../../fubcations/mastars/Post'
import {useDispatch} from 'react-redux'
import {actions} from '../../store/admin/auth.slice'
import JwtService from '../../utils/TokenServices'
import GetIp from '../../fubcations/mastars/GetIp'
import {useTranslation} from 'react-i18next'
export default function LoginForm({setIsActive, setPhone}: any) {
  const [userInfo, setUserInfo] = useState<any>({
    phone_code: '+964',
    phone: '0592146780',
    password: 'password',
  })
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const {i18n} = useTranslation()

  const handelSubmit = async (e: any) => {
    e.preventDefault()
    GetIp(
      Post,
      LoginSchema,
      userInfo,
      LoginObj,
      setErrors,
      (data: any) => {
        if (data?.data?.data) {
          dispatch(
            actions.login({...data?.data?.data, isConfirmProfile: data?.data?.isConfirmProfile})
          )
          dispatch(actions.setRtl(data.data.data.is_rtl ? 1 : 0))
          i18n.changeLanguage(data?.data?.data?.language_code)

          JwtService.setToken(data?.data?.data?.token)
        } else {
          setPhone(userInfo.phone)
          dispatch(actions.login({...data.data, is2F: data.data.is2FA}))
        }
      },
      setLoading,
      'auth/login'
    )
  }

  return (
    <form>
      <Stack gap='24px'>
        <Title text={'signinTitle'} />
        <InputWithLabel
          type={'tel'}
          placeHolder={'mobileNumber'}
          data={userInfo}
          setData={setUserInfo}
          name={'phone'}
          width='100%'
          isNumberPhone
          errors={errors}
        />
        <InputWithLabel
          type={'password'}
          placeHolder={'password'}
          data={userInfo}
          setData={setUserInfo}
          name={'password'}
          errors={errors}
        />
        <MainBtn
          isLoading={loading}
          disabled={userInfo['password'] && userInfo['phone'] ? false : true}
          handelSubmit={handelSubmit}
          text={'login'}
        />
        <TextLink
          onClick={() => {
            setIsActive(1)
          }}
          text={'forgotPassword'}
        />
        <Stack gap='10px'>
          <Label text='dontHaveAnAccount' />
          <TextLink
            onClick={() => {
              setIsActive(3)
            }}
            text={'signup'}
          />
        </Stack>
      </Stack>
    </form>
  )
}
