import React from 'react'

export default function WithDrowItemObj(data: any) {
  return {
    id: data.id,
    withdraw_way_id: data.withdraw_way_id,
    name: data.name,
    type: data.type,
    bank_adress: data.bank_adress,
    swift_code: data.swift_code,
    owner: data.owner,
    iban: data.iban,
    exchange_name: data.exchange_name,
    exchange_mobile: data.exchange_mobile,
    wallet_name: data.wallet_name,
    wallet_mobile: data.wallet_mobile,
  }
}
