export function ProfileObj(data: any) {
  return {
    name: data.name,
    email: data.email,
    dateBirth: data.dateBirth,
    accountNo: data.accountNo,
    accountType: data.accountType,
  }
}
export function BusinessAccountDetailsObj(data: any) {
  return {
    businessName: data.businessName,
    email: data.email,
    dateBirth: data.dateBirth,
    accountNo: data.accountNo,
    accountType: data.accountType,
  }
}
export function BusinessAccountDetailsForUserObj(data: any) {
  return {
    business_name: data.businessName,
    business_id: data.type,
    manger_name: data.businessManager,
    business_about: data.content,
    domain_web: data.domainName,
    commercial_record: data.imageOne,
    passport_or_accommodation: data.imageTwo,
    user_type: data.isCitizen === 1 ? 'citizen' : 'foreign',
    average_earning: data.avarage,
    id_card_face_two: data.imageThree,
    id_card_face_one: data.imageFour,
  }
}
export function ChangePasswordObj(data: any) {
  return {
    current_password: data.oldPassword,
    new_password: data.newPassword,
    new_password_confirmation: data.confirmPassword,
    isRevoke: 0,
  }
}
export function ContactUsObj(data: any) {
  return {
    mobile: data.phone,
    email: data.email,
    message: data.content,
    reason_id: data.reason,
    name: data.name,
  }
}
export function NotifyObj(data: any) {
  return {
    is_notify: data.is_notify,
  }
}
export function Active2FAObj(data: any) {
  return {
    active_2FA: data.active_2FA,
  }
}
export function LangObj(data: any) {
  return {
    language_id: data.language_id,
  }
}
