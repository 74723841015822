import React from 'react'

export default function ErrMsg({msgErr, name}: {msgErr: string; name: string}) {

  return (
    <>
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>{msgErr}</div>
        </div>
    </>
  )
}
