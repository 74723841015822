import {Stack} from '@mui/material'
import React from 'react'
import Nike from '../../svg/Nike'
import Title from '../../components/texts/Title'
import Label from '../../components/texts/Label'
import MainBtn from '../../components/buttons/MainBtn'

export default function SuccessResetPassword({setIsActive}: any) {
  return (
    <Stack gap='24px' alignItems={'center'}>
      <Nike />
      <Title text='passwordReset' />
      <Label text='successfullyResetPassword' />
      <MainBtn text='login' handelSubmit={() => setIsActive(0)} />
    </Stack>
  )
}
