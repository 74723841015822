import {Stack} from '@mui/material'
import React from 'react'
import SelectBtn from '../../../components/buttons/SelectBtn'
import BtnWithIcon from '../../../components/buttons/BtnWithIcon'
import Transfer from '../../../svg/Transfer'
import {useNavigate} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'

export default function ActionBtns() {
  const navigate = useNavigate()
  const store = useSelector((state: RootState) => state.vender)

  return (
    <Stack direction={'row'} gap='24px'>
      <BtnWithIcon
        onClick={() => navigate('depositHistory')}
        icon={<Transfer color='#467DE1' />}
        name='depositHistory'
        without={true}
        width='133px'
      />
      {store.user && store.user.type === 'Vendor' && (
        <BtnWithIcon
          onClick={() => navigate('withDrawHistory')}
          icon={<Transfer />}
          name='withDrawHistory'
          width='133px'
        />
      )}
    </Stack>
  )
}
