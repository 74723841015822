import React from 'react'

export function TiketObj(data: any) {
  return {
    id: data.ticketId,
    status: data.status,
    date: data.date,
    name: data.subject,
  }
}
export function MassageObj(data: any) {
  return {
    userImg: data.user && data.user.avater,
    name: data.user && data.user.name,
    msg: data.message,
    date: data.date,
    type: data.sender_type,
  }
}
