import {Button, SxProps} from '@mui/material'

export default function Btn({
  text,
  bg,
  submit,
  type,
  sx,
  width,
  disabeld,
}: {
  text: string
  bg: string
  type: string
  submit?: any
  sx?: SxProps
  width?: string
  disabeld?: boolean
}) {
  return (
    <Button
      onClick={submit}
      disabled={disabeld}
      sx={{
        textTransform: 'capitalize',
        minWidth: width || '120px',
        height: '44px',
        borderRadius: '8px',
        border: '1px solid #D81923',
        background:
          bg === type ? (disabeld ? '#d819238c' : '#D81923') : 'var(--bs-app-footer-height)',
        color: bg !== type ? '#D81923' : 'white',
        fontSize: '16px',
        fontWeight: '500',
        ...sx,
      }}
    >
      {text}
    </Button>
  )
}
