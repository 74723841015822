import React from 'react'

export default function Print() {
  return (
    <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='40' height='40' rx='4' fill='#467DE1' fill-opacity='0.1' />
      <path
        d='M14 25.9827C12.4466 25.9359 11.52 25.7626 10.8787 25.1213C10 24.2426 10 22.8284 10 20C10 17.1716 10 15.7574 10.8787 14.8787C11.7574 14 13.1716 14 16 14H24C26.8284 14 28.2426 14 29.1213 14.8787C30 15.7574 30 17.1716 30 20C30 22.8284 30 24.2426 29.1213 25.1213C28.48 25.7626 27.5535 25.9359 26 25.9827'
        stroke='#467DE1'
        stroke-width='1.5'
      />
      <path d='M17 18H14' stroke='#467DE1' stroke-width='1.5' stroke-linecap='round' />
      <path d='M27 23H13' stroke='#467DE1' stroke-width='1.5' stroke-linecap='round' />
      <path
        d='M25.1213 10.8787L24.591 11.409V11.409L25.1213 10.8787ZM14.8787 10.8787L15.409 11.409V11.409L14.8787 10.8787ZM14.8787 29.1213L15.409 28.591H15.409L14.8787 29.1213ZM26.75 23C26.75 22.5858 26.4142 22.25 26 22.25C25.5858 22.25 25.25 22.5858 25.25 23H26.75ZM14.75 23C14.75 22.5858 14.4142 22.25 14 22.25C13.5858 22.25 13.25 22.5858 13.25 23H14.75ZM25.25 24C25.25 25.4354 25.2484 26.4365 25.1469 27.1919C25.0482 27.9257 24.8678 28.3142 24.591 28.591L25.6517 29.6517C26.2536 29.0497 26.5125 28.2919 26.6335 27.3918C26.7516 26.5132 26.75 25.393 26.75 24H25.25ZM20 30.75C21.393 30.75 22.5132 30.7516 23.3918 30.6335C24.2919 30.5125 25.0497 30.2536 25.6517 29.6517L24.591 28.591C24.3142 28.8678 23.9257 29.0482 23.1919 29.1469C22.4365 29.2484 21.4354 29.25 20 29.25V30.75ZM20 10.75C21.4354 10.75 22.4365 10.7516 23.1919 10.8531C23.9257 10.9518 24.3142 11.1322 24.591 11.409L25.6517 10.3483C25.0497 9.74643 24.2919 9.48754 23.3918 9.36652C22.5132 9.24841 21.393 9.25 20 9.25V10.75ZM20 9.25C18.607 9.25 17.4868 9.24841 16.6082 9.36652C15.7081 9.48754 14.9503 9.74643 14.3483 10.3483L15.409 11.409C15.6858 11.1322 16.0743 10.9518 16.8081 10.8531C17.5635 10.7516 18.5646 10.75 20 10.75V9.25ZM13.25 24C13.25 25.393 13.2484 26.5132 13.3665 27.3918C13.4875 28.2919 13.7464 29.0497 14.3484 29.6517L15.409 28.591C15.1322 28.3142 14.9518 27.9257 14.8531 27.1919C14.7516 26.4365 14.75 25.4354 14.75 24H13.25ZM20 29.25C18.5646 29.25 17.5635 29.2484 16.8081 29.1469C16.0743 29.0482 15.6858 28.8678 15.409 28.591L14.3484 29.6517C14.9503 30.2536 15.7081 30.5125 16.6082 30.6335C17.4868 30.7516 18.607 30.75 20 30.75V29.25ZM26.7323 13.9774C26.6859 12.4352 26.5237 11.2204 25.6517 10.3483L24.591 11.409C25.0016 11.8196 25.1859 12.4579 25.233 14.0226L26.7323 13.9774ZM14.767 14.0226C14.8141 12.4579 14.9984 11.8196 15.409 11.409L14.3483 10.3483C13.4763 11.2204 13.3141 12.4352 13.2677 13.9774L14.767 14.0226ZM26.75 24V23H25.25V24H26.75ZM14.75 24V23H13.25V24H14.75Z'
        fill='#467DE1'
      />
      <circle cx='25' cy='18' r='1' fill='#467DE1' />
    </svg>
  )
}
