import React from 'react'

export default function Nike() {
  return (
    <svg width='80' height='80' viewBox='0 0 80 80' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle opacity='0.15' cx='40' cy='40' r='40' fill='#4579D9' />
      <circle opacity='0.2' cx='40' cy='40' r='30' fill='#4579D9' />
      <circle cx='40' cy='40' r='20' fill='#4579D9' />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M48.0486 34.3515C48.5173 34.8201 48.5173 35.5799 48.0486 36.0485L38.4486 45.6485C37.98 46.1172 37.2202 46.1172 36.7516 45.6485L31.9516 40.8485C31.4829 40.3799 31.4829 39.6201 31.9516 39.1515C32.4202 38.6828 33.18 38.6828 33.6486 39.1515L37.6001 43.1029L46.3516 34.3515C46.8202 33.8828 47.58 33.8828 48.0486 34.3515Z'
        fill='white'
      />
    </svg>
  )
}
