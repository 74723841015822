import {Stack, Typography} from '@mui/material'
import React, {useState} from 'react'
import InputType from '../customTable/InputType'
import {useTranslation} from 'react-i18next'
import Vendor from '../../Api/SuperAdmin'
import * as Yup from 'yup'
import ErrorMsg from '../../components/texts/ErrorMsg'
import {RootState} from '../../store'
import {useSelector} from 'react-redux'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import InputWithLabel from '../../parts/InputWithLabel'
import Title from '../../components/texts/Title'
import MainBtn from '../../components/buttons/MainBtn'
import {ResetSchema} from '../../yup/SignSchema'
import {ResetPasswordObj} from '../modules/post/SignsModule'
import Post from '../../fubcations/mastars/Post'
export default function RestPassword({code, phone, code_phone, setIsActive}: any) {
  const [userInfo, setUserInfo] = useState({})
  const [isLoading, setUsLoading] = React.useState(false)
  const [msgErr, setMsgErr] = React.useState<string>('')
  const store = useSelector((state: RootState) => state.vender)

  const [errors, setErrors] = useState({})

  const handelSubmit = async (e: any) => {
    e.preventDefault()
    Post(
      ResetSchema,
      {...userInfo, phone: phone},
      ResetPasswordObj,
      setErrors,
      () => {
        setIsActive(4)
      },
      setUsLoading,
      'auth/password/forgot/reset'
    )
  }
  const {t} = useTranslation()

  return (
    <form onSubmit={handelSubmit}>
      <Stack gap='24px'>
        <Title text={'resetPassword'} />
        <InputWithLabel
          type={'password'}
          placeHolder={'password'}
          data={userInfo}
          setData={setUserInfo}
          name={'new_password'}
          errors={errors}
        />
        <InputWithLabel
          type={'password'}
          placeHolder={'confirmationPassword'}
          data={userInfo}
          setData={setUserInfo}
          name={'new_password_confirmation'}
          errors={errors}
        />

        {/* <Typography sx={{fontSize: '13px', color: '#3E97FF', fontWeight: '600'}}>
        {t('forgotPassword')}
      </Typography> */}
        <Stack width={'100%'}>
          <MainBtn onClick={handelSubmit} text='resetPassword' />
        </Stack>
      </Stack>
    </form>
  )
}
