import React from 'react'

export default function Delete() {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.75 11.875C10.0261 11.875 10.25 11.6511 10.25 11.375V6.375C10.25 6.0989 10.0261 5.875 9.75 5.875C9.4739 5.875 9.25 6.0989 9.25 6.375V11.375C9.25 11.6511 9.4739 11.875 9.75 11.875Z'
        fill='black'
      />
      <path
        d='M6.25 11.875C6.5261 11.875 6.75 11.6511 6.75 11.375V6.375C6.75 6.0989 6.5261 5.875 6.25 5.875C5.9739 5.875 5.75 6.0989 5.75 6.375V11.375C5.75 11.6511 5.9739 11.875 6.25 11.875Z'
        fill='black'
      />
      <path
        d='M10 2.125C10.2761 2.125 10.5 1.9011 10.5 1.625C10.5 1.3489 10.2761 1.125 10 1.125H6C5.7239 1.125 5.5 1.3489 5.5 1.625C5.5 1.9011 5.7239 2.125 6 2.125H10Z'
        fill='black'
      />
      <path
        d='M2.5 2.625C2.2239 2.625 2 2.8489 2 3.125C2 3.4011 2.2239 3.625 2.5 3.625H3V12.825C3 13.955 3.92 14.875 5.05 14.875H10.95C12.08 14.875 13 13.955 13 12.825V3.625H13.5C13.7761 3.625 14 3.4011 14 3.125C14 2.8489 13.7761 2.625 13.5 2.625H12.5H3.5H2.5ZM12 3.625V12.825C12 13.405 11.53 13.875 10.95 13.875H5.05C4.47 13.875 4 13.405 4 12.825V3.625H12Z'
        fill='black'
      />
    </svg>
  )
}
