import {Container, Stack, Typography} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {useSelector} from 'react-redux'
import {RootState} from '../../store'
import LabelInput from '../../modules/customTable/LabelInput'
import CheckBox from '../../modules/customTable/CheckBox'
import RestSaveButton from '../../modules/customTable/RestSaveBtns'

export default function Filter({open, setOpen, setFilterData, filterData}: any) {
  const {t} = useTranslation()
  const [data, setData] = useState<any>({status: filterData.status || 1})
  const [isActive, setIsActive] = useState(filterData.status === 0 ? 'type2' : 'type1')
  const store = useSelector((state: RootState) => state.vender)

  return (
    <Container
      dir={store.isRtl === 0 ? 'ltr' : 'rtl'}
      sx={{padding: '7px 24px 28px 24px'}}
      maxWidth='md'
    >
      <div>
        <p style={{width: '300px'}}></p>
        <Stack sx={{paddingBottom: '20px', paddingTop: '5px', borderBottom: '1px solid #D8D8E5'}}>
          <Typography sx={{fontSize: '18px', fontWeight: '600', color: 'var(--bs-body-color)'}}>
            {t('filter')}
          </Typography>
        </Stack>

        <Stack gap='8px' sx={{padding: '24px 0'}}>
          <LabelInput text={t('status')} />
          <Stack gap='34px' direction={'row'} mt={'10px'}>
            <CheckBox
              submit={() => {
                if (isActive !== 'type1') {
                  setIsActive('type1')
                }
                setData({...data, status: 1})
              }}
              bg={isActive}
              type='type1'
              text='active'
            />
            <CheckBox
              submit={() => {
                if (isActive !== 'type2') {
                  setIsActive('type2')
                }
                setData({...data, status: 0})
              }}
              bg={isActive}
              type='type2'
              text='inactive'
            />
          </Stack>
        </Stack>
        <RestSaveButton
          SaveText={t('apply')}
          cancelText={t('cancel')}
          submit={() => {
            setFilterData(data)
            setOpen(false)
          }}
          cancel={() => {
            setOpen(false)
          }}
          isLoading={false}
        />
        <Stack alignItems={'flex-end'}>{/* <ErrorMsg msgErr={msgErr} name='' /> */}</Stack>

        {/* {isLoading && <Loading />} */}
      </div>
    </Container>
  )
}
