import {CircularProgress} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

export default function SecondaryBtn({
  isLoading,
  handelSubmit,
  text,
  disabled,
  isLink,
  width,
  fs,
  border,
}: any) {
  const {t} = useTranslation()
  function capitalizeFirstLetter(text: any) {
    if (!text) return text
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
  }
  const translatedText = t(text)
  const capitalizedText = capitalizeFirstLetter(translatedText)
  return (
    <button
      disabled={disabled || isLoading}
      style={{
        fontSize: fs || '14px',
        color: border ? '#048FD9' : isLink ? 'var(--link-color)' : 'var(--main-color)',
        fontWeight: '600',
        background: '#ff000000',
        borderRadius: '4px',
        padding: '8px 24px',
        border: border ? border : 'none',
        width: width || '100%',
      }}
      onClick={handelSubmit}
    >
      {isLoading ? <CircularProgress size={'20px'} color='info' /> : capitalizedText}
    </button>
  )
}
