import * as Yup from 'yup'
import dayjs from 'dayjs'
export const LoginSchema = Yup.object().shape({
  phone: Yup.string().required('fieldRequired').min(10, 'phoneMsg'),
  password: Yup.string().required('fieldRequired').min(8, 'passwordMsg'),
})
export const SignUpSchema = Yup.object().shape({
  phone: Yup.string().required('fieldRequired').min(10, 'phoneMsg'),
  password: Yup.string().required('fieldRequired').min(8, 'passwordMsg'),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'PasswordsMustMatch')
    .required('fieldRequired'),
  isAccept: Yup.boolean().oneOf([true], 'fieldRequired').required('fieldRequired'),
})
export const ForgetSchema = Yup.object().shape({
  mobile: Yup.string().required('fieldRequired').min(5, 'phoneMsg'),
})

export const ResetSchema = Yup.object().shape({
  new_password: Yup.string().required('fieldRequired').min(6, 'passwordMsg'),
  new_password_confirmation: Yup.string()
    .oneOf([Yup.ref('new_password'), null], 'PasswordsMustMatch')
    .required('fieldRequired'),
})
export const PersonalInfoSchema = Yup.object().shape({
  name: Yup.string().required('fieldRequired'),
  country: Yup.string().required('fieldRequired'),
  email: Yup.string().email('invalidEmail').required('fieldRequired'),
  dateBirth: Yup.string()
    .required('fieldRequired')
    .test('age', 'You must be at least 18 years old', function (value) {
      const birthDate = dayjs(value, 'DD/MM/YYYY')
      if (!birthDate.isValid()) {
        return false
      }
      const today = dayjs()
      const age = today.diff(birthDate, 'year')
      return age >= 18
    }),
})
export const VereficationSchema = Yup.object().shape({
  a: Yup.string().required('fieldRequired'),
  b: Yup.string().required('fieldRequired'),
  c: Yup.string().required('fieldRequired'),
  d: Yup.string().required('fieldRequired'),
  e: Yup.string().required('fieldRequired'),
})
