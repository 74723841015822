import React from 'react'

export default function NoNetWorkIcon() {
  return (
    <svg
      width='161'
      height='162'
      viewBox='0 0 161 162'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M146.314 68.4316C145.062 61.1858 137.637 55.5502 125.65 52.5982C124.935 52.4193 124.219 52.5088 123.683 52.8666C122.52 51.0775 121.267 49.3779 119.925 47.8572C110.801 37.1227 97.2936 30.3242 82.1759 30.3242C54.803 30.3242 32.6184 52.5088 32.6184 79.8817C32.6184 84.6228 33.334 89.2744 34.5864 93.6576C36.8227 94.731 39.7747 95.715 43.6212 96.5201C54.803 98.8459 70.0101 98.667 85.1279 95.8939C109.817 91.4212 125.293 82.6548 131.733 76.3035C131.376 72.8148 130.749 69.505 129.765 66.1952C128.96 63.5116 127.976 60.9175 126.813 58.4128C135.133 60.828 140.321 64.8534 141.126 69.3261C141.573 71.8308 140.768 74.5145 138.8 77.377C131.554 87.7536 110.354 97.683 86.1119 102.066C50.867 108.328 25.1043 101.172 23.1363 90.4373C22.7785 88.4693 23.1363 86.5013 24.2097 84.4439C24.8359 83.1915 24.3886 81.5813 23.0468 80.8657C21.7945 80.2395 20.1843 80.6868 19.4687 82.0286C17.8585 85.1595 17.3218 88.2904 17.8585 91.4213C19.2898 99.2932 27.609 105.108 41.206 107.881C47.1994 109.133 53.9084 109.759 61.1542 109.759C69.384 109.759 78.1505 108.954 86.9169 107.434C112.948 102.782 134.954 92.2263 143.094 80.5079C145.957 76.393 147.03 72.3676 146.314 68.4316ZM61.1542 116.111C56.3237 116.111 51.7615 115.842 47.3783 115.305C56.3237 124.072 68.5789 129.529 82.1759 129.529C102.035 129.529 119.21 117.81 127.082 100.903C115.9 106.628 102.214 111.012 88.1693 113.516C78.9555 115.216 69.7418 116.111 61.1542 116.111Z'
        fill='url(#paint0_linear_1161_34111)'
      />
      <g filter='url(#filter0_f_1161_34111)'>
        <path
          d='M46.5215 39.0391L47.946 42.8889L51.7959 44.3135L47.946 45.738L46.5215 49.5879L45.0969 45.738L41.2471 44.3135L45.0969 42.8889L46.5215 39.0391Z'
          fill='#6774DA'
        />
      </g>
      <circle cx='24.5798' cy='119.844' r='2.00855' stroke='#6774DA' stroke-width='4' />
      <path
        d='M139.175 122.051L141.167 127.435L146.551 129.427L141.167 131.419L139.175 136.803L137.183 131.419L131.799 129.427L137.183 127.435L139.175 122.051Z'
        fill='#6774DA'
      />
      <g filter='url(#filter1_f_1161_34111)'>
        <circle cx='108.422' cy='45.7263' r='15.1306' fill='#FF4F75' />
      </g>
      <circle cx='108.422' cy='42.7009' r='15.1306' fill='#FF4F75' />
      <path
        d='M107.128 45.372L106.504 37.164L106.384 33.588H110.344L110.224 37.164L109.6 45.372H107.128ZM108.376 51.78C107.128 51.78 106.24 50.892 106.24 49.644C106.24 48.396 107.152 47.532 108.376 47.532C109.576 47.532 110.488 48.396 110.488 49.644C110.488 50.892 109.576 51.78 108.376 51.78Z'
        fill='white'
      />
      <circle cx='135.57' cy='98.826' r='2' stroke='#6774DA' stroke-width='3.54271' />
      <circle cx='81.9834' cy='83' r='79' fill='url(#paint1_linear_1161_34111)' />
      <circle cx='151.216' cy='48.1746' r='2.7283' stroke='#6774DA' stroke-width='4' />
      <g filter='url(#filter2_f_1161_34111)'>
        <circle cx='82.1404' cy='141.863' r='4.7283' fill='#6E7BDE' />
      </g>
      <g filter='url(#filter3_f_1161_34111)'>
        <path
          d='M49.7784 4.14453L52.1793 10.6328L58.6676 13.0337L52.1793 15.4346L49.7784 21.9229L47.3775 15.4346L40.8892 13.0337L47.3775 10.6328L49.7784 4.14453Z'
          fill='#6774DA'
        />
      </g>
      <path
        d='M8.06385 73.2726C10.2777 73.8658 11.0193 76.6332 9.39858 78.2539C7.77789 79.8746 5.01052 79.133 4.41731 76.9192C3.8241 74.7053 5.84995 72.6794 8.06385 73.2726Z'
        fill='#6471D8'
      />
      <defs>
        <filter
          id='filter0_f_1161_34111'
          x='37.2471'
          y='35.0391'
          width='18.5488'
          height='18.5488'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feGaussianBlur stdDeviation='2' result='effect1_foregroundBlur_1161_34111' />
        </filter>
        <filter
          id='filter1_f_1161_34111'
          x='63.291'
          y='0.595703'
          width='90.2612'
          height='90.2607'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feGaussianBlur stdDeviation='15' result='effect1_foregroundBlur_1161_34111' />
        </filter>
        <filter
          id='filter2_f_1161_34111'
          x='73.4121'
          y='133.135'
          width='17.4565'
          height='17.457'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feGaussianBlur stdDeviation='2' result='effect1_foregroundBlur_1161_34111' />
        </filter>
        <filter
          id='filter3_f_1161_34111'
          x='36.8892'
          y='0.144531'
          width='25.7783'
          height='25.7783'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feGaussianBlur stdDeviation='2' result='effect1_foregroundBlur_1161_34111' />
        </filter>
        <linearGradient
          id='paint0_linear_1161_34111'
          x1='82.0931'
          y1='30.3242'
          x2='82.0931'
          y2='129.529'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#DEE2FF' />
          <stop offset='1' stop-color='#5B69D7' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_1161_34111'
          x1='81.9834'
          y1='4'
          x2='81.9834'
          y2='162'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='white' stop-opacity='0' />
          <stop offset='1' stop-color='#D9DDFF' />
        </linearGradient>
      </defs>
    </svg>
  )
}
