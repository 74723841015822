import {Stack} from '@mui/material'
import React from 'react'
import Bank from '../../svg/Bank'
import TicketSvg from '../../svg/Ticket'
import Status from './Status'
import LabelWithValue from './LabelWithValue'
import MainBtn from '../../components/buttons/MainBtn'
import {useNavigate} from 'react-router-dom'

export default function Ticket({item}: any) {
  const navigate = useNavigate()
  return (
    <Stack
      p='24px'
      width={'262px'}
      height={'284px'}
      borderRadius={'8px'}
      flexShrink={0}
      bgcolor={'#FCFCFC'}
      gap='24px'
    >
      <Stack justifyContent={'space-between'} direction={'row'}>
        <TicketSvg />
        <Status text={item.status} />
      </Stack>
      <Stack gap={'24px'}>
        <LabelWithValue text='id' answer={item.id} />
        <LabelWithValue text='date' answer={item.date} />
        <LabelWithValue text='name' answer={item.name} />
      </Stack>
      <MainBtn
        p='10px 16px'
        handelSubmit={() => {
          navigate(`${item.id}`)
        }}
        width='140px'
        text='ticketDetails'
      />
    </Stack>
  )
}
