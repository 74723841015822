import React from 'react'

export default function Request({color, fillColor}: any) {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3.7423 20.5545C4.94192 22 7.17463 22 11.64 22H12.3612C16.8266 22 19.0593 22 20.259 20.5545M3.7423 20.5545C2.54268 19.1091 2.95414 16.9146 3.77706 12.5257C4.36228 9.40452 4.65489 7.84393 5.76579 6.92196M3.7423 20.5545C3.7423 20.5545 3.7423 20.5545 3.7423 20.5545ZM20.259 20.5545C21.4586 19.1091 21.0471 16.9146 20.2242 12.5257C19.639 9.40452 19.3464 7.84393 18.2355 6.92196M20.259 20.5545C20.259 20.5545 20.259 20.5545 20.259 20.5545ZM18.2355 6.92196C17.1246 6 15.5368 6 12.3612 6H11.64C8.46447 6 6.87669 6 5.76579 6.92196M18.2355 6.92196C18.2355 6.92196 18.2355 6.92196 18.2355 6.92196ZM5.76579 6.92196C5.76579 6.92196 5.76579 6.92196 5.76579 6.92196Z'
        stroke={color || fillColor || '#6D6D6D'}
        stroke-width='1.5'
      />
      <path
        d='M14.5303 12.5303C14.8232 12.2375 14.8232 11.7626 14.5303 11.4697C14.2374 11.1768 13.7625 11.1768 13.4697 11.4697L14.5303 12.5303ZM9.46967 15.4697C9.17678 15.7626 9.17678 16.2374 9.46967 16.5303C9.76256 16.8232 10.2374 16.8232 10.5303 16.5303L9.46967 15.4697ZM10.5303 11.4697C10.2374 11.1768 9.76255 11.1768 9.46965 11.4697C9.17676 11.7626 9.17676 12.2374 9.46965 12.5303L10.5303 11.4697ZM13.4696 16.5303C13.7625 16.8232 14.2374 16.8232 14.5303 16.5303C14.8232 16.2374 14.8232 15.7625 14.5303 15.4697L13.4696 16.5303ZM13.4697 11.4697L9.46967 15.4697L10.5303 16.5303L14.5303 12.5303L13.4697 11.4697ZM9.46965 12.5303L13.4696 16.5303L14.5303 15.4697L10.5303 11.4697L9.46965 12.5303Z'
        fill={color || fillColor || '#6D6D6D'}
      />
      <path
        d='M9 6V5C9 3.34315 10.3431 2 12 2C13.6569 2 15 3.34315 15 5V6'
        stroke={color || fillColor || '#6D6D6D'}
        stroke-width='1.5'
        stroke-linecap='round'
      />
    </svg>
  )
}
