import React, {useState, useEffect} from 'react'
import SunEditor from 'suneditor-react'
import 'suneditor/dist/css/suneditor.min.css'
import {RootState} from '../../store'
import {useSelector} from 'react-redux'

const SunEditorText = ({name, change, value, child}: any) => {
  const store = useSelector((state: RootState) => state.vender)

  const [languages, setlanguages] = useState(['en'])

  useEffect(() => {
    setlanguages(store.storeLangs)
  }, [store.storeLangs])
  useEffect(() => {
    if (!value[name][child]) {
      change({...value, [name]: {...value[name], [child]: ''}})
    }
  }, [value[name][child]])
  return (
    <>
      <SunEditor
        value={(value[name] && value[name][child]) || ''}
        onChange={(e: string) => {
          change({...value, [name]: {...value[name], [child]: e}})
        }}
        defaultValue={(value[name] && value[name][child]) || ' '}
        {...({name, change, value, child} as any)} // Cast the props to SunEditorReactProps
        setOptions={{
          buttonList: [
            ['undo', 'redo'],
            ['font', 'fontSize', 'formatBlock'],
            ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
            ['removeFormat'],
            ['outdent', 'indent'],
            ['align', 'horizontalRule', 'list', 'table'],
            ['link', 'image', 'video'],
            ['fullScreen', 'showBlocks', 'codeView'],
            ['preview', 'print'],
          ],
        }}
      />
    </>
  )
}
export default SunEditorText
