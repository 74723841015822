import {Typography, SxProps} from '@mui/material'

export default function LabelInput({text, sx}: {text: string; sx?: SxProps}) {
  return (
    <Typography
      sx={{
        fontSize: '16px',
        color: 'var(--bs-heading-color, inherit)',
        fontWeight: 400,
        lineHeight: '16px',
        textTransform: 'capitalize',
        ...sx,
      }}
    >
      {text}
    </Typography>
  )
}
