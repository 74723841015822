import {Stack} from '@mui/material'
import {CancelBtn, SaveBtn} from './styles'

export default function RestSaveButton({
  SaveText,
  cancelText,
  submit,
  cancel,
  isLoading,
  isReverse,
  hidecancel,
}: {
  SaveText: string
  cancelText: string
  submit: any
  cancel: any
  isLoading: boolean
  isReverse?: boolean
  hidecancel?: boolean
}) {
  return (
    <Stack
      mt={'20px'}
      gap='20px'
      width={'100%'}
      direction={isReverse ? 'row-reverse' : 'row'}
      justifyContent={isReverse ? 'flex-start' : 'flex-end'}
    >
      {!hidecancel && (
        <CancelBtn onClick={cancel} type='button' className='btn me-3'>
          {cancelText}
        </CancelBtn>
      )}
      <SaveBtn
        disabled={isLoading}
        type='submit'
        onClick={submit}
        // disabled={formik.isSubmitting || !formik.isValid || !formik.touched}

        className='btn btn-danger'
      >
        <span className='indicator-label'>{isLoading ? 'loading...' : SaveText}</span>
      </SaveBtn>
    </Stack>
  )
}
