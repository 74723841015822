import React, {useState} from 'react'

function ChartSelect({option, setDate, date}: any) {
  // إنشاء حالة لحفظ القيمة المختارة
  const [selectedValue, setSelectedValue] = useState('')

  // دالة لمعالجة تغيير القيمة المختارة
  const handleChange = (event: any) => {
    setDate(event.target.value)
  }

  return (
    <select
      style={{border: 'none', color: '#1E85FF', fontSize: '16px'}}
      id='example-select'
      value={date}
      onChange={handleChange}
    >
      {option.map((e: any) => (
        <option value={e}> {e}</option>
      ))}
    </select>
  )
}

export default ChartSelect
