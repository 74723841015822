import * as Yup from 'yup'
import moment from 'moment'

export const FilterDateSchema = Yup.object().shape({
  fromDate: Yup.string()
    .required('fieldRequired')
    .test('is-valid-date', 'Invalid date format', function (value) {
      return moment(value, 'DD/MM/YYYY', true).isValid()
    }),
  toDate: Yup.string()
    .required('fieldRequired')
    .test('is-valid-date', 'Invalid date format', function (value) {
      return moment(value, 'DD/MM/YYYY', true).isValid()
    })
    .test('is-after', 'fromDateMsg', function (value) {
      const {fromDate} = this.parent
      return moment(value, 'DD/MM/YYYY').isAfter(moment(fromDate, 'DD/MM/YYYY'))
    }),
})
