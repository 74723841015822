import {configureStore} from '@reduxjs/toolkit'
import {
  TypedUseSelectorHook,
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from 'react-redux'
import VendorReducer from './admin/auth.slice'

// Redux store
const store = configureStore({
  reducer: {
    vender: VendorReducer,
  },
})

// Inferred types
export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>

// Custom redux hooks
export const useDispatch = () => useReduxDispatch<AppDispatch>()
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector

export default store
