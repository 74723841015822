export function TransferToUserObj(data: any) {
  return {
    amount: data.amount,
    recipient_name: data.recipientsName,
    account_number: data.recipientsAccountNumber,
    message: data.content,
  }
}

export function TransferToNonUserObj(data: any) {
  return {
    amount: data.amount,
    recipient_name: data.recipientsName,
    recipient_email: data.email,
    mobile: data.phone,
    message: data.content,
    country_code_mobile: data.code,
  }
}
export function RequestMoneyObj(data: any) {
  return {
    amount: data.amount,
    payer_name: data.payerFullName,
    payer_mobile: data.phone,
    note: data.content,
    country_code_mobile: data.code,
  }
}
export function VereficationObj(data: any) {
  return {
    code: data['a'] + data['b'] + data['c'] + data['d'] + data['e'],
    recipient_name: data.recipientsName,
    account_number: data.recipientsAccountNumber,
    message: data.content,
    amount: data.amount,
  }
}
export function VereficationToNonUserObj(data: any) {
  return {
    code: data['a'] + data['b'] + data['c'] + data['d'] + data['e'],
    recipient_name: data.recipientsName,
    mobile: data.phone,
    recipient_email: data.email,
    country_code_mobile: data.code,
    message: data.content,
    amount: data.amount,
  }
}
