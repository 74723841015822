import React from 'react'

export default function Arrow({color}: any) {
  return (
    <svg
      width='19'
      height='19'
      viewBox='0 0 19 19'
      fill={'none'}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.1334 7.5036L13.5429 7.91312L9.5 11.956L5.45711 7.91312L5.86664 7.5036L9.14683 10.7766L9.5 11.129L9.85317 10.7766L13.1334 7.5036Z'
        fill='white'
        stroke={color || '#060606'}
      />
    </svg>
  )
}
