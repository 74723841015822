import {Stack} from '@mui/material'
import React, {useState} from 'react'
import Language from './Language'
import Get from '../../../../fubcations/mastars/Get'
import LanguagesObj from '../../../../modules/modules/get/LanguagesModule'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../store'

export default function Languages({SelectLang, setSelectLang}: any) {
  const store = useSelector((state: RootState) => state.vender)

  return (
    <Stack gap='24px'>
      {store.langs.map((e: any) => (
        <Language setSelectLang={setSelectLang} SelectLang={SelectLang} item={LanguagesObj(e)} />
      ))}
    </Stack>
  )
}
