import React from 'react'

export default function Nile() {
  return (
    <svg
      width='120'
      height='120'
      viewBox='0 0 120 120'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='120' height='120' fill='white' />
      <circle opacity='0.15' cx='60' cy='60' r='60' fill='#467DE1' />
      <circle opacity='0.2' cx='60' cy='60' r='45' fill='#467DE1' />
      <circle cx='60' cy='60' r='30' fill='#467DE1' />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M60 48C53.3726 48 48 53.3726 48 60C48 66.6274 53.3726 72 60 72C66.6274 72 72 66.6274 72 60C72 53.3726 66.6274 48 60 48ZM45 60C45 51.7157 51.7157 45 60 45C68.2843 45 75 51.7157 75 60C75 68.2843 68.2843 75 60 75C51.7157 75 45 68.2843 45 60ZM60 52.5C60.8284 52.5 61.5 53.1716 61.5 54V60C61.5 60.8284 60.8284 61.5 60 61.5C59.1716 61.5 58.5 60.8284 58.5 60V54C58.5 53.1716 59.1716 52.5 60 52.5Z'
        fill='white'
      />
      <path
        d='M61.5 66C61.5 66.8284 60.8284 67.5 60 67.5C59.1716 67.5 58.5 66.8284 58.5 66C58.5 65.1716 59.1716 64.5 60 64.5C60.8284 64.5 61.5 65.1716 61.5 66Z'
        fill='white'
      />
    </svg>
  )
}
