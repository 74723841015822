import React from 'react'

export default function Bank() {
  return (
    <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='20' cy='20' r='20' fill='white' />
      <path
        d='M13 17V25M17.5 17V25M22.5 17V25M27 17V25M11 26.6L11 27.4C11 27.9601 11 28.2401 11.109 28.454C11.2049 28.6422 11.3578 28.7951 11.546 28.891C11.7599 29 12.0399 29 12.6 29H27.4C27.9601 29 28.2401 29 28.454 28.891C28.6422 28.7951 28.7951 28.6422 28.891 28.454C29 28.2401 29 27.9601 29 27.4V26.6C29 26.04 29 25.7599 28.891 25.546C28.7951 25.3579 28.6422 25.2049 28.454 25.109C28.2401 25 27.9601 25 27.4 25H12.6C12.0399 25 11.7599 25 11.546 25.109C11.3578 25.2049 11.2049 25.3579 11.109 25.546C11 25.7599 11 26.04 11 26.6ZM19.6529 11.0771L12.2529 12.7216C11.8059 12.8209 11.5823 12.8706 11.4155 12.9908C11.2683 13.0968 11.1527 13.2409 11.0811 13.4076C11 13.5965 11 13.8255 11 14.2835L11 15.4C11 15.9601 11 16.2401 11.109 16.454C11.2049 16.6422 11.3578 16.7951 11.546 16.891C11.7599 17 12.0399 17 12.6 17H27.4C27.9601 17 28.2401 17 28.454 16.891C28.6422 16.7951 28.7951 16.6422 28.891 16.454C29 16.2401 29 15.9601 29 15.4V14.2835C29 13.8255 29 13.5965 28.9188 13.4076C28.8473 13.2409 28.7317 13.0968 28.5845 12.9908C28.4177 12.8706 28.1942 12.8209 27.7471 12.7216L20.3471 11.0771C20.2176 11.0484 20.1528 11.034 20.0874 11.0282C20.0292 11.0231 19.9708 11.0231 19.9126 11.0282C19.8472 11.034 19.7824 11.0484 19.6529 11.0771Z'
        stroke='#B47AFF'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}
