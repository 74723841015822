import {Stack} from '@mui/system'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert, {AlertProps} from '@mui/material/Alert'
import {RootState} from '../../store'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})
export default function CopyBtn({text}: any) {
  const [open, setOpen] = React.useState(false)
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  const {t} = useTranslation()
  const store = useSelector((state: RootState) => state.vender)
  const copyLink = () => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setOpen(true)
      })
      .catch(() => {})
  }
  return (
    <Stack justifyContent={'flex-end'} flexWrap={'wrap'} gap='10px' direction={'row'}>
      <Stack gap='2px' direction={'row'}>
        <button
          style={{
            border: 'none',
            background: '#467DE11A',
            padding: '8px 20px',
            color: '#467DE1',
            borderRadius: '8px',
            textTransform: 'capitalize',
          }}
          onClick={copyLink}
        >
          {t('copy')}
        </button>
      </Stack>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity='success' sx={{width: '100%'}}>
          {t('msgSuccessfully')}
        </Alert>
      </Snackbar>
    </Stack>
  )
}
