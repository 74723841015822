import {Stack, Typography} from '@mui/material'
import React, {useState} from 'react'
import Items from './Items'
import GoBack from '../../../parts/GoBack'
import DropDawn from './DropDawn'
import Item from './Item'

export default function SideBar({contant, setContant, SelectLang}: any) {
  const [open, setOpen] = useState(false)
  return (
    <Stack>
      <Stack display={{md: 'none'}} p='24px'>
        <GoBack text='goBack' />
      </Stack>{' '}
      <Stack
        borderRadius={'8px'}
        border={' 1px solid #E8E8E8'}
        width={{xs: '100%', md: '361px'}}
        // height={{xs: 'auto', md: '691px'}}
      >
        <Stack display={{xs: 'none', md: 'block'}} p='24px'>
          <GoBack text='goBack' />
        </Stack>{' '}
        <Stack display={{xs: 'none', md: 'block'}}>
          <Items SelectLang={SelectLang} contant={contant} setContant={setContant} />
        </Stack>
        <Stack display={{xs: 'block', md: 'none'}}>
          <DropDawn
            selectItems={
              <Items SelectLang={SelectLang} contant={contant} setContant={setContant} />
            }
            open={open}
            setOpen={setOpen}
            btn={
              <Stack
                onClick={() => {
                  setOpen(true)
                }}
              >
                {' '}
                <Item
                  isSelected
                  open={open}
                  item={{name: contant}}
                  contant={contant}
                  setContant={setContant}
                />
              </Stack>
            }
          />{' '}
        </Stack>
      </Stack>
    </Stack>
  )
}
