/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import {useEffect, useState} from 'react'

import {useLayout} from '../../core'
import {Navbar} from './Navbar'
import {Stack} from '@mui/material'
import Logo from '../../../../app/svg/Logo'
import MainItems from './MainItems'
import DrawerComponent from '../../DrowerSideBar'
export function HeaderWrapper({activeMenu}: any) {
  const {config, classes} = useLayout()
  if (!config.app?.header?.display) {
    return null
  }
  const image = localStorage.getItem('image')
  const links = [
    {name: 'home', to: '/dashboard'},
    {name: 'wallet', to: '/wallet'},
    {name: 'transactions', to: '/transactions'},
    {name: 'purchases', to: '/purchases'},
    {name: 'tickets', to: '/ticket'},
  ]
  return (
    <Stack
      m='auto'
      width={'100%'}
      maxWidth={'1170px'}
      p={{xs: '30px 10px 0 10px', lg: '32px 0 0 0'}}
    >
      <Stack
        alignItems={'center'}
        direction={'row'}
        justifyContent={'space-between'}
        borderBottom={'1px solid #E7E7E7'}
        pb='20px'
      >
        <Stack display={{xs: 'block', lg: 'none'}}>
          <DrawerComponent />
        </Stack>
        <Stack
          display={{xs: 'none', lg: 'block'}}
          position={'sticky'}
          top={'0'}
          height={'50px'}
          width={{sm: '120px ', xs: '80px'}}
        >
          <Logo width='82.3px' />
        </Stack>

        <Stack width={'100%'} display={{xs: 'none', lg: 'block'}}>
          <MainItems links={links} />
        </Stack>
        <Navbar />
      </Stack>
    </Stack>
  )
}
