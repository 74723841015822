import React, {useEffect} from 'react'
import JwtService from '../../../utils/TokenServices'
import {useDispatch} from 'react-redux'
import {actions} from '../../../store/admin/auth.slice'
import {useNavigate} from 'react-router-dom'

export default function Error401() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  useEffect(() => {
    navigate('/')

    JwtService.destroyToken()
    localStorage.clear()
    dispatch(actions.login(null))
  }, [])
  return (
    <>
      <img
        style={{width: '500px', height: '600px', margin: 'auto'}}
        src={'./media/avatars/403.png'}
      />
    </>
  )
}
