import React, {useState} from 'react'

function DateInput({
  date,
  setDate,
}: {
  date: string | undefined
  setDate: React.Dispatch<React.SetStateAction<string | undefined>>
}) {
  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDate(event.target.value)
  }

  return (
    <>
      <input
        type='date'
        id='dateInput'
        name='dateInput'
        value={date}
        onChange={handleDateChange}
        style={{
          padding: '12.0625px 13px 12.5px',
          background: 'var(--bs-body-bg)',
          border: '1px solid var(--bs-gray-300)',
          borderRadius: '6.18px',
          width: '100%',
          color: 'var(--bs-text-dark)',
        }}
      />
    </>
  )
}

export default DateInput
