import {Stack, Typography} from '@mui/material'
import React, {useState} from 'react'
import Nike from '../../../../svg/Nike'
import NikeIcon from '../../../../svg/NikeIcon'
import {NotifySchema} from '../../../../yup/SettingSchema'
import Post from '../../../../fubcations/mastars/Post'
import {Active2FAObj, LangObj} from '../../../../modules/modules/post/SettingsModule'
import {useDispatch} from 'react-redux'
import {actions} from '../../../../store/admin/auth.slice'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../store'
import {useTranslation} from 'react-i18next'

export default function Language({item, SelectLang, setSelectLang}: any) {
  const [errors, setErrors] = useState({})
  const store = useSelector((state: RootState) => state.vender)
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const {i18n} = useTranslation()

  return (
    <Stack
      justifyContent={'space-between'}
      onClick={() => {
        Post(
          NotifySchema,
          {language_id: item.id},
          LangObj,
          setErrors,
          () => {
            setSelectLang(item)
            i18n.changeLanguage(item.code)

            dispatch(
              actions.login({...store.user, language_code: item.code, language_name: item.name})
            )
          },
          setIsLoading,
          'profile/change-language'
        )
      }}
      sx={{
        width: {xs: 'auto', sm: '453px'},

        color: SelectLang && item.code === store.user.language_code ? '#fff' : '#19224C',
        background:
          SelectLang && item.code === store.user.language_code
            ? 'linear-gradient(180deg, #6775E2 -46.29%, #44B9EA 79.49%)'
            : '#F7F7F7',
        cursor: 'pointer',
        '&:hover': {
          color:
            SelectLang && item.code === store.user.language_code ? '#fff' : 'var(--link-color)',
          border: '1px solid var(--link-color)',
        },
      }}
      p='16px'
      borderRadius={'16px'}
      direction={'row'}
    >
      <Stack alignItems={'center'} direction={'row'} gap='10px'>
        <img style={{width: '24px', height: '24px', borderRadius: '100%'}} src={item.flag} alt='' />
        <Typography sx={{fontSize: '16px', fontWeight: '400', lineHeight: '24px'}}>
          {item.name}
        </Typography>
      </Stack>
      {SelectLang && item.code === store.user.language_code && <NikeIcon />}
    </Stack>
  )
}
