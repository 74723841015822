import {Stack, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import Status from '../Status'

export default function LabelWithValue({text, answer, isStatus}: any) {
  const {t} = useTranslation()
  return (
    <Stack gap='5px' alignItems={'center'} direction={'row'}>
      <Typography
        sx={{
          color: '#636B6F',
          fontSize: '16px',
          fontWeight: '400',
          textTransform: 'capitalize',
          lineHeight: '15px',
        }}
      >
        {t(text)}
      </Typography>
      :{isStatus && <Status text={t(answer)} />}
      {!isStatus && (
        <Typography
          sx={{
            color: '#A5A5A5',
            fontSize: '16px',
            fontWeight: '400',
            textTransform: 'capitalize',
            lineHeight: '15px',
            letterSpacing: '-0.5px',
          }}
        >
          {t(answer)}
        </Typography>
      )}
    </Stack>
  )
}
