import {IconButton, Stack} from '@mui/material'
import React from 'react'
import {FilterBtn} from '../modules/customTable/styles'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import PopUp from './PopUp'
import FilterIcon from '../svg/FilterIcon'
import FilterForm from './FilterForm'

export default function Filter({open, setOpen, data, setData, filterRows, setFilterRows}: any) {
  return (
    <Stack>
      <Stack
        sx={{cursor: 'pointer'}}
        onClick={() => {
          setOpen(true)
        }}
      >
        <FilterIcon />
      </Stack>
      <PopUp open={open} setOpen={setOpen}>
        <FilterForm
          msgErr=''
          setOpen={setOpen}
          filterRows={filterRows}
          setFilterRows={setFilterRows}
          data={data}
          setData={setData}
        />
      </PopUp>
    </Stack>
  )
}
