import {Stack} from '@mui/material'
import React from 'react'
import NoTicket from '../../svg/NoTicket'
import Label from '../../components/texts/Label'

export default function EmptyTickets() {
  return (
    <Stack gap='35px' mt='32px' width={'100%'} alignItems={'center'}>
      <NoTicket />
      <Stack gap='4px' alignItems={'center'}>
        <Label fs='18px' fw='600' lineHeight='38px' text='noTicketsFound' />
        <Label fs='18px' fw='400' lineHeight='38px' text='noTicketMsg' />
      </Stack>
    </Stack>
  )
}
