import React, {useEffect, useState} from 'react'
import {Stack, Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'
import TitlePage from '../../../modules/customTable/TitlePage'
import {useNavigate, useParams} from 'react-router-dom'
import GoBack from '../../../parts/GoBack'
import Bank from '../../../svg/Bank'
import LabelWithValue from './LabelWithValue'
import Chat from './Chat'
import TicketSvg from '../../../svg/Ticket'
import Get from '../../../fubcations/mastars/Get'
import Loading from '../../../modules/customTable/LoadingForm'

export default function TicketDetails() {
  const {t} = useTranslation()
  const {id} = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const bannerInfo = {
    title: t('ticketDetails'),
    home: t('home'),
    linkHome: '/',
  }
  const navigate = useNavigate()
  const [data, setData] = useState<any>({messages: []})
  Get([], setData, `tickets/${id}/detials`, setIsLoading)
  const [messages, setMassages] = useState([])
  useEffect(() => {
    setMassages(data.messages)
  }, [data])
  return (
    <Stack gap={'32px'}>
      <TitlePage info={bannerInfo} />
      <Stack
        // gap={'60px'}
        direction={{sm: 'row', xs: 'column'}}
        p={{xs: '0 30px'}}
        minHeight={'429px'}
      >
        <Stack position={'relative'} height={'100%'} minWidth={'250px'} gap='32px'>
          <GoBack onClick={() => navigate('/ticket')} text='goBack' />
          {!isLoading ? (
            <Stack direction={{xs: 'row', sm: 'column'}} gap='29px'>
              <TicketSvg />
              <Stack flexWrap={'wrap'} direction={{xs: 'row', sm: 'column'}} gap='27px'>
                <LabelWithValue
                  text='id'
                  answer={`${data.info_data_ticket && data.info_data_ticket.code}`}
                />
                <LabelWithValue
                  text='date'
                  answer={data.info_data_ticket && data.info_data_ticket.date}
                />
                {/* <LabelWithValue color='#666666' text='name' answer='Kakashi' /> */}
                <LabelWithValue
                  isStatus
                  text='status'
                  answer={data.info_data_ticket && data.info_data_ticket.status}
                />
              </Stack>
            </Stack>
          ) : (
            <Loading />
          )}
        </Stack>
        <Stack width={'100%'} direction={'row'} gap='60px'>
          <Stack height={'100%'} width={{xs: '0', sm: '1px'}} bgcolor={'#C3D3D7'}></Stack>
          <Stack width={'100%'}>
            <Chat isLoadingMassages={isLoading} id={id} setData={setMassages} data={messages} />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}
