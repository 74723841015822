import React from 'react'

export default function Copy() {
  return (
    <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='40' height='40' rx='4' fill='#467DE1' fill-opacity='0.1' />
      <path
        d='M24 20.9V25.1C24 28.6 22.6 30 19.1 30H14.9C11.4 30 10 28.6 10 25.1V20.9C10 17.4 11.4 16 14.9 16H19.1C22.6 16 24 17.4 24 20.9Z'
        stroke='#467DE1'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M30 14.9V19.1C30 22.6 28.6 24 25.1 24H24V20.9C24 17.4 22.6 16 19.1 16H16V14.9C16 11.4 17.4 10 20.9 10H25.1C28.6 10 30 11.4 30 14.9Z'
        stroke='#467DE1'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}
