import React from 'react'

import {useMemo} from 'react'
import {useSelector} from 'react-redux'
import {RootState} from '../../store'
import Arrow from '../../svg/Arrow'
import {Stack} from '@mui/material'
interface TablePaginationProps {
  rowsPerPageOptions: number[]
  count: number
  rowsPerPage: number
  page: number
  onPageChange: (newPage: number) => void
  onRowsPerPageChange: (newPage: number) => void
}

export const PaginationSection = ({
  rowsPerPageOptions,
  count,
  rowsPerPage,
  page,
  onPageChange,
  onRowsPerPageChange,
}: TablePaginationProps) => {
  // on click of previous button
  const handlePrevious = () => {
    if (page > 1 && page <= count) {
      onPageChange(page - 1)
    }
    return
  }

  const handleNextPage = () => {
    if (page < count / rowsPerPage) {
      onPageChange(page + 1)
    }
  }

  const handleExactNumber = (number: any) => {
    onPageChange(number)

    // const pageNumber = number - 1;
    // if (pageNumber !== page) {
    //      onPageChange(pageNumber);
    // }
  }

  //  HANDLE SELECT SIZE
  const handleSize = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = parseInt(e.target.value)
    onRowsPerPageChange(value)
    onPageChange(1)
  }

  const paginationNumbers = useMemo(() => {
    let start = Math.max(page - 2, 1)
    const maxPage = Math.ceil(count / rowsPerPage)
    let end = Math.min(start + 4, maxPage)

    if (maxPage > 5 && end === maxPage) {
      start = Math.max(end - 4, 1)
    }

    const result = []

    if (start > 1) {
      result.push(1)
      if (start > 2) {
        result.push('...')
      }
    }

    for (let i = start; i <= end; i++) {
      result.push(i)
    }

    if (end < maxPage) {
      if (end < maxPage - 1) {
        result.push('...')
      }
      result.push(maxPage)
    }

    return result
  }, [page, count, rowsPerPage])

  const store = useSelector((state: RootState) => state.vender)

  return (
    <div className='d-flex flex-stack flex-wrap pt-6 gap-5'>
      <div className='fs-6 fw-bold text-danger-700'>
        <div className='me-4'>
          <select
            name='rowsPerPage'
            data-control='select2'
            data-placeholder='Select a option'
            onChange={handleSize}
            value={rowsPerPage}
            data-hide-search='true'
            className='form-select form-select-sm form-select-white w-125px form-select form-select-solid'
          >
            {rowsPerPageOptions.map((size) => {
              return (
                <option value={size} key={size}>
                  {size}
                </option>
              )
            })}
          </select>
        </div>
      </div>

      <ul className='pagination'>
        {page !== 1 && (
          <li className='page-item previous'>
            <button
              style={{background: '#467DE1', height: '40px', width: '40px', padding: '10px'}}
              className='page-link'
              onClick={handlePrevious}
            >
              <Stack
                sx={{
                  transform: store.isRtl === 0 ? 'rotate(90deg)' : 'rotate(270deg)',
                  color: '#fff',
                }}
              >
                <Arrow color='#fff' />
              </Stack>
              {/* <i className='next'></i> */}
            </button>
            {/* <button className='page-link' onClick={handlePrevious}>
            <i
              style={{transform: store.isRtl === 0 ? '' : 'rotate(180deg)'}}
              className='previous'
            ></i>
          </button> */}
          </li>
        )}

        {paginationNumbers.map((number) => {
          return (
            <li
              className={`page-item ${
                (page && number === page) || (!page && number === 1) ? 'active' : ''
              }`}
              key={number}
              onClick={() => {
                if (number !== '...') {
                  handleExactNumber(number)
                }
              }}
            >
              <button
                style={{height: '40px', width: '40px', padding: '10px'}}
                className='page-link'
              >
                {number}
              </button>
            </li>
          )
        })}
        {paginationNumbers.length > 1 && (
          <li className='page-item next'>
            <button
              style={{background: '#467DE1', height: '40px', width: '40px', padding: '10px'}}
              className='page-link'
              onClick={handleNextPage}
            >
              <Stack
                sx={{
                  transform: store.isRtl === 0 ? 'rotate(270deg)' : 'rotate(90deg)',
                  color: '#fff',
                }}
              >
                <Arrow color='#fff' />
              </Stack>
              {/* <i className='next'></i> */}
            </button>
          </li>
        )}
      </ul>
    </div>
  )
}
