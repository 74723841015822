import {Stack} from '@mui/material'
import React, {useState} from 'react'
import Item from './Item'
import Post from '../../../fubcations/mastars/Post'
import {NotifySchema} from '../../../yup/SettingSchema'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import {useDispatch} from 'react-redux'
import {actions} from '../../../store/admin/auth.slice'
import {Active2FAObj, NotifyObj} from '../../../modules/modules/post/SettingsModule'

export default function Items({contant, setContant, SelectLang}: any) {
  const store = useSelector((state: RootState) => state.vender)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingAth, setIsLoadingAth] = useState(false)
  const dispatch = useDispatch()
  const [errors, setErrors] = useState({})

  const items =
    store.user && store.user.type === 'Vendor'
      ? [
          {
            name: 'businessAccountDetails',
            onClick: () => {
              setContant('businessAccountDetails')
            },
          },
          {
            name: 'changePassword',
            onClick: () => {
              setContant('changePassword')
            },
          },
          {
            name: 'notifications',
            onClick: () => {
              Post(
                NotifySchema,
                {is_notify: store.user.is_notify ? 0 : 1},
                NotifyObj,
                setErrors,
                () => {
                  dispatch(actions.login({...store.user, is_notify: !store.user.is_notify}))
                },
                setIsLoading,
                'profile/active-notification'
              )
            },
          },
          {
            name: 'twoFactorAuthentication',
            onClick: () => {
              Post(
                NotifySchema,
                {active_2FA: store.user.is_2FA ? 0 : 1},
                Active2FAObj,
                setErrors,
                () => {
                  dispatch(actions.login({...store.user, is_2FA: !store.user.is_2FA}))
                },
                setIsLoadingAth,
                'profile/change/2FA'
              )
            },
          },
          {
            name: 'language',
            onClick: () => {
              setContant('language')
            },
          },
          {
            name: 'savedWithdrawMethods',
            onClick: () => {
              setContant('savedWithdrawMethods')
            },
          },
          {
            name: 'aboutUs',
            onClick: () => {
              setContant('aboutUs')
            },
          },
          {
            name: 'privacy',
            onClick: () => {
              setContant('privacy')
            },
          },
          {
            name: 'contactUs',
            onClick: () => {
              setContant('contactUs')
            },
          },
          {
            name: 'keys',
            onClick: () => {
              setContant('keys')
            },
          },
          {
            name: 'feq',
            onClick: () => {
              setContant('feq')
            },
          },
        ]
      : [
          {
            name: 'convertToBusiness',
            onClick: () => {
              setContant('convertToBusiness')
            },
          },
          {
            name: 'changePassword',
            onClick: () => {
              setContant('changePassword')
            },
          },
          {
            name: 'notifications',
            onClick: () => {
              Post(
                NotifySchema,
                {is_notify: store.user.is_notify ? 0 : 1},
                NotifyObj,
                setErrors,
                () => {
                  dispatch(actions.login({...store.user, is_notify: !store.user.is_notify}))
                },
                setIsLoading,
                'profile/active-notification'
              )
            },
          },
          {
            name: 'twoFactorAuthentication',
            onClick: () => {
              Post(
                NotifySchema,
                {active_2FA: store.user.is_2FA ? 0 : 1},
                Active2FAObj,
                setErrors,
                () => {
                  dispatch(actions.login({...store.user, is_2FA: !store.user.is_2FA}))
                },
                setIsLoadingAth,
                'profile/change/2FA'
              )
            },
          },
          {
            name: 'language',
            onClick: () => {
              setContant('language')
            },
          },

          {
            name: 'aboutUs',
            onClick: () => {
              setContant('aboutUs')
            },
          },
          {
            name: 'privacy',
            onClick: () => {
              setContant('privacy')
            },
          },
          {
            name: 'contactUs',
            onClick: () => {
              setContant('contactUs')
            },
          },
          {
            name: 'keys',
            onClick: () => {
              setContant('keys')
            },
          },
          {
            name: 'feq',
            onClick: () => {
              setContant('feq')
            },
          },
        ]

  return (
    <Stack gap='8px'>
      {items.map((e: any) => (
        <Item
          SelectLang={SelectLang}
          isSelected={false}
          item={e}
          contant={contant}
          setContant={setContant}
          isLoading={isLoading}
          isLoadingAth={isLoadingAth}
        />
      ))}
    </Stack>
  )
}
