import React from 'react'

export default function ReKYC() {
  return (
    <svg width='64' height='48' viewBox='0 0 64 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='0.5' y='0.5' width='63' height='47' rx='3.5' fill='white' />
      <rect x='0.5' y='0.5' width='63' height='47' rx='3.5' stroke='#048FD9' />
      <path
        d='M30.0733 19.3863C30.6533 19.2129 31.2933 19.0996 32 19.0996C35.1933 19.0996 37.78 21.6863 37.78 24.8796C37.78 28.0729 35.1933 30.6596 32 30.6596C28.8066 30.6596 26.22 28.0729 26.22 24.8796C26.22 23.6929 26.58 22.5863 27.1933 21.6663'
        stroke='#048FD9'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M29.2466 19.5473L31.1732 17.334'
        stroke='#048FD9'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M29.2466 19.5469L31.4932 21.1869'
        stroke='#048FD9'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}
