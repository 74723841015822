import React from 'react'

export default function Upload() {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clip-path='url(#clip0_1_33250)'>
        <path
          d='M15.5416 7.81596C15.2869 7.81596 15.0832 8.01969 15.0832 8.27436V12.4441C15.0832 13.5783 14.1596 14.4985 13.0289 14.4985H2.97114C1.83701 14.4985 0.916808 13.5749 0.916808 12.4441V8.20645C0.916808 7.95178 0.713073 7.74805 0.458404 7.74805C0.203735 7.74805 0 7.95178 0 8.20645V12.4441C0 14.0842 1.33447 15.4153 2.97114 15.4153H13.0289C14.6689 15.4153 16 14.0808 16 12.4441V8.27436C16 8.02309 15.7963 7.81596 15.5416 7.81596Z'
          fill='black'
        />
        <path
          d='M5.40929 4.28373L7.54171 2.1513V11.4518C7.54171 11.7065 7.74545 11.9102 8.00011 11.9102C8.25478 11.9102 8.45852 11.7065 8.45852 11.4518V2.1513L10.5909 4.28373C10.6792 4.37202 10.7981 4.41956 10.9135 4.41956C11.0324 4.41956 11.1478 4.37541 11.2361 4.28373C11.4161 4.10377 11.4161 3.81514 11.2361 3.63518L8.32269 0.721761C8.2378 0.636871 8.11896 0.585938 8.00011 0.585938C7.87787 0.585938 7.76242 0.633476 7.67753 0.721761L4.76412 3.63518C4.58416 3.81514 4.58416 4.10377 4.76412 4.28373C4.94069 4.4603 5.23271 4.4603 5.40929 4.28373Z'
          fill='black'
        />
      </g>
      <defs>
        <clipPath id='clip0_1_33250'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
