import React from 'react'
import * as Yup from 'yup'
import Apiservices from '../../../lib/ApiServices'

export default async function Put(
  schema: any,
  data: any,
  ModuleObj: any,
  setErrors: any,
  successFun?: any,
  setLoading?: any,
  path?: any
) {
  try {
    setErrors({})

    await schema.validate(data, {abortEarly: false})

    let Module = ModuleObj(data)

    if (data.isFormData) {
      const formData = new FormData()
      for (const key in Module) {
        if (Module[key]) {
          formData.append(key, Module[key])
        }
      }

      Module = formData
    }
    let fetchData = null
    if (path) {
      setLoading(true)

      fetchData = await Apiservices.put(path, Module)

      setLoading(false)
    }

    setErrors({})
    if (successFun) {
      successFun(fetchData)
    }
    return {valid: true, Module: Module}
  } catch (err) {
    if (setLoading) {
      setLoading(false)
    }

    const errors: {[key: string]: string} = {}
    if (err instanceof Yup.ValidationError && err.inner) {
      err.inner.forEach((error) => {
        if (error.path) {
          errors[error.path] = error.message
        }
      })
    }

    setErrors(errors)

    return {valid: false, errors}
  }
}
