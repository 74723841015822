import React from 'react'

export default function GoBackArrow() {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.33333 11.3337L4 8.00032M4 8.00032L7.33333 4.66699M4 8.00032L12 8.00033'
        stroke='#467DE1'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}
