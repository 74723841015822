/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC, useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../helpers'
import {useLang, setLanguage} from '../../../i18n/Metronici18n'
import {useTranslation} from 'react-i18next'
import {useDispatch} from 'react-redux'
import {actions} from '../../../../app/store/admin/auth.slice'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../app/store'
import {Box, Stack} from '@mui/material'
import Get from '../../../../app/fubcations/mastars/Get'
import {NotifySchema} from '../../../../app/yup/SettingSchema'
import {LangObj} from '../../../../app/modules/modules/post/SettingsModule'
import Post from '../../../../app/fubcations/mastars/Post'
// const languages = [
//   {
//     id: 1,
//     lang: 'en',
//     name: 'English',
//     flag: toAbsoluteUrl('/media/flags/united-states.svg'),
//     isRtl: 0,
//   },
//   {
//     id: 2,
//     lang: 'ar',
//     name: 'العربيه',
//     flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/f6/Flag_of_Iraq.svg/220px-Flag_of_Iraq.svg.png',
//     isRtl: 1,
//   },
// ]

const Languages: FC = () => {
  const store = useSelector((state: RootState) => state.vender)

  const lang = useLang()
  const currentLanguage = store.langs.find((x: any) => x.lang === lang)
  const {i18n} = useTranslation()
  const [islang, setIsLang] = useState<any>({
    code: (store.user && store.user.language_code) || 'en',
    name: (store.user && store.user.language_name) || 'English',
    flag: toAbsoluteUrl('/media/flags/united-states.svg'),
  })
  const dispatch = useDispatch()

  const {t} = useTranslation()
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)

  return (
    <div
      className='menu-item px-5'
      data-kt-menu-trigger='hover'
      data-kt-menu-placement='left-start'
      data-kt-menu-flip='bottom'
    >
      <a className='menu-link px-5'>
        <span className='menu-title position-relative'>
          {t('language')}
          <span className='fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0'>
            {store.user && store.user.language_name}
            <img className='w-15px h-15px rounded-1 ms-2' src={islang.flag} alt='metronic' />
          </span>
        </span>
      </a>

      <Box
        className='menu-sub menu-sub-dropdown w-175px py-4'
        data-kt-menu-placement='center-start'
        style={{
          background: '#fff',
          boxShadow: 'rgba(0, 0, 0, 0.24) rgba(0, 0, 0, 0.24) -3px 1px 3px 1px',
        }}
      >
        {store.langs.map((l: any) => (
          <div
            className='menu-item px-3'
            key={l.lang}
            onClick={() => {
              Post(
                NotifySchema,
                {language_id: l.id},
                LangObj,
                setErrors,
                () => {
                  setIsLang(l)
                  dispatch(actions.login({...store.user, language_code: l.code}))
                  i18n.changeLanguage(l.code)
                  dispatch(actions.setRtl(l.is_rtl ? 1 : 0))
                },
                setLoading,
                'profile/change-language'
              )
            }}
          >
            <a className={clsx('menu-link d-flex px-5', {active: l.code === islang.code})}>
              <span className='symbol symbol-20px me-4'>
                <img className='rounded-1' src={l.flag || l.image} alt='metronic' />
              </span>
              {l.name}
            </a>
          </div>
        ))}
      </Box>
    </div>
  )
}

export {Languages}
