import React from 'react'

export default function Deposite() {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.6668 9.33398V13.334M12.6668 13.334L14.0002 12.0007M12.6668 13.334L11.3335 12.0007'
        stroke='#467DE1'
        stroke-width='1.25'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M14.6668 7.99935C14.6668 5.48519 14.6668 4.22811 13.8858 3.44706C13.1047 2.66602 11.8477 2.66602 9.3335 2.66602M9.3335 13.3327H6.66683C4.15267 13.3327 2.89559 13.3327 2.11454 12.5516C1.3335 11.7706 1.3335 10.5135 1.3335 7.99935C1.3335 5.48519 1.3335 4.22811 2.11454 3.44706C2.89559 2.66602 4.15267 2.66602 6.66683 2.66602'
        stroke='#467DE1'
        stroke-width='1.25'
        stroke-linecap='round'
      />
      <path d='M6.66667 10.666H4' stroke='#467DE1' stroke-width='1.25' stroke-linecap='round' />
      <path
        d='M8.66683 10.666H8.3335'
        stroke='#467DE1'
        stroke-width='1.25'
        stroke-linecap='round'
      />
      <path
        d='M1.3335 6.66602L4.66683 6.66602M14.6668 6.66602L7.3335 6.66602'
        stroke='#467DE1'
        stroke-width='1.25'
        stroke-linecap='round'
      />
    </svg>
  )
}
