import {Stack} from '@mui/material'
import React from 'react'
import NoTicket from '../svg/NoTicket'
import Label from '../components/texts/Label'
import NoData from '../svg/NoData'
import NoNetWorkIcon from '../svg/NoNetWorkIcon'

export default function NoNetWork() {
  return (
    <Stack gap='35px' mt='32px' width={'100%'} alignItems={'center'}>
      <NoNetWorkIcon />
      <Stack gap='4px' alignItems={'center'}>
        <Label fs='18px' fw='600' lineHeight='38px' text='Oops!' />
        <Label fs='18px' fw='400' lineHeight='38px' text='noConaction' />
      </Stack>
    </Stack>
  )
}
