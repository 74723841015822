import {Box, Stack, Typography} from '@mui/material'
import React, {useState} from 'react'

import {useDispatch} from 'react-redux'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import ConfirmForm from './ConfirmForm'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../../../store'
import InputWithLabel from '../../../../../../parts/InputWithLabel'
import MainBtn from '../../../../../../components/buttons/MainBtn'
import PopUp from '../../../../../../parts/PopUp'
import SelectWithLabel from '../../../../../../parts/SelectWithLabel'
import GoBack from '../../../../../../parts/GoBack'
import {BankSchema} from '../../../../../../yup/WalletSchema'
import {BankObj} from '../../../../../../modules/modules/post/WalletModule'
import Post from '../../../../../../fubcations/mastars/Post'
import Put from '../../../../../../fubcations/mastars/Put'
import Get from '../../../../../../fubcations/mastars/Get'
import CityModule from '../../../../../../modules/modules/get/CityModule'
import WirgdrawWayModule from '../../../../../../modules/modules/get/WirgdrawWayModule'

export default function FormTransferToUser({setIsActive, setOpenSuccess, isSetting}: any) {
  const [data, setData] = useState({bankName: 0})
  const [msgErr, setMsgErr] = useState('')
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const [getItemLoading, setGetItemLoading] = useState(false)
  const [banks, setBanks] = useState([])
  const {id} = useParams()

  Get([], setBanks, 'vendor/withdraw/ways/bank')
  if (id) {
    Get([], setData, `vendor/withdraw/saved/ways/${id}`, setGetItemLoading, WirgdrawWayModule)
  }

  const navigate = useNavigate()
  const submit = () => {
    if (id) {
      Put(
        BankSchema,
        data,
        BankObj,
        setErrors,
        () => {
          if (isSetting) {
            navigate('/settings')
          } else {
            navigate('/wallet/methods')
          }
        },
        setLoading,
        `vendor/withdraw/bank/${id}`
      )
    } else {
      Post(
        BankSchema,
        data,
        BankObj,
        setErrors,
        () => {
          if (isSetting) {
            navigate('/settings')
          } else {
            navigate('/wallet/methods')
          }
        },
        setLoading,
        'vendor/withdraw/bank'
      )
    }
  }
  return (
    <Stack gap='40px' p={{xs: '0 30px'}}>
      <Stack gap='32px'>
        <GoBack
          onClick={() => {
            if (isSetting) {
              navigate('/settings')
            } else {
              navigate('/wallet/methods')
            }
          }}
          text='goBack'
        />

        <Stack
          gap='32px'
          justifyContent={'space-between'}
          flexWrap={'wrap'}
          direction={'row'}
          width={{md: '836px'}}
        >
          <SelectWithLabel
            fw='400'
            name='bankName'
            placeHolder='bankName'
            type='text'
            msgErr={msgErr}
            setData={setData}
            data={data}
            width={{xs: '100%', md: '395px'}}
            errors={errors}
            options={banks}
            Module={CityModule}
          />

          <InputWithLabel
            fw='400'
            name='bankAddress'
            placeHolder='bankAddress'
            type='text'
            msgErr={msgErr}
            setData={setData}
            data={data}
            width={{xs: '100%', md: '395px'}}
            errors={errors}
          />
          <InputWithLabel
            fw='400'
            name='owner'
            placeHolder='owner'
            type='text'
            msgErr={msgErr}
            setData={setData}
            data={data}
            errors={errors}
            width={{xs: '100%', md: '395px'}}
          />
          <InputWithLabel
            fw='400'
            name='swiftCode'
            placeHolder='swiftCode'
            type='text'
            msgErr={msgErr}
            setData={setData}
            data={data}
            errors={errors}
            width={{xs: '100%', md: '395px'}}
          />
          <InputWithLabel
            fw='400'
            name='IBAN'
            placeHolder='IBAN'
            type='text'
            msgErr={msgErr}
            setData={setData}
            data={data}
            errors={errors}
            width={{xs: '100%'}}
          />

          <Box display={'flex'}>
            <MainBtn isLoading={loading} text='save' handelSubmit={submit} />
          </Box>
        </Stack>
      </Stack>
    </Stack>
  )
}
