import React from 'react'

export default function Top() {
  return (
    <svg width='53' height='54' viewBox='0 0 53 54' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M26.4999 6.57359C26.0803 6.57359 25.6607 6.72817 25.3295 7.05942L11.9249 20.464C11.2845 21.1044 11.2845 22.1644 11.9249 22.8048C12.5653 23.4453 13.6253 23.4453 14.2657 22.8048L26.4999 10.5707L38.7341 22.8048C39.3745 23.4453 40.4345 23.4453 41.0749 22.8048C41.7153 22.1644 41.7153 21.1044 41.0749 20.464L27.6703 7.05942C27.3391 6.72817 26.9195 6.57359 26.4999 6.57359Z'
        fill='#FF1A1A'
        fill-opacity='0.2'
      />
      <path
        d='M26.5 6.94899C25.5946 6.94899 24.8438 7.69982 24.8438 8.60524V45.7715C24.8438 46.6769 25.5946 47.4277 26.5 47.4277C27.4054 47.4277 28.1562 46.6769 28.1562 45.7715V8.60524C28.1562 7.69982 27.4054 6.94899 26.5 6.94899Z'
        fill='#FF1A1A'
        fill-opacity='0.2'
      />
    </svg>
  )
}
