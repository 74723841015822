import {Stack} from '@mui/material'
import React from 'react'
import Nike from '../../../../svg/Nike'
import Title from '../../../../components/texts/Title'
import Label from '../../../../components/texts/Label'
import LabelWithValue from '../../../dashboard/pages/transferToUser/LabelWithValue'
import MainBtn from '../../../../components/buttons/MainBtn'
import {useNavigate} from 'react-router-dom'
import SecondaryBtn from '../../../../components/buttons/SacandaryBtn'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../store'

export default function Success({total}: any) {
  const navigate = useNavigate()
  const store = useSelector((state: RootState) => state.vender)

  return (
    <Stack gap='24px' alignItems={'center'} justifyContent={'center'} p='60px 40px'>
      <Nike />
      <Label lineHeight='28px' align='center' text='balanceSuccessMsg' fw='600' fs='20px' />
      <Title isAmount fs='30px' fw='800' text={`${total} ${store.user.currencyCode}`} />

      <Stack width={'90%'} flexWrap={{xs: 'wrap', sm: 'nowrap'}} direction={'row'} gap='15px'>
        <MainBtn
          text='backToHome'
          handelSubmit={() => {
            navigate('/dashboard')
          }}
        />
      </Stack>
    </Stack>
  )
}
