import React, {useState, useCallback, useEffect} from 'react'
import TableHead from './TablHead'
import Row from './Row'

import {Box, CircularProgress, Stack} from '@mui/material'
import {useDispatch} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import Filter from './Filter'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import TitlePage from '../../modules/customTable/TitlePage'
import {FilterBtn} from '../../modules/customTable/styles'
import PopUp from '../../parts/PopUp'
import Btn from '../../modules/customTable/Btn'
import {PaginationSection} from '../../modules/customTable/pagnationSection'
import Preint from '../../svg/Preint'

export default function Table({
  rows,
  setRows,
  added,
  setAdded,
}: {
  rows: any
  setRows: React.Dispatch<React.SetStateAction<any>>
  added: boolean
  setAdded: any
}) {
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState<number>(1)
  const [rowsPerPage, setRowsPerPage] = useState<number>(5)
  const [total, setTotal] = useState(1)
  const {t} = useTranslation()
  const [search, setSearch] = useState('')
  const [timerId, setTimerId] = useState<number | null>(null)
  const [filterData, setFilterData] = useState({})
  const [filter, setFilter] = useState(false)
  const [newRows, setNewRows] = useState(rows.slice((page - 1) * rowsPerPage, rowsPerPage))

  const bannerInfo = {
    title: t('accountStatemant'),
    home: t('home'),
    linkHome: '/',
  }
  const navigate = useNavigate()

  const handleChangePage = (newPage: number) => {
    if (newPage !== page) {
      setPage(newPage)
      setNewRows(rows.slice((newPage - 1) * rowsPerPage, rowsPerPage * newPage))
      setTimeout(() => {
        setIsLoading(false)
      }, 0)

      setIsLoading(true)
    }
  }
  const handleChangeRowsPerPage = (newPrePage: number) => {
    setRowsPerPage(newPrePage)
    setNewRows(rows.slice(0, newPrePage))
    setPage(1)
  }

  return (
    <div className={`card fds sha`}>
      <Box p={{xs: '0px'}}>
        <Stack
          direction={'row'}
          alignItems={'center'}
          flexWrap={'wrap'}
          gap='32px'
          justifyContent={'space-between'}
          mb={'32px'}
        >
          <TitlePage info={bannerInfo} />
          <Stack flexWrap={'wrap'} gap='32px' direction={'row'}>
            <Stack position={'relative'}>
              <FilterBtn
                onClick={() => {
                  setFilter(true)
                }}
                className={'filter-btn text-c5e min-w-85px min-w-40px bg-hover-primary'}
              >
                <FilterAltIcon sx={{color: '#5E6278', width: '13px', height: '15px', mr: '4px'}} />

                <span className='indicator-label fw-semibold pt-1'>{t('filter')}</span>
              </FilterBtn>
            </Stack>

            {filter && (
              <PopUp open={filter} setOpen={setFilter}>
                <Filter
                  filterData={filterData}
                  setFilterData={setFilterData}
                  open={filter}
                  setOpen={setFilter}
                />
              </PopUp>
            )}
          </Stack>
        </Stack>
        <div className='table-responsive overflow-auto'>
          <table className='table  table-row-gray-300 align-middle gs-0 gy-4'>
            <TableHead />
            {!added ? (
              <>
                {!isLoading ? (
                  <tbody className='mb-4'>
                    {newRows.map((e: any, i: any) => (
                      <Row
                        setAdded={setAdded}
                        setRows={setRows}
                        rows={rows}
                        item={e}
                        i={i}
                        key={i}
                      />
                    ))}
                  </tbody>
                ) : (
                  <CircularProgress />
                )}
              </>
            ) : (
              <h1>loading</h1>
            )}
          </table>

          {/* pagnation  item   */}
        </div>
        <PaginationSection
          rowsPerPageOptions={[5, 10, 15]}
          count={rows.length || 1}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </div>
  )
}
