import React from 'react'

export default function transactionsModule(data: any) {
  return {
    title: data.title,
    type: data.transaction_type,
    amount: data.amount,
    date: data.date_humans,
  }
}
