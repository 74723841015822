import {Stack} from '@mui/material'
import React from 'react'
import {SidebarMenuItem} from '../sidebar/sidebar-menu/SidebarMenuItem'
import MenuItem from './MenuItem'

export default function MainItems({links, isBorder}: any) {
  return (
    <Stack
      borderBottom={isBorder ? '1px dashed #E7E7E7' : 'none'}
      pb={isBorder ? '15px' : '0'}
      // gap='32px'
      direction={{lg: 'row'}}
      justifyContent={'space-around'}
      width={'100%'}
    >
      {links.map((item: any) => (
        <MenuItem item={item} />
      ))}
    </Stack>
  )
}
