import {Box, Stack} from '@mui/material'
import React, {useState} from 'react'

import {useDispatch} from 'react-redux'
import {Link, useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import ConfirmForm from '../purchases/ConfirmForm'
import TextEreaWithLabel from '../../parts/TextEreaWithLabel'
import MainBtn from '../../components/buttons/MainBtn'
import InputWithLabel from '../../parts/InputWithLabel'
import SelectWithLabel from '../../parts/SelectWithLabel'
import UploadFile from '../../modules/customTable/UploadFile'
import GoBack from '../../parts/GoBack'
import Post from '../../fubcations/mastars/Post'
import {TicketSchema} from '../../yup/TeckitShcema'
import {TicketObj} from '../../modules/modules/post/TicketsModule'
import CityModule from '../../modules/modules/get/CityModule'
import Get from '../../fubcations/mastars/Get'

export default function AddTicketForm() {
  const [data, setData] = useState({code: '+964'})
  const [msgErr, setMsgErr] = useState('')
  const [errors, setErrors] = useState({})
  const [options, setOptions] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const [base64Image, setBase64Image] = useState('')
  Get([], setOptions, 'tickets/types')
  const submit = () => {
    Post(
      TicketSchema,
      {...data, file: base64Image, isFormData: true},
      TicketObj,
      setErrors,
      () => {
        navigate('/ticket')
      },
      setIsLoading,
      'tickets/add-new'
    )
  }
  return (
    <Stack gap='40px' p={{xs: '0 30px'}}>
      <Stack gap='32px'>
        <GoBack onClick={() => navigate('/ticket')} text='goBack' />

        <Stack
          gap='32px'
          justifyContent={'space-between'}
          flexWrap={'wrap'}
          direction={'row'}
          width={{md: '836px'}}
        >
          <SelectWithLabel
            fw='400'
            name='ticketType'
            placeHolder='ticketType'
            type='text'
            msgErr={msgErr}
            setData={setData}
            data={data}
            width={{xs: '100%', md: '395px'}}
            errors={errors}
            Module={CityModule}
            options={options}
          />
          <InputWithLabel
            fw='400'
            name='subject'
            placeHolder='subject'
            type='text'
            msgErr={msgErr}
            setData={setData}
            data={data}
            width={{xs: '100%', md: '395px'}}
            errors={errors}
          />
          <Stack width={'100%'}>
            <TextEreaWithLabel
              fw='400'
              name='description'
              placeHolder='description'
              msgErr={msgErr}
              setData={setData}
              data={data}
              width='100%'
              errors={errors}
            />
          </Stack>
          <Stack direction={'row'} justifyContent={'flex-start'} width={'100%'}>
            <UploadFile setBase64Image={setBase64Image} base64Image={base64Image} />
          </Stack>
          <Box display={'flex'}>
            <MainBtn isLoading={isLoading} text='continue' handelSubmit={submit} />
          </Box>
        </Stack>
      </Stack>
    </Stack>
  )
}
