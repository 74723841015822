import React from 'react'
import * as Yup from 'yup'
import Apiservices from '../../../lib/ApiServices'
import {toast} from 'react-toastify'

export default async function Post(
  schema: any,
  data: any,
  ModuleObj: any,
  setErrors: any,
  successFun?: any,
  setLoading?: any,
  path?: any
) {
  try {
    setErrors({})

    await schema.validate(data, {abortEarly: false})

    let Module = ModuleObj(data)

    if (data.isFormData) {
      const formData = new FormData()
      for (const key in Module) {
        if (Module[key]) {
          formData.append(key, Module[key])
        }
      }

      Module = formData
    }
    let fetchData = null
    if (path) {
      setLoading(true)

      fetchData = await Apiservices.post(path, Module)

      setLoading(false)
    }

    setErrors({})
    if (successFun) {
      successFun(fetchData)
    }

    toast.success(fetchData && fetchData.data.message) // Success toast

    return {valid: true, Module: Module}
  } catch (err: any) {
    if (setLoading) {
      setLoading(false)
    }
    toast.error(err && err.response && err.response.data && err.response.data.message) // Success toast

    const errors: {[key: string]: string} = {}
    if (err instanceof Yup.ValidationError && err.inner) {
      err.inner.forEach((error) => {
        if (error.path) {
          errors[error.path] = error.message
        }
      })
    }

    setErrors(errors)

    return {valid: false, errors}
  }
}
