import {Stack, Typography} from '@mui/material'
import React, {useState} from 'react'
import SelectLangs from './SelectLang'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {Navbar} from './Navbar'
import {useTranslation} from 'react-i18next'
import Get from '../../fubcations/mastars/Get'
import {useSelector} from 'react-redux'
import {RootState} from '../../store'

export default function GetStart() {
  const {t} = useTranslation()
  const language = navigator.language
  const store = useSelector((state: RootState) => state.vender)

  const myLang = store.langs.find((e: any) => e.code === language.split('-')[0])

  return (
    <Stack alignItems={'center'} gap={{md: '46px', xs: '20px'}} direction={'row'}>
      <Stack display={'block'} width={'120px'}>
        <SelectLangs
          value={{
            code:
              (store.user && store.user.language_code) || localStorage.getItem('myLang') || 'en',
          }}
          option={store.langs}
          color={'none'}
        />
      </Stack>

      <Stack display={{md: 'none', xs: 'block'}}>{/* <Navbar /> */}</Stack>
    </Stack>
  )
}
