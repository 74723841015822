import {Stack} from '@mui/material'
import React, {useState} from 'react'
import InputDateWithLabel from './InputDateWithLabel'
import MainBtn from '../components/buttons/MainBtn'
import SecondaryBtn from '../components/buttons/SacandaryBtn'
import Title from '../components/texts/Title'
import InputDateMuiWithLabel from './InputDateMuiWithLabel'
import {FilterDateSchema} from '../yup/Filter'
import Post from '../fubcations/mastars/Post'
import {FilterObj} from '../modules/modules/post/Filter'

const parseDate = (dateStr: any) => {
  return new Date(dateStr)
}

// تحويل التاريخ من 'DD/MM/YYYY' إلى كائن Date
const parseFilterDate = (dateStr: any) => {
  const [day, month, year] = dateStr.split('/')
  return new Date(`${year}-${month}-${day}`)
}

const filterData = (data: any, fromDateStr: any, toDateStr: any) => {
  const fromDate = parseFilterDate(fromDateStr)
  const toDate = parseFilterDate(toDateStr)

  return data.filter((item: any) => {
    const itemDate = parseDate(item.date)
    return itemDate >= fromDate && itemDate <= toDate
  })
}
export default function FilterForm({setData, data, filterRows, setFilterRows, setOpen}: any) {
  const [errors, setErrors] = useState({})
  const submit = async () => {
    Post(FilterDateSchema, data, FilterObj, setErrors, () => {
      const result = filterData(filterRows, data.fromDate, data.toDate)

      setFilterRows(result)
      setOpen(false)
    })
  }
  return (
    <Stack p='24px' width={'376px'}>
      <Title fs='18px' text='filter' />
      <Stack m='24px 0' width={'100%'} height={'2px'} bgcolor={'#D8D8E5'}></Stack>
      <Stack gap='32px'>
        <InputDateMuiWithLabel
          fw='400'
          name='fromDate'
          placeHolder='fromDate'
          type='text'
          errors={errors}
          setData={setData}
          data={data}
          width='100%'
        />{' '}
        <InputDateMuiWithLabel
          fw='400'
          name='toDate'
          placeHolder='toDate'
          type='text'
          errors={errors}
          setData={setData}
          data={data}
          width='100%'
        />
      </Stack>
      <Stack width={'100%'} justifyContent={'flex-end'} mt='40px' gap='24px' direction={'row'}>
        <SecondaryBtn
          width='auto'
          text='reset'
          handelSubmit={() => {
            setFilterRows(filterRows)
            setOpen(false)

            setErrors({})
            setData({})
          }}
        />
        <MainBtn width='auto' text={'apply'} handelSubmit={submit} />
      </Stack>{' '}
    </Stack>
  )
}
