import React from 'react'

export default function Home({color}: any) {
  return (
    <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10.56 5.32771V5.33V7.23C10.56 8.23613 10.2956 8.92232 9.85895 9.35895C9.42232 9.79557 8.73613 10.06 7.73 10.06H5.83C4.82387 10.06 4.13768 9.79557 3.70105 9.35895C3.26443 8.92232 3 8.23613 3 7.23V5.33C3 4.32387 3.26441 3.63797 3.70191 3.20145C4.13959 2.76474 4.82828 2.5 5.84 2.5H7.74C8.74631 2.5 9.43213 2.76451 9.8673 3.20066C10.3023 3.63661 10.5646 4.322 10.56 5.32771Z'
        stroke={color || '#6D6D6D'}
      />
      <path
        d='M17.2704 2.5H19.1704C20.1766 2.5 20.8628 2.76443 21.2994 3.20105C21.736 3.63768 22.0004 4.32387 22.0004 5.33V7.23C22.0004 8.23613 21.736 8.92232 21.2994 9.35895C20.8628 9.79557 20.1766 10.06 19.1704 10.06H17.2704C16.2643 10.06 15.5781 9.79557 15.1415 9.35895C14.7049 8.92232 14.4404 8.23613 14.4404 7.23V5.33C14.4404 4.32387 14.7049 3.63768 15.1415 3.20105C15.5781 2.76443 16.2643 2.5 17.2704 2.5Z'
        stroke={color || '#6D6D6D'}
      />
      <path
        d='M17.2704 13.9297H19.1704C20.1766 13.9297 20.8628 14.1941 21.2994 14.6307C21.736 15.0674 22.0004 15.7536 22.0004 16.7597V18.6597C22.0004 19.6658 21.736 20.352 21.2994 20.7886C20.8628 21.2253 20.1766 21.4897 19.1704 21.4897H17.2704C16.2643 21.4897 15.5781 21.2253 15.1415 20.7886C14.7049 20.352 14.4404 19.6658 14.4404 18.6597V16.7597C14.4404 15.7536 14.7049 15.0674 15.1415 14.6307C15.5781 14.1941 16.2643 13.9297 17.2704 13.9297Z'
        stroke={color || '#6D6D6D'}
      />
      <path
        d='M10.56 16.7674V16.7697V18.6697C10.56 19.6758 10.2956 20.362 9.85895 20.7986C9.42232 21.2353 8.73613 21.4997 7.73 21.4997H5.83C4.82387 21.4997 4.13797 21.2353 3.70145 20.7978C3.26474 20.3601 3 19.6714 3 18.6597V16.7597C3 15.7536 3.26441 15.0677 3.70191 14.6311C4.13959 14.1944 4.82828 13.9297 5.84 13.9297H7.74C8.74631 13.9297 9.43184 14.1942 9.86691 14.6312C10.302 15.0682 10.5646 15.7561 10.56 16.7674Z'
        stroke={color || '#6D6D6D'}
      />
    </svg>
  )
}
