import React from 'react'

export default function DownChart() {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='24' height='24' rx='4' fill='#FDEDF1' />
      <path
        d='M15.3332 12.6667L11.9998 16M11.9998 16L8.6665 12.6667M11.9998 16L11.9998 8'
        stroke='#EF476F'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}
