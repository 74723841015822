import {Box, Button, Stack, Typography, SxProps} from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import React from 'react'
import LabelInput from './LabelInput'
import {useTranslation} from 'react-i18next'
import CloseIcon from '@mui/icons-material/Close'

export default function CheckBox({
  text,
  bg,
  submit,
  type,
  sx,
  closeIcon,
}: {
  text: string
  bg: string
  type: string
  submit: any
  sx?: SxProps
  closeIcon?: boolean
}) {
  const {t} = useTranslation()

  return (
    <Stack
      gap='8px'
      sx={{...sx, cursor: 'pointer'}}
      alignItems={'center'}
      direction={'row'}
      onClick={submit}
    >
      <Stack
        alignItems={'center'}
        justifyContent={'center'}
        sx={{
          width: '19px',
          height: '19px',
          borderRadius: '4px',
          background: bg === type ? '#D81923' : 'var(--bs-app-bg-color)',
        }}
      >
        {!closeIcon ? (
          bg === type && <CheckIcon sx={{color: '#fff', fontSize: '14px'}} />
        ) : (
          <CloseIcon sx={{color: '#fff', fontSize: '14px'}} />
        )}
      </Stack>
      <LabelInput text={t(text)} />
    </Stack>
  )
}
