import React from 'react'

export default function DepositeHome() {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='12' cy='12' r='12' fill='#E3FBFF' />
      <g clip-path='url(#clip0_0_1)'>
        <path
          d='M14.562 5C14.7229 5.09507 14.7704 5.23403 14.7704 5.42052C14.7667 7.34759 14.7667 9.271 14.7667 11.1981C14.7667 11.5308 14.6717 11.6259 14.3426 11.6259C12.3972 11.6259 10.4519 11.6222 8.50651 11.6222C7.47532 11.6222 6.44414 11.6222 5.41296 11.6296C5.22646 11.6296 5.08751 11.5784 4.99609 11.4138V5.22671C5.04729 5.12067 5.12408 5.04754 5.22646 5H14.562ZM14.1268 10.997C14.1341 10.9787 14.1451 10.964 14.1451 10.9458C14.1451 10.613 14.1414 10.2802 14.1414 9.94749C14.1414 9.7354 14.1414 9.51965 14.1414 9.30391C14.1414 8.6384 14.1414 7.97288 14.1414 7.30736C14.1414 7.09893 14.1414 6.8905 14.1414 6.67842C14.1451 6.35297 14.1414 6.02387 14.1488 5.69843C14.1488 5.62895 14.1232 5.61432 14.061 5.61432C13.7356 5.62164 13.4138 5.62164 13.092 5.62164C12.8799 5.62164 12.6678 5.62164 12.4557 5.62164C12.4045 5.62164 12.3533 5.61798 12.3021 5.61798C10.9492 5.61798 9.59254 5.61798 8.23957 5.61798C7.92875 5.61798 7.62159 5.62164 7.31077 5.62164H6.68548C6.36003 5.62164 6.03093 5.62164 5.70549 5.61432C5.63967 5.61432 5.61773 5.63261 5.61773 5.69843C5.62139 6.03118 5.62139 6.35663 5.62504 6.68573V7.31102C5.62504 7.97654 5.62504 8.64205 5.62504 9.30757C5.62504 9.51965 5.62504 9.73174 5.62504 9.94749C5.62504 10.2693 5.62504 10.5911 5.62139 10.9128C5.62139 10.975 5.63236 11.0043 5.70549 11.0006C6.03093 10.9933 6.36003 10.997 6.68548 10.9933C6.89391 11.0079 7.10234 11.0079 7.31443 10.9933C7.35099 10.997 7.38756 11.0043 7.42413 11.0043C9.06963 11.0043 10.7151 11.0043 12.3606 11.0043C12.3936 11.0043 12.4265 10.997 12.4594 10.997C12.6715 11.0079 12.8872 11.0079 13.1029 10.997C13.1176 10.997 13.1285 11.0006 13.1432 11.0006C13.465 11.0152 13.7977 11.0152 14.1268 10.997Z'
          fill='#2E81FF'
        />
        <path
          d='M5.22623 5C5.12384 5.04754 5.04705 5.12067 4.99951 5.22671C4.99951 5 4.99951 5 5.22623 5Z'
          fill='#FCFDFD'
        />
        <path
          d='M14.4999 19.3993C13.1505 19.3993 11.8012 19.3993 10.4519 19.3993C9.929 19.3993 9.63647 19.1031 9.63281 18.5802C9.63281 16.9165 9.63281 15.2527 9.63281 13.5889C9.63281 13.066 9.92535 12.7734 10.4519 12.7734C13.1615 12.7734 15.8711 12.7734 18.577 12.7734C19.1 12.7734 19.3961 13.0696 19.3961 13.5889C19.3961 15.249 19.3961 16.9055 19.3961 18.5656C19.3961 19.0958 19.1 19.3957 18.5734 19.3957C17.2168 19.3993 15.8601 19.3993 14.4999 19.3993ZM14.5218 15.1137C13.1469 15.1137 11.7756 15.1174 10.4007 15.1101C10.2727 15.1101 10.2508 15.143 10.2508 15.2636C10.2544 16.3643 10.2544 17.465 10.2544 18.5693C10.2544 18.7667 10.2691 18.7814 10.4592 18.7814H18.5734C18.7635 18.7814 18.7855 18.7631 18.7855 18.5729C18.7855 17.465 18.7818 16.3606 18.7891 15.2563C18.7891 15.1393 18.7635 15.1101 18.6465 15.1101C17.268 15.1137 15.893 15.1137 14.5218 15.1137ZM14.5072 14.4921C15.8894 14.4921 17.2716 14.4921 18.6538 14.4957C18.7599 14.4957 18.7891 14.4701 18.7855 14.3641C18.7782 14.1191 18.7818 13.8778 18.7818 13.6328C18.7818 13.406 18.7745 13.3951 18.5441 13.3951H11.3039C11.026 13.3951 10.7518 13.3951 10.4738 13.3951C10.2691 13.3951 10.2544 13.4097 10.2544 13.6072C10.2544 13.8412 10.2618 14.0752 10.2508 14.3092C10.2435 14.4482 10.2764 14.4957 10.43 14.4957C11.7866 14.4921 13.1469 14.4921 14.5072 14.4921Z'
          fill='#2E81FF'
        />
        <path
          d='M17.3595 10.1491V9.35195C17.3595 9.07039 17.3522 7.626 17.3632 7.34443C17.3668 7.23473 17.3303 7.21645 17.2315 7.21645C16.7269 7.22011 16.2186 7.22011 15.714 7.22011C15.5239 7.22011 15.4068 7.13235 15.3703 6.97511C15.3264 6.78862 15.458 6.60578 15.6518 6.60578C16.332 6.59847 17.0085 6.60213 17.6886 6.60578C17.8605 6.60578 17.9702 6.73743 17.9775 6.92392C17.9848 7.17257 17.9811 8.58405 17.9811 8.8327C17.9811 9.2715 17.9811 9.7103 17.9811 10.1491C17.9958 10.1601 18.0067 10.1674 18.0214 10.1784C18.1055 10.0833 18.1896 9.98456 18.281 9.89314C18.4346 9.7359 18.6174 9.72493 18.7527 9.86023C18.8843 9.99187 18.8734 10.1747 18.7161 10.3319C18.4529 10.5989 18.1896 10.8622 17.9226 11.1254C17.7617 11.2827 17.5899 11.2827 17.4326 11.1254C17.1621 10.8585 16.8951 10.5916 16.6282 10.321C16.4819 10.171 16.4709 9.99187 16.5989 9.86023C16.7269 9.72859 16.9097 9.7359 17.0597 9.88217C17.1511 9.97359 17.2388 10.0687 17.3303 10.1637C17.3339 10.1601 17.3449 10.1528 17.3595 10.1491Z'
          fill='#2E81FF'
        />
        <path
          d='M7.05819 13.8091V14.6063C7.05819 14.8878 7.06551 16.3322 7.05454 16.6138C7.05088 16.7271 7.08745 16.7418 7.18618 16.7418C7.6908 16.7381 8.19908 16.7381 8.7037 16.7381C8.89384 16.7381 9.01086 16.8259 9.04743 16.9831C9.09131 17.1696 8.95967 17.3524 8.76586 17.3524C8.08572 17.3597 7.40923 17.3561 6.72909 17.3524C6.55723 17.3524 6.44387 17.2208 6.44021 17.0343C6.4329 16.7856 6.43656 15.3742 6.43656 15.1255C6.43656 14.6867 6.43656 14.2479 6.43656 13.8091C6.42193 13.7981 6.41096 13.7908 6.39633 13.7799C6.30857 13.8749 6.22813 13.9737 6.13671 14.0651C5.98313 14.2223 5.8003 14.2333 5.665 14.098C5.53336 13.9663 5.54433 13.7835 5.70156 13.6263C5.96485 13.3593 6.23178 13.0961 6.49506 12.8328C6.65596 12.6755 6.82782 12.6755 6.98506 12.8328C7.25565 13.0997 7.52259 13.3666 7.78953 13.6372C7.9358 13.7872 7.94677 13.9663 7.81878 14.098C7.6908 14.2296 7.50796 14.2223 7.35804 14.076C7.26297 13.9846 7.17886 13.8896 7.08745 13.7945C7.08379 13.7981 7.07282 13.8054 7.05819 13.8091Z'
          fill='#2E81FF'
        />
        <path
          d='M7.31073 5.625C7.21931 6.46238 6.46238 7.21931 5.625 7.31073V6.68544C6.19179 6.54283 6.54283 6.18813 6.68544 5.625H7.31073Z'
          fill='#2E81FF'
        />
        <path
          d='M14.1413 7.31073C13.3588 7.21931 12.6421 6.55745 12.4848 5.77492C12.4738 5.72373 12.4665 5.67619 12.4556 5.625C12.6677 5.625 12.8797 5.625 13.0918 5.625C13.2198 6.19544 13.5818 6.53917 14.1413 6.68544C14.1413 6.89021 14.1413 7.09864 14.1413 7.31073Z'
          fill='#2E81FF'
        />
        <path
          d='M5.62109 9.30859C6.39265 9.44755 6.92653 9.88269 7.21541 10.614C7.26294 10.7347 7.29585 10.8627 7.30682 10.9943C7.09839 11.0089 6.88996 11.0089 6.67787 10.9943C6.54258 10.4458 6.1184 10.0216 5.62109 9.94486C5.62109 9.73277 5.62109 9.52068 5.62109 9.30859Z'
          fill='#2E81FF'
        />
        <path
          d='M12.4556 10.9943C12.5762 10.1679 13.2235 9.4512 14.1413 9.30859C14.1413 9.52068 14.1413 9.73643 14.1413 9.95217C13.6586 10.0107 13.1503 10.5226 13.0991 10.998C12.8834 11.0053 12.6677 11.0089 12.4556 10.9943Z'
          fill='#2E81FF'
        />
        <path
          d='M13.1357 10.9973C13.4648 10.9827 13.7939 10.9827 14.1267 10.9973C13.7976 11.0156 13.4648 11.0156 13.1357 10.9973Z'
          fill='#35CCCC'
        />
        <path
          d='M11.476 8.32384C11.4723 9.20144 10.752 9.91449 9.87803 9.91084C8.99677 9.90352 8.28737 9.18316 8.29103 8.29824C8.29469 7.41698 9.01871 6.70759 9.90362 6.7149C10.7776 6.72221 11.4797 7.44258 11.476 8.32384ZM10.858 8.31287C10.858 7.77168 10.4265 7.33654 9.89265 7.33288C9.34781 7.32922 8.90901 7.76802 8.90901 8.31287C8.90901 8.85406 9.3405 9.28189 9.88534 9.28189C10.4265 9.28189 10.8544 8.85771 10.858 8.31287Z'
          fill='#2E81FF'
        />
        <path
          d='M14.3754 17.6852C14.2364 17.6852 14.0938 17.6888 13.9548 17.6852C13.7684 17.6815 13.6367 17.5499 13.6367 17.378C13.6367 17.2061 13.7684 17.0709 13.9512 17.0672C14.2328 17.0635 14.5143 17.0635 14.7922 17.0672C14.9824 17.0709 15.103 17.1952 15.1067 17.3744C15.1067 17.5608 14.9824 17.6815 14.7849 17.6852H14.7703H14.3754Z'
          fill='#2E81FF'
        />
        <path
          d='M16.9421 17.6854C16.8068 17.6854 16.6715 17.6891 16.5362 17.6854C16.3388 17.6818 16.2108 17.5574 16.2144 17.3746C16.2144 17.1991 16.3424 17.0711 16.5289 17.0711C16.8068 17.0674 17.0811 17.0674 17.359 17.0711C17.5528 17.0748 17.6881 17.2064 17.6844 17.3819C17.6808 17.5574 17.5455 17.6854 17.3517 17.6891C17.2127 17.6854 17.0774 17.6854 16.9421 17.6854Z'
          fill='#2E81FF'
        />
      </g>
      <defs>
        <clipPath id='clip0_0_1'>
          <rect width='14.4' height='14.4' fill='white' transform='translate(4.99951 5)' />
        </clipPath>
      </defs>
    </svg>
  )
}
