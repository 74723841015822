import {Stack} from '@mui/material'
import React from 'react'
import TextLink from '../../components/texts/TextLink'
import Title from '../../components/texts/Title'
import Label from '../../components/texts/Label'

export default function TermsConditions({handelBack}: any) {
  return (
    <Stack gap='24px' p='40px'>
      <TextLink onClick={handelBack} text='backRegistration' />
      <Title text='TermsConditions' />
      <Label text='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget elit proin feugiat viverra libero laoreet. Blandit sollicitudin enim tempor diam nibh. Velit potenti enim neque, nunc id ullamcorper. Nec tellus tincidunt neque non at etiam eget volutpat. Dui sed link in text fringilla sit. Leo non risus morbi dui netus. Leo id vel, eu odio malesuada donec mollis. Integer nunc blandit dignissim eget mi et tortor augue at. Massa lacus, nunc bibendum quam enim. Est, vitae sit dolor facilisi orci at bibendum. Velit cursus at enim, metus auctor velit sed luctus fringilla. In lobortis tempus nullam amet a rhoncus. Eu habitant arcu, elementum vel eget quis elit. Ut malesuada urna dis elit eu. Auctor sed tortor faucibus nunc cursus vestibulum purus volutpat commodo. Non penatibus quam facilisis eu commodo, tellus nulla non. Elementum neque varius adipiscing tellus amet, viverra nec tincidunt facilisis.' />
      <Title fs='18px' text='TermsConditions' />
      <Label text='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget elit proin feugiat viverra libero laoreet. Blandit sollicitudin enim tempor diam nibh. Velit potenti enim neque, nunc id ullamcorper. Nec tellus tincidunt neque non at etiam eget volutpat. Dui sed link in text fringilla sit. Leo non risus morbi dui netus. Leo id vel, eu odio malesuada donec mollis. Integer nunc blandit dignissim eget mi et tortor augue at. Massa lacus, nunc bibendum quam enim. Est, vitae sit dolor facilisi orci at bibendum. Velit cursus at enim, metus auctor velit sed luctus fringilla. In lobortis tempus nullam amet a rhoncus. Eu habitant arcu, elementum vel eget quis elit. Ut malesuada urna dis elit eu. Auctor sed tortor faucibus nunc cursus vestibulum purus volutpat commodo. Non penatibus quam facilisis eu commodo, tellus nulla non. Elementum neque varius adipiscing tellus amet, viverra nec tincidunt facilisis.' />
    </Stack>
  )
}
