import {IconButton, Stack} from '@mui/material'
import React, {useState} from 'react'
import SelectWithBtn from '../../../../parts/SelectWithBtn'
import Dots from '../../../../svg/Dots'
import Edit from '../../../../svg/Edit'
import ActionBtn from './ActionBtn'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../store'
import {useNavigate} from 'react-router-dom'
import EditIcon from '../../../../svg/EditIcon'
import Delete from '../../../../svg/Delete'

export default function ActionBtns({item, openDeleteMsg, setOpenDeleteMsg}: any) {
  const [open, setOpen] = useState(false)
  const store = useSelector((state: RootState) => state.vender)
  const navigate = useNavigate()
  const actions = [
    {
      name: 'edit',
      icon: <EditIcon />,
      onclick: () => {
        navigate(`${item.type}/${item.id}`)
      },
    },
    {
      name: 'delete',
      icon: <Delete />,
      onclick: () => {
        setOpenDeleteMsg(true)
      },
    },
  ]
  return (
    <Stack>
      <SelectWithBtn
        btn={
          <IconButton onClick={() => setOpen(!open)}>
            <Dots />
          </IconButton>
        }
        setOpen={setOpen}
        open={open}
        selectItems={
          <Stack
            // sx={{
            //   right: store.isRtl === 0 ? '-17px' : 'auto',
            //   left: store.isRtl !== 0 ? '-17px' : 'auto',
            // }}
            position={'absolute'}
            bgcolor={'#fff'}
            top='-24px'
            borderRadius={'4px'}
            border={'1px solid var(--link-color)'}
            // gap={'10px'}
          >
            {actions.map((e: any) => (
              <ActionBtn item={e} />
            ))}
          </Stack>
        }
      />
    </Stack>
  )
}
