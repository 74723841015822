import {Stack} from '@mui/material'
import React from 'react'
import BusinessAccountDetails from './BusinessAccountDetails'
import ChangePassword from './ChangePassword'
import SavedWithdrawMethods from './SavedWithdrawMethods'
import Languages from './languages'
import AboutUs from './AboutUs'
import Privacy from './Privacy'
import ContactUs from './ContactUs'
import Keys from './Keys'
import FAQS from './FAQ'
import ConvertToBusiness from './ConvertToBusiness'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'

export default function Contant({contant, SelectLang, setSelectLang}: any) {
  return (
    <Stack
      height={'100%'}
      width={{xs: '100%', md: contant === 'convertToBusiness' ? '100%' : '453px'}}
    >
      {contant === 'businessAccountDetails' && <BusinessAccountDetails />}
      {contant === 'convertToBusiness' && <ConvertToBusiness />}
      {contant === 'changePassword' && <ChangePassword />}
      {contant === 'savedWithdrawMethods' && <SavedWithdrawMethods />}
      {contant === 'aboutUs' && <AboutUs />}
      {contant === 'contactUs' && <ContactUs />}
      {contant === 'keys' && <Keys />}
      {contant === 'privacy' && <Privacy />}
      {contant === 'feq' && <FAQS />}
      {contant === 'language' && (
        <Languages setSelectLang={setSelectLang} SelectLang={SelectLang} />
      )}
    </Stack>
  )
}
