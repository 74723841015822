import {Stack, Typography} from '@mui/material'
import React from 'react'
import Label from '../../../components/texts/Label'

export default function Massage({massage}: any) {
  const images = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg']

  return (
    <Stack gap='10px'>
      <Stack gap='5px' direction={'row'} alignItems={'center'}>
        <img
          style={{width: '24px', height: '24px', borderRadius: '100%'}}
          src={massage.userImg}
          alt=''
        />
        <Label color='#212121' fw={'600'} text={massage.name} />
      </Stack>
      <Label color='#212121' fw={'600'} text={massage.type} />
      {massage.msg && <Label color='#A5A5A5' lineHeight='17px' text={massage.msg} />}
      {massage.attachment &&
      images.includes(massage.attachment.split('.')[massage.attachment.split('.').length - 1]) ? (
        <img
          onClick={() => {}}
          style={{width: '120px', marginTop: '20px'}}
          src={massage.attachment}
          alt=''
        />
      ) : massage.attachment &&
        !images.includes(
          massage.attachment.split('.')[massage.attachment.split('.').length - 1]
        ) ? (
        <a href={massage.attachment}>
          {' '}
          <Stack
            m='28px 24px'
            gap='8px'
            bgcolor={'rgba(0, 158, 247, 0.1)'}
            p='12px 20px'
            alignItems={'center'}
            direction={'row'}
            borderRadius={'8px'}
          >
            <img style={{width: '16px'}} src='/media/avatars/icons/Upload.svg' alt='' />
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: '400',
                color: '#009EF7',
                marginTop: '3px',
                overflow: 'hidden',
              }}
            >
              {massage.attachment}
            </Typography>
          </Stack>
        </a>
      ) : (
        ''
      )}{' '}
      <Stack p='0 60px' alignItems={'flex-end'}>
        <Label color='#A5A5A5' lineHeight='17px' text={massage.date} />
      </Stack>
    </Stack>
  )
}
