import {Stack} from '@mui/material'
import React, {useState} from 'react'
import TitlePage from '../../modules/customTable/TitlePage'
import {useTranslation} from 'react-i18next'
import BtnWithIcon from '../../components/buttons/BtnWithIcon'
import EditIcon from '../../svg/EditIcon'
import UploadAvatarImg from '../../modules/customTable/UploadAvatarImg'
import NikeProfile from '../../svg/NikeProfile'
import {useSelector} from 'react-redux'
import {RootState} from '../../store'
import InputWithLabel from '../../parts/InputWithLabel'
import InputDateWithLabel from '../../parts/InputDateWithLabel'
import Label from '../../components/texts/Label'
import RadioButtonsGroup from '../../components/inputs/RadioGroup'
import Post from '../../fubcations/mastars/Post'
import {ProfileSchema} from '../../yup/SettingSchema'
import {ProfileObj} from '../../modules/modules/post/SettingsModule'
import InputDateMuiWithLabel from '../../parts/InputDateMuiWithLabel'

export default function Profile() {
  const {t} = useTranslation()
  const bannerInfo = {
    title: t('profile'),
    linkHome: '/',
  }
  const store = useSelector((state: RootState) => state.vender)

  const [base64Image, setBase64Image] = useState(store.user && store.user.avater)
  const [msgErr, setMsgErr] = useState('')
  const [data, setData] = useState({
    accountNo: store.user.account_number,
    accountType: store.user.type,
    name: store.user.full_name,
    dateBirth: store.user.date_of_birth,
    email: store.user.email,
  })
  const [isEdit, setIsEdit] = useState(true)
  const [errors, setErrors] = useState({})
  const submit = () => {
    Post(ProfileSchema, data, ProfileObj, setErrors, () => {
      setIsEdit(!isEdit)
    })
  }
  return (
    <Stack>
      <Stack flexWrap={'wrap'} justifyContent={'space-between'} direction={'row'}>
        <TitlePage info={bannerInfo} />
        {/* <BtnWithIcon
          onClick={() => {
            if (!isEdit) {
              submit()
            } else {
              setIsEdit(!isEdit)
            }
          }}
          icon={<EditIcon color='#fff' />}
          name={isEdit ? 'edit' : 'save'}
          width='133px'
        /> */}
      </Stack>
      <Stack>
        <Stack gap='40px' p={{xs: '0 30px'}}>
          <Stack position={'relative'} width='100px' height={'100px'} alignItems={'flex-start'}>
            <UploadAvatarImg disable setBase64Image={setBase64Image} base64Image={base64Image} />
            <Stack
              sx={{
                top: '58px',
                right: store.isRtl === 0 ? '0' : 'auto',
                left: store.isRtl !== 0 ? '0' : 'auto',
              }}
              position='absolute'
            >
              <NikeProfile />
            </Stack>
          </Stack>
          <InputWithLabel
            fw='400'
            name='accountNo'
            placeHolder='accountNo'
            type='text'
            msgErr={msgErr}
            setData={setData}
            data={data}
            width={{xs: '100%', md: '395px'}}
            disabled={isEdit}
            errors={errors}
          />
          <InputWithLabel
            fw='400'
            name='accountType'
            placeHolder='accountType'
            type='text'
            msgErr={msgErr}
            setData={setData}
            data={data}
            width={{xs: '100%', md: '395px'}}
            disabled={isEdit}
            errors={errors}
          />{' '}
          <InputWithLabel
            fw='400'
            name='name'
            placeHolder='name'
            type='text'
            msgErr={msgErr}
            setData={setData}
            data={data}
            width={{xs: '100%', md: '395px'}}
            disabled={isEdit}
            errors={errors}
          />
          <InputWithLabel
            fw='400'
            name='dateBirth'
            placeHolder='dateBirth'
            type='text'
            msgErr={msgErr}
            setData={setData}
            data={data}
            width={{xs: '100%', md: '395px'}}
            disabled={isEdit}
            errors={errors}
          />
          {/* <InputDateMuiWithLabel
            fw='400'
            name='dateBirth'
            placeHolder='dateBirth'
            type='text'
            msgErr={msgErr}
            setData={setData}
            data={data}
            width={{xs: '100%', md: '395px'}}
            disabled={isEdit}
            errors={errors}
          /> */}
          <InputWithLabel
            fw='400'
            name='email'
            placeHolder='email'
            type='text'
            msgErr={msgErr}
            setData={setData}
            data={data}
            width={{xs: '100%', md: '395px'}}
            disabled={isEdit}
            errors={errors}
          />
          <Stack width={'100%'}>
            <Label fw='400' text='chooseGender' />

            <RadioButtonsGroup disabled={isEdit} value={store.user.gender} data={['M', 'F']} />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}
