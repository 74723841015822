import {Button, styled} from '@mui/material'

export const ActionTableBtn = styled('a')({
  minWidth: '80px',
  minHeight: '36px',
  padding: '9px 8px 8px !important',
  transition: 'all 0.3s ease-in-out',
  display: 'flex',
  gap: '8px',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '8px',
  '& svg': {
    width: '7px',
    height: '4px',
  },
  ':hover': {
    backgroundColor: '#bd121b !important',
  },
})
export const FilterBtn = styled('button')({
  border: '1px solid var(--bs-gray-300)',
  ':hover': {
    backgroundColor: '#e8e8e8 !important',
  },
})

export const ResetBtn = styled('button')({
  border: 'none',
  minWidth: '100px',
  minHeight: '48px',
  padding: '8px',
  color: '#5E6278 !important',
  textTransform: 'capitalize',
  transition: 'all 0.3s ease-in-out',
  borderRadius: '8px',
  fontSize: '18px',
  fontWeight: 'medium',
  backgroundColor: '#E1E3EA !important',
  ':hover': {
    backgroundColor: '#ccced6 !important',
  },
})
export const ApplyBtn = styled(ResetBtn)({
  color: '#fff !important',
  backgroundColor: '#D81923 !important',
  ':hover': {
    backgroundColor: '#c5111b !important',
  },
})

export const InputCheckbox = styled('input')({
  '&:not(:checked)': {
    backgroundColor: '#8585857d !important',
  },
  '&:checked': {
    backgroundColor: '#0688E7 !important',
  },
})

export const SaveBtn = styled('button')({
  border: 'none',
  minWidth: '120px',
  minHeight: '40px',
  padding: '8px',
  color: '#fff !important',
  textTransform: 'capitalize',
  transition: 'all 0.3s ease-in-out',
  borderRadius: '8px',
  fontWeight: 'medium',
  backgroundColor: '#D81923 !important',
  ':hover': {
    backgroundColor: '#be272f !important',
  },
})
export const CancelBtn = styled(SaveBtn)({
  color: 'var(--bs-body-color) !important',
  backgroundColor: 'rgb(25 ,34 ,76, 1%) !important',
  ':hover': {
    backgroundColor: 'rgb(25 ,34 ,76, 5%) !important',
  },
})
