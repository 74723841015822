import {Stack} from '@mui/material'
import React from 'react'
import TopTransatcion from '../../../svg/TopTransatcion'
import DownTransation from '../../../svg/DownTransation'
import Cyrcle from '../../../svg/Cyrcle'
import Label from '../../../components/texts/Label'

export default function Payment({item}: any) {
  return (
    <Stack gap='14px' direction={'row'}>
      <Stack gap='12px' alignItems={'center'} direction={'row'}>
        <Stack bgcolor={'#fff'}>
          {' '}
          <Cyrcle />
        </Stack>

        {item.type === 'Expense' ? <TopTransatcion /> : <DownTransation />}
      </Stack>

      <Stack justifyContent={'space-between'}>
        <Label fs='14px' fw='400' color='#19224C' text={item.title} />
        <Label fs='12px' fw='400' color='#636B6F' text={item.date} />
      </Stack>
      <Label color={item.type === 'Expense' ? '#FF3D00' : '#37BD6B'} text={item.type} />
      <Label
        fs='15px'
        fw='400'
        color='#263238'
        isAmount
        text={` ${item.type === 'Expense' ? '-' : '+'} ${item.amount}`}
      />
    </Stack>
  )
}
