import {Box, Stack} from '@mui/material'
import React, {useState} from 'react'
import Title from '../../../components/texts/Title'
import UploadImg from '../../../components/inputs/UploadImg'
import Label from '../../../components/texts/Label'
import InputWithLabel from '../../../parts/InputWithLabel'
import InputDateWithLabel from '../../../parts/InputDateWithLabel'
import RadioButtonsGroup from '../../../components/inputs/RadioGroup'
import MainBtn from '../../../components/buttons/MainBtn'
import {useDispatch} from 'react-redux'
import TextEreaWithLabel from '../../../parts/TextEreaWithLabel'
import InputImgWithLabel from '../../../parts/InputImgWithLabel'
import SecondaryBtn from '../../../components/buttons/SacandaryBtn'
import Post from '../../../fubcations/mastars/Post'
import {BusinessAccountDetailsSchema} from '../../../yup/SettingSchema'
import Get from '../../../fubcations/mastars/Get'
import {BusinessAccountDetailsObj} from '../../../modules/modules/post/SettingsModule'
import Loading from '../../../modules/customTable/LoadingForm'
import BusinessDetailsObj from '../../../modules/modules/get/BusinessAccountDetailsModule'

export default function BusinessAccountDetails() {
  const [data, setData] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [base64Image, setBase64Image] = useState(
    'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSwA3CQaTUi6rIb3OOFkqgyiYy6yhjMfokvxgk0XHhYbQ&s'
  )
  const [msgErr, setMsgErr] = useState('')
  const dispatch = useDispatch()
  const [edit, setEdit] = useState(false)
  const [errors, setErrors] = useState({})
  const submit = () => {
    Post(BusinessAccountDetailsSchema, data, BusinessAccountDetailsObj, setErrors, () => {})
  }
  Get([], setData, 'convert-business/detials', setIsLoading, BusinessDetailsObj)

  return (
    <Stack minHeight={'100%'} position={'relative'}>
      {isLoading ? (
        <Loading />
      ) : (
        <Stack>
          <Stack gap={'32px'}>
            <InputWithLabel
              fw='400'
              name='businessType'
              placeHolder='businessType'
              type='text'
              msgErr={msgErr}
              setData={setData}
              data={data}
              width='100%'
              disabled={!edit}
              errors={errors}
            />
            <InputWithLabel
              fw='400'
              name='businessName'
              placeHolder='businessName'
              type='text'
              msgErr={msgErr}
              setData={setData}
              data={data}
              width='100%'
              disabled={!edit}
              errors={errors}
            />
            <InputWithLabel
              fw='400'
              name='nameOfBusinessManager'
              placeHolder='nameOfBusinessManager'
              type='text'
              msgErr={msgErr}
              setData={setData}
              data={data}
              width='100%'
              disabled={!edit}
              errors={errors}
            />
            <InputWithLabel
              fw='400'
              name='domainName'
              placeHolder='domainName'
              type='text'
              msgErr={msgErr}
              setData={setData}
              data={data}
              width='100%'
              disabled={!edit}
              errors={errors}
            />
            <TextEreaWithLabel
              fw='400'
              name='content'
              placeHolder='writeAboutYourBusiness'
              msgErr={msgErr}
              setData={setData}
              data={data}
              width='100%'
              disabled={!edit}
              errors={errors}
            />
            <Stack
              gap='32px'
              flexWrap={'wrap'}
              // justifyContent={{md: 'space-between'}}
              direction={'row'}
            >
              <InputImgWithLabel
                width='121px'
                height='70px'
                br='8px'
                data={data}
                setData={setData}
                name='imageOne'
                msgErr=''
                setBase64Image={setBase64Image}
                base64Image={base64Image}
                disabled={!edit}
                placeHolder={'photoOfCommercialRecord'}
                border=' 2px solid #979797'
              />
              <InputImgWithLabel
                width='121px'
                height='70px'
                br='8px'
                data={data}
                setData={setData}
                name='imageTwo'
                msgErr=''
                setBase64Image={setBase64Image}
                base64Image={base64Image}
                disabled={!edit}
                placeHolder={'photoPassport'}
                border=' 2px solid #979797'
              />
            </Stack>
            <InputImgWithLabel
              width='119px'
              height='119px'
              br='8px'
              data={data}
              setData={setData}
              name='file'
              msgErr=''
              setBase64Image={setBase64Image}
              base64Image={base64Image}
              disabled={!edit}
              placeHolder={'photoPassport'}
              border=' 2px solid #979797'
              isPdf
            />
            <Stack gap='24px' direction={'row'}>
              {' '}
              {edit && (
                <SecondaryBtn
                  width='auto'
                  text='cancel'
                  handelSubmit={() => {
                    setEdit(!edit)
                  }}
                />
              )}
              {/* <MainBtn
            width='auto'
            text={edit ? 'save' : 'edit'}
            handelSubmit={() => {

              if (edit) {
                submit()
              } else {
                setEdit(!edit)
              }
            }}
          /> */}
            </Stack>
          </Stack>
        </Stack>
      )}
    </Stack>
  )
}
