import {Box, CircularProgress, IconButton, Stack} from '@mui/material'
import React, {useEffect, useRef, useState} from 'react'
import {useParams} from 'react-router-dom'
import ReactDOM from 'react-dom'
import UploadFileMsg from './UpdateFileMsg'
import InputType from '../../../components/inputs/InputType'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'

export default function ActionBtns({
  send,
  msgs,
  setMsgs,
  msgContant,
  selectedFile,
  setMsgContant,
  setSelectedFile,
  isLoading,
}: any) {
  const messagesRef = useRef<HTMLDivElement>(null)
  const store = useSelector((state: RootState) => state.vender)

  useEffect(() => {
    scrollToBottom()
  }, [msgs])

  const scrollToBottom = () => {
    const messagesContainer = ReactDOM.findDOMNode(messagesRef.current) as HTMLDivElement
    if (messagesContainer) {
      messagesContainer.scrollTop = messagesContainer.scrollHeight
    }
  }

  // useEffect(() => {
  //   setMsgs(data.messages)
  // }, [data])

  return (
    <>
      <Stack
        onSubmit={send}
        component={'form'}
        alignItems={'center'}
        gap='24px'
        direction={'row'}
        mt='24px'
      >
        <Box position={'relative'} width='calc(100% )'>
          <InputType
            placeHolder='message'
            noBorder
            name={'message'}
            data={msgContant}
            setData={setMsgContant}
            p='17px 0'
            br='8px'
            type='text'
          />
          <Stack
            sx={{
              right: store.isRtl === 0 ? '14px' : 'auto',
              left: store.isRtl !== 0 ? '14px' : 'auto',
              top: '8px',
              gap: '16px',
            }}
            direction={'row'}
            position={'absolute'}
          >
            {isLoading ? (
              <CircularProgress size={'20px'} />
            ) : (
              <>
                <UploadFileMsg selectedFile={selectedFile} setSelectedFile={setSelectedFile} />
                <IconButton
                  onClick={send}
                  disabled={msgContant && !msgContant.message && !selectedFile}
                  sx={{
                    background: '#ff000000',
                    width: '24px',
                    height: '24px',
                    transform: store.isRtl === 1 ? 'rotate(180deg)' : '',
                  }}
                >
                  {msgContant.message || selectedFile ? (
                    <img
                      style={{width: '24px', height: '24px'}}
                      src='./media/avatars/icons/activeSend.svg'
                      alt=''
                    />
                  ) : (
                    <img
                      style={{width: '24px', height: '24px'}}
                      src='./media/avatars/icons/send.svg'
                      alt=''
                    />
                  )}
                </IconButton>
              </>
            )}
          </Stack>
        </Box>
      </Stack>
    </>
  )
}
