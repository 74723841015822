import {Stack} from '@mui/material'
import React, {useState} from 'react'
import Title from '../../components/texts/Title'
import Label from '../../components/texts/Label'
import InputCode from './InputCode'
import TextLink from '../../components/texts/TextLink'
import MainBtn from '../../components/buttons/MainBtn'
import CountdownTimer from './Timer'
import Apiservices from '../../../lib/ApiServices'

export default function VerificationCode({
  codeValue,
  setCodeValue,
  msgErr,
  setIsActive,
  handelSubmit,
  mobile,
  errors,
  isLoading,
  reSendPath,
}: any) {
  const [isfocus, setFoucus] = useState('a')
  const [timeLeft, setTimeLeft] = useState(120) // بالثواني
  return (
    <Stack gap='24px'>
      <Title text={'verificationCode'} />
      <Label fs='16px' text='verificationCodeDetails' />
      <Stack gap='20px' justifyContent={'center'} direction={'row'}>
        {['a', 'b', 'c', 'd', 'e'].map((e, i) => (
          <InputCode
            isfocus={isfocus}
            codeValue={codeValue}
            e={e}
            setCodeValue={setCodeValue}
            setFoucus={setFoucus}
            arrCode={msgErr}
            errors={errors}
          />
        ))}
      </Stack>
      {timeLeft === 0 ? (
        <TextLink
          text={'resend'}
          onClick={async () => {
            try {
              Apiservices.post(reSendPath || 'auth/register/verefication/re-send', {mobile: mobile})
              setTimeLeft(120)
            } catch (err: any) {}
          }}
        />
      ) : (
        <CountdownTimer timeLeft={timeLeft} setTimeLeft={setTimeLeft} />
      )}{' '}
      <MainBtn isLoading={isLoading} text='submit' handelSubmit={handelSubmit} />
    </Stack>
  )
}
