import {CircularProgress, Stack} from '@mui/material'
import React from 'react'

export default function Loading() {
  return (
    <Stack
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        background: 'var(--bs-body-bg)',
        top: '0',
        right: '0',
        borderRadius: '12px',
      }}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <CircularProgress />
    </Stack>
  )
}
