import React from 'react'
import * as Yup from 'yup'
import Apiservices from '../../../lib/ApiServices'

export default async function Delete(data: any, successFun?: any, setLoading?: any, path?: any) {
  try {
    let fetchData = null
    if (path) {
      setLoading(true)

      fetchData = await Apiservices.delete(path, data)

      setLoading(false)
    }

    if (successFun) {
      successFun(fetchData)
    }
  } catch (err) {
    if (setLoading) {
      setLoading(false)
    }

    const errors: {[key: string]: string} = {}
    if (err instanceof Yup.ValidationError && err.inner) {
      err.inner.forEach((error) => {
        if (error.path) {
          errors[error.path] = error.message
        }
      })
    }

    return {valid: false, errors}
  }
}
