import React from 'react'
import {useSelector} from 'react-redux'
import {RootState} from '../store'

export default function Cyrcale() {
  const store = useSelector((state: RootState) => state.vender)

  return (
    <svg
      width='386'
      height='444'
      viewBox='0 0 386 444'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{
        position: 'absolute',

        right: store.isRtl === 0 ? '0' : 'auto',
        left: store.isRtl !== 0 ? '0' : 'auto',
        top: '0',
        zIndex: '-1',
        transform: store.isRtl !== 0 ? 'rotateY(-180deg)' : 'rotateY(0)',
        width: '25vw',
      }}
    >
      <path
        d='M270.5 444C419.893 444 541 322.894 541 173.501C541 24.1074 419.893 -97 270.5 -97C121.107 -97 0 24.1074 0 173.501C0 322.894 121.107 444 270.5 444Z'
        fill='#467DE1'
      />
    </svg>
  )
}
