import {Stack} from '@mui/material'
import React, {useRef, useEffect} from 'react'
import SelectBtn from '../components/buttons/SelectBtn'
import SelectForBtn from '../components/selects/SelectForBtn'
import {useSelector} from 'react-redux'
import {RootState} from '../store'

export default function SelectWithBtn({open, setOpen, selectItems, btn, isLeft}: any) {
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const store = useSelector((state: RootState) => state.vender)

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        setOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef, setOpen])

  return (
    <Stack position={'relative'}>
      {btn}
      {open && (
        <Stack
          minWidth={'312px'}
          sx={{
            position: 'absolute',
            top: '42px',
            right: {
              xs: store.isRtl === 0 ? 'auto' : 0,
              md: store.isRtl === 0 && isLeft ? '0' : 'auto',
            },
            left: {
              xs: store.isRtl !== 0 ? '0' : 'auto',
              md: store.isRtl !== 0 && isLeft ? '0' : 'auto',
            },
            zIndex: '10',
          }}
          ref={wrapperRef}
        >
          {selectItems}
        </Stack>
      )}
    </Stack>
  )
}
