export function SettingTransfersObj(data: any) {
  return {
    min_amount: data.min_amount,
    is_max_amount: data.is_max_amount,
    max_amount: data.max_amount,
    fee_value: data.fee_value,
    fee_type: data.fee_type,
  }
}
export function balanceDepositObj(data: any) {
  return {
    balance: data.wallet_balance,
    fee_value: data.fee_value,
    isFixed: data.isFixed,
  }
}
