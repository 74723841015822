import React from 'react'

export default function FilterIcon() {
  return (
    <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='40' height='40' rx='4' fill='#467DE1' fill-opacity='0.1' />
      <path
        d='M14 15C14 14.4477 14.4477 14 15 14H25C25.5523 14 26 14.4477 26 15V16.2525C26 16.5177 25.8946 16.772 25.7071 16.9596L21.6262 21.0404C21.4387 21.228 21.3333 21.4823 21.3333 21.7475V23.3333L18.6667 26V21.7475C18.6667 21.4823 18.5613 21.228 18.3738 21.0404L14.2929 16.9596C14.1054 16.772 14 16.5177 14 16.2525V15Z'
        stroke='#467DE1'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}
