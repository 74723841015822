import React, {useState} from 'react'
import {CircularProgress, Stack} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {RootState} from '../../store'
import Status from '../../components/texts/Status'
import TextLink from '../../components/texts/TextLink'
import PopUp from '../../parts/PopUp'
import ActionBtn from './ActionBtn'
import {NotifySchema} from '../../yup/SettingSchema'
import PaymentRefundObj from '../../modules/modules/get/PaymentRefund'
import Post from '../../fubcations/mastars/Post'
import {
  paymentRefundAcceptObj,
  paymentRefundRejectObj,
} from '../../modules/modules/post/PaymentModule'
import ConfirmForm from './ConfirmForm'
import {paymentAcceptSchema} from '../../yup/PaymentSchema'

export default function Row({
  item,
  i,
  setRows,
  rows,
  setAdded,
}: {
  item: any
  i: number
  rows: any
  setRows: any
  setAdded: any
}) {
  const [state, setState] = useState(item.status)
  const [open, setOpen] = React.useState(false)
  const [editItem, setEditItem] = useState(item)
  const navigate = useNavigate()
  const handelEdit = () => {
    navigate(`/helpdesk/faq-edit/${item.id}`)
  }

  const handleStatusChange = async (e: React.ChangeEvent<HTMLInputElement>) => {}

  const [isDeleted, setDeleted] = useState(true)
  const [errors, setErrors] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [status, setStatus] = useState('pending')
  return (
    <>
      {isDeleted && (
        <>
          <tr
            style={{
              background: i % 2 === 0 ? '#fff' : '#f6f8fa',
              color: '#111C1F',
              fontSize: '14px',
              fontWeight: '400',
              lineHeight: '16px',
            }}
            // className='fw-bold text-muted '
          >
            <td
              style={{
                padding: '12px 12px',
              }}
            >
              {editItem.code}
            </td>
            <td style={{padding: '12px 0px'}}>{editItem.client}</td>
            <td style={{padding: '12px 0px'}}>{editItem.city}</td>

            <td style={{padding: '12px 0'}}>{editItem.amount}</td>
            <td style={{padding: '12px 0'}}>{editItem.amountRefund}</td>
            <td style={{padding: '12px 0'}}>{editItem.date}</td>
            <td style={{padding: '12px 0'}}>
              <Status text={editItem.status} />{' '}
            </td>
            <td style={{padding: '12px 12px', textAlign: 'end'}}>
              {isLoading ? (
                <CircularProgress size={'20px'} />
              ) : (
                <Stack direction={'row'} gap='32px'>
                  <ActionBtn
                    text={'refund'}
                    disabled={editItem.status !== 'pending' && true}
                    bgcolor={editItem.status !== 'pending' ? '#C4C4C4' : '#46CB9B'}
                    fun={() => {
                      setOpen(true)
                      setStatus('accept')
                    }}
                  />
                  <ActionBtn
                    text={'reject'}
                    disabled={editItem.status !== 'pending' && true}
                    bgcolor={editItem.status !== 'pending' ? '#C4C4C4' : '#D81923'}
                    fun={() => {
                      setOpen(true)

                      setStatus('reject')
                    }}
                  />
                </Stack>
              )}
            </td>
          </tr>
        </>
      )}
      <PopUp open={open} setOpen={setOpen} width='md'>
        <ConfirmForm
          data={editItem}
          setData={setEditItem}
          status={status}
          errors={errors}
          isLoading={isLoading}
          submit={() => {
            if (status === 'accept') {
              Post(
                paymentAcceptSchema,
                {newAmount: editItem.newAmount, id: editItem.id},
                paymentRefundAcceptObj,
                setErrors,
                () => {
                  setOpen(false)
                  setEditItem({...editItem, status: 'accept'})
                },
                setIsLoading,
                'vendor/payments/online/refund/accept'
              )
            } else {
              Post(
                NotifySchema,
                {id: editItem.id},
                paymentRefundRejectObj,
                setErrors,
                () => {
                  setEditItem({...editItem, status: 'reject'})
                  setOpen(false)
                },
                setIsLoading,
                'vendor/payments/online/refund/reject'
              )
            }
          }}
          setOpen={setOpen}
        />
      </PopUp>
    </>
  )
}
