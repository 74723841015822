import * as Yup from 'yup'
import dayjs from 'dayjs'
export const TransferToUserSchema = Yup.object().shape({
  amount: Yup.number().required('fieldRequired').min(1, 'amountMsg'),
  // amountToBeReceive: Yup.number().required('fieldRequired').min(1, 'amountMsg'),
  recipientsName: Yup.string().required('fieldRequired'),
  recipientsAccountNumber: Yup.string().required('fieldRequired'),
  // content: Yup.string().required('fieldRequired'),
})
export const TransferToNonUserSchema = Yup.object().shape({
  amount: Yup.number().required('fieldRequired').min(1, 'amountMsg'),
  // amountToBeReceive: Yup.number().required('fieldRequired').min(1, 'amountMsg'),
  recipientsName: Yup.string().required('fieldRequired'),
  phone: Yup.string().required('fieldRequired').min(10, 'phoneMsg'),
  email: Yup.string().email('invalidEmail').required('fieldRequired'),
  // content: Yup.string().required('fieldRequired'),
})
export const RequestMoneySchema = Yup.object().shape({
  amount: Yup.number().required('fieldRequired').min(1, 'amountMsg'),
  phone: Yup.string().required('fieldRequired').min(5, 'phoneMsg'),
  content: Yup.string().required('fieldRequired'),
  payerFullName: Yup.string().required('fieldRequired'),
})
