import {Box, Stack} from '@mui/material'
import React from 'react'

import LabelWithValue from '../wallet/pages/withdraw/pages/bank/LabelWithValue'
import {useNavigate} from 'react-router-dom'
import TextLink from '../../components/texts/TextLink'
import Logo from '../../svg/Logo'
import Title from '../../components/texts/Title'
import Label from '../../components/texts/Label'
import MainBtn from '../../components/buttons/MainBtn'
import Status from '../../components/texts/Status'
import GoBack from '../../parts/GoBack'
import {useSelector} from 'react-redux'
import {RootState} from '../../store'

export default function ConfirmForm({setOpen, item}: any) {
  const store = useSelector((state: RootState) => state.vender)
  return (
    <Stack p='24px'>
      <Stack alignItems={'center'} justifyContent={'space-between'} direction={'row'}>
        <GoBack onClick={() => setOpen(false)} text='goBack' />
        <Logo />
      </Stack>
      <Stack p='24px 0' borderBottom='1px dashed #9D97B1' gap='24px'>
        <Title text='invoiceDetails' />
      </Stack>
      <Stack borderBottom='1px dashed #9D97B1' gap='24px' p='24px 0'>
        <LabelWithValue isAmount value={item.id} label='invoiceId' />
        <LabelWithValue value={item.orderDate} label='orderDate' />
        <LabelWithValue value={item.valueDate} label='valueDate' />
      </Stack>
      <Stack borderBottom='1px dashed #9D97B1' gap='24px' p='24px 0'>
        <LabelWithValue value={item.orderDate} label='client' />
        <LabelWithValue value={item.orderDate} label='balance' />
      </Stack>
      <Stack borderBottom='1px dashed #9D97B1' gap='24px' p='24px 0'>
        <LabelWithValue
          value={`${item['amount']}`}
          text3={` ${store.user.currencyCode}`}
          isAmount
          label='amount'
        />{' '}
        {/* <LabelWithValue value='??' label='delivery' /> */}
      </Stack>
      <Stack
        direction={'row'}
        justifyContent={'flex-end'}
        alignItems={'center'}
        // borderBottom='1px dashed #9D97B1'
        gap='5px'
        p='24px 0'
      >
        <Label text='total' />
        <Label text=':' />
        <Title isAmount fs='18px' text={`${item.total}`} />
      </Stack>
    </Stack>
  )
}
