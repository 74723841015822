import React from 'react'

export default function DownLoadIcon() {
  return (
    <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='40' height='40' rx='4' fill='#467DE1' fill-opacity='0.1' />
      <circle cx='20' cy='20' r='10' stroke='#467DE1' stroke-width='1.5' />
      <path
        d='M20 16L20 24M20 24L23 21M20 24L17 21'
        stroke='#467DE1'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}
