import {Stack, Typography} from '@mui/material'
import QRCode from 'qrcode.react'
import React from 'react'
import Logo from '../../svg/Logo'
import {FooterWrapper} from '../../../_metronic/layout/components/footer'
import Label from '../../components/texts/Label'

export default function PreintQR({phoneNumber}: any) {
  return (
    <Stack
      justifyContent={'space-between'}
      m='40px'
      width={'90vw'}
      height={'95vh'}
      position={'fixed'}
      top='-10000'
      zIndex={'-11'}
    >
      <Stack height={'79px'} width={'196px'}>
        <Logo />
      </Stack>
      <Stack gap='32px' maxWidth={'350px'} m='0 auto' alignItems={'center'}>
        {' '}
        <QRCode value={phoneNumber} size={350} />
        <Stack height={'20px'}></Stack>
      </Stack>
      <Stack width={'100%'}>
        <Typography
          sx={{textAlign: 'center', fontSize: '12px', fontWeight: '400', color: '#6F6F6F'}}
        >
          2024 &#9400; <span style={{color: '#0D6EFD'}}>KONIN Pay</span> All Rights Reserved
        </Typography>
      </Stack>
    </Stack>
  )
}
