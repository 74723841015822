import React from 'react'

export default function WirgdrawWayModule(data: any) {
  return {
    bankName: data.withdraw_way_id,
    officeName: data.withdraw_way_id,
    bankAddress: data.bank_adress,
    owner: data.owner,
    swiftCode: data.swift_code,
    IBAN: data.iban,
    phone: data.wallet_mobile || data.exchange_mobile,
    name: data.wallet_name || data.exchange_name,
  }
}
