import React from 'react'

export default function TicketIcon({color}: any) {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12 21.25H7C3.35 21.25 1.25 19.15 1.25 15.5V8.5C1.25 4.85 3.35 2.75 7 2.75H17C20.65 2.75 22.75 4.85 22.75 8.5V11.5C22.75 11.91 22.41 12.25 22 12.25C21.59 12.25 21.25 11.91 21.25 11.5V8.5C21.25 5.64 19.86 4.25 17 4.25H7C4.14 4.25 2.75 5.64 2.75 8.5V15.5C2.75 18.36 4.14 19.75 7 19.75H12C12.41 19.75 12.75 20.09 12.75 20.5C12.75 20.91 12.41 21.25 12 21.25Z'
        fill={color || '#6D6D6D'}
      />
      <path
        d='M11.9988 12.87C11.1588 12.87 10.3088 12.61 9.6588 12.08L6.5288 9.57996C6.2088 9.31996 6.14881 8.84997 6.4088 8.52997C6.66881 8.20997 7.13879 8.15997 7.45879 8.40997L10.5888 10.91C11.3488 11.52 12.6388 11.52 13.3988 10.91L16.5288 8.40997C16.8488 8.14997 17.3188 8.19997 17.5788 8.52997C17.8388 8.84997 17.7888 9.32996 17.4588 9.57996L14.3288 12.08C13.6888 12.61 12.8388 12.87 11.9988 12.87Z'
        fill={color || '#6D6D6D'}
      />
      <path
        d='M15.8196 21.7796C15.4396 21.7796 15.0796 21.6396 14.8196 21.3796C14.5096 21.0696 14.3696 20.6196 14.4396 20.1496L14.6296 18.7996C14.6796 18.4496 14.8896 18.0296 15.1396 17.7796L18.6796 14.2396C19.1596 13.7596 19.6296 13.5096 20.1396 13.4596C20.7596 13.3996 21.3796 13.6596 21.9596 14.2396C22.5396 14.8196 22.7996 15.4296 22.7396 16.0596C22.6896 16.5596 22.4296 17.0396 21.9596 17.5196L18.4196 21.0596C18.1696 21.3096 17.7496 21.5196 17.3996 21.5696L16.0495 21.7596C15.9695 21.7696 15.8996 21.7796 15.8196 21.7796ZM20.3096 14.9496C20.2996 14.9496 20.2896 14.9496 20.2796 14.9496C20.1396 14.9596 19.9496 15.0896 19.7396 15.2996L16.1996 18.8396C16.1696 18.8696 16.1196 18.9696 16.1196 19.0096L15.9396 20.2596L17.1896 20.0796C17.2296 20.0696 17.3295 20.0196 17.3595 19.9896L20.8996 16.4496C21.1096 16.2296 21.2396 16.0496 21.2496 15.9096C21.2696 15.7096 21.0696 15.4696 20.8996 15.2996C20.7396 15.1396 20.5096 14.9496 20.3096 14.9496Z'
        fill={color || '#6D6D6D'}
      />
      <path
        d='M20.9206 18.2509C20.8506 18.2509 20.7806 18.2409 20.7206 18.2209C19.4006 17.8509 18.3506 16.8009 17.9806 15.4809C17.8706 15.0809 18.1006 14.6709 18.5006 14.5509C18.9006 14.4409 19.3106 14.6709 19.4206 15.0709C19.6506 15.8909 20.3006 16.5409 21.1206 16.7709C21.5206 16.8809 21.7506 17.3009 21.6406 17.7009C21.5506 18.0309 21.2506 18.2509 20.9206 18.2509Z'
        fill={color || '#6D6D6D'}
      />
    </svg>
  )
}
