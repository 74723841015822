import {Stack} from '@mui/material'
import React from 'react'
import Title from '../../../components/texts/Title'
import FormRefund from './Form'

export default function Refund() {
  return (
    <Stack gap='32px'>
      <Title text='refund' />
      <FormRefund />
    </Stack>
  )
}
