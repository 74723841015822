import React, {useEffect, useState} from 'react'
import {Stack, Typography} from '@mui/material'
import Table from './Table'
import {useTranslation} from 'react-i18next'

export default function AccountStatemant() {
  const {t} = useTranslation()

  const [rows, setRows] = useState<any>([
    {
      id: '#11111',
      amount: '1254',
      balance: '1254',
      reference: 'bank',
      orderDate: '1/1/2024',
      client: '1/1/2024',
      valueDate: 'pending',
    },
    {
      id: '#11111',
      amount: '1254',
      balance: '1254',
      reference: 'bank',
      orderDate: '1/1/2024',
      client: '1/1/2024',
      valueDate: 'pending',
    },
  ])
  const [added, setAdded] = useState(!false)

  return <Table setAdded={setAdded} added={added} rows={rows} setRows={setRows} />
}
// var(--bs-body-bg)
