import Apiservices from '../../lib/ApiServices'

const HomeApi = {
  getSillingTop: async () => {
    const data = await Apiservices.get('/vendor/dashboard/top-selling')
    return data
  },
  getOrder: async (search: any) => {
    let url = `/vendor/dashboard/last-orders?`
    if (search) {
      url = url + `search=${search}`
    }
    const data = await Apiservices.get(url)
    return data
  },
  getAnalysis: async (date: any) => {
    if (date.type) {
      const data = await Apiservices.get(`/vendor/dashboard/get-analysis?type=${date.type}`)
      return data
    } else {
      const data = await Apiservices.get(
        `/vendor/dashboard/get-analysis?type=custom-range&from=${date.start}&to=${date.end}`
      )
      return data
    }
  },
  getChart: async (date: any) => {
    const data = await Apiservices.get(
      `/vendor/dashboard/get-chart?type=custom-range&from=${date.start}&to=${date.end}`
    )
    return data
  },
}
export default HomeApi
