import {Box, SpeedDialIcon, Stack, Typography} from '@mui/material'
import React, {useEffect, useState} from 'react'

import {useDispatch} from 'react-redux'
import InputWithLabel from '../../../../parts/InputWithLabel'
import MainBtn from '../../../../components/buttons/MainBtn'
import TextLink from '../../../../components/texts/TextLink'
import {Link, useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import TextEreaWithLabel from '../../../../parts/TextEreaWithLabel'
import PopUp from '../../../../parts/PopUp'
import ConfirmForm from './ConfirmForm'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../store'
import Bank from '../../../../svg/Bank'
import WithDrawMethod from './WithDrawMethod'
import Wallet from '../../../../svg/Wallet'
import WithDrawItems from './WithDrawItems'
import Success from './Success'
import GoBack from '../../../../parts/GoBack'
import ExchangeOfficeIcon from '../../../../svg/ExchangeOfficeIcon'
import Post from '../../../../fubcations/mastars/Post'
import {WalletSchema} from '../../../../yup/WalletSchema'
import {WalletObj} from '../../../../modules/modules/post/WalletModule'
import Get from '../../../../fubcations/mastars/Get'
import Loading from '../../../../modules/customTable/LoadingForm'
import Apiservices from '../../../../../lib/ApiServices'
import Label from '../../../../components/texts/Label'
import {SettingWithdrawObj} from '../../../../modules/modules/get/SettingWithdrawModule'

export default function FormTransferToUser({data, setData}: any) {
  const [msgErr, setMsgErr] = useState('')
  const {t} = useTranslation()
  const store = useSelector((state: RootState) => state.vender)
  const [isActiveMethod, setIsActiveMethod] = useState('bank')
  const [isMethod, setIsMethod] = useState<any>(0)

  const [MethodItems, setMethodItems] = useState<any>([])
  const [isAdd, setIsAdd] = useState(false)
  const navigate = useNavigate()
  const [errors, setErrors] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [item, setItem] = useState({withdraw_way_id: 0})
  const methods = [
    {
      icon: <Bank />,
      name: 'bank',
      id: 1,
    },
    {
      icon: <Wallet />,
      name: 'EWallet',
      id: 2,
    },
    {
      icon: <ExchangeOfficeIcon />,
      name: 'exchangeOffice',
      id: 3,
    },
  ]
  const [open, setOpen] = useState(false)

  const submit = () => {
    Post(
      WalletSchema,
      {...data, id: isMethod},
      WalletObj,
      setErrors,
      () => {
        setOpen(true)
      },
      setLoading,
      'vendor/withdraw'
    )
  }
  Get([], setMethodItems, 'vendor/withdraw/saved', setIsLoading)

  useEffect(() => {
    setIsMethod((MethodItems && MethodItems[0] && MethodItems[0].id) || 0)
    if (MethodItems[0]) {
      setItem(MethodItems[0])
    }
  }, [MethodItems])
  const getSetting = async () => {
    const data = await Apiservices.get(`vendor/withdraw/${item.withdraw_way_id}`)
    const newData = SettingWithdrawObj(data?.data?.data)
    setData(newData)
  }
  useEffect(() => {
    if (item.withdraw_way_id !== 0) {
      getSetting()
    }
  }, [item.withdraw_way_id])

  return (
    <Stack gap='40px' p={{xs: '0 30px'}}>
      <Stack gap='32px'>
        <GoBack
          onClick={() => {
            if (!isAdd || MethodItems.length === 0) {
              navigate('/wallet')
            } else {
              setIsAdd(false)
            }
          }}
          text='goBack'
        />

        <Stack
          gap='32px'
          // justifyContent={'space-between'}
          flexWrap={'wrap'}
          direction={'row'}
          width={{lg: '836px'}}
        >
          {isMethod !== 0 && (
            <>
              <Stack width={{xs: '100%', md: '395px'}} position={'relative'}>
                <InputWithLabel
                  fw='400'
                  name='range'
                  placeHolder='yourBalance'
                  type='text'
                  msgErr={msgErr}
                  setData={setData}
                  data={data}
                  width={{xs: '100%', md: '395px'}}
                  disabled
                />
                <Typography
                  sx={{
                    right: store.isRtl === 0 ? '14px' : 'auto',
                    left: store.isRtl !== 0 ? '14px' : 'auto',
                    top: '31px',
                    fontSize: '18px',
                    fontWeight: '600',
                  }}
                  position={'absolute'}
                >
                  {data['wallet_balance']}
                </Typography>
              </Stack>

              <InputWithLabel
                fw='400'
                name='amount'
                placeHolder='enterYourAmount'
                type='text'
                msgErr={msgErr}
                setData={setData}
                data={data}
                errors={errors}
                width={{xs: '100%', md: '395px'}}
                label={
                  (data && data.amount) >= +data.max_amount ||
                  (data && data.amount) <= +data.min_amount ? (
                    <Stack direction={'row'} gap='5px'>
                      <Label color='rgb(239, 71, 111)' text='MinMaxAmount' />
                      <Label
                        color='rgb(239, 71, 111)'
                        text={`${data.min_amount} - ${data.max_amount}`}
                      />
                    </Stack>
                  ) : (
                    <></>
                  )
                }
              />
              <Stack position={'relative'}>
                <Stack
                  height={'40px'}
                  bgcolor={'#f2eded'}
                  width={{xs: '100%'}}
                  position={'absolute'}
                  top={'22px'}
                  zIndex={'2'}
                  borderRadius={'4px'}
                  justifyContent={'center'}
                  p='16px'
                >
                  <Label
                    text={
                      data.fee_type === 'fixed' && +data['amount'] - +data.fee_value
                        ? +data['amount'] - +data.fee_value
                        : data.fee_type === 'percentage' &&
                          +data.amount - +data.amount * (+data.fee_value / 100)
                        ? +data.amount - +data.amount * (+data.fee_value / 100)
                        : '0'
                    }
                  />
                </Stack>
                <InputWithLabel
                  fw='400'
                  name='amountToBeReceive'
                  placeHolder='amountToBeReceive'
                  type='number'
                  errors={errors}
                  setData={setData}
                  data={data}
                  width={{xs: '100%', md: '395px'}}
                  disabled
                />
              </Stack>
            </>
          )}

          <Stack
            gap='24px'
            width={{xs: '100%', md: '827px'}}
            minHeight={'350px'}
            position={'relative'}
          >
            {!isLoading ? (
              <>
                {' '}
                {isAdd && (
                  <>
                    {' '}
                    {methods.map((e: any) => (
                      <WithDrawMethod
                        setIsActive={setIsActiveMethod}
                        isActive={isActiveMethod}
                        item={e}
                      />
                    ))}
                    <Box mt='16px' display={'flex'}>
                      <MainBtn
                        width='auto'
                        text='continue'
                        handelSubmit={() => {
                          navigate(isActiveMethod)
                        }}
                      />
                    </Box>
                  </>
                )}
                {!isAdd && (
                  <WithDrawItems
                    setIsAdd={setIsAdd}
                    isMethod={isMethod}
                    setIsMethod={setIsMethod}
                    data={MethodItems}
                    submit={submit}
                    open={open}
                    setOpen={setOpen}
                    setErrors={setErrors}
                    setMethodItems={setMethodItems}
                    loading={loading}
                    amount={data.amount}
                    setItem={setItem}
                  />
                )}
              </>
            ) : (
              <Loading />
            )}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}
