import React from 'react'

export default function DownTransation() {
  return (
    <svg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='36' height='36' rx='18' fill='#FFECE6' />
      <path
        d='M18.0001 11.832C17.8734 11.832 17.7467 11.8786 17.6467 11.9786L13.6001 16.0253C13.4067 16.2186 13.4067 16.5386 13.6001 16.732C13.7934 16.9253 14.1134 16.9253 14.3067 16.732L18.0001 13.0386L21.6934 16.732C21.8867 16.9253 22.2067 16.9253 22.4001 16.732C22.5934 16.5386 22.5934 16.2186 22.4001 16.0253L18.3534 11.9786C18.2534 11.8786 18.1267 11.832 18.0001 11.832Z'
        fill='#FF1A1A'
      />
      <path
        d='M18 11.946C17.7267 11.946 17.5 12.1727 17.5 12.446V23.666C17.5 23.9393 17.7267 24.166 18 24.166C18.2733 24.166 18.5 23.9393 18.5 23.666V12.446C18.5 12.1727 18.2733 11.946 18 11.946Z'
        fill='#FF1A1A'
      />
    </svg>
  )
}
