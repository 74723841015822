import React, {useState} from 'react'
import {Stack} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {RootState} from '../../store'
import Status from '../../components/texts/Status'
import TextLink from '../../components/texts/TextLink'
import ConfirmForm from './ConfirmForm'
import PopUp from '../../parts/PopUp'

export default function Row({
  item,
  i,
  setRows,
  rows,
  setAdded,
}: {
  item: any
  i: number
  rows: any
  setRows: any
  setAdded: any
}) {
  const [state, setState] = useState(item.status)
  const [open, setOpen] = React.useState(false)
  const [editItem, setEditItem] = useState(item)
  const navigate = useNavigate()
  const handelEdit = () => {
    navigate(`/helpdesk/faq-edit/${item.id}`)
  }

  const handleStatusChange = async (e: React.ChangeEvent<HTMLInputElement>) => {}

  const [isDeleted, setDeleted] = useState(true)
  const handelDelete = async () => {
    setAdded(false)
    setAdded(true)
    setRows(rows.filter((e: any) => e.id !== item.id))
  }
  const store = useSelector((state: RootState) => state.vender)

  return (
    <>
      {isDeleted && (
        <>
          <tr
            style={{
              background: i % 2 === 0 ? '#fff' : '#f6f8fa',
              color: '#111C1F',
              fontSize: '14px',
              fontWeight: '400',
              lineHeight: '16px',
              height: '44px',
            }}
            // className='fw-bold text-muted '
          >
            <td
              style={{
                padding: '12px 12px',
              }}
            >
              {editItem.id}
            </td>
            <td style={{padding: '12px 0'}}>
              {' '}
              <TextLink
                text='details'
                onClick={() => {
                  setOpen(true)
                }}
              />
            </td>
            <td style={{padding: '12px 0'}}>{editItem.amount}</td>

            <td style={{padding: '12px 0px'}}>{editItem.balance}</td>
            <td style={{padding: '12px 12px', textAlign: 'end'}}>{editItem.payFor}</td>

            {/* <td style={{padding: '12px 0'}}>{editItem.orderDate}</td>
            <td style={{padding: '12px 12px', textAlign: 'end'}}>{editItem.valueDate}</td> */}
          </tr>
          <PopUp open={open} setOpen={setOpen} width='md'>
            <ConfirmForm item={editItem} setOpen={setOpen} />
          </PopUp>
        </>
      )}
    </>
  )
}
