import {Box, Stack, Typography} from '@mui/material'
import React, {useState} from 'react'

import {useDispatch} from 'react-redux'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import ConfirmForm from './ConfirmForm'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../../../store'
import InputWithLabel from '../../../../../../parts/InputWithLabel'
import MainBtn from '../../../../../../components/buttons/MainBtn'
import PopUp from '../../../../../../parts/PopUp'
import SelectWithLabel from '../../../../../../parts/SelectWithLabel'
import InputPhoneWithLabel from '../../../../../../parts/InputPhoneWithLabel'
import GoBack from '../../../../../../parts/GoBack'
import {EWalletSchema} from '../../../../../../yup/WalletSchema'
import {EWalletObj} from '../../../../../../modules/modules/post/WalletModule'
import Post from '../../../../../../fubcations/mastars/Post'
import Put from '../../../../../../fubcations/mastars/Put'
import Get from '../../../../../../fubcations/mastars/Get'
import CityModule from '../../../../../../modules/modules/get/CityModule'
import WirgdrawWayModule from '../../../../../../modules/modules/get/WirgdrawWayModule'

export default function FormTransferToUser({setIsActive, setOpenSuccess, isSetting}: any) {
  const [data, setData] = useState({bankName: 0, code: '+964'})

  const [msgErr, setMsgErr] = useState('')
  const [open, setOpen] = useState(false)
  const [errors, setErrors] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [getItemLoading, setGetItemLoading] = useState(false)

  const navigate = useNavigate()
  const {id} = useParams()
  const [wallets, setWallets] = useState([])
  Get([], setWallets, 'vendor/withdraw/ways/wallet')
  if (id) {
    Get([], setData, `vendor/withdraw/saved/ways/${id}`, setGetItemLoading, WirgdrawWayModule)
  }
  const submit = () => {
    if (id) {
      Put(
        EWalletSchema,
        data,
        EWalletObj,
        setErrors,
        () => {
          navigate('/wallet/methods')
        },
        setIsLoading,
        `vendor/withdraw/wallet/${id}`
      )
    } else {
      Post(
        EWalletSchema,
        data,
        EWalletObj,
        setErrors,
        () => {
          navigate('/wallet/methods')
        },
        setIsLoading,
        'vendor/withdraw/wallet'
      )
    }
  }

  return (
    <Stack gap='40px' p={{xs: '0 30px'}}>
      <Stack gap='32px'>
        <GoBack
          onClick={() => {
            if (isSetting) {
              navigate('/settings')
            } else {
              navigate('/wallet/methods')
            }
          }}
          text='goBack'
        />

        <Stack
          gap='32px'
          // justifyContent={'space-between'}
          flexWrap={'wrap'}
          direction={'row'}
          width={{lg: '836px'}}
        >
          <SelectWithLabel
            fw='400'
            name='bankName'
            placeHolder='walletName'
            type='text'
            msgErr={msgErr}
            setData={setData}
            data={data}
            width={{xs: '100%', md: '395px'}}
            errors={errors}
            options={wallets}
            Module={CityModule}
          />

          <InputWithLabel
            fw='400'
            name='name'
            placeHolder='name'
            type='text'
            msgErr={msgErr}
            setData={setData}
            data={data}
            width={{xs: '100%', md: '395px'}}
            errors={errors}
          />
          <Stack width={'100%'}>
            <InputPhoneWithLabel
              fw='400'
              name='phone'
              placeHolder='payerMobileNumber'
              msgErr={msgErr}
              setData={setData}
              data={data}
              width={{xs: '100%', md: '395px'}}
              errors={errors}
              codeName='code'
            />
          </Stack>

          <Box display={'flex'}>
            <MainBtn isLoading={isLoading} text='save' handelSubmit={submit} />
          </Box>
        </Stack>
        {/* <PopUp open={open} setOpen={setOpen} width='md'>
          <ConfirmForm
            setOpenSuccess={setOpenSuccess}
            setIsActive={setIsActive}
            setOpen={setOpen}
            submit={() => {
              Post(EWalletSchema, data, EWalletObj, setErrors, () => {
                navigate('/wallet/methods')
              })
            }}
          />
        </PopUp> */}
      </Stack>
    </Stack>
  )
}
