import React from 'react'
import {useTranslation} from 'react-i18next'

export default function ActionBtn({fun, disabled, bgcolor, text}: any) {
  const {t} = useTranslation()
  return (
    <button
      onClick={fun}
      disabled={disabled}
      style={{
        color: '#fff',
        background: bgcolor,
        textTransform: 'capitalize',
        border: 'none',
        borderRadius: '4px',
        padding: '6px 12px',
      }}
    >
      {t(text)}
    </button>
  )
}
