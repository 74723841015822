import React, {useEffect, useState} from 'react'
import {Stack, Typography} from '@mui/material'
import Table from './Table'
import {useTranslation} from 'react-i18next'
import Get from '../../fubcations/mastars/Get'

export default function PaymentRefund() {
  const [rows, setRows] = useState<any>([])
  const [added, setAdded] = useState(true)
  Get([], setRows, 'vendor/payments/online/refund/requests', setAdded)

  return <Table setAdded={setAdded} added={added} rows={rows} setRows={setRows} />
}
// var(--bs-body-bg)
