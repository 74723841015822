import React, {useState} from 'react'
import {ActionsCell} from './customTable/Actions'
import CustomSelect from '../components/selects/CustomSelect'
import {Stack} from '@mui/material'
import DateInput from './customTable/DateInput'
import DeletedMsg from './customTable/DeleteMsg'
import SunEditorText from './customTable/SunEditorText'
import EditorText from './customTable/EditorText'
import CustomTable from './customTable'
import ImgMsg from './customTable/ImgMsg'
import InputColor from './customTable/InputColor'
import InputType from './customTable/InputType'
import LabelInput from './customTable/LabelInput'
import TabsLangWithFlag from './customTable/LangTabs'
import Loading from './customTable/LoadingScreen'
import ErrMsg from './customTable/MsgErr'
import MultiSelectDropdown from './customTable/MultiSelectDropdown'
import RedStart from './customTable/RedStart'
import RestSaveButton from './customTable/RestSaveBtns'
import Switch from './customTable/Switch'
import TitlePage from './customTable/TitlePage'
import UploadImg from './customTable/UploadImg'
import SelectGroup from './customTable/SelectGroup'
import Btn from './customTable/Btn'
import InputGroup from './customTable/InputDate'
import InputDate from './customTable/InputDate'
import AvatarImg from './customTable/AvatarImg'
import CheckBox from './customTable/CheckBox'
import InputWithUnit from './customTable/InputWithUnit'
import CardBtn from './customTable/CardBtn'

export default function CustomPage() {
  const [select, setSelect] = useState({sunEditorTest: {}})
  const [date, setDate] = useState<any>('')
  const [openDeleteMsg, setOpenDeleteMsg] = useState(false)
  const [activeLang, setActiveLang] = useState('')
  const [base64Image, setBase64Image] = useState('')
  const [selected, setSelected] = useState({})
  const [isActive, setIsActive] = useState('type1')
  return (
    <div>
      <Stack direction={'row'} flexWrap={'wrap'} gap='40px'>
        <Btn
          submit={() => {
            if (isActive !== 'type1') {
              setIsActive('type1')
            }
          }}
          bg={isActive}
          type='type1'
          text='test'
        />
        <Btn
          submit={() => {
            if (isActive !== 'type2') {
              setIsActive('type2')
            }
          }}
          bg={isActive}
          type='type2'
          text='test1'
        />
        <Stack width={'300px'}>
          <InputDate />
        </Stack>
        <CheckBox
          submit={() => {
            if (isActive !== 'type1') {
              setIsActive('type1')
            }
          }}
          bg={isActive}
          type='type1'
          text='test'
        />
        <CheckBox
          submit={() => {
            if (isActive !== 'type2') {
              setIsActive('type2')
            }
          }}
          bg={isActive}
          type='type2'
          text='test1'
        />
        <Stack>
          <AvatarImg />
        </Stack>
        <Stack width={'300px'}>{/* <InputWithUnit placeHolder={'test'} unit={'EP'} /> */}</Stack>
        <CardBtn width='194px' submit={() => {}} text='select' disabled />
        <CardBtn width='194px' submit={() => {}} text='select' disabled={false} />
        <Stack width={'200px'}>
          <CustomSelect
            name={'select'}
            values={select}
            setValues={setSelect}
            value={{id: 0}}
            option={[{id: 1, name: 'test'}]}
          />
        </Stack>
        <Stack width={'200px'}>
          <MultiSelectDropdown
            values={select}
            setValues={setSelect}
            name='MultiSelect'
            selectOptions={[
              {id: 1, name: {en: 'test'}},
              {id: 2, name: {en: 'test2'}},
            ]}
          />
        </Stack>
        <Stack width={'200px'}>
          <SelectGroup
            selected={selected}
            setSelected={setSelected}
            setOptionValue={[]}
            selectedOption={[
              {
                id: 1,
                name: {en: 'test'},
                options: [
                  {id: 1, name: {en: 'option1'}},
                  {id: 2, name: {en: 'option2'}},
                ],
              },
              {
                id: 2,
                name: {en: 'test2'},
                options: [
                  {id: 1, name: {en: 'option1'}},
                  {id: 2, name: {en: 'option2'}},
                ],
              },
            ]}
          />
        </Stack>
        <Stack width={'200px'}>
          <ActionsCell
            onDelete={() => {}}
            onEdit={() => {}}
            onReplay={() => {}}
            onView={() => {
              setOpenDeleteMsg(true)
            }}
          />
        </Stack>
        <Stack width={'200px'}>
          <DateInput date={date} setDate={setDate} />
        </Stack>
        <Stack width={'200px'}></Stack>
        <DeletedMsg open={openDeleteMsg} setOpen={setOpenDeleteMsg} onClick={() => {}} />
        <Stack width={'300px'}>
          <ImgMsg />
        </Stack>
        <Stack width={'300px'}>
          <InputColor type='color' name={'color'} value={select} change={setSelect} />
        </Stack>
        <Stack width={'300px'}>
          <InputType type='string' name={'color'} value={select} change={setSelect} />
        </Stack>
        <Stack width={'300px'}>
          <RestSaveButton
            SaveText={'save'}
            cancelText={'cancel'}
            submit={() => {}}
            cancel={() => {}}
            isLoading={false}
          />
          <RestSaveButton
            SaveText={'save'}
            cancelText={'cancel'}
            submit={() => {}}
            cancel={() => {}}
            isLoading={true}
          />
        </Stack>
        <Switch name={'status'} value={true} handleChange={() => {}} />
        <TitlePage info={{title: 'test', home: 'home', linkHome: '/'}} />
        <Stack gap='7px' direction={'row'} width={'100px'}>
          <LabelInput text='label' />
          <RedStart />
        </Stack>
        <Stack position={'relative'} width={'300px'}>
          <TabsLangWithFlag
            setActiveLang={setActiveLang}
            activeLang={activeLang}
            languages={[
              {code: 'en', lang: 'english'},
              {code: 'ar', lang: 'عربي'},
            ]}
          />
          <InputType type='string' name={'color'} value={select} change={setSelect} />
          <ErrMsg msgErr={'label error'} name={'label'} />
        </Stack>
        <Stack position={'relative'} height={'100px'} width={'100px'}>
          <Loading />
        </Stack>
        <Stack position={'relative'} height={'100px'} width={'100px'}>
          <UploadImg base64Image={base64Image} setBase64Image={setBase64Image} />
        </Stack>
        <Stack width={'600px'}>
          <SunEditorText name={'sunEditorTest'} value={select} change={setSelect} child={'en'} />
        </Stack>
        <Stack width={'600px'}>
          <EditorText name={'sunEditorTest'} value={select} change={setSelect} child={'en'} />
        </Stack>
        <Stack width={'1000px'}>
          <CustomTable />
        </Stack>
      </Stack>
    </div>
  )
}
