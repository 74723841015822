import {Stack} from '@mui/material'
import React from 'react'

export default function Logo({width}: any) {
  return (
    <Stack>
      <img style={{width: width || 'auto'}} src='./media/avatars/logo.svg' alt='' />
    </Stack>
  )
}
