import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import {RootState} from '../../store'
import {
  Stack,
  Typography,
  Select,
  MenuItem,
  createTheme,
  ThemeProvider,
  FormControl,
  InputLabel,
  Box,
} from '@mui/material'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {useDispatch} from 'react-redux'
import i18n from '../../../i18n'
import {actions} from '../../store/admin/auth.slice'
import {NotifySchema} from '../../yup/SettingSchema'
import {LangObj} from '../modules/post/SettingsModule'
import Post from '../../fubcations/mastars/Post'

const theme = createTheme({
  components: {
    MuiSelect: {
      styleOverrides: {
        select: {
          '&:before': {
            borderBottom: 'none !important',
            background: 'red',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          background: 'var(--bs-body-bg)',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          background: 'var(--bs-body-bg)',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          background: 'var(--bs-body-bg)',
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          background: 'var(--bs-body-bg)',
        },
      },
    },
  },
})

export default function SelectLangs({option, value}: {option: any; value?: any; color?: string}) {
  const [selectedOption, setSelectedOption] = useState<any>(value)
  const dispatch = useDispatch()
  const store = useSelector((state: RootState) => state.vender)

  const handleChange = (event: any) => {
    const selectedId = parseInt(event.target.value)
    const selectedItem = option.find(
      (item: any) => item.id === selectedId || item.value === selectedId
    )

    setSelectedOption(selectedItem)
  }
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)

  return (
    <Stack position={'relative'}>
      <ThemeProvider theme={theme}>
        <FormControl className='select-lang' variant='standard' sx={{m: 1, minWidth: 80}}>
          <Select
            labelId='demo-simple-select-standard-label'
            id='demo-simple-select-standard'
            sx={{
              // border: '1px solid var(--bs-gray-300)',
              color: ' var(--bs-text-dark)',
              '&.css-rnbnro-MuiInputBase-root-MuiInput-root-MuiSelect-root:before': {
                borderBottom: '1px solid red !important',
              },
              // borderRadius: '24px',
              width: '100%',
            }}
            value={
              (store.user && store.user.language_code) || localStorage.getItem('myLang') || 'en'
            }
            onChange={handleChange}
          >
            {option.map((e: any) => (
              <MenuItem
                onClick={() => {
                  if (store.user) {
                    Post(
                      NotifySchema,
                      {language_id: e.id},
                      LangObj,
                      setErrors,
                      () => {
                        dispatch(actions.setRtl(e.is_rtl ? 1 : 0))
                        dispatch(actions.login({...store.user, language_code: e.code}))
                        i18n.changeLanguage(e.code)
                      },
                      setLoading,
                      'profile/change-language'
                    )
                  } else {
                    localStorage.setItem('myLang', e.code)
                    i18n.changeLanguage(e.code)
                  }
                }}
                sx={{color: ' var(--bs-text-dark)'}}
                key={e.id}
                value={e.code}
              >
                <Stack
                  gap='10px'
                  sx={{'&.css-1auam8d-MuiStack-root': {width: '80%'}}}
                  direction={'row'}
                >
                  <img className='w-15px h-15px rounded-1 ms-2' src={e.flag} alt='' />
                  <Typography>{e.name}</Typography>
                </Stack>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </ThemeProvider>
    </Stack>
  )
}
