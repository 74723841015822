import React, {useState, useCallback} from 'react'
import HeadBar from './Head'
import HeadTable from './HeadTable'
import Row from './Row'
import Search from './Search'
import {Stack} from '@mui/material'
import {PaginationSection} from './pagnationSection'

export default function CustomTable() {
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const rows = [1, 2, 3, 4, 5, 6, 7, 8]
  const [timerId, setTimerId] = useState<number | null>(null)
  const [search, setSearch] = useState('')
  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newSearchValue = e.target.value
      setSearch(e.target.value)
      if (timerId) {
        clearTimeout(timerId)
      }

      setTimerId(window.setTimeout(() => {}, 500))
    },
    [timerId]
  )
  const handleChangePage = (newPage: number) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (newPrePage: number) => {
    setRowsPerPage(newPrePage)
    setPage(1)
  }
  return (
    <div style={{minWidth: '900px', margin: 'auto'}} className={`card fds sha`}>
      {/* <HeadBar /> */}
      <div className='card-body py-3 pb-5'>
        <div className='table-responsive overflow-auto'>
          <Stack width={'200px'}>
            <Search search={search} handleSearch={handleSearch} />
          </Stack>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <HeadTable />

            <tbody className='mb-4'>
              {rows.map((e, i) => (
                <Row key={i} />
              ))}
            </tbody>
          </table>
          {true && (
            <PaginationSection
              rowsPerPageOptions={[10, 20, 30, 40, 50, 60]}
              count={100}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
          {/* pagnation  item   */}

          {/* {isLoading && <CustomerListLoading />} */}
        </div>
      </div>
    </div>
  )
}
