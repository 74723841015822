import React, {useState, useCallback, useEffect} from 'react'
import TableHead from './TablHead'
import Row from './Row'

import {Box, CircularProgress, Stack} from '@mui/material'
import {useDispatch} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import TitlePage from '../../../../modules/customTable/TitlePage'
import Filter from '../../../../parts/Filter'
import {PaginationSection} from '../../../../modules/customTable/pagnationSection'
import Loading from '../../../../modules/customTable/LoadingForm'
import DepositHistoryModule from '../../../../modules/modules/get/DepositHistory'
import EmptyData from '../../../../parts/EmptyData'

export default function Table({
  rows,
  setRows,
  added,
  setAdded,
}: {
  rows: any
  setRows: React.Dispatch<React.SetStateAction<any>>
  added: boolean
  setAdded: any
}) {
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState<number>(1)
  const [rowsPerPage, setRowsPerPage] = useState<number>(5)
  const [total, setTotal] = useState(1)
  const {t} = useTranslation()
  const [search, setSearch] = useState('')
  const [timerId, setTimerId] = useState<number | null>(null)
  const [filterData, setFilterData] = useState({})
  const [filter, setFilter] = useState(false)
  const [ModifiedRow, setModifiedRow] = useState(rows)
  const [newRows, setNewRows] = useState(ModifiedRow.slice((page - 1) * rowsPerPage, rowsPerPage))

  const bannerInfo = {
    title: t('depositHistory'),
    home: t('home'),
    linkHome: '/',
  }
  const handleChangePage = (newPage: number) => {
    if (newPage !== page) {
      setPage(newPage)
      setNewRows(ModifiedRow.slice((newPage - 1) * rowsPerPage, rowsPerPage * newPage))
      setTimeout(() => {
        setIsLoading(false)
      }, 0)

      setIsLoading(true)
    }
  }
  const handleChangeRowsPerPage = (newPrePage: number) => {
    setRowsPerPage(newPrePage)
    setNewRows(rows.slice(0, newPrePage))
    setPage(1)
  }
  useEffect(() => {
    setNewRows(ModifiedRow.slice((page - 1) * rowsPerPage, rowsPerPage))
  }, [ModifiedRow])
  useEffect(() => {
    setModifiedRow(rows)
  }, [rows])
  return (
    <div className={`card fds sha`}>
      <Box p={{xs: '0px'}}>
        <Stack
          direction={'row'}
          alignItems={'center'}
          flexWrap={'wrap'}
          gap='32px'
          justifyContent={'space-between'}
          mb={'32px'}
        >
          <TitlePage info={bannerInfo} />
          <Filter
            data={filterData}
            setData={setFilterData}
            setFilterRows={setModifiedRow}
            filterRows={rows.map((e: any) => {
              return {...e, date: e.date}
            })}
            open={filter}
            setOpen={setFilter}
          />
        </Stack>
        {!added ? (
          <>
            <Stack className='table-responsive overflow-auto'>
              <table
                style={{position: 'relative', minHeight: newRows.length === 0 ? '500px' : '0'}}
                className='table  table-row-gray-300 align-middle gs-0 gy-4'
              >
                <TableHead />

                <>
                  {!isLoading ? (
                    <tbody className='mb-4'>
                      {!added && newRows.length === 0 ? (
                        <Stack width={'100%'} height={'200px'} position={'absolute'}>
                          <EmptyData />
                        </Stack>
                      ) : (
                        newRows.map((e: any, i: any) => (
                          <Row
                            setAdded={setAdded}
                            setRows={setRows}
                            rows={rows}
                            item={DepositHistoryModule(e)}
                            i={i}
                            key={i}
                          />
                        ))
                      )}
                    </tbody>
                  ) : (
                    <CircularProgress />
                  )}
                </>
              </table>
            </Stack>
            {newRows.length !== 0 && (
              <PaginationSection
                rowsPerPageOptions={[5, 10, 15]}
                count={ModifiedRow.length || 1}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}{' '}
          </>
        ) : (
          <Loading />
        )}
      </Box>
    </div>
  )
}
