import React from 'react'

export default function PaymentModule(data: any) {
  return {
    id: data.id,
    name: data.name,
    img: data.image,
    link: `/wallet/balanceDeposit/${data.id}/${data.name}`,
    type: data.type,
  }
}
