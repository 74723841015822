import React from 'react'

export default function ExchangeOfficeIcon() {
  return (
    <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='20' cy='20' r='20' fill='white' />
      <path
        d='M15.3776 19.6296H14.6276H15.3776ZM15.3776 20.5556L14.8161 21.0528C14.9514 21.2056 15.1431 21.2966 15.347 21.3049C15.5508 21.3133 15.7493 21.2382 15.8966 21.0969L15.3776 20.5556ZM17.5191 19.5414C17.818 19.2547 17.828 18.7799 17.5414 18.4809C17.2547 18.182 16.7799 18.172 16.4809 18.4586L17.5191 19.5414ZM14.5615 18.5028C14.2869 18.1927 13.8129 18.1639 13.5028 18.4385C13.1927 18.7131 13.1639 19.1871 13.4385 19.4972L14.5615 18.5028ZM22.9317 17.0093C23.213 17.3134 23.6875 17.3318 23.9915 17.0506C24.2956 16.7693 24.3141 16.2948 24.0328 15.9907L22.9317 17.0093ZM20.0437 14.25C17.058 14.25 14.6276 16.653 14.6276 19.6296H16.1276C16.1276 17.4925 17.8753 15.75 20.0437 15.75V14.25ZM14.6276 19.6296L14.6276 20.5556H16.1276L16.1276 19.6296H14.6276ZM15.8966 21.0969L17.5191 19.5414L16.4809 18.4586L14.8585 20.0142L15.8966 21.0969ZM15.939 20.0583L14.5615 18.5028L13.4385 19.4972L14.8161 21.0528L15.939 20.0583ZM24.0328 15.9907C23.0431 14.9209 21.6212 14.25 20.0437 14.25V15.75C21.1879 15.75 22.2154 16.235 22.9317 17.0093L24.0328 15.9907Z'
        fill='#FF9637'
      />
      <path
        d='M24.6188 19.4445L25.1795 18.9463C25.044 18.7939 24.8523 18.7031 24.6485 18.695C24.4447 18.687 24.2464 18.7622 24.0993 18.9035L24.6188 19.4445ZM22.4805 20.4582C22.1817 20.7451 22.1722 21.2199 22.4591 21.5187C22.746 21.8174 23.2208 21.827 23.5195 21.5401L22.4805 20.4582ZM25.4393 21.4973C25.7144 21.8069 26.1885 21.8349 26.4981 21.5598C26.8078 21.2847 26.8358 20.8107 26.5607 20.501L25.4393 21.4973ZM17.0469 23.0048C16.7634 22.7028 16.2888 22.6877 15.9868 22.9712C15.6847 23.2547 15.6697 23.7293 15.9531 24.0313L17.0469 23.0048ZM19.9348 25.75C22.9276 25.75 25.3688 23.3497 25.3688 20.3704H23.8688C23.8688 22.5048 22.1158 24.25 19.9348 24.25V25.75ZM25.3688 20.3704V19.4445H23.8688V20.3704H25.3688ZM24.0993 18.9035L22.4805 20.4582L23.5195 21.5401L25.1383 19.9854L24.0993 18.9035ZM24.0581 19.9426L25.4393 21.4973L26.5607 20.501L25.1795 18.9463L24.0581 19.9426ZM15.9531 24.0313C16.9454 25.0887 18.3635 25.75 19.9348 25.75V24.25C18.792 24.25 17.7655 23.7705 17.0469 23.0048L15.9531 24.0313Z'
        fill='#FF9637'
      />
      <circle cx='20' cy='20' r='10' stroke='#FF9637' stroke-width='1.5' />
    </svg>
  )
}
