import {Button, Stack, Tooltip} from '@mui/material'
import React, {useEffect} from 'react'
import {useState} from 'react'
import ImgMsg from './ImgMsg'
import {useTranslation} from 'react-i18next'

export default function UploadImg({base64Image, setBase64Image, name, value, setValue}: any) {
  const [image, setImage] = useState(base64Image || '/media/avatars/img.svg')
  const {t} = useTranslation()

  const handleImageChange = (event: any) => {
    const file = event.target.files[0]
    if (file && file.type.includes('image/')) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        setBase64Image(file)
        if (name && value && setValue) {
          setValue({...value, [name]: file})
        }
        setImage(reader.result as string)
      }
      reader.onerror = (error) => {}
    } else {
      setBase64Image(null)
      alert(t('Only jpeg , jpg , png and svg image files are accepted'))
    }
  }

  useEffect(() => {
    if (value && value[name] && value[name].type && value[name].type.includes('image/')) {
      const reader = new FileReader()
      reader.readAsDataURL(value[name])
      reader.onload = () => {
        setBase64Image(value[name])

        setImage(reader.result as string)
      }
      reader.onerror = (error) => {}
    }
  }, [])
  return (
    <>
      <Tooltip title='Only jpeg , jpg , png and svg image files are accepted'>
        <Stack width='100%' height='100%'>
          <Button
            component={'label'}
            sx={{width: '100%', height: '100%', padding: '10px', borderRadius: '7px'}}
          >
            <img
              style={{width: '100%', height: '100%'}}
              src={(value && typeof value[name] === 'string' && value[name]) || image}
              alt=''
            />
            <input
              hidden
              // accept='.jpg, .jpeg, .png, .svg'
              onChange={handleImageChange}
              multiple
              type='file'
            />
          </Button>
        </Stack>
      </Tooltip>
    </>
  )
}
