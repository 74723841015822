export default function DepositHistoryModule(data: any) {
  return {
    date: data.date,
    depositId: data.id,
    depositMethod: data.deposit_method,
    amount: data.amount_deposit,
    fee: data.fee,
    status: data.status,
    reciveAmount: data.recive_amount,
    balance: data.wallet_before,
  }
}
