import React from 'react'

export default function NoWithDraw() {
  return (
    <svg
      width='296'
      height='244'
      viewBox='0 0 296 244'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_d_885_21850)'>
        <path
          d='M13.7891 108.232V110.665'
          stroke='#D2D8DF'
          stroke-width='2.921'
          stroke-linecap='round'
        />
        <path
          d='M8.3252 109.549L10.3131 111.596'
          stroke='#D2D8DF'
          stroke-width='2.921'
          stroke-linecap='round'
        />
        <path d='M6 114.997H8.81092' stroke='#D2D8DF' stroke-width='2.921' stroke-linecap='round' />
        <path
          d='M8.09717 120.541L10.0851 118.495'
          stroke='#D2D8DF'
          stroke-width='2.921'
          stroke-linecap='round'
        />
        <path
          d='M13.7891 121.207V118.774'
          stroke='#D2D8DF'
          stroke-width='2.921'
          stroke-linecap='round'
        />
        <path
          d='M17.8435 119.585L16.2217 117.963'
          stroke='#D2D8DF'
          stroke-width='2.921'
          stroke-linecap='round'
        />
        <path
          d='M21.0876 115.53H18.6548'
          stroke='#D2D8DF'
          stroke-width='2.921'
          stroke-linecap='round'
        />
        <path
          d='M19.4656 109.854L17.8438 111.476'
          stroke='#D2D8DF'
          stroke-width='2.921'
          stroke-linecap='round'
        />
        <path d='M144.349 2V3.62186' stroke='#D2D8DF' stroke-width='2.921' stroke-linecap='round' />
        <path
          d='M139.483 6.86558H141.105'
          stroke='#D2D8DF'
          stroke-width='2.921'
          stroke-linecap='round'
        />
        <path
          d='M144.349 11.7312V10.1093'
          stroke='#D2D8DF'
          stroke-width='2.921'
          stroke-linecap='round'
        />
        <path
          d='M148.404 6.86558H146.782'
          stroke='#D2D8DF'
          stroke-width='2.921'
          stroke-linecap='round'
        />
        <path
          d='M50.2808 70.1181V72.5509'
          stroke='#D2D8DF'
          stroke-width='2.921'
          stroke-linecap='round'
        />
        <path
          d='M42.9824 77.4165H45.4152'
          stroke='#D2D8DF'
          stroke-width='2.921'
          stroke-linecap='round'
        />
        <path
          d='M50.2808 83.904V81.4712'
          stroke='#D2D8DF'
          stroke-width='2.921'
          stroke-linecap='round'
        />
        <path
          d='M56.7682 77.4165H54.3354'
          stroke='#D2D8DF'
          stroke-width='2.921'
          stroke-linecap='round'
        />
        <path
          d='M224.837 158.541C226.775 158.541 228.347 160.113 228.347 162.052C228.347 163.991 226.775 165.562 224.837 165.562C222.899 165.562 221.327 163.991 221.327 162.052'
          stroke='#D2D8DF'
          stroke-width='2.921'
          stroke-linecap='round'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M240.899 126.691C242.983 126.041 246.177 123.493 246.917 120.751C247.587 123.175 250.398 126.041 252.934 126.258C250.079 127.269 247.35 130.272 246.917 132.631C246.622 130.227 242.847 127.042 240.899 126.691Z'
          fill='#EBECEE'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M26.9844 157.46C28.0267 157.135 29.6232 155.861 29.9929 154.49C30.3282 155.701 31.7333 157.135 33.0014 157.244C31.5742 157.748 30.2097 159.25 29.9929 160.43C29.8456 159.227 27.9578 157.635 26.9844 157.46Z'
          fill='#EBECEE'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M46.3271 44.6525C46.3271 46.1564 45.1086 47.3746 43.6057 47.3746C42.1021 47.3746 40.8843 46.1564 40.8843 44.6525C40.8843 43.1492 42.1021 41.9304 43.6057 41.9304C45.1086 41.9304 46.3271 43.1492 46.3271 44.6525Z'
          fill='#D1D8DF'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M228.052 51.9618C228.052 54.4746 226.015 56.5116 223.504 56.5116C220.991 56.5116 218.955 54.4746 218.955 51.9618C218.955 49.449 220.991 47.412 223.504 47.412C226.015 47.412 228.052 49.449 228.052 51.9618Z'
          fill='#EBECEE'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M291.057 39.6349C291.057 41.6284 289.442 43.2439 287.45 43.2439C285.457 43.2439 283.842 41.6284 283.842 39.6349C283.842 37.6413 285.457 36.0258 287.45 36.0258C289.442 36.0258 291.057 37.6413 291.057 39.6349Z'
          fill='#EBECEE'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M97.1882 208.193C97.1882 209.45 96.17 210.468 94.9136 210.468C93.6579 210.468 92.6396 209.45 92.6396 208.193C92.6396 206.937 93.6579 205.918 94.9136 205.918C96.17 205.918 97.1882 206.937 97.1882 208.193Z'
          stroke='#D2D8DF'
          stroke-width='2.921'
          stroke-linecap='round'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M95.8349 27.1644C95.8349 28.6684 94.6164 29.8866 93.1135 29.8866C91.6106 29.8866 90.3921 28.6684 90.3921 27.1644C90.3921 25.6612 91.6106 24.4423 93.1135 24.4423C94.6164 24.4423 95.8349 25.6612 95.8349 27.1644Z'
          stroke='#D2D8DF'
          stroke-width='2.921'
          stroke-linecap='round'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M165.253 233.388C163.514 234.049 161.568 233.176 160.906 231.437C160.245 229.697 161.118 227.75 162.857 227.089C164.596 226.427 166.542 227.3 167.203 229.04C167.866 230.779 166.992 232.726 165.253 233.388Z'
          stroke='#D2D8DF'
          stroke-width='2.921'
          stroke-linecap='round'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M164.216 169.052H97.7155C93.0349 169.052 89.2036 165.209 89.2036 160.513V65.6805C89.2036 60.9842 93.0349 57.1434 97.7155 57.1434H164.216C168.898 57.1434 172.729 60.9842 172.729 65.6805V160.513C172.729 165.209 168.898 169.052 164.216 169.052Z'
          fill='#E0E2EE'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M158.539 173.106H92.0393C87.3587 173.106 83.5273 169.264 83.5273 164.567V69.7352C83.5273 65.0388 87.3587 61.1981 92.0393 61.1981H158.539C163.222 61.1981 167.053 65.0388 167.053 69.7352V164.567C167.053 169.264 163.222 173.106 158.539 173.106Z'
          fill='#E8EBF2'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M153.018 176.307H86.3801C81.6898 176.307 77.8506 172.481 77.8506 167.804V73.364C77.8506 68.6871 81.6898 64.8623 86.3801 64.8623H153.018C157.71 64.8623 161.549 68.6871 161.549 73.364V167.804C161.549 172.481 157.71 176.307 153.018 176.307Z'
          fill='#D8DBEA'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M153.018 176.307H86.3801C81.6898 176.307 77.8506 172.481 77.8506 167.804V73.364C77.8506 68.6871 81.6898 64.8623 86.3801 64.8623H153.018C157.71 64.8623 161.549 68.6871 161.549 73.364V167.804C161.549 172.481 157.71 176.307 153.018 176.307Z'
          fill='#F1F2F7'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M143.412 93.6353H94.1924C92.7868 93.6353 91.6362 92.5407 91.6362 91.2034C91.6362 89.8643 92.7868 88.7697 94.1924 88.7697H143.412C144.818 88.7697 145.969 89.8643 145.969 91.2034C145.969 92.5407 144.818 93.6353 143.412 93.6353Z'
          fill='#E0E2EE'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M143.412 108.232H94.1924C92.7868 108.232 91.6362 107.137 91.6362 105.8C91.6362 104.461 92.7868 103.366 94.1924 103.366H143.412C144.818 103.366 145.969 104.461 145.969 105.8C145.969 107.137 144.818 108.232 143.412 108.232Z'
          fill='#E0E2EE'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M143.412 122.829H94.1924C92.7868 122.829 91.6362 121.734 91.6362 120.397C91.6362 119.06 92.7868 117.963 94.1924 117.963H143.412C144.818 117.963 145.969 119.06 145.969 120.397C145.969 121.734 144.818 122.829 143.412 122.829Z'
          fill='#E0E2EE'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M143.412 137.426H94.1924C92.7868 137.426 91.6362 136.331 91.6362 134.994C91.6362 133.655 92.7868 132.56 94.1924 132.56H143.412C144.818 132.56 145.969 133.655 145.969 134.994C145.969 136.331 144.818 137.426 143.412 137.426Z'
          fill='#E0E2EE'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M119.884 152.022H94.2034C92.7917 152.022 91.6362 150.928 91.6362 149.59C91.6362 148.251 92.7917 147.157 94.2034 147.157H119.884C121.296 147.157 122.452 148.251 122.452 149.59C122.452 150.928 121.296 152.022 119.884 152.022Z'
          fill='#E0E2EE'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M171.72 133.157C161.878 142.998 146.641 144.108 135.579 136.51C134.166 135.522 132.82 134.412 131.565 133.157C130.919 132.513 130.311 131.849 129.737 131.15C128.59 129.753 127.584 128.263 126.761 126.724C125.31 124.124 124.324 121.365 123.785 118.513C122.047 109.571 124.646 99.9448 131.565 93.0283C138.502 86.0912 148.129 83.5099 157.073 85.2307C159.923 85.7858 162.685 86.7721 165.285 88.2061C166.827 89.048 168.296 90.0529 169.695 91.2001C170.393 91.7717 171.057 92.3825 171.701 93.0283C172.958 94.2828 174.085 95.6261 175.055 97.0416C182.656 108.099 181.543 123.335 171.72 133.157Z'
          fill='white'
          fill-opacity='0.1'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M168.966 131.219C158.727 141.46 142.133 141.458 131.892 131.219C121.664 120.991 121.664 104.396 131.905 94.1565C142.133 83.9287 158.727 83.9287 168.954 94.1565C179.194 104.396 179.194 120.991 168.966 131.219ZM174.221 88.8958C161.078 75.7528 139.78 75.7528 126.637 88.8958C113.496 102.037 113.484 123.348 126.626 136.491C138.587 148.449 157.336 149.528 170.523 139.709C171.811 138.749 173.051 137.673 174.234 136.491C175.415 135.308 176.492 134.068 177.45 132.78C187.269 119.591 186.181 100.856 174.221 88.8958Z'
          fill='#E0E2EE'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M225.944 186.034L225.392 186.586C221.742 190.238 215.764 190.238 212.112 186.586L178.406 152.88L192.238 139.047L225.944 172.753C229.597 176.406 229.597 182.382 225.944 186.034Z'
          fill='url(#paint0_linear_885_21850)'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M178.177 131.749L188.137 141.709L181.066 148.778L171.107 138.818C172.422 137.838 173.685 136.74 174.895 135.534C176.1 134.329 177.199 133.063 178.177 131.749Z'
          fill='#E0E2EE'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M225.44 172.339L211.696 186.081L209.221 183.605L222.964 169.863L225.44 172.339Z'
          fill='#EF545F'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M193.003 139.523L178.882 153.644L176.784 151.546L190.905 137.425L193.003 139.523Z'
          fill='url(#paint1_linear_885_21850)'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M162.188 90.6336L127.558 125.261C126.254 122.924 125.367 120.444 124.885 117.88L154.806 87.9586C157.368 88.4595 159.85 89.3443 162.188 90.6336Z'
          fill='white'
          fill-opacity='0.5'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M173.54 98.8643L136.102 134.182C134.761 133.299 133.485 132.306 132.295 131.183C131.683 130.605 131.103 130.012 130.561 129.386L168.455 93.6351C169.119 94.1484 169.749 94.695 170.36 95.2729C171.55 96.3956 172.62 97.5976 173.54 98.8643Z'
          fill='white'
          fill-opacity='0.5'
        />
      </g>
      <defs>
        <filter
          id='filter0_d_885_21850'
          x='0.539551'
          y='0.539501'
          width='294.518'
          height='242.53'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='2' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_885_21850' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_885_21850'
            result='shape'
          />
        </filter>
        <linearGradient
          id='paint0_linear_885_21850'
          x1='203.545'
          y1='113.908'
          x2='153.267'
          y2='164.186'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#FF8960' />
          <stop offset='1' stop-color='#FF62A5' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_885_21850'
          x1='184.893'
          y1='129.316'
          x2='168.675'
          y2='145.535'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#FF404E' />
          <stop offset='1' stop-color='#FF62A5' />
        </linearGradient>
      </defs>
    </svg>
  )
}
