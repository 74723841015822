import React from 'react'

export default function FileIcon() {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clip-path='url(#clip0_1_61586)'>
        <path
          d='M16.5227 2.17892C15.5745 1.23382 14.2903 0.703125 12.9515 0.703125C11.6128 0.703125 10.3286 1.23382 9.38039 2.17892L1.06961 10.4891C0.732626 10.8206 0.464619 11.2156 0.281045 11.6513C0.097471 12.0869 0.00196325 12.5546 2.99507e-05 13.0273C-0.00190334 13.5001 0.0897761 13.9685 0.269781 14.4057C0.449785 14.8428 0.714553 15.24 1.04881 15.5743C1.38307 15.9086 1.78021 16.1734 2.21733 16.3534C2.65444 16.5335 3.12287 16.6252 3.59561 16.6234C4.06836 16.6215 4.53605 16.526 4.97172 16.3425C5.40739 16.159 5.80242 15.8911 6.13402 15.5541L13.9258 7.76295C14.3166 7.36401 14.5355 6.8278 14.5355 6.26935C14.5355 5.71091 14.3166 5.1747 13.9258 4.77575C13.5233 4.39185 12.9884 4.17767 12.4322 4.17767C11.876 4.17767 11.3411 4.39185 10.9386 4.77575L3.66645 12.0479C3.55547 12.1701 3.49574 12.3304 3.49963 12.4954C3.50352 12.6604 3.57072 12.8177 3.68733 12.9345C3.80394 13.0514 3.96102 13.1189 4.12604 13.1231C4.29107 13.1274 4.45141 13.068 4.57386 12.9573L11.8486 5.68574C12.0057 5.53513 12.2149 5.45103 12.4325 5.45103C12.6502 5.45103 12.8594 5.53513 13.0165 5.68574C13.1689 5.8419 13.2542 6.05147 13.2542 6.26968C13.2542 6.48789 13.1689 6.69745 13.0165 6.85361L5.22468 14.6448C4.79418 15.0752 4.21034 15.3169 3.60159 15.3169C2.99284 15.3168 2.40904 15.0749 1.97863 14.6444C1.54822 14.214 1.30645 13.6301 1.30651 13.0214C1.30657 12.4126 1.54846 11.8288 1.97895 11.3984L10.2897 3.08826C10.999 2.39851 11.9513 2.01568 12.9406 2.02259C13.93 2.02949 14.8768 2.42557 15.5764 3.12515C16.276 3.82474 16.6721 4.77159 16.679 5.76093C16.6859 6.75027 16.3031 7.70256 15.6133 8.41184L7.82216 16.203C7.70501 16.3243 7.64019 16.4867 7.64166 16.6554C7.64312 16.824 7.71076 16.9853 7.82999 17.1045C7.94923 17.2237 8.11053 17.2914 8.27915 17.2928C8.44776 17.2943 8.61021 17.2295 8.7315 17.1123L16.5227 9.32183C17.4684 8.37386 17.9996 7.08945 17.9996 5.75037C17.9996 4.41129 17.4684 3.12689 16.5227 2.17892Z'
          fill='#2C2B2B'
        />
      </g>
      <defs>
        <clipPath id='clip0_1_61586'>
          <rect width='18' height='18' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
