import clsx from 'clsx'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {HeaderNotificationsMenu, HeaderUserMenu, Search, ThemeModeSwitcher} from '../../../partials'
import {useLayout} from '../../core'
import {Avatar, Badge, Stack} from '@mui/material'
import SelectLangs from '../../../../app/modules/login/SelectLang'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import Setting from '../../../../app/svg/Setting'
import Label from '../../../../app/components/texts/Label'
import Arrow from '../../../../app/svg/Arrow'
import {useNavigate} from 'react-router-dom'
import Get from '../../../../app/fubcations/mastars/Get'
import {useState} from 'react'
import store, {RootState} from '../../../../app/store'
import {useSelector} from 'react-redux'
import Notifacations from '../../../../app/pages/notifacations'
import TooltipContiner from '../../../../app/parts/TooltipContiner'
const itemClass = 'ms-1 ms-lg-3'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'svg-icon-1'

const Navbar = () => {
  const store = useSelector((state: RootState) => state.vender)

  const {config} = useLayout()
  const image = localStorage.getItem('image')
  const language = navigator.language

  const myLang = store.langs.find((e: any) => e.lang === language.split('-')[0])
  const navigate = useNavigate()

  return (
    <Stack alignItems={'center'} gap='15px' direction={'row'} className='app-navbar flex-shrink-0'>
      <Stack display={{lg: 'block', xs: 'none'}} width={'120px'}>
        <SelectLangs
          value={{
            code: (store.user && store.user.language_code) || 'en',
          }}
          option={store.langs}
          color={'var(--bs-app-footer-bg-color)'}
        />
      </Stack>
      <Notifacations />

      <TooltipContiner text='settings'>
        <Stack onClick={() => navigate('/settings')} sx={{display: {lg: 'block', xs: 'none'}}}>
          <Setting />
        </Stack>
      </TooltipContiner>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <Stack gap='10px' direction={'row'}>
            <Avatar src={image || '/media/avatars/300-1.jpg'} alt='' />
            <Stack>
              <Label color='#5D5A66' fw='400' text={store.user.full_name} />
              <Label color='#89898A' fw='400' text={store.user.type} />
            </Stack>
            <Arrow />
          </Stack>
        </div>
        <HeaderUserMenu />
      </div>
    </Stack>
  )
}

export {Navbar}
