import React from 'react'

export default function TransactionModule(data: any) {
  return {
    amount: data.amount,
    type: data.transaction_type,
    transactionsType: data.details.transaction,
    transactionsNumber: data.details.account_number,
    date: data.date,
  }
}
