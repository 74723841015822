import {Box, SxProps} from '@mui/material'

type IProps = {
  border?: string
  width?: string
  height?: string
  sx?: SxProps
  children?: React.ReactNode
  borderRadius?: string
}
const BoxBorder = ({border, borderRadius, height, width, children, sx}: IProps) => {
  return (
    <Box width={width} borderRadius={borderRadius} height={height} border={border} sx={{...sx}}>
      {children}
    </Box>
  )
}

export default BoxBorder
