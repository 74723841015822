import React from 'react'

export default function Transfer({color}: any) {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.66683 2.66602C4.15267 2.66602 2.89559 2.66602 2.11454 3.44706C1.3335 4.22811 1.3335 5.48519 1.3335 7.99935C1.3335 10.5135 1.3335 11.7706 2.11454 12.5516C2.89559 13.3327 4.15267 13.3327 6.66683 13.3327H7.66683M9.3335 2.66602C11.8477 2.66602 13.1047 2.66602 13.8858 3.44706C14.5945 4.15579 14.6601 5.25648 14.6662 7.33268'
        stroke={color || 'white'}
        stroke-width='1.25'
        stroke-linecap='round'
      />
      <path
        d='M10.3333 9.33398V13.334M10.3333 13.334L11.6667 12.0007M10.3333 13.334L9 12.0007M13.3333 13.334V9.33398M13.3333 9.33398L14.6667 10.6673M13.3333 9.33398L12 10.6673'
        stroke={color || 'white'}
        stroke-width='1.25'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M6.66667 10.666H4'
        stroke={color || 'white'}
        stroke-width='1.25'
        stroke-linecap='round'
      />
      <path
        d='M1.3335 6.66602L4.66683 6.66602M14.6668 6.66602L7.3335 6.66602'
        stroke={color || 'white'}
        stroke-width='1.25'
        stroke-linecap='round'
      />
    </svg>
  )
}
