import {Stack} from '@mui/material'
import React from 'react'
import TitlePage from '../../modules/customTable/TitlePage'
import {useTranslation} from 'react-i18next'
import AddTicketForm from './Form'
import Title from '../../components/texts/Title'

export default function AddTicket() {
  const {t} = useTranslation()
  const bannerInfo = {
    title: t('tickets'),
    home: t('home'),
    linkHome: '/',
  }
  return (
    <Stack gap='32px'>
      <Title text='addTicket' />
      <AddTicketForm />
    </Stack>
  )
}
