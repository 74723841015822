import {Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

function capitalizeFirstLetter(text: any) {
  if (!text) return text
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
}

export default function Label({
  text,
  fs,
  fw,
  color,
  align,
  answer,
  lineHeight,
  text2,
  text3,
  isAmount,
}: any) {
  const {t} = useTranslation()
  const translatedText = t(text)
  const capitalizedText = capitalizeFirstLetter(translatedText)

  return (
    <Typography
      align={align}
      sx={{
        color: color || '#111C1F',
        textTransform: 'none', // Ensure textTransform doesn't interfere
        lineHeight: lineHeight || '15px',
      }}
      fontWeight={fw || '400'}
      fontSize={fs || '14px'}
    >
      {text2}
      {isAmount ? t(text) : capitalizedText}
      {answer && ` : ${answer}`}
      {text3 && ` ${text3}`}
    </Typography>
  )
}
