export default function BusinessDetailsObj(data: any) {
  return {
    businessType: data.business_type,
    businessName: data.business_name,
    nameOfBusinessManager: data.manger_name,
    domainName: data.domain_web,
    content: data.business_about,
    imageOne: data.commercial_record,
    imageTwo: data.passport_or_accommodation,
    file: data.id_card_face_one,
  }
}
