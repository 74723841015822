import React from 'react'

export default function NikeProfile() {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='10' cy='10' r='10' fill='white' />
      <path
        d='M9.65344 17.0089C7.80964 17.0006 6.29351 16.4864 4.95825 15.4716C3.59508 14.4355 2.67625 13.0791 2.25591 11.4184C1.73509 9.36188 2.00248 7.40644 3.12004 5.5833C4.13823 3.92259 5.59909 2.83741 7.45517 2.29203C8.7508 1.91133 10.0626 1.91021 11.375 2.2457C12.1208 2.43661 12.8124 2.74363 13.4711 3.13774C13.6241 3.22928 13.6201 3.28287 13.5135 3.41182C12.8342 4.23575 12.8241 5.33377 13.4778 6.13314C14.132 6.93363 15.3054 7.12398 16.2108 6.56632C16.3549 6.47756 16.4068 6.49878 16.4581 6.6495C16.7272 7.44161 16.9393 8.24042 16.9896 9.08501C17.0526 10.1467 16.8919 11.1655 16.5223 12.1552C16.1533 13.1433 15.5934 14.0152 14.8538 14.7666C13.7262 15.912 12.3619 16.6187 10.7822 16.9023C10.3574 16.9782 9.92696 17.0301 9.65344 17.0089ZM5.92397 9.03923C5.58625 9.03365 5.26918 9.18549 5.07436 9.44729C4.76902 9.85702 4.77962 10.3192 5.11344 10.7976C5.60802 11.5077 6.0959 12.2222 6.605 12.9211C7.01585 13.4849 7.57128 13.7534 8.28357 13.6596C8.80718 13.591 9.1756 13.2906 9.50383 12.9055C10.4171 11.8342 11.3381 10.7697 12.2553 9.7024C12.6388 9.25582 13.0273 8.81315 13.4013 8.35821C13.7893 7.88651 13.748 7.24065 13.3226 6.87557C12.8498 6.46975 12.2279 6.5172 11.8015 6.99615C11.5447 7.28475 11.2957 7.57949 11.0412 7.87032C10.0888 8.96052 9.16779 10.0775 8.20374 11.1582C8.0547 11.3251 8.02734 11.2928 7.92686 11.146C7.53946 10.5799 7.14926 10.0156 6.74623 9.46069C6.54918 9.18884 6.26895 9.04258 5.92397 9.03923Z'
        fill='#01ACF1'
      />
      <path
        d='M16.5535 4.77542C16.5619 5.60047 15.9054 6.26977 15.0854 6.27145C14.2955 6.27312 13.6385 5.59935 13.6256 4.77542C13.6139 4.00229 14.2832 3.33633 15.0781 3.32628C15.8764 3.31624 16.587 3.98498 16.5535 4.77542Z'
        fill='#04ACF1'
      />
      <path
        d='M5.92441 9.03971C6.26883 9.04251 6.54962 9.18932 6.74667 9.46117C7.1497 10.016 7.5399 10.5804 7.9273 11.1464C8.02778 11.2932 8.05513 11.3256 8.20418 11.1587C9.16822 10.078 10.0893 8.961 11.0416 7.8708C11.2956 7.57997 11.5451 7.28467 11.8019 6.99663C12.2284 6.51768 12.8502 6.47023 13.3231 6.87606C13.7484 7.24113 13.7897 7.88699 13.4018 8.35869C13.0278 8.81308 12.6398 9.2563 12.2557 9.70288C11.3386 10.7708 10.4175 11.8347 9.50427 12.9059C9.17604 13.2911 8.80761 13.5914 8.284 13.6601C7.57116 13.7539 7.01573 13.4854 6.60544 12.9216C6.09634 12.2227 5.6079 11.5082 5.11388 10.7981C4.78062 10.3192 4.76946 9.85751 5.0748 9.44777C5.27018 9.18653 5.58669 9.03469 5.92441 9.03971Z'
        fill='#FBFDFD'
      />
    </svg>
  )
}
