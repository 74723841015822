import React from 'react'

export default function OrderObj(data: any) {
  return {
    id: data?.id,
    amount: data?.detials?.amount,
    balance: data?.detials?.balance,
    reference: '??',
    orderDate: data?.detials?.order_date,
    client: data?.detials?.clint_name,
    valueDate: data?.detials?.value_date,
    total: data?.detials?.total,
  }
}
