import {PaletteMode, createTheme} from '@mui/material'

declare module '@mui/material/styles' {
  interface Palette {
    sub1: Palette['primary']
  }
  interface Palette {
    sub2: Palette['primary']
  }
  interface Palette {
    sub3: Palette['primary']
  }
  interface Palette {
    sub4: Palette['primary']
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    sub1?: PaletteOptions['primary']
  }
  interface PaletteOptions {
    sub2?: PaletteOptions['primary']
  }
  interface PaletteOptions {
    sub3?: PaletteOptions['primary']
  }
  interface PaletteOptions {
    sub4?: PaletteOptions['primary']
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#2C9EB7',
      light: '#FFE1E3',
    },
    sub1: {
      main: '#0BB783',
    },
    sub2: {
      main: '#009EF7',
    },
    sub3: {
      main: '#FFA800',
    },
    sub4: {
      main: '#D71B22',
    },
    info: {
      main: '#fff',
    },
  },
})

export default theme
