import {Stack, Typography} from '@mui/material'
import React from 'react'
import TitlePage from '../../modules/customTable/TitlePage'
import {useTranslation} from 'react-i18next'
import ActionBtns from './ActionBtns'
import Label from '../../components/texts/Label'
import QR from '../../parts/QR'

export default function QRCode() {
  const {t} = useTranslation()

  const bannerInfo = {
    title: t('QRCode'),
    home: t('home'),
    linkHome: '/',
  }
  return (
    <Stack gap='80px'>
      <ActionBtns />
    </Stack>
  )
}
