import {Stack} from '@mui/material'
import React from 'react'
import Label from '../components/texts/Label'
import InputType from '../components/inputs/InputType'
import ErrorMsg from '../components/texts/ErrorMsg'
import CopyBtn from '../components/buttons/CopyBtn'
import {useSelector} from 'react-redux'
import {RootState} from '../store'

export default function InputWithLabelWithCopyBtn({
  data,
  setData,
  type,
  placeHolder,
  name,
  isNumberPhone,
  fw,
  width,
  disabled,
}: any) {
  const store = useSelector((state: RootState) => state.vender)

  return (
    <Stack width={width || 'auto'} gap='8px'>
      <Label fw={fw} text={placeHolder} />
      <Stack position={'relative'}>
        <InputType
          isCopy
          type={type}
          placeHolder={placeHolder}
          data={data}
          setData={setData}
          name={name}
          width='100%'
          isNumberPhone={isNumberPhone}
          disabled={disabled}
        />
        <Stack
          sx={{
            right: store.isRtl === 0 ? '24px' : 'auto',
            left: store.isRtl !== 0 ? '24px' : 'auto',
            top: '2.2px',
          }}
          position={'absolute'}
          bgcolor={'#fff'}
        >
          <CopyBtn text={data[name]} />
        </Stack>
      </Stack>
    </Stack>
  )
}
