import React, {useState} from 'react'
import {Stack} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {RootState} from '../../../../store'
import TextLink from '../../../../components/texts/TextLink'
import PopUp from '../../../../parts/PopUp'
import ConfirmForm from './ConfirmForm'
import Status from '../../../../components/texts/Status'

export default function Row({
  item,
  i,
  setRows,
  rows,
  setAdded,
}: {
  item: any
  i: number
  rows: any
  setRows: any
  setAdded: any
}) {
  const [state, setState] = useState(item.status)
  const [open, setOpen] = React.useState(false)
  const [editItem, setEditItem] = useState(item)
  const navigate = useNavigate()
  const handelEdit = () => {
    navigate(`/helpdesk/faq-edit/${item.id}`)
  }

  const handleStatusChange = async (e: React.ChangeEvent<HTMLInputElement>) => {}

  const [isDeleted, setDeleted] = useState(true)
  const store = useSelector((state: RootState) => state.vender)

  return (
    <>
      {isDeleted && (
        <>
          <tr
            style={{
              background: i % 2 === 0 ? '#fff' : '#f6f8fa',
              color: '#111C1F',
              fontSize: '14px',
              fontWeight: '400',
              lineHeight: '16px',
            }}
            // className='fw-bold text-muted '
          >
            <td
              style={{
                padding: '12px 12px',
              }}
            >
              {editItem.date}
            </td>
            <td style={{padding: '12px 0px'}}>
              <TextLink onClick={() => setOpen(true)} text={editItem.depositId} />
            </td>
            <td style={{padding: '12px 0px'}}>{editItem.depositMethod}</td>
            {store.user && store.user.type === 'Vendor' && (
              <>
                {' '}
                <td style={{padding: '12px 0px'}}>{editItem.fee}</td>
                <td style={{padding: '12px 0px'}}>
                  <Status text={editItem.status} />
                </td>
              </>
            )}
            {/* <td style={{padding: '12px 0px'}}>{editItem.amount}</td> */}

            <td style={{padding: '12px 12px', textAlign: 'end'}}>{editItem.reciveAmount}</td>
          </tr>
        </>
      )}
      <PopUp width='md' setOpen={setOpen} open={open}>
        <ConfirmForm item={editItem} />
      </PopUp>
    </>
  )
}
