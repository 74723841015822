import * as React from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {Stack} from '@mui/material'

export default function FAQ({item}: any) {
  const [expanded, setExpanded] = React.useState<string | false>(false)

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <Stack>
      <Accordion
        sx={{
          '&.MuiAccordion-root': {
            boxShadow: 'rgba(33, 35, 38, 0.1) 0px 10px 10px -10px',
          },
        }}
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1bh-content'
          id='panel1bh-header'
        >
          <Typography sx={{flexShrink: 0, fontSize: '16px', fontWeight: '600'}}>
            {item.q}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{fontSize: '16px', fontWeight: '400', color: 'rgba(60, 60, 67, 0.85)'}}>
            {item.answer}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Stack>
  )
}
