import {Stack} from '@mui/material'
import React, {useState} from 'react'
import FormTransferToUser from './Form'
import Title from '../../../../../../components/texts/Title'
import PopUp from '../../../../../../parts/PopUp'
import Success from '../../Success'

export default function Withdraw({isSetting}: any) {
  const [isActive, setIsActive] = useState(0)
  const [openSuccess, setOpenSuccess] = useState(false)

  return (
    <>
      <Stack gap='32px'>
        <Title text='withDrawWithBank' />
        <FormTransferToUser
          isSetting={isSetting}
          setOpenSuccess={setOpenSuccess}
          setIsActive={setIsActive}
        />
      </Stack>

      <PopUp width='xs' setOpen={setOpenSuccess} open={openSuccess}>
        <Success />
      </PopUp>
    </>
  )
}
