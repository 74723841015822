import {Box, Stack} from '@mui/material'
import React from 'react'

import LabelWithValue from '../../bank/LabelWithValue'
import {useNavigate} from 'react-router-dom'
import TextLink from '../../../../../../../components/texts/TextLink'
import Logo from '../../../../../../../svg/Logo'
import Title from '../../../../../../../components/texts/Title'
import Label from '../../../../../../../components/texts/Label'
import MainBtn from '../../../../../../../components/buttons/MainBtn'
import Status from '../../../../../../../components/texts/Status'
import GoBack from '../../../../../../../parts/GoBack'
import InputWithLabel from '../../../../../../../parts/InputWithLabel'

export default function ConfirmForm({setOpen, editItem, setData}: any) {
  return (
    <Stack p='24px'>
      <Stack alignItems={'center'} justifyContent={'space-between'} direction={'row'}>
        <GoBack onClick={() => setOpen(false)} text='goBack' />
        <Logo />
      </Stack>
      <Stack p='24px 0' borderBottom='1px dashed #9D97B1' gap='24px'>
        <Title text='withdrawDetails' />
        <Stack gap='10px' alignItems={'center'} direction={'row'}>
          <Label fw='400' text='status' />
          :
          <Status width='auto' text={editItem.status} />
        </Stack>
        {editItem.status === 'reject' && (
          <Stack
            p='16px'
            border='1px solid var(--secondary-color)'
            borderRadius={'6px'}
            bgcolor={'#fafafb'}
            width={'100%'}
            gap='5px'
          >
            <Label text='reasonForRefusedWithdraw' />
            <Label
              fw='400'
              text='Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque commodi consequuntur porro quod necessitatibus a odio repellat? Laboriosam magnam dolorem perferendis, rem, dolore cumque, ducimus placeat delectus voluptatibus doloribus saepe.
'
            />
          </Stack>
        )}
      </Stack>
      {editItem.status === 'accept' && (
        <Stack borderBottom='1px dashed #9D97B1' gap='24px' p='24px 0'>
          <Label text='transferImage' />
          <Stack
            alignItems={'center'}
            borderRadius={'8px'}
            bgcolor={'F8F8F9'}
            border='3px solid #DCE5F1'
            height={'80px'}
            width={'80px'}
          >
            <img height={'100%'} style={{width: '60px'}} src={editItem.image} alt='' />
          </Stack>
          <InputWithLabel
            fw='400'
            name='transferID'
            placeHolder='transferID'
            disabled
            type='text'
            setData={setData}
            data={editItem}
            width={{xs: '100%', md: '395px'}}
            errors={{}}
          />
        </Stack>
      )}

      <Stack borderBottom='1px dashed #9D97B1' gap='24px' p='24px 0'>
        <LabelWithValue isAmount value={editItem.name} label={`withdrawwith${editItem.type}`} />
        <LabelWithValue value={editItem.owner} label='owner' />
        <LabelWithValue value={editItem.code} label='swiftCode' />
        <LabelWithValue value={editItem.iban} label='IBAN' />
      </Stack>
      <Stack borderBottom='1px dashed #9D97B1' gap='24px' p='24px 0'>
        <LabelWithValue isAmount value={editItem.amount} label='amount' />
        <LabelWithValue isAmount value={editItem.fee} label='fee' />
      </Stack>
      <Stack
        direction={'row'}
        justifyContent={'flex-end'}
        alignItems={'center'}
        // borderBottom='1px dashed #9D97B1'
        gap='5px'
        p='24px 0'
      >
        <Label text='total' />
        <Label text=':' />
        <Title isAmount fs='18px' text={editItem.total} />
      </Stack>
      {/* <Stack width={'120px'} pt={'24px'} direction={'row'} justifyContent={'flex-start'}>
        <MainBtn
          text='confirm'
          handelSubmit={() => {
            navigate('/wallet/methods')
            // navigate('/dashboard')
          }}
        />
      </Stack> */}
    </Stack>
  )
}
