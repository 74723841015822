import {Stack} from '@mui/material'
import React, {useState} from 'react'
import Title from '../../../../components/texts/Title'
import FormTransferToUser from './Form'
import VerificationPage from '../../parts/VerificationPage'
import PopUp from '../../../../parts/PopUp'
import Success from '../../parts/Success'

export default function RequestMoney() {
  const [isActive, setIsActive] = useState(0)
  const [openSuccess, setOpenSuccess] = useState(false)
  const [data, setData] = useState<any>({code: '+964'})
  const [setting, setSetting] = useState<any>({})
  return (
    <>
      <Stack gap='32px'>
        <Title text='requestMoney' />
        <FormTransferToUser
          setting={setting}
          setSetting={setSetting}
          data={data}
          setData={setData}
          setOpenSuccess={setOpenSuccess}
          setIsActive={setIsActive}
        />
      </Stack>

      <PopUp width='xs' setOpen={setOpenSuccess} open={openSuccess}>
        <Success
          data={{
            name: data.payerFullName,
            amount:
              setting.fee_type === 'fixed' && +data['amount'] - +setting.fee_value
                ? +data['amount'] - +setting.fee_value
                : setting.fee_type === 'percentage' &&
                  +data.amount - +data.amount * (+setting.fee_value / 100)
                ? +data.amount - +data.amount * (+setting.fee_value / 100)
                : '0',
          }}
        />
      </PopUp>
    </>
  )
}
