import React from 'react'

export default function EditIcon({color}: any) {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11.5 14.5C11.224 14.5 11 14.276 11 14C11 13.724 11.224 13.5 11.5 13.5H11.5005C11.5005 13.224 11.724 13 12.0002 13C12.2765 13 12.5 13.224 12.5 13.5C12.5 14.0515 12.0515 14.5 11.5 14.5Z'
        fill={color || 'black'}
      />
      <path
        d='M12 13.9975C11.724 13.9975 11.5 13.7735 11.5 13.4975V8.59375C11.5 8.31775 11.724 8.09375 12 8.09375C12.276 8.09375 12.5 8.31775 12.5 8.59375V13.4975C12.5 13.7735 12.276 13.9975 12 13.9975Z'
        fill={color || 'black'}
      />
      <path
        d='M11.5 14.5H2.5C2.224 14.5 2 14.276 2 14C2 13.724 2.224 13.5 2.5 13.5H11.5C11.776 13.5 12 13.724 12 14C12 14.276 11.776 14.5 11.5 14.5Z'
        fill={color || 'black'}
      />
      <path
        d='M2.5 14.5C1.9485 14.5 1.5 14.0515 1.5 13.5C1.5 13.224 1.724 13 2 13C2.276 13 2.5 13.224 2.5 13.5V13.5007C2.776 13.5007 3 13.7242 3 14.0002C3 14.2762 2.776 14.5 2.5 14.5Z'
        fill={color || 'black'}
      />
      <path
        d='M2 14C1.724 14 1.5 13.776 1.5 13.5V4.5C1.5 4.224 1.724 4 2 4C2.276 4 2.5 4.224 2.5 4.5V13.5C2.5 13.776 2.276 14 2 14Z'
        fill={color || 'black'}
      />
      <path
        d='M1.99975 5C1.7235 5 1.5 4.776 1.5 4.5C1.5 3.9485 1.9485 3.5 2.5 3.5C2.776 3.5 3 3.724 3 4C3 4.276 2.776 4.5 2.5 4.5H2.4995C2.4995 4.776 2.276 5 1.99975 5Z'
        fill={color || 'black'}
      />
      <path
        d='M7.40375 4.5H2.5C2.224 4.5 2 4.276 2 4C2 3.724 2.224 3.5 2.5 3.5H7.40375C7.67975 3.5 7.90375 3.724 7.90375 4C7.90375 4.276 7.68 4.5 7.40375 4.5Z'
        fill={color || 'black'}
      />
      <path
        d='M6.98236 7.75038C6.85436 7.75038 6.72636 7.70163 6.62886 7.60388C6.43361 7.40863 6.43361 7.09213 6.62886 6.89688L11.5121 2.01363C11.7071 1.81837 12.0241 1.81837 12.2191 2.01363C12.4144 2.20888 12.4144 2.52538 12.2191 2.72063L7.33586 7.60388C7.23836 7.70163 7.11011 7.75038 6.98236 7.75038Z'
        fill={color || 'black'}
      />
      <path
        d='M6.5005 10.0008C6.46575 10.0008 6.4305 9.99731 6.39525 9.98956C6.12525 9.93181 5.95325 9.66581 6.01125 9.39606L6.494 7.14556C6.55175 6.87531 6.81825 6.70256 7.08775 6.76156C7.35775 6.81931 7.52975 7.08531 7.47175 7.35506L6.989 9.60556C6.9385 9.84031 6.73125 10.0008 6.5005 10.0008Z'
        fill={color || 'black'}
      />
      <path
        d='M8.74994 9.516C8.62194 9.516 8.49394 9.46725 8.39644 9.3695C8.20119 9.17425 8.20119 8.85775 8.39644 8.6625L13.2797 3.77925C13.4747 3.584 13.7917 3.584 13.9867 3.77925C14.1819 3.9745 14.1819 4.291 13.9867 4.48625L9.10344 9.3695C9.00594 9.46725 8.87794 9.516 8.74994 9.516Z'
        fill={color || 'black'}
      />
      <path
        d='M6.49968 9.99841C6.26893 9.99841 6.06168 9.83791 6.01118 9.60341C5.95343 9.33341 6.12518 9.06741 6.39518 9.00966L8.64568 8.52666C8.91493 8.46941 9.18143 8.64041 9.23943 8.91041C9.29718 9.18041 9.12543 9.44641 8.85543 9.50416L6.60493 9.98716C6.56968 9.99467 6.53443 9.99841 6.49968 9.99841Z'
        fill={color || 'black'}
      />
      <path
        d='M12.7501 5.51769C12.6221 5.51769 12.4941 5.46894 12.3966 5.37119L10.6289 3.60344C10.4336 3.40819 10.4336 3.09169 10.6289 2.89644C10.8239 2.70119 11.1409 2.70119 11.3359 2.89644L13.1036 4.66419C13.2989 4.85944 13.2989 5.17594 13.1036 5.37119C13.0061 5.46894 12.8781 5.51769 12.7501 5.51769Z'
        fill={color || 'black'}
      />
      <path
        d='M13.6334 4.63291C13.5054 4.63291 13.3774 4.58416 13.2797 4.48641C13.0844 4.29116 13.0844 3.97441 13.2797 3.77916C13.4182 3.64091 13.4944 3.45241 13.4944 3.24916C13.4944 3.04591 13.4182 2.85741 13.2797 2.71916C13.0022 2.44141 12.4959 2.44166 12.2189 2.71891C12.0237 2.91416 11.7072 2.91416 11.5117 2.71916C11.3164 2.52391 11.3164 2.20716 11.5117 2.01191C11.8392 1.68416 12.2787 1.50391 12.7492 1.50391C13.2197 1.50391 13.6594 1.68441 13.9867 2.01191C14.3139 2.33916 14.4942 2.77841 14.4942 3.24916C14.4942 3.71991 14.3139 4.15941 13.9864 4.48666C13.8894 4.58416 13.7614 4.63291 13.6334 4.63291Z'
        fill={color || 'black'}
      />
    </svg>
  )
}
