import React, {useRef, useState} from 'react'
import QRCode from 'qrcode.react'
import {toPng} from 'html-to-image'
import {Snackbar, Stack} from '@mui/material'
import {useReactToPrint} from 'react-to-print'

import {useTranslation} from 'react-i18next'
import TitlePage from '../../modules/customTable/TitlePage'
import Label from '../../components/texts/Label'
import DownLoadIcon from '../../svg/DownLoadIcon'
import Print from '../../svg/Print'
import SelectBtn from '../../components/buttons/SelectBtn'
import MuiAlert, {AlertProps} from '@mui/material/Alert'
import Shere from '../../svg/Shere'
import Loading from '../../modules/customTable/LoadingForm'
import PreintQR from './PreintQR'
import Copy from '../../svg/Copy'
import TooltipContiner from '../../parts/TooltipContiner'
import {useSelector} from 'react-redux'
import {RootState} from '../../store'
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})
const QR: React.FC = () => {
  const store = useSelector((state: RootState) => state.vender)

  const phoneNumber = store.user.mobile // استبدل هذا برقم الجوال الذي تريده
  const qrRef = useRef<HTMLDivElement>(null)
  const PrentRef = useRef<HTMLDivElement>(null)
  const [copySuccess, setCopySuccess] = useState('')
  const [downloading, setDownloading] = useState(false) // حالة التحميل
  const [open, setOpen] = React.useState(false)
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }
  const handlePrint = useReactToPrint({
    content: () => PrentRef.current,
  })
  const downloadQRCode = () => {
    if (qrRef.current === null) {
      return
    }
    setDownloading(true) // تعيين حالة التحميل إلى صحيحة

    toPng(qrRef.current)
      .then((dataUrl) => {
        const link = document.createElement('a')
        link.href = dataUrl
        link.download = 'qrcode.png'
        link.click()
      })
      .catch((err) => {})
      .finally(() => {
        setDownloading(false) // إعادة تعيين حالة التحميل إلى خطأ
      })
  }
  const shareQRCode = async () => {
    if (qrRef.current === null) {
      return
    }

    try {
      setDownloading(true) // تعيين حالة التحميل إلى صحيحة

      const dataUrl = await toPng(qrRef.current)
      const blob = await (await fetch(dataUrl)).blob()
      const file = new File([blob], 'qrcode.png', {type: blob.type})

      if (navigator.share) {
        await navigator.share({
          title: 'QR Code',
          text: 'Check out this QR code!',
          files: [file],
        })
      } else {
      }

      // نسخ الرابط إلى الحافظة
      navigator.clipboard.writeText(dataUrl).then(
        () => {
          setCopySuccess('Link copied to clipboard!')
        },
        (err) => {}
      )
    } catch (err) {
    } finally {
      setDownloading(false) // تعيين حالة التحميل إلى صحيحة
    }
  }

  const copyQRCodeLink = () => {
    if (qrRef.current === null) {
      return
    }
    setDownloading(true) // تعيين حالة التحميل إلى صحيحة

    toPng(qrRef.current)
      .then((dataUrl) => {
        fetch(dataUrl)
          .then((res) => res.blob())
          .then((blob) => {
            const url = URL.createObjectURL(blob)
            navigator.clipboard.writeText(url).then(
              () => {
                setOpen(true)
              },
              (err) => {}
            )
          })
      })
      .catch((err) => {})
      .finally(() => {
        setDownloading(false) // إعادة تعيين حالة التحميل إلى خطأ
      })
  }

  const {t} = useTranslation()

  const bannerInfo = {
    title: t('QRCode'),
    home: t('home'),
    linkHome: '/',
  }
  return (
    <Stack>
      <Stack bgcolor={'#ffff'}>
        <Stack
          sx={{width: '100%', height: 'auto'}}
          gap='29px'
          flexWrap={'wrap'}
          justifyContent={'space-between'}
          direction={'row'}
        >
          <TitlePage isName info={bannerInfo} />
          <Stack flexWrap={'wrap'} gap='29px' direction={'row'}>
            <TooltipContiner text='download'>
              <Stack sx={{cursor: 'pointer'}} onClick={downloadQRCode}>
                <DownLoadIcon />
              </Stack>
            </TooltipContiner>
            <TooltipContiner text='Print'>
              <Stack sx={{cursor: 'pointer'}} onClick={handlePrint}>
                <Print />
              </Stack>
            </TooltipContiner>
            <TooltipContiner text='copyQR'>
              <Stack sx={{cursor: 'pointer'}} onClick={copyQRCodeLink}>
                <Copy />
              </Stack>
            </TooltipContiner>
            <SelectBtn
              isName
              withoutArrow
              data={{name: 'shereQR'}}
              icon={<Shere />}
              onClick={shareQRCode}
              width='133px'
            />
          </Stack>
        </Stack>
        <Stack position={'relative'} alignItems={'center'} width={'100%'}>
          <Stack gap='32px' m='81px 0' alignItems={'center'} maxWidth={'350px'} minHeight={'300px'}>
            {downloading ? (
              <Loading />
            ) : (
              <>
                <Stack
                  m='auto'
                  alignContent={'center'}
                  sx={{width: '100%', height: 'auto'}}
                  ref={qrRef}
                >
                  <QRCode value={phoneNumber} size={350} />
                </Stack>
                <Label
                  lineHeight='normal'
                  align='center'
                  fs='16px'
                  fw='600'
                  color='#494B69'
                  text='qrMassage'
                />
              </>
            )}
          </Stack>
        </Stack>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity='success' sx={{width: '100%'}}>
            {t('msgCopySuccessfully')}
          </Alert>
        </Snackbar>{' '}
      </Stack>
      <Stack ref={PrentRef}>
        <PreintQR />
      </Stack>
    </Stack>
  )
}

export default QR
