import {Stack} from '@mui/material'
import React from 'react'
import LinkSelect from '../buttons/LinkSelect'

export default function SelectForBtn({links, issmallImg}: any) {
  return (
    <Stack
      sx={{
        borderRadius: '8px',
        border: '1px solid #E8E8E8',
        bgcolor: '#fff',
        boxShadow: '3px 4px 20px 0px rgba(0, 0, 0, 0.11)',
      }}
      gap='24px'
      p='24px'
    >
      {links.map((e: any) => (
        <LinkSelect issmallImg={issmallImg} link={e} />
      ))}
    </Stack>
  )
}
