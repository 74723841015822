import {Box, Stack} from '@mui/material'
import React from 'react'

import LabelWithValue from '../wallet/pages/withdraw/pages/bank/LabelWithValue'
import {useNavigate} from 'react-router-dom'
import TextLink from '../../components/texts/TextLink'
import Logo from '../../svg/Logo'
import Title from '../../components/texts/Title'
import Label from '../../components/texts/Label'
import MainBtn from '../../components/buttons/MainBtn'
import Status from '../../components/texts/Status'
import GoBack from '../../parts/GoBack'

export default function ConfirmForm({setOpen}: any) {
  const navigate = useNavigate()
  return (
    <Stack p='24px'>
      <Stack alignItems={'center'} justifyContent={'space-between'} direction={'row'}>
        <GoBack onClick={() => setOpen(false)} text='goBack' />
        <Logo />
      </Stack>
      <Stack p='24px 0' borderBottom='1px dashed #9D97B1' gap='24px'>
        <Title text='invoiceDetails' />
      </Stack>
      <Stack borderBottom='1px dashed #9D97B1' gap='24px' p='24px 0'>
        <LabelWithValue value='mohmmed' label='type' />
        <LabelWithValue value='mohmmed' label='invoiceNB' />
        <LabelWithValue value='mohmmed' label='paymentDate' />
        <LabelWithValue value='mohmmed' label='payFor' />
      </Stack>

      <Stack borderBottom='1px dashed #9D97B1' gap='24px' p='24px 0'>
        <LabelWithValue value='25$' label='amount' />
        <LabelWithValue value='1$' label='balance' />
      </Stack>
      <Stack
        direction={'row'}
        justifyContent={'flex-end'}
        alignItems={'center'}
        // borderBottom='1px dashed #9D97B1'
        gap='5px'
        p='24px 0'
      >
        <Label text='total' />
        <Label text=':' />
        <Title fs='18px' text='275.55$' />
      </Stack>
      {/* <Stack width={'120px'} pt={'24px'} direction={'row'} justifyContent={'flex-start'}>
        <MainBtn
          text='confirm'
          handelSubmit={() => {
            navigate('/wallet/methods')
            // navigate('/dashboard')
          }}
        />
      </Stack> */}
    </Stack>
  )
}
