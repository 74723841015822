import {Box, Stack} from '@mui/material'
import React from 'react'
import TextLink from '../../../../components/texts/TextLink'
import Logo from '../../../../svg/Logo'
import Title from '../../../../components/texts/Title'
import Label from '../../../../components/texts/Label'
import LabelWithValue from './LabelWithValue'
import MainBtn from '../../../../components/buttons/MainBtn'
import {useNavigate} from 'react-router-dom'
import GoBack from '../../../../parts/GoBack'
import {Data} from '@react-google-maps/api'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../store'

export default function ConfirmForm({setOpen, isLoading, submit, data, setting, total}: any) {
  const store = useSelector((state: RootState) => state.vender)

  const navigate = useNavigate()
  return (
    <Stack p='24px'>
      <Stack alignItems={'center'} justifyContent={'space-between'} direction={'row'}>
        <GoBack onClick={() => setOpen(false)} text='goBack' />
        <Logo />
      </Stack>
      <Stack p='24px 0' borderBottom='1px dashed #9D97B1' gap='24px'>
        <Title text='balanceDeposit' />
        <Label fw='400' text='confirmDeposit' />
      </Stack>
      <Stack borderBottom='1px dashed #9D97B1' gap='24px' p='24px 0'>
        <LabelWithValue value={data.name} label='depositWith' />
        <LabelWithValue value={data.amount} label='amount' />
        <LabelWithValue
          value={`${setting.isFixed ? `` : '%'}${setting.fee_value}`}
          text3={` ${!setting.isFixed ? `` : store.user.currencyCode}`}
          label='fee'
        />{' '}
      </Stack>

      <Stack
        direction={'row'}
        justifyContent={'flex-end'}
        alignItems={'center'}
        // borderBottom='1px dashed #9D97B1'
        gap='5px'
        p='24px 0 0 0'
      >
        <Title isAmount fs='18px' text={`${total} ${store.user.currencyCode}`} />
      </Stack>
      <Stack width={'120px'} pt={'24px'} direction={'row'} justifyContent={'flex-start'}>
        <MainBtn
          text='confirm'
          isLoading={isLoading}
          handelSubmit={() => {
            submit()
            // setOpenSuccess(true)
            // setOpen(false)
            // navigate('/dashboard')
          }}
        />
      </Stack>
    </Stack>
  )
}
