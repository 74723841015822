export function WalletObj(data: any) {
  return {
    amount: data.amount,
    withdraw_way_id: data.id,
  }
}
export function BankObj(data: any) {
  return {
    bank_id: data.bankName,
    address: data.bankAddress,
    owner: data.owner,
    swift: data.swiftCode,
    iban: data.IBAN,
  }
}
export function EWalletObj(data: any) {
  return {
    wallet_id: data.bankName,
    mobile: data.phone,
    name: data.name,
  }
}
export function ExchangeOfficeObj(data: any) {
  return {
    exchange_id: data.officeName,
    mobile: data.phone,
    name: data.name,
  }
}
