import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {useTranslation} from 'react-i18next'

import {useSelector} from 'react-redux'
import {RootState} from '../../store'
import TransferToUser from '../../pages/dashboard/pages/transferToUser/TransferToUser'
import TransferToNonUser from '../../pages/dashboard/pages/transferToNonUser/TransferToNonUser'
import RequestMoney from '../../pages/dashboard/pages/requestMoney/RequestMoney'
import Transactions from '../../pages/transactions'
import TransactionsTable from '../../pages/transactions/pages/table'
import BalanceDeposit from '../../pages/wallet/pages/balanceDeposit'
import WalletPage from '../../pages/wallet'
import DepositHistory from '../../pages/wallet/pages/DepositHistory'
import Withdraw from '../../pages/wallet/pages/withdraw'
import ExchangeOffice from '../../pages/wallet/pages/withdraw/pages/ExchangeOffice'
import Bank from '../../pages/wallet/pages/withdraw/pages/bank'
import Ewallet from '../../pages/wallet/pages/withdraw/pages/EWallet/index'
import WithDrawHistoryTable from '../../pages/wallet/pages/withdraw/pages/WithDrawHistory/table copy'
import Error403 from '../../modules/errors/components/Error403'

const Wallet = () => {
  const {t} = useTranslation()
  const store = useSelector((state: RootState) => state.vender)

  return (
    <Routes>
      <Route
        element={
          <>
            {/* <ProfileHeader /> */}
            <Outlet />
          </>
        }
      >
        <Route index element={<WalletPage />} />

        <Route path='depositHistory' element={<DepositHistory />} />

        <>
          {' '}
          <Route
            path='methods/bank'
            element={store.user && store.user.type === 'Vendor' ? <Bank /> : <Error403 />}
          />
          <Route
            path='methods/bank/:id'
            element={store.user && store.user.type === 'Vendor' ? <Bank /> : <Error403 />}
          />
          <Route
            path='methods/EWallet'
            element={store.user && store.user.type === 'Vendor' ? <Ewallet /> : <Error403 />}
          />
          <Route
            path='methods/wallet/:id'
            element={store.user && store.user.type === 'Vendor' ? <Ewallet /> : <Error403 />}
          />
          <Route
            path='methods/exchangeOffice'
            element={store.user && store.user.type === 'Vendor' ? <ExchangeOffice /> : <Error403 />}
          />
          <Route
            path='methods/exchange/:id'
            element={store.user && store.user.type === 'Vendor' ? <ExchangeOffice /> : <Error403 />}
          />
          <Route
            path='methods'
            element={store.user && store.user.type === 'Vendor' ? <Withdraw /> : <Error403 />}
          />
          <Route
            path='withDrawHistory'
            element={
              store.user && store.user.type === 'Vendor' ? <WithDrawHistoryTable /> : <Error403 />
            }
          />
        </>

        <Route path='balanceDeposit/:id/:name' element={<BalanceDeposit />} />

        <Route index element={<Navigate to='/crafted/pages/profile/overview' />} />
      </Route>
    </Routes>
  )
}

export default Wallet
