import {Stack} from '@mui/material'
import React from 'react'
import Label from '../../../../components/texts/Label'

export default function LabelWithValue({label, value, color, text2, text3}: any) {
  return (
    <Stack direction={'row'} justifyContent={'space-between'}>
      <Label color={color} fw='400' text={label} />
      <Label text3={text3} fw='600' fs='14px' text2={text2} text={value} />
    </Stack>
  )
}
