import React, {useState} from 'react'
import Get from '../../fubcations/mastars/Get'
import Item from './Item'
import {Divider, Stack} from '@mui/material'
import Loading from '../../modules/customTable/LoadingForm'

export default function Items() {
  const [data, setData] = React.useState([])
  const [isLoading, setIsLoading] = useState()
  Get([], setData, 'notification', setIsLoading)
  return (
    <Stack height={isLoading ? '200px' : 'auto'} position={'relative'}>
      {!isLoading ? (
        <>
          {' '}
          {data &&
            data.map((e: any) => (
              <>
                <Item item={e} />
                <Divider color='#484848' />
              </>
            ))}
        </>
      ) : (
        <Loading />
      )}
    </Stack>
  )
}
