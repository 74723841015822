import {Box, Button, Stack, Tooltip, Typography, useTheme} from '@mui/material'
import React, {useEffect} from 'react'
import {useState} from 'react'
import ImgMsg from './ImgMsg'
import {useTranslation} from 'react-i18next'
import {log} from 'console'
import {BoxBorder} from '../../../components'
import {RootState, useSelector} from '../../store'
import Upload from '../../svg/Upload'

export default function UploadFile({base64Image, setBase64Image}: any) {
  const [image, setImage] = useState(base64Image)
  const {t} = useTranslation()
  const store = useSelector((state: RootState) => state.vender)

  // const handleImageChange = (event: any) => {
  //   const file = event.target.files[0]
  //   const reader = new FileReader()
  //   reader.readAsDataURL(file)
  //   reader.onload = () => {
  //     setBase64Image(reader.result)
  //     setImage(reader.result as string)
  //   }
  //   reader.onerror = (error) => {}
  // }

  const handleImageChange = (event: any) => {
    const file = event.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        setBase64Image(file)
        setImage(reader.result as string)
      }
      reader.onerror = (error) => {}
    } else {
      setBase64Image(null)
      alert(t('Only jpeg , jpg , png and svg image files are accepted'))
    }
  }

  return (
    <>
      <Tooltip title='Only jpeg , jpg , png and svg image files are accepted'>
        <Stack height='100%'>
          <Button
            sx={{
              width: '100%',
              height: '100%',
              padding: '0',
              borderRadius: '6px',
            }}
            component='label'
          >
            <Box
              height='46px'
              // width='100%'
              // sx={{minWidth: '120px'}}
              borderRadius='6.16px'
              border={base64Image ? '1px solid var(--bs-gray-500)' : '1px solid var(--bs-gray-300)'}
            >
              {base64Image ? (
                <Box
                  width={'100%'}
                  height={'100%'}
                  display={'flex'}
                  alignItems={'center'}
                  ml={store.isRtl === 0 ? '20px' : 0}
                  mr={store.isRtl === 1 ? 0 : '20px'}
                  gap={'8px'}
                >
                  <BoxBorder
                    sx={{
                      p: '8px',
                      display: 'flex',
                      alignItems: 'center',
                      '& > svg': {flexShrink: 0},
                    }}
                    border='1px solid var(--bs-gray-300)'
                    width='36px'
                    height='36px'
                    borderRadius='3px'
                  >
                    <svg
                      width='20'
                      height='26'
                      viewBox='0 0 20 26'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M3.0013 1.33203H11.168L19.3346 9.4987V22.332C19.3346 22.9509 19.0888 23.5444 18.6512 23.9819C18.2136 24.4195 17.6201 24.6654 17.0013 24.6654H3.0013C2.38246 24.6654 1.78897 24.4195 1.35139 23.9819C0.913801 23.5444 0.667969 22.9509 0.667969 22.332V3.66536C0.667969 3.04653 0.913801 2.45303 1.35139 2.01545C1.78897 1.57786 2.38246 1.33203 3.0013 1.33203Z'
                        stroke='#298BED'
                        stroke-width='0.8'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                    </svg>
                  </BoxBorder>
                  <Box>
                    <Typography color={'black'} fontSize={12} textTransform={'none'}>
                      file
                    </Typography>
                    <Typography color={'black'} fontSize={12}>
                      500kb
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <Stack
                  height={'100%'}
                  direction={'row'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  gap='20px'
                  p='20px'
                >
                  <Typography sx={{color: '#bdbdbd', textTransform: 'capitalize'}}>
                    {t('addFile')}
                  </Typography>

                  <Box
                    // width={'100%'}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    // p='12px'
                  >
                    <Upload />
                  </Box>
                </Stack>
              )}
            </Box>
            <input
              hidden
              accept='.txt, .doc, .docx, .pdf, .xls, .xlsx, .ppt, .pptx,'
              onChange={handleImageChange}
              type='file'
            />
          </Button>
        </Stack>
      </Tooltip>
    </>
  )
}
