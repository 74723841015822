import * as Yup from 'yup'
import dayjs from 'dayjs'

export const ProfileSchema = Yup.object().shape({
  accountNo: Yup.number().required('fieldRequired'),
  name: Yup.string().required('fieldRequired'),
  email: Yup.string().email('invalidEmail').required('fieldRequired'),
  accountType: Yup.string().required('fieldRequired'),
  dateBirth: Yup.string()
    .required('fieldRequired')
    .test('age', 'You must be at least 18 years old', function (value) {
      const birthDate = dayjs(value, 'DD/MM/YYYY')
      if (!birthDate.isValid()) {
        return false
      }
      const today = dayjs()
      const age = today.diff(birthDate, 'year')
      return age >= 18
    }),
})

export const BusinessAccountDetailsSchema = Yup.object().shape({
  businessName: Yup.string().required('fieldRequired'),
  name: Yup.string().required('fieldRequired'),
  nameOfBusinessManager: Yup.string().required('fieldRequired'),
  domainName: Yup.string().required('fieldRequired'),
  email: Yup.string().email('invalidEmail').required('fieldRequired'),
  businessType: Yup.string().required('fieldRequired'),
  content: Yup.string().required('fieldRequired'),
})
export const ConvertToBusinessSchema = Yup.object().shape({
  // lastName: Yup.string().required('fieldRequired'),
  // firstName: Yup.string().required('fieldRequired'),
  // phone: Yup.string().required('fieldRequired').min(5, 'phoneMsg'),
  businessName: Yup.string().required('fieldRequired'),
  businessManager: Yup.string().required('fieldRequired'),
  type: Yup.number().required('fieldRequired'),
  // businessManager: Yup.string().required('fieldRequired'),
  domainName: Yup.string().required('fieldRequired'),
  avarage: Yup.string().required('fieldRequired'),
})
export const ChangePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required('fieldRequired').min(8, 'passwordMsg'),
  newPassword: Yup.string().required('fieldRequired').min(8, 'passwordMsg'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'PasswordsMustMatch')
    .required('fieldRequired'),
})
export const ContactUsSchema = Yup.object().shape({
  name: Yup.string().required('fieldRequired'),
  reason: Yup.string().required('fieldRequired'),
  email: Yup.string().email('invalidEmail').required('fieldRequired'),
  content: Yup.string().required('fieldRequired'),
  phone: Yup.string().required('fieldRequired').min(5, 'phoneMsg'),
})
export const NotifySchema = Yup.object().shape({
  // is_notify: Yup.number().required('fieldRequired'),
})
