import {Stack} from '@mui/material'
import React from 'react'
import Links from './Links'
import GetStart from './GetStart'
import {HeaderUserMenu} from '../../../_metronic/partials'
import {Navbar} from './Navbar'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import Logo from '../../svg/Logo'

export default function Header() {
  return (
    <Stack p={{xs: '30px 30px', md: '32px 135px'}}>
      <Stack
        alignItems={'center'}
        direction={'row'}
        justifyContent={'space-between'}
        borderBottom={'1px solid #E7E7E7'}
      >
        <Stack position={'sticky'} top={'0'} height={'50px'} width={{sm: '82px ', xs: '80px'}}>
          <Logo />
        </Stack>

        <GetStart />
      </Stack>
    </Stack>
  )
}
