import {Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

export default function TextLink({text, onClick, fs}: any) {
  const {t} = useTranslation()
  function capitalizeFirstLetter(text: any) {
    if (!text) return text
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
  }
  const translatedText = t(text)
  const capitalizedText = capitalizeFirstLetter(translatedText)
  return (
    <Typography
      sx={{cursor: 'pointer'}}
      color={'var(--link-color)'}
      // fontWeight={'600'}
      fontSize={fs || '14px'}
      onClick={onClick}
    >
      {capitalizedText}
    </Typography>
  )
}
