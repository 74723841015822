import React, {useEffect, useState} from 'react'
import {Stack, Typography} from '@mui/material'
import Table from './Table'
import {useTranslation} from 'react-i18next'
import Get from '../../../../fubcations/mastars/Get'

export default function DepositHistory() {
  const [rows, setRows] = useState<any>([])
  const [added, setAdded] = useState(!false)
  Get([], setRows, 'deposit/history', setAdded)

  return <Table setAdded={setAdded} added={added} rows={rows} setRows={setRows} />
}
// var(--bs-body-bg)
