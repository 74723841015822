import React, {useEffect, useState} from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {RootState} from '../../store'
import {useSelector} from 'react-redux'

const EditorText: React.FC<any> = ({name, change, value, child}: any) => {
  const store = useSelector((state: RootState) => state.vender)

  const [languages, setlanguages] = useState(['en'])

  useEffect(() => {
    setlanguages(store.storeLangs)
  }, [store.storeLangs])

  return (
    <div>
      {languages.map((ele, i) => (
        <React.Fragment>
          {child === ele && (
            <ReactQuill
              value={value[name] && value[name][child]}
              onChange={(e: string) => {
                change({...value, [name]: {...value[name], [child]: e}})
              }}
              modules={{
                toolbar: [
                  [{header: [1, 2, false]}],
                  ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                  [{list: 'ordered'}, {list: 'bullet'}],
                  // ['link', 'image', 'video'], // إضافة خيار الصورة والرابط
                  ['clean'],
                ],
              }}
              formats={[
                'header',
                'bold',
                'italic',
                'underline',
                'strike',
                'blockquote',
                'list',
                'bullet',
                'link',
                'image',
                'video', // إضافة صيغة الصورة والرابط
              ]}
            />
          )}
        </React.Fragment>
      ))}
    </div>
  )
}

export default EditorText
