import React from 'react'

export default function WithDrowHistoryObj(data: any) {
  return {
    withdrawId: data.user_bank_id || data.user_wallet_id || data.user_exchange_id,
    amount: data.amount,
    balance: data.balance,
    method: data.type,
    orderDate: data.date,
    withdrawDate: data.withdraw_date,
    status: data.status,
    type: data.type,
    name: data.name,
    owner: data.owner,
    code: data.code,
    iban: data.iban,
    fee: data.fee,
    image: data.transfer_image,
    transferID: data.transfer_id,
    total: data.total,
  }
}
