import {Stack} from '@mui/material'
import React from 'react'
import Massage from './Massage'
import {MassageObj} from '../../../modules/modules/get/Tiket'

export default function Massages({data}: any) {
  return (
    <Stack gap='60px'>
      {data.map((e: any) => (
        <Massage massage={MassageObj(e)} />
      ))}
    </Stack>
  )
}
