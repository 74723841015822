import React from 'react'

export default function RequestRefundObj(data: any) {
  return {
    code: data.payment_code,
    payFor: data.pay_for,
    reason: data.reason,
    amount: data.amount_request,
    amountRefund: data.amount_refund,
    date: data.date,
    status: data.status_refund,
    id: data.id,
  }
}
