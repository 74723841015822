import {Stack} from '@mui/material'
import React from 'react'
import Label from '../components/texts/Label'
import InputType from '../components/inputs/InputType'
import ErrorMsg from '../components/texts/ErrorMsg'
import PhoneInput from '../components/inputs/PhoneInput'

export default function InputPhoneWithLabel({
  data,
  setData,
  msgErr,
  placeHolder,
  name,
  fw,
  width,
  codeName,
  errors,
  type,
}: any) {
  return (
    <Stack width={width || 'auto'} gap='8px'>
      <Label fw={fw} text={placeHolder} />
      <PhoneInput
        placeHolder={placeHolder}
        data={data}
        setData={setData}
        name={name}
        width='100%'
        Codename={codeName}
        isError={errors && errors[name]}
      />
      {errors && errors[name] && <ErrorMsg msgErr={errors[name]} />}
    </Stack>
  )
}
