import React, {useState} from 'react'
import InputType from './components/inputs/InputType'
import {Checkbox, Stack} from '@mui/material'
import UploadImg from './components/inputs/UploadImg'
import InputDate from './components/inputs/InputDate'
import RadioButtonsGroup from './components/inputs/RadioGroup'
const label = {inputProps: {'aria-label': 'Checkbox demo'}}

export default function NewCustomPage() {
  const [data, setData] = useState({date: new Date()})
  const [base64Image, setBase64Image] = useState('')
  return (
    <div>
      <h1>CustomPage</h1>
      <Stack direction={'row'} gap={'20px'} flexWrap={'wrap'}>
        {/* <RadioButtonsGroup /> */}
        {/* <InputDate setData={setData} data={data} name='date' width='300px' /> */}
        {/* <UploadImg
          setBase64Image={setBase64Image}
          base64Image={base64Image}
          name='image'
          setData={setData}
          data={data}
        /> */}
        {/* <InputType
          placeHolder='test'
          name='test'
          setData={setData}
          data={data}
          width='250px'
          type='text'
        /> */}
        {/* <InputType
          placeHolder='test'
          name='test'
          setData={setData}
          data={data}
          maxWidth='250px'
          type='password'
        /> */}
        {/* <InputType
          placeHolder='test'
          name='test'
          setData={setData}
          data={data}
          maxWidth='250px'
          type='text'
          isNumberPhone
        />
        <Checkbox {...label} /> */}
      </Stack>
    </div>
  )
}
