import {Stack} from '@mui/material'
import React, {useState} from 'react'
import Title from '../../../../components/texts/Title'
import FormTransferToUser from './Form'
import VerificationPage from '../../parts/VerificationPage'
import PopUp from '../../../../parts/PopUp'
import Success from '../../parts/Success'
import {VereficationObj} from '../../../../modules/modules/post/TransferModule'

export default function TransferToUser() {
  const [isActive, setIsActive] = useState(0)
  const [openSuccess, setOpenSuccess] = useState(false)
  const [data, setData] = useState<any>({})
  const [setting, setSetting] = useState<any>({})

  return (
    <>
      {isActive === 0 ? (
        <Stack gap='32px'>
          <Title text='transferToUser' />
          <FormTransferToUser
            setting={setting}
            setSetting={setSetting}
            data={data}
            setData={setData}
            setIsActive={setIsActive}
          />
        </Stack>
      ) : (
        <VerificationPage
          path={'transfers/user-to-user/verification'}
          Module={VereficationObj}
          data={data}
          setOpenSuccess={setOpenSuccess}
          setIsActive={setIsActive}
        />
      )}
      <PopUp width='xs' setOpen={setOpenSuccess} open={openSuccess}>
        <Success
          data={{
            name: data.recipientsName,
            amount:
              setting.fee_type === 'fixed' && +data['amount'] - +setting.fee_value
                ? +data['amount'] - +setting.fee_value
                : setting.fee_type === 'percentage' &&
                  +data.amount - +data.amount * (+setting.fee_value / 100)
                ? +data.amount - +data.amount * (+setting.fee_value / 100)
                : '0',
          }}
        />
      </PopUp>
    </>
  )
}
