import React from 'react'

export default function PurchasesObj(data: any) {
  return {
    id: data?.id,
    amount: data?.amount,
    balance: data.balance,
    payFor: data.pay_for,
    orderDate: data?.date,
    valueDate: '??',
    title: data.title,
    statusRefund: data && data.refund_details && data.refund_details.status_refund,
    amountRefund: data && data.refund_details && data.refund_details.amount_refund,
    amountRequest: data && data.refund_details && data.refund_details.amount_request,
    vendorName: '??',
    total: data.total,
  }
}
