import * as React from 'react'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import {useTranslation} from 'react-i18next'
import {Stack} from '@mui/material'

export default function RadioButtonsGroup({data, value, disabled, setData, info}: any) {
  const {t} = useTranslation()

  return (
    <FormControl>
      <RadioGroup
        aria-labelledby='demo-radio-buttons-group-label'
        value={value}
        name='radio-buttons-group'
      >
        <Stack direction={'row'}>
          {data.map((item: any, index: number) => (
            <FormControlLabel
              key={index}
              onChange={(event: any) => {
                setData({...info, gender: event.target.value})
              }}
              value={item}
              control={<Radio disabled={disabled} />}
              label={t(`${item}`)}
            />
          ))}
        </Stack>
      </RadioGroup>
    </FormControl>
  )
}
