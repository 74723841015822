import {Stack} from '@mui/material'
import React from 'react'
import Label from '../components/texts/Label'
import InputType from '../components/inputs/InputType'
import ErrorMsg from '../components/texts/ErrorMsg'
import TextErea from '../components/inputs/TextErea'

export default function TextEreaWithLabel({
  data,
  setData,
  msgErr,
  placeHolder,
  name,
  fw,
  width,
  disabled,
  errors,
}: any) {
  return (
    <Stack gap='8px'>
      <Label fw={fw} text={placeHolder} />
      <TextErea
        disabled={disabled}
        placeHolder={placeHolder}
        data={data}
        setData={setData}
        name={name}
        width={width}
        isError={errors && errors[name]}
      />
      {errors && errors[name] && <ErrorMsg msgErr={errors[name]} />}
    </Stack>
  )
}
