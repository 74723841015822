import React from 'react'

export default function NoTicket() {
  return (
    <svg
      width='305'
      height='312'
      viewBox='0 0 305 312'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M233.496 42.144C235.727 42.7417 236.474 45.5301 234.841 47.1631L223.818 58.1858C222.185 59.8188 219.397 59.0717 218.799 56.841L214.764 41.7837C214.167 39.553 216.208 37.5117 218.439 38.1094L233.496 42.144Z'
        fill='#58CEFF'
      />
      <circle cx='152.171' cy='152.171' r='152.171' fill='url(#paint0_linear_1130_31598)' />
      <circle cx='285.527' cy='98.5755' r='7.10772' stroke='#54CDFF' stroke-width='4' />
      <circle cx='278.331' cy='191.552' r='5.26445' stroke='#54CDFF' stroke-width='4' />
      <g filter='url(#filter0_f_1130_31598)'>
        <circle cx='189.942' cy='273.687' r='9.10772' fill='#58CEFF' />
      </g>
      <g filter='url(#filter1_f_1130_31598)'>
        <path
          d='M101.607 273.755L106.232 286.253L118.73 290.877L106.232 295.502L101.607 308L96.9827 295.502L84.4849 290.877L96.9827 286.253L101.607 273.755Z'
          fill='#54CDFF'
        />
      </g>
      <g filter='url(#filter2_f_1130_31598)'>
        <path
          d='M38.8232 71.8799L41.5672 79.2955L48.9829 82.0395L41.5672 84.7836L38.8232 92.1992L36.0792 84.7836L28.6636 82.0395L36.0792 79.2955L38.8232 71.8799Z'
          fill='#58CEFF'
        />
      </g>
      <circle cx='28.7379' cy='171.363' r='5.72133' stroke='#58CEFF' stroke-width='4' />
      <path
        d='M14.5787 198.816L18.4161 209.187L28.7867 213.024L18.4161 216.862L14.5787 227.233L10.7412 216.862L0.370605 213.024L10.7412 209.187L14.5787 198.816Z'
        fill='#58CEFF'
      />
      <rect
        x='61.5449'
        y='125.407'
        width='181.994'
        height='53.5275'
        rx='20'
        fill='url(#paint1_linear_1130_31598)'
      />
      <circle cx='84.1028' cy='151.789' r='7.26445' fill='white' />
      <rect
        x='98.2495'
        y='145.289'
        width='97.8789'
        height='13.7642'
        rx='6.88211'
        fill='url(#paint2_linear_1130_31598)'
      />
      <rect
        x='61.5449'
        y='191.935'
        width='181.994'
        height='53.5275'
        rx='20'
        fill='url(#paint3_linear_1130_31598)'
      />
      <circle cx='84.1028' cy='218.316' r='7.26445' fill='white' />
      <rect
        x='98.2495'
        y='211.816'
        width='97.8789'
        height='13.7642'
        rx='6.88211'
        fill='url(#paint4_linear_1130_31598)'
      />
      <rect
        x='61.5449'
        y='58.8809'
        width='181.994'
        height='53.5275'
        rx='20'
        fill='url(#paint5_linear_1130_31598)'
      />
      <circle cx='84.1028' cy='85.2625' r='7.26445' fill='white' />
      <rect
        x='98.2495'
        y='78.7627'
        width='97.8789'
        height='13.7642'
        rx='6.88211'
        fill='url(#paint6_linear_1130_31598)'
      />
      <g filter='url(#filter3_f_1130_31598)'>
        <circle cx='225.273' cy='184.086' r='29.1447' fill='#6775E2' />
      </g>
      <circle cx='225.273' cy='178.257' r='29.1447' fill='#6775E2' />
      <path
        d='M222.968 183.909L221.668 166.809L221.418 159.359H229.668L229.418 166.809L228.118 183.909H222.968ZM225.568 197.259C222.968 197.259 221.118 195.409 221.118 192.809C221.118 190.209 223.018 188.409 225.568 188.409C228.068 188.409 229.968 190.209 229.968 192.809C229.968 195.409 228.068 197.259 225.568 197.259Z'
        fill='white'
      />
      <defs>
        <filter
          id='filter0_f_1130_31598'
          x='176.834'
          y='260.579'
          width='26.2153'
          height='26.2158'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feGaussianBlur stdDeviation='2' result='effect1_foregroundBlur_1130_31598' />
        </filter>
        <filter
          id='filter1_f_1130_31598'
          x='80.4849'
          y='269.755'
          width='42.2451'
          height='42.2451'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feGaussianBlur stdDeviation='2' result='effect1_foregroundBlur_1130_31598' />
        </filter>
        <filter
          id='filter2_f_1130_31598'
          x='24.6636'
          y='67.8799'
          width='28.3193'
          height='28.3193'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feGaussianBlur stdDeviation='2' result='effect1_foregroundBlur_1130_31598' />
        </filter>
        <filter
          id='filter3_f_1130_31598'
          x='166.128'
          y='124.941'
          width='118.29'
          height='118.289'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feGaussianBlur stdDeviation='15' result='effect1_foregroundBlur_1130_31598' />
        </filter>
        <linearGradient
          id='paint0_linear_1130_31598'
          x1='152.171'
          y1='0'
          x2='152.171'
          y2='304.342'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='white' stop-opacity='0' />
          <stop offset='1' stop-color='#AAE6FF' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_1130_31598'
          x1='152.542'
          y1='125.407'
          x2='152.542'
          y2='178.935'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#6775E2' />
          <stop offset='1' stop-color='#55CDFF' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_1130_31598'
          x1='98.2495'
          y1='153.318'
          x2='196.128'
          y2='153.701'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='white' />
          <stop offset='1' stop-color='white' stop-opacity='0' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_1130_31598'
          x1='152.542'
          y1='191.935'
          x2='152.542'
          y2='245.462'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#B8EAFF' />
          <stop offset='1' stop-color='#55CDFF' />
        </linearGradient>
        <linearGradient
          id='paint4_linear_1130_31598'
          x1='99.3965'
          y1='217.934'
          x2='196.128'
          y2='218.699'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='white' />
          <stop offset='1' stop-color='white' stop-opacity='0' />
        </linearGradient>
        <linearGradient
          id='paint5_linear_1130_31598'
          x1='152.542'
          y1='58.8809'
          x2='152.542'
          y2='112.408'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#B8EAFF' />
          <stop offset='1' stop-color='#55CDFF' />
        </linearGradient>
        <linearGradient
          id='paint6_linear_1130_31598'
          x1='98.2495'
          y1='85.6448'
          x2='175.482'
          y2='85.6448'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='white' />
          <stop offset='1' stop-color='white' stop-opacity='0' />
        </linearGradient>
      </defs>
    </svg>
  )
}
