import {Stack} from '@mui/material'
import React from 'react'
import Nike from '../../svg/Nike'
import Title from '../../components/texts/Title'
import Label from '../../components/texts/Label'
import MainBtn from '../../components/buttons/MainBtn'
import Error from '../../svg/Error'

export default function NotFoundPage({setIsActive}: any) {
  return (
    <Stack gap='24px' alignItems={'center'}>
      <Error />
      <Title text='pageNotFound' />
      <Label text='pageCouldntFound' />
      <MainBtn text='login' handelSubmit={() => setIsActive(0)} />
    </Stack>
  )
}
