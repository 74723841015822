import {Stack} from '@mui/material'
import React from 'react'
import Label from '../../../components/texts/Label'

import KYCFile from '../../../svg/KYCFile'
import MainBtn from '../../../components/buttons/MainBtn'

export default function KYCBtn({isClickable, isKyc}: any) {
  return (
    <>
      {isClickable ? (
        <MainBtn
          bgcolor={'#048FD9'}
          isAmount
          width='auto'
          disabled={!isKyc}
          text={'FillouttheKYCform'}
          // isLoading={isLoadingSubmit}
          handelSubmit={() => {}}
        />
      ) : (
        <Stack
          borderRadius={'4px'}
          bgcolor={'rgb(246, 247, 249)'}
          height={'40px'}
          p=' 12px 12px'
          alignItems={'center'}
          width={{xs: '100%', md: 'calc(50% - 8px)'}}
          direction={'row'}
          justifyContent={'space-between'}
          border={'1px solid rgb(219, 222, 224)'}
        >
          <Label isAmount text='KYCForm' />
          <KYCFile />
        </Stack>
      )}
    </>
  )
}
