import React from 'react'

export default function TicketSvg() {
  return (
    <svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='24' cy='24' r='24' fill='#467DE1' fill-opacity='0.15' />
      <g clip-path='url(#clip0_0_1)'>
        <path
          d='M38.7453 20.9121C38.9939 20.8121 39.1571 20.5699 39.1571 20.3015V16.0778C39.1571 15.7147 38.8623 15.4199 38.4992 15.4199H8.23604C7.87288 15.42 7.57812 15.7147 7.57812 16.0778V20.3015C7.57812 20.5699 7.74126 20.812 7.98995 20.9121C9.31756 21.445 10.2097 22.9397 10.2097 24.6305C10.2097 26.3212 9.31756 27.816 7.98995 28.3489C7.74126 28.4489 7.57812 28.691 7.57812 28.9594V33.1831C7.57812 33.5463 7.87288 33.841 8.23604 33.841H38.4992C38.8624 33.841 39.1571 33.5463 39.1571 33.1831V28.9594C39.1571 28.691 38.994 28.4489 38.7453 28.3489C37.4177 27.816 36.5256 26.3212 36.5256 24.6305C36.5256 22.9397 37.4176 21.445 38.7453 20.9121ZM35.2097 24.6305C35.2097 26.6805 36.2505 28.5292 37.8413 29.3765V32.5252H29.9466V31.8673C29.9466 31.5041 29.6518 31.2094 29.2887 31.2094C28.9255 31.2094 28.6307 31.5041 28.6307 31.8673V32.5252H8.89389V29.3765C10.4847 28.5292 11.5255 26.6818 11.5255 24.6305C11.5255 22.5791 10.4847 20.7318 8.89389 19.8844V16.7358H28.6307V17.3937C28.6307 17.7568 28.9255 18.0516 29.2887 18.0516C29.6518 18.0516 29.9466 17.7568 29.9466 17.3937V16.7358H37.8413V19.8844C36.2505 20.7331 35.2097 22.5805 35.2097 24.6305Z'
          fill='#467DE1'
        />
        <path
          d='M29.2868 19.3672C28.9237 19.3672 28.6289 19.6619 28.6289 20.0251V21.3409C28.6289 21.704 28.9237 21.9988 29.2868 21.9988C29.65 21.9988 29.9447 21.704 29.9447 21.3409V20.0251C29.9447 19.6619 29.65 19.3672 29.2868 19.3672Z'
          fill='#467DE1'
        />
        <path
          d='M29.2907 23.3145C28.9276 23.3145 28.6328 23.6092 28.6328 23.9724V25.2881C28.6328 25.6513 28.9276 25.9461 29.2907 25.9461C29.6539 25.9461 29.9486 25.6513 29.9486 25.2881V23.9724C29.9486 23.6091 29.6539 23.3145 29.2907 23.3145Z'
          fill='#467DE1'
        />
        <path
          d='M29.2907 27.2617C28.9276 27.2617 28.6328 27.5565 28.6328 27.9196V29.2354C28.6328 29.5986 28.9276 29.8933 29.2907 29.8933C29.6539 29.8933 29.9486 29.5986 29.9486 29.2354V27.9196C29.9486 27.5564 29.6539 27.2617 29.2907 27.2617Z'
          fill='#467DE1'
        />
        <path
          d='M21.3931 28.5781H14.8142C14.451 28.5781 14.1562 28.8729 14.1562 29.236C14.1562 29.5992 14.451 29.894 14.8142 29.894H21.3931C21.7563 29.894 22.051 29.5992 22.051 29.236C22.051 28.8729 21.7563 28.5781 21.3931 28.5781Z'
          fill='#467DE1'
        />
        <path
          d='M24.0247 25.9453H14.8142C14.451 25.9453 14.1562 26.2401 14.1562 26.6032C14.1562 26.9664 14.451 27.2611 14.8142 27.2611H24.0247C24.3878 27.2611 24.6826 26.9664 24.6826 26.6032C24.6826 26.2401 24.3879 25.9453 24.0247 25.9453Z'
          fill='#467DE1'
        />
      </g>
      <defs>
        <clipPath id='clip0_0_1'>
          <rect
            width='31.5789'
            height='31.5789'
            fill='white'
            transform='translate(7.57812 8.8418)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}
