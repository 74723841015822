/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {ProductSaleChartSection} from '../../../_metronic/partials/chart/productSaleChartSection'
import {SalesSection} from '../../../_metronic/partials/chart/SaleChartSection'
import {Chart2Section} from '../../../_metronic/partials/chart/section2'
import {
  CardsWidget17,
  CardsWidget20,
  CardsWidgetChart,
  ListsWidget3,
  TablesWidget9,
} from '../../../_metronic/partials/widgets'
import {TablesLastAddProduct} from '../../../_metronic/partials/widgets/tables/LastAddProduct'
import ResentOrder from './resntOrder'
import {useTranslation} from 'react-i18next'
import TitlePage from '../../modules/customTable/TitlePage'
import {Stack} from '@mui/system'
import ActionBtns from './parts/ActionBtns'
import Cards from './parts/Cards'
import {async} from 'q'
import HomeApi from '../../Api/home'
import TopSilling from './parts/TopSilling'
import DateInput from '../../modules/customTable/DateInput'
import DateInputChart from './parts/DateInputChart'
import {Typography} from '@mui/material'
import {useSelector} from 'react-redux'
import {RootState} from '../../store'
import Get from '../../fubcations/mastars/Get'
import Operations from './parts/Operations'
import Label from '../../components/texts/Label'
import ChartSelect from '../../components/selects/ChartSelect'
import Loading from '../../modules/customTable/LoadingForm'

const DashboardPage: FC = () => {
  const {t} = useTranslation()

  const bannerInfo = {
    title: t('dashboard'),
    home: t('home'),
    linkHome: '/',
  }
  const [cards, setCards] = useState({})
  const [data, setData] = useState<any>({})
  const [chart, setChart] = useState([])
  const [date, setDate] = useState('yearly')
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingChart, setIsLoadingChart] = useState(false)
  Get([], setData, 'screen/vendor/home', setIsLoading)
  Get([date], setChart, `screen/vendor/home/charts?date_type=${date}`, setIsLoadingChart)
  const [newDate, setNewData] = useState({
    sales: chart.map((e: any) => e.amount),
    dates: chart.map((e: any) => e.date),
  })
  const reCycelData = () => {
    setIsLoadingChart(true)
    setNewData({
      sales: chart.map((e: any) => e.amount),
      dates: chart.map((e: any) => e.date),
    })
  }
  useEffect(() => {
    reCycelData()
    setTimeout(() => {
      setIsLoadingChart(!true)
    }, 0)
  }, [chart])
  return (
    <Stack minHeight={'600px'} position={'relative'}>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Stack flexWrap={'wrap'} justifyContent={'space-between'} direction={'row'}>
            <TitlePage info={bannerInfo} />

            <ActionBtns data={data} isLoading={isLoading} />
          </Stack>
          <Stack
            flexWrap={{xs: 'wrap', md: 'nowrap'}}
            direction={'row'}
            justifyContent={'space-between'}
            mt='36px'
            gap='32px'
          >
            <Stack
              width={{xs: '100%', sm: 'auto'}}
              flexWrap={'wrap'}
              gap={{lg: '20px'}}
              direction={{lg: 'row'}}
            >
              <Cards
                data={{
                  balance: data.abrove_balance,
                  income: data.transaction_income,
                  expense: data.transaction_expense,
                  deposits: data.deposits,
                  transactions: data.transactions_total,
                }}
              />
              <Stack minWidth={{lg: '607px'}}>
                <Stack
                  alignItems={'center'}
                  mt='32px'
                  direction={'row'}
                  justifyContent={'space-between'}
                >
                  <Label fs='20px' text={date} />
                  <ChartSelect
                    date={date}
                    setDate={setDate}
                    option={['yearly', 'weekly', 'monthly']}
                  />
                </Stack>
                <Stack minHeight={'270px'} position={'relative'}>
                  {isLoadingChart ? (
                    <Loading />
                  ) : (
                    <Stack dir='ltr'>
                      <CardsWidgetChart
                        charts={newDate}
                        className=''
                        description='Active Projects'
                        color='#F1416C'
                        img={toAbsoluteUrl('/media/patterns/vector-1.png')}
                      />
                    </Stack>
                  )}
                </Stack>
              </Stack>
            </Stack>

            <Operations operations={data.transactions} />
          </Stack>
        </>
      )}
    </Stack>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()

  return (
    <>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
