import React, {useState, useEffect} from 'react'
import Select, {ActionMeta} from 'react-select'

const MultiSelectDropdown = ({
  selectOptions,
  values,
  setValues,
  name,
}: {
  values: any
  setValues: any
  selectOptions: any
  name: string
}) => {
  const [selectedOptions, setSelectedOptions] = useState<any[]>([])
  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      backgroundColor: 'var(--bs-body-bg)',
    }),
    option: (provided: any) => ({
      ...provided,
      backgroundColor: 'var(--bs-body-bg)',
      color: 'var(--bs-heading-color, inherit)',
    }),
    menu: (provided: any) => ({
      ...provided,
      backgroundColor: 'var(--bs-body-bg)',
    }),
    multiValue: (provided: any) => ({
      ...provided,
      backgroundColor: 'var(--bs-app-bg-color)',
      color: 'var(--bs-heading-color, inherit)',
    }),
    multiValueLabel: (provided: any) => ({
      ...provided,
      color: 'var(--bs-heading-color, inherit)',
    }),
  }
  useEffect(() => {
    setValues({...values, [name]: selectedOptions.map((option) => option.value)})
  }, [selectedOptions])

  const options = selectOptions.map((product: any) => ({
    value: product.id,
    label: product.name.en,
  }))
  const [isCome, setIscome] = useState(true)
  useEffect(() => {
    if (selectOptions.length > 0 && values[name] && isCome && values.id) {
      setIscome(false)
      const options = selectOptions
        .filter((item: any) => values[name].includes(item.id))
        .map((item: any) => ({value: item.id, label: item.name.en}))
      if (options.length > 0) {
        setSelectedOptions([...selectedOptions, ...options])
      }
    } else if (values[name] && values[name].length === 0) {
      setIscome(false)
    }
  }, [selectOptions, values[name]])

  const handleChange = (selected: any, actionMeta: any) => {
    setSelectedOptions(selected as any[])
  }

  return (
    <Select
      isMulti
      options={options}
      styles={customStyles}
      value={selectedOptions}
      onChange={handleChange}
    />
  )
}

export default MultiSelectDropdown
