import React from 'react'

export default function Down() {
  return (
    <svg width='53' height='54' viewBox='0 0 53 54' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M26.4999 47.4264C26.0803 47.4264 25.6607 47.2718 25.3295 46.9406L11.9249 33.536C11.2845 32.8956 11.2845 31.8356 11.9249 31.1952C12.5653 30.5547 13.6253 30.5547 14.2657 31.1952L26.4999 43.4293L38.7341 31.1952C39.3745 30.5547 40.4345 30.5547 41.0749 31.1952C41.7153 31.8356 41.7153 32.8956 41.0749 33.536L27.6703 46.9406C27.3391 47.2718 26.9195 47.4264 26.4999 47.4264Z'
        fill='#37BD6B'
        fill-opacity='0.2'
      />
      <path
        d='M26.5 47.051C25.5946 47.051 24.8438 46.3002 24.8438 45.3948V8.22852C24.8438 7.3231 25.5946 6.57227 26.5 6.57227C27.4054 6.57227 28.1562 7.3231 28.1562 8.22852V45.3948C28.1562 46.3002 27.4054 47.051 26.5 47.051Z'
        fill='#37BD6B'
        fill-opacity='0.2'
      />
    </svg>
  )
}
