import {Stack} from '@mui/material'
import React from 'react'
import Label from '../../../../components/texts/Label'
import {useTranslation} from 'react-i18next'

export default function ActionBtn({item}: any) {
  const {t} = useTranslation()
  return (
    <Stack
      sx={{cursor: 'pointer'}}
      p={'10px'}
      gap={'5px'}
      borderBottom={item.name === 'edit' ? '1px solid var(--link-color)' : 'none'}
      direction={'row'}
      onClick={item.onclick}
      alignItems={'center'}
    >
      {item.icon}
      <Label text={t(item.name)} />
    </Stack>
  )
}
