/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {Languages} from '../../../partials/layout/header-menus/Languages'
import {toAbsoluteUrl} from '../../../helpers'
import JwtService from '../../../../app/utils/TokenServices'
import {useDispatch} from 'react-redux'
import {actions} from '../../../../app/store/admin/auth.slice'
import {useTranslation} from 'react-i18next'
import {Avatar, Box, Stack, Typography} from '@mui/material'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../app/store'
import Vendor from '../../../../app/Api/SuperAdmin'
import Label from '../../../../app/components/texts/Label'
import QR from '../../../../app/svg/QR'
import Apiservices from '../../../../lib/ApiServices'

const HeaderUserMenu: FC = () => {
  const dispatch = useDispatch()

  const handelLogout = async () => {
    JwtService.destroyToken()
    localStorage.clear()
    dispatch(actions.login(null))
    await Apiservices.get('auth/logout')
  }
  const {t} = useTranslation()

  useEffect(() => {
    dispatch(
      actions.getUserInfo({
        name: localStorage.getItem('name'),
        image: localStorage.getItem('image'),
      })
    )
  }, [])
  const store = useSelector((state: RootState) => state.vender)

  return (
    <Box
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
      sx={{bgcolor: '#fff', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'}}
    >
      {store.user && store.user.type === 'Vendor' && (
        <div className='menu-item px-3'>
          <div className='menu-link d-flex align-items-center px-3'>
            <Link style={{width: '100%', padding: '0 16px'}} to={'/QRCode'} className='menu-link'>
              <Stack
                width={'100%'}
                alignItems={'center'}
                direction={'row'}
                justifyContent={'space-between'}
              >
                <Typography sx={{fontSize: '14px'}}>{t('shereQR')}</Typography>
                <img style={{width: '25px'}} src='./media/avatars/qr.png' alt='' />
              </Stack>
            </Link>

            <div className='d-flex flex-column'></div>
          </div>
        </div>
      )}

      <div className='separator my-2'></div>
      <div className='menu-item px-5'>
        <Link to={'/profile'} className='menu-link px-5'>
          {t('myProfile')}
        </Link>
      </div>

      <div className='separator my-2'></div>
      <Stack sx={{display: {lg: 'none', xs: 'block'}}}>
        <Languages />
      </Stack>
      <div className='menu-item px-5 my-1'>
        <Stack sx={{display: {lg: 'none', xs: 'block'}}}>
          <Link to='/settings' className='menu-link px-5'>
            {t('settings')}
          </Link>{' '}
        </Stack>
      </div>
      <div className='menu-item px-5'>
        <a onClick={handelLogout} className='menu-link px-5'>
          {t('signOut')}
        </a>
      </div>
    </Box>
  )
}

export {HeaderUserMenu}
