import React, {useState} from 'react'
import VerificationCode from './VerificationCode'
import GetIp from '../../fubcations/mastars/GetIp'
import Post from '../../fubcations/mastars/Post'
import {VereficationSchema} from '../../yup/SignSchema'
import {VereficationObj} from '../modules/post/SignsModule'
import {useDispatch} from 'react-redux'
import {actions} from '../../store/admin/auth.slice'
import JwtService from '../../utils/TokenServices'

export default function VerificationCodeLoginPage({setIsActive, phone}: any) {
  const [loading, setLoading] = useState(false)
  const [userInfo, setUserInfo] = useState<any>({})
  const [codeValue, setCodeValue] = useState<any>({})
  const [errors, setErrors] = useState({})
  const dispatch = useDispatch()

  const handelSubmit = async () => {
    GetIp(
      Post,
      VereficationSchema,
      {
        mobile: phone,
        ...codeValue,
      },
      VereficationObj,
      setErrors,
      (data: any) => {
        dispatch(
          actions.login({...data?.data?.data, isConfirmProfile: data?.data?.isConfirmProfile})
        )
        JwtService.setToken(data?.data?.data?.token)
        // setIsActive(2)
        // setPhone(userInfo.mobile)
      },
      setLoading,
      'auth/login/2FA/verefication'
    )
  }
  return (
    <>
      {' '}
      <VerificationCode
        isLoading={loading}
        handelSubmit={handelSubmit}
        setIsActive={setIsActive}
        msgErr={''}
        setCodeValue={setCodeValue}
        codeValue={codeValue}
        errors={errors}
        reSendPath='auth/login/2FA/verefication/re-send'
        mobile={phone}
      />
    </>
  )
}
