import React from 'react'

export default function Camera() {
  return (
    <svg width='85' height='85' viewBox='0 0 85 85' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M85 42.5C85 65.9721 65.9721 85 42.5 85C19.0279 85 0 65.9721 0 42.5C0 19.0279 19.0279 0 42.5 0C65.9721 0 85 19.0279 85 42.5Z'
        fill='#D8D8D8'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M51.6638 35.2315C52.5503 35.2183 53.403 35.5609 54.0198 36.1783C54.6578 36.7756 55.0125 37.6022 54.9997 38.462V49.7661C54.9997 50.6237 54.6482 51.4462 54.0226 52.0526C53.397 52.659 52.5485 52.9996 51.6638 52.9996H33.3331C32.4466 53.0129 31.5939 52.6702 30.9772 52.0528C30.3403 51.455 29.9867 50.6284 30.0004 49.7691V38.462C29.9875 37.6022 30.3422 36.7756 30.9803 36.1783C31.5971 35.5609 32.4498 35.2183 33.3363 35.2315H36.2489L36.9129 33.5137C37.0973 33.0784 37.4126 32.7069 37.8178 32.4476C38.2075 32.165 38.6791 32.0084 39.1657 32H45.8281C46.3147 32.0084 46.7863 32.165 47.176 32.4476C47.5812 32.7069 47.8964 33.0784 48.0808 33.5137L48.7449 35.2315H51.6638ZM42.4974 49.7692C44.0485 49.7914 45.5399 49.1909 46.6183 48.11C47.7143 47.0524 48.3305 45.6151 48.3305 44.1161C48.3305 42.617 47.7143 41.1798 46.6183 40.1222C45.5399 39.0413 44.0485 38.4408 42.4974 38.463C40.9464 38.4408 39.4549 39.0413 38.3766 40.1222C37.2808 41.1799 36.6648 42.6171 36.6648 44.1161C36.6648 45.6151 37.2808 47.0523 38.3766 48.11C39.4549 49.1909 40.9464 49.7914 42.4974 49.7692Z'
        fill='white'
      />
      <path
        d='M42.4984 40.4779C40.7076 40.4754 39.1644 41.6998 38.8131 43.4019C38.4617 45.104 39.4 46.8096 41.0539 47.4752C42.7079 48.1409 44.6165 47.581 45.6123 46.1382C46.608 44.6954 46.4133 42.7717 45.1473 41.544C44.454 40.8494 43.4953 40.4636 42.4984 40.4779Z'
        fill='white'
      />
    </svg>
  )
}
