import {Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
function capitalizeFirstLetter(text: any) {
  if (!text) return text
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
}
export default function Title({text, fs, fw, isAmount}: any) {
  const {t} = useTranslation()
  const translatedText = t(text)
  const capitalizedText = capitalizeFirstLetter(translatedText)
  return (
    <Typography
      sx={{color: '#19224C', lineHeight: '24px'}}
      fontWeight={fw || '600'}
      fontSize={fs || '24px'}
    >
      {isAmount ? translatedText : capitalizedText}
    </Typography>
  )
}
