import * as Yup from 'yup'
export const WalletSchema = Yup.object().shape({
  amount: Yup.number().required('fieldRequired').min(1, 'amountMsg'),
})
export const BankSchema = Yup.object().shape({
  bankName: Yup.number().required('fieldRequired').min(1, 'fieldRequired'),
  bankAddress: Yup.string().required('fieldRequired'),
  owner: Yup.string().required('fieldRequired'),
  swiftCode: Yup.string().required('fieldRequired'),
  IBAN: Yup.string().required('fieldRequired'),
})
export const EWalletSchema = Yup.object().shape({
  bankName: Yup.number().required('fieldRequired').min(1, 'fieldRequired'),
  name: Yup.string().required('fieldRequired'),
  phone: Yup.number().required('fieldRequired').min(6, 'phoneMsg'),
})
export const ExchangeOfficeSchema = Yup.object().shape({
  officeName: Yup.number().required('fieldRequired').min(1, 'fieldRequired'),
  name: Yup.string().required('fieldRequired'),
  phone: Yup.number().required('fieldRequired').min(6, 'phoneMsg'),
})
