import {Stack} from '@mui/material'
import React from 'react'
import Label from '../components/texts/Label'
import InputType from '../components/inputs/InputType'
import ErrorMsg from '../components/texts/ErrorMsg'
import CustomSelect from '../components/selects/CustomSelect'
import {useTranslation} from 'react-i18next'

export default function SelectWithLabel({
  data,
  setData,
  msgErr,
  placeHolder,
  name,
  fw,
  width,
  errors,
  options,
  Module,
}: any) {
  const {t} = useTranslation()
  return (
    <Stack width={width || 'auto'} gap='8px'>
      <Label fw={fw} text={t(placeHolder)} />
      <CustomSelect
        name={name}
        values={data}
        placeHolder={placeHolder}
        setValues={setData}
        value={{id: data[name]}}
        isError={errors && errors[name]}
        option={options}
        Module={Module}
      />
      {errors && errors[name] && <ErrorMsg msgErr={errors[name]} />}
    </Stack>
  )
}
