import {Stack} from '@mui/material'
import React from 'react'
import Label from '../../../components/texts/Label'

export default function LabelWithValue({icon, text, value}: any) {
  return (
    <Stack alignItems={'center'} width={'100%'} justifyContent={'space-between'} direction={'row'}>
      <Stack alignItems={'center'} direction={'row'} gap='5px'>
        {icon}
        <Label fw='600' text={text} />
      </Stack>
      <Label fw='600' isAmount text={value} />
    </Stack>
  )
}
