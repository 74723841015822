import {IconButton, Stack, Typography} from '@mui/material'
import React, {useState} from 'react'
import OpenEye from '../../svg/OpenEye'
import CloseEye from '../../svg/CloseEye'
import {useSelector} from 'react-redux'
import {RootState} from '../../store'
import {useTranslation} from 'react-i18next'

export default function InputType({
  type,
  width,
  name,
  data,
  setData,
  placeHolder,
  isNumberPhone,
  disabled,
  p,
  br,
  isError,
  isCopy,
  value,
}: any) {
  const [isOpenEye, setIsOpenEye] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const store = useSelector((state: RootState) => state.vender)
  const {t} = useTranslation()

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleBlur = () => {
    setIsFocused(false)
  }

  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      gap='0px'
      p={p ? p : isNumberPhone ? '0 0 0 5px' : '0'}
      borderRadius={br ? br : 'auto'}
      className={`inputTypeContainer ${isFocused ? 'focused' : ''}`}
      position={'relative'}
      width={width}
      borderColor={isError ? 'rgb(239, 71, 111)' : '#DBDEE0'}
      bgcolor={disabled ? '#fafafa' : store.user && store.user.isConfirmProfile ? '#F6F7F9' : ''}
    >
      {isNumberPhone && (
        <Typography sx={{color: '#000'}} fontWeight={'400'} fontSize={'14px'}>
          +966
        </Typography>
      )}
      <input
        disabled={disabled}
        placeholder={`${t(placeHolder)}`}
        onChange={(e) => setData({...data, [name]: e.target.value})}
        value={value ? value : isCopy && data[name] ? `${data[name].slice(0, 30)}...` : data[name]}
        type={isOpenEye ? 'text' : type}
        className='inputType'
        onFocus={handleFocus}
        onBlur={handleBlur}
        style={{fontWeight: '400 !important', background: '#ff000000'}}
        min={1}
      />
      <Stack
        onClick={() => {
          setIsOpenEye(!isOpenEye)
        }}
        sx={{
          right: store.isRtl === 0 ? '14px' : 'auto',
          left: store.isRtl !== 0 ? '14px' : 'auto',
          top: '12px',
          position: 'absolute',
          cursor: 'pointer',
        }}
      >
        {type === 'password' ? isOpenEye ? <OpenEye /> : <CloseEye /> : ''}
      </Stack>
    </Stack>
  )
}
