import React from 'react'

export default function NikeIcon() {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M4.54406 11.8221C4.8695 11.4967 5.39714 11.4967 5.72258 11.8221L10.0143 15.7245L18.2227 6.44468C18.5481 6.11927 19.0758 6.11927 19.4012 6.44468C19.7266 6.7701 19.7266 7.29777 19.4012 7.62318L10.6036 17.4923C10.2782 17.8177 9.75051 17.8177 9.42509 17.4923L4.54406 13.0006C4.21863 12.6752 4.21863 12.1475 4.54406 11.8221Z'
        fill='white'
      />
    </svg>
  )
}
