import React from 'react'

export default function NoData() {
  return (
    <svg
      width='254'
      height='248'
      viewBox='0 0 254 248'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='126.814' cy='124' r='124' fill='url(#paint0_linear_1131_32219)' />
      <circle cx='235.171' cy='57.8943' r='5.42163' stroke='#8BB2FF' stroke-width='4' />
      <circle cx='129.92' cy='232.111' r='3.9196' stroke='#8BB2FF' stroke-width='4' />
      <circle cx='218.402' cy='115.588' r='3.9196' stroke='#8BB2FF' stroke-width='4' />
      <circle cx='7.42163' cy='106.497' r='7.42163' fill='url(#paint1_linear_1131_32219)' />
      <circle cx='121.214' cy='206.581' r='3.93731' stroke='#8BB2FF' stroke-width='4' />
      <g filter='url(#filter0_f_1131_32219)'>
        <path
          d='M37.0756 132.724L39.1793 138.409L44.8645 140.513L39.1793 142.616L37.0756 148.302L34.9718 142.616L29.2866 140.513L34.9718 138.409L37.0756 132.724Z'
          fill='#8BB2FF'
        />
      </g>
      <path
        d='M242.13 157.025L245.258 165.476L253.708 168.603L245.258 171.73L242.13 180.181L239.003 171.73L230.553 168.603L239.003 165.476L242.13 157.025Z'
        fill='#8BB2FF'
      />
      <rect
        x='49.8496'
        y='24.3018'
        width='155.156'
        height='206.251'
        rx='20'
        fill='url(#paint2_linear_1131_32219)'
      />
      <g filter='url(#filter1_f_1131_32219)'>
        <path
          d='M76.2647 11.2158L80.0332 21.4L90.2174 25.1685L80.0332 28.937L76.2647 39.1212L72.4962 28.937L62.312 25.1685L72.4962 21.4L76.2647 11.2158Z'
          fill='#8BB2FF'
        />
      </g>
      <rect
        x='71.0352'
        y='58.5732'
        width='89.1055'
        height='7.47739'
        rx='3.73869'
        fill='url(#paint3_linear_1131_32219)'
      />
      <rect x='71.0352' y='82.251' width='50.4724' height='7.47739' rx='3.73869' fill='#94BAFF' />
      <rect
        x='71.0352'
        y='105.93'
        width='105.93'
        height='7.47739'
        rx='3.73869'
        fill='url(#paint4_linear_1131_32219)'
      />
      <rect x='71.0352' y='129.608' width='84.7437' height='7.47739' rx='3.73869' fill='#94BAFF' />
      <rect x='71.0352' y='153.286' width='84.7437' height='7.47739' rx='3.73869' fill='#94BAFF' />
      <rect x='71.0352' y='176.965' width='57.3266' height='7.47739' rx='3.73869' fill='#94BAFF' />
      <circle
        cx='155.291'
        cy='168.999'
        r='33.6961'
        transform='rotate(-60 155.291 168.999)'
        fill='#F6F9FF'
        stroke='#538FFF'
        stroke-width='14'
      />
      <rect
        x='182.465'
        y='191.509'
        width='11.815'
        height='51.1983'
        rx='5.9075'
        transform='rotate(-60 182.465 191.509)'
        fill='#538FFF'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M142.205 177.303C140.924 178.585 140.924 180.663 142.205 181.944C143.487 183.226 145.565 183.226 146.847 181.944L154.969 173.822L163.091 181.945C164.373 183.227 166.451 183.227 167.733 181.945C169.014 180.663 169.014 178.585 167.733 177.303L159.61 169.181L167.733 161.058C169.014 159.777 169.014 157.698 167.733 156.417C166.451 155.135 164.373 155.135 163.091 156.417L154.969 164.539L146.847 156.417C145.565 155.136 143.487 155.136 142.205 156.417C140.924 157.699 140.924 159.777 142.205 161.059L150.327 169.181L142.205 177.303Z'
        fill='#FF4F75'
      />
      <path
        d='M26.6725 184.648C28.9032 185.246 29.6503 188.034 28.0173 189.667L23.9184 193.766C22.2854 195.399 19.4971 194.652 18.8993 192.421L17.399 186.822C16.8013 184.591 18.8426 182.55 21.0733 183.148L26.6725 184.648Z'
        fill='#8CB3FF'
      />
      <defs>
        <filter
          id='filter0_f_1131_32219'
          x='25.2866'
          y='128.724'
          width='23.5781'
          height='23.5781'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feGaussianBlur stdDeviation='2' result='effect1_foregroundBlur_1131_32219' />
        </filter>
        <filter
          id='filter1_f_1131_32219'
          x='58.312'
          y='7.21582'
          width='35.9053'
          height='35.9053'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feGaussianBlur stdDeviation='2' result='effect1_foregroundBlur_1131_32219' />
        </filter>
        <linearGradient
          id='paint0_linear_1131_32219'
          x1='126.814'
          y1='0'
          x2='126.814'
          y2='248'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='white' stop-opacity='0' />
          <stop offset='1' stop-color='#D9E8FF' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_1131_32219'
          x1='7.42163'
          y1='99.0752'
          x2='7.42163'
          y2='113.918'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#8CB3FF' />
          <stop offset='1' stop-color='#8BB2FF' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_1131_32219'
          x1='53.2767'
          y1='27.1058'
          x2='205.005'
          y2='228.372'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#E3EBFF' />
          <stop offset='0.484375' stop-color='#F5F9FF' />
          <stop offset='0.973958' stop-color='#7AA7FF' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_1131_32219'
          x1='71.0352'
          y1='62.3119'
          x2='160.141'
          y2='62.312'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#94BAFF' />
          <stop offset='1' stop-color='#94BAFF' stop-opacity='0' />
        </linearGradient>
        <linearGradient
          id='paint4_linear_1131_32219'
          x1='72.5929'
          y1='109.668'
          x2='178.834'
          y2='109.668'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#94BAFF' />
          <stop offset='1' stop-color='#94BAFF' stop-opacity='0' />
        </linearGradient>
      </defs>
    </svg>
  )
}
