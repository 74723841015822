import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import {TransitionProps} from '@mui/material/transitions'
import {useTranslation} from 'react-i18next'
import {Typography} from '@mui/material'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />
})

export default function DeletedMsg({open, setOpen, onClick}: any) {
  const {t} = useTranslation()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <></>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby='alert-dialog-slide-description'
        sx={{
          '& .MuiPaper-root': {
            backgroundColor: 'var(--bs-body-bg)',
          },
          '& .MuiDialog-paperWidthSm': {
            maxWidth: '600px',
          },
        }}
      >
        {/* <DialogTitle>{"Use Google's location service?"}</DialogTitle> */}
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>
            <Typography sx={{fontSize: '18px', color: 'var(--bs-heading-color, inherit)'}}>
              {t('deleteMsg')}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button sx={{color: 'var(--bs-heading-color, inherit)'}} onClick={handleClose}>
            {t('disagree')}
          </Button>
          <Button
            onClick={() => {
              onClick()
              setOpen(false)
            }}
            sx={{color: 'var(--bs-heading-color, inherit)'}}
          >
            {t('agree')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
