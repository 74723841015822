import {Stack} from '@mui/material'
import React from 'react'
import Label from '../components/texts/Label'
import InputType from '../components/inputs/InputType'
import ErrorMsg from '../components/texts/ErrorMsg'
import InputDate from '../components/inputs/InputDate'
import InputDateMui from '../components/inputs/InputDateMui'
import Calender from '../svg/Calender'
import {useSelector} from 'react-redux'
import {RootState} from '../store'

export default function InputDateMuiWithLabel({
  data,
  setData,
  msgErr,
  type,
  placeHolder,
  name,
  isNumberPhone,
  fw,
  width,
  disabled,
  errors,
}: any) {
  const store = useSelector((state: RootState) => state.vender)

  return (
    <Stack width={width || 'auto'} gap='8px'>
      <Label fw={fw} text={placeHolder} />
      <Stack position={'relative'}>
        <InputDateMui
          disabled={disabled}
          data={data}
          setData={setData}
          name={name}
          width='100%'
          isError={errors && errors[name]}
        />
        <Stack
          sx={{
            right: store.isRtl === 0 ? '12px' : 'auto',
            left: store.isRtl !== 0 ? '12px' : 'auto',

            bgcolor: '#fff',
            top: '12px',
            zIndex: '1',
          }}
          position={'absolute'}
        >
          <Calender />
        </Stack>
      </Stack>{' '}
      {errors && errors[name] && <ErrorMsg msgErr={errors[name]} />}
    </Stack>
  )
}
