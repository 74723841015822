import React from 'react'

export default function KYCFile() {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5 0C4.3125 0 3.75 0.5625 3.75 1.25V18.75C3.75 19.4375 4.3125 20 5 20H17.5C18.1875 20 18.75 19.4375 18.75 18.75V5L13.75 0H5Z'
        fill='#E2E5E7'
      />
      <path d='M15 5H18.75L13.75 0V3.75C13.75 4.4375 14.3125 5 15 5Z' fill='#B0B7BD' />
      <path d='M18.75 8.75L15 5H18.75V8.75Z' fill='#CAD1D8' />
      <path
        d='M16.25 16.25C16.25 16.5938 15.9688 16.875 15.625 16.875H1.875C1.53125 16.875 1.25 16.5938 1.25 16.25V10C1.25 9.65625 1.53125 9.375 1.875 9.375H15.625C15.9688 9.375 16.25 9.65625 16.25 10V16.25Z'
        fill='#F15642'
      />
      <path
        d='M3.97461 11.8411C3.97461 11.6761 4.10461 11.4961 4.31398 11.4961H5.46836C6.11836 11.4961 6.70336 11.9311 6.70336 12.7648C6.70336 13.5548 6.11836 13.9948 5.46836 13.9948H4.63398V14.6548C4.63398 14.8748 4.49398 14.9992 4.31398 14.9992C4.14898 14.9992 3.97461 14.8748 3.97461 14.6548V11.8411ZM4.63398 12.1255V13.3705H5.46836C5.80336 13.3705 6.06836 13.0748 6.06836 12.7648C6.06836 12.4155 5.80336 12.1255 5.46836 12.1255H4.63398ZM7.68211 14.9992C7.51711 14.9992 7.33711 14.9092 7.33711 14.6898V11.8511C7.33711 11.6717 7.51711 11.5411 7.68211 11.5411H8.82648C11.1102 11.5411 11.0602 14.9992 8.87148 14.9992H7.68211ZM7.99711 12.1511V14.3898H8.82648C10.1759 14.3898 10.2359 12.1511 8.82648 12.1511H7.99711ZM11.8702 12.1911V12.9855H13.1446C13.3246 12.9855 13.5046 13.1655 13.5046 13.3398C13.5046 13.5048 13.3246 13.6398 13.1446 13.6398H11.8702V14.6892C11.8702 14.8642 11.7459 14.9986 11.5709 14.9986C11.3509 14.9986 11.2165 14.8642 11.2165 14.6892V11.8505C11.2165 11.6711 11.3515 11.5405 11.5709 11.5405H13.3252C13.5452 11.5405 13.6752 11.6711 13.6752 11.8505C13.6752 12.0105 13.5452 12.1905 13.3252 12.1905H11.8702V12.1911Z'
        fill='white'
      />
      <path
        d='M15.625 16.875H3.75V17.5H15.625C15.9688 17.5 16.25 17.2188 16.25 16.875V16.25C16.25 16.5938 15.9688 16.875 15.625 16.875Z'
        fill='#CAD1D8'
      />
    </svg>
  )
}
