import {Stack} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import TitlePage from '../../../modules/customTable/TitlePage'
import Title from '../../../components/texts/Title'
import Label from '../../../components/texts/Label'
import Get from '../../../fubcations/mastars/Get'
import path from 'path'
import Loading from '../../../modules/customTable/LoadingForm'

export default function AboutUs() {
  const [about, setAbout] = useState<any>({})
  const [isLoading, setIsLoading] = useState(false)
  Get([], setAbout, 'general/about', setIsLoading)
  return (
    <Stack minHeight={'100%'} position={'relative'}>
      {isLoading ? (
        <Loading />
      ) : (
        <Stack gap='24px'>
          <Title fs='18px' text='aboutUs' />
          <Stack gap='10px'>
            <Label fw='600' lineHeight='28px' text={about.title} />
            <Label lineHeight='28px' text={about.body} />{' '}
          </Stack>
        </Stack>
      )}
    </Stack>
  )
}
