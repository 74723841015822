import React from 'react'

export default function PaymentRefundObj(data: any) {
  return {
    code: data.payment_code,
    client: data.user_fullName,
    city: '??',
    amount: data.amount_request,
    amountRefund: data.amount_refund,
    accountNo: data.user_account_number,
    date: data.date,
    status: data.status_refund,
    id: data.id,
  }
}
