import React from 'react'

export default function PaymentRefund({color, fillColor}: any) {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M21.9991 11C21.99 7.8857 21.8915 6.23467 20.8284 5.17157C19.6569 4 17.7712 4 14 4H10C6.22876 4 4.34315 4 3.17157 5.17157C2 6.34315 2 8.22876 2 12C2 15.7712 2 17.6569 3.17157 18.8284C4.34315 20 6.22876 20 10 20H11.5'
        stroke={color || fillColor || '#6D6D6D'}
        stroke-width='1.5'
        stroke-linecap='round'
      />
      <path
        d='M10 16H6'
        stroke={color || fillColor || '#6D6D6D'}
        stroke-width='1.5'
        stroke-linecap='round'
      />
      <path
        d='M2 10L22 10'
        stroke={color || fillColor || '#6D6D6D'}
        stroke-width='1.5'
        stroke-linecap='round'
      />
      <path
        d='M20.8284 14.8508L20.2861 15.3688L20.2861 15.3688L20.8284 14.8508ZM21.182 15.221V15.971C21.5962 15.971 21.932 15.6352 21.932 15.221H21.182ZM19.0607 14.471C18.6464 14.471 18.3107 14.8068 18.3107 15.221C18.3107 15.6352 18.6464 15.971 19.0607 15.971V14.471ZM21.932 13C21.932 12.5858 21.5962 12.25 21.182 12.25C20.7678 12.25 20.432 12.5858 20.432 13H21.932ZM22.7135 17.1998C22.6644 16.7885 22.2911 16.495 21.8798 16.5442C21.4685 16.5934 21.175 16.9667 21.2242 17.3779L22.7135 17.1998ZM20.2861 20.2554C19.0194 21.5815 16.9806 21.5815 15.7139 20.2554L14.6292 21.2914C16.4867 23.2362 19.5133 23.2362 21.3708 21.2914L20.2861 20.2554ZM15.7139 20.2554C14.4287 18.9098 14.4287 16.7144 15.7139 15.3688L14.6292 14.3328C12.7903 16.2581 12.7903 19.3661 14.6292 21.2914L15.7139 20.2554ZM15.7139 15.3688C16.9806 14.0427 19.0194 14.0427 20.2861 15.3688L21.3708 14.3328C19.5133 12.388 16.4867 12.388 14.6292 14.3328L15.7139 15.3688ZM20.2861 15.3688L20.6396 15.739L21.7243 14.7029L21.3708 14.3328L20.2861 15.3688ZM19.0607 15.971H21.182V14.471H19.0607V15.971ZM21.932 15.221V13H20.432V15.221H21.932ZM21.2242 17.3779C21.3475 18.4095 21.0295 19.4771 20.2861 20.2554L21.3708 21.2914C22.4442 20.1676 22.8864 18.6457 22.7135 17.1998L21.2242 17.3779Z'
        fill={color || fillColor || '#6D6D6D'}
      />
    </svg>
  )
}
