import React from 'react'

export function LoginObj(data: any) {
  return {
    mobile: data.phone,
    password: data.password,
    fcm_token: 'fcm_token',
    device_name: 'device_name',
    mac_address: data.ip,
    mobile_code: data.phone_code,
  }
}
export function SignUpObj(data: any) {
  return {
    mobile: data.phone,
    password: data.password,
    password_confirmation: data.password_confirmation,
    mac_address: '123123',
    code_mobile_country: data.phone_code,
  }
}
export function VereficationObj(data: any) {
  return {
    mac_address: data.ip,
    device_name: 'device_name',
    mobile: data.phone || data.mobile,
    code: data['a'] + data['b'] + data['c'] + data['d'] + data['e'],
    fcm_token: 'fcm_token',
  }
}
export function ForgetPasswordObj(data: any) {
  return {
    mobile: data.mobile,
    mobile_code: data.mobile_code,
  }
}
export function ResetPasswordObj(data: any) {
  return {
    mobile: data.phone,
    new_password: data.new_password,
    new_password_confirmation: data.new_password_confirmation,
  }
}
export function PersonalInfoObj(data: any) {
  return {
    first_name: data.name,
    last_name: data.name,
    city_id: `${data.country}`,
    email: data.email,
    date_of_birth: data.dateBirth,
    gender: data.gender,
    avater: data.avatar,
  }
}
