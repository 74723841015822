import {Box, Stack} from '@mui/material'
import React, {useState} from 'react'

import {useDispatch} from 'react-redux'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import ConfirmForm from '../ConfirmForm'
import TextEreaWithLabel from '../../../parts/TextEreaWithLabel'
import MainBtn from '../../../components/buttons/MainBtn'
import GoBack from '../../../parts/GoBack'
import {RefundSchema} from '../../../yup/PurchasesShcema'
import Post from '../../../fubcations/mastars/Post'
import {RefundObj} from '../../../modules/modules/post/PurchasesModule'

export default function FormRefund() {
  const [data, setData] = useState({code: '+964'})
  const [msgErr, setMsgErr] = useState('')
  const [errors, setErrors] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const {id} = useParams()
  const navigate = useNavigate()
  const submit = async () => {
    Post(
      RefundSchema,
      {...data, id: id},
      RefundObj,
      setErrors,
      () => {
        navigate('/purchases')
      },
      setIsLoading,
      'purchases/payment/refund'
    )
  }
  return (
    <Stack gap='40px' p={{xs: '0 30px'}}>
      <Stack gap='32px'>
        <GoBack onClick={() => navigate('/purchases')} text='goBack' />

        <Stack
          gap='32px'
          justifyContent={'space-between'}
          flexWrap={'wrap'}
          direction={'row'}
          width={{lg: '836px'}}
        >
          <Stack width={'100%'}>
            <TextEreaWithLabel
              fw='400'
              name='refund'
              placeHolder='reason'
              errors={errors}
              setData={setData}
              data={data}
              width='100%'
              msgErr={msgErr}
            />
          </Stack>

          <Box display={'flex'}>
            <MainBtn isLoading={isLoading} text='continue' handelSubmit={submit} />
          </Box>
        </Stack>
      </Stack>
    </Stack>
  )
}
