/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {MenuComponent} from '../../../_metronic/assets/ts/components'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {useThemeMode} from '../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import {useTranslation} from 'react-i18next'
import DeletedMsg from './DeleteMsg'
import {ActionTableBtn} from './styles'
type Props = {
  onEdit?: any
  onDelete?: () => void
  onView?: (() => void) | null
  onReplay?: (() => void) | null
  forEmail?: (() => void) | null
}
const ActionsCell: FC<Props> = ({onEdit, onDelete, onView, onReplay, forEmail}) => {
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])
  const {mode} = useThemeMode()
  const {t} = useTranslation()
  const [open, setOpen] = useState(false)

  return (
    <>
      <ActionTableBtn
        href='#'
        className='btn btn-sm bg-primary text-white'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        {t('actions')}
        <KTSVG path='/media/icons/duotune/arrows/arr098.svg' className='m-0' />
      </ActionTableBtn>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        {onReplay && (
          <div className='menu-item px-3'>
            <a style={{display: 'flex', gap: '5px'}} className='menu-link px-3' onClick={onReplay}>
              <img
                src={toAbsoluteUrl('/media/avatars/replay.svg')}
                alt='SVG Image'
                style={{width: '15px', marginRight: '5px'}}
              />

              {t('replay')}
            </a>
          </div>
        )}
        {forEmail && (
          <div className='menu-item px-3'>
            <a style={{display: 'flex', gap: '5px'}} className='menu-link px-3' onClick={forEmail}>
              <svg
                width='16'
                height='12'
                viewBox='0 0 16 12'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M1.33398 5.99935C1.33398 3.48519 1.33398 2.22811 2.11503 1.44706C2.89608 0.666016 4.15316 0.666016 6.66732 0.666016H9.33398C11.8481 0.666016 13.1052 0.666016 13.8863 1.44706C14.6673 2.22811 14.6673 3.48519 14.6673 5.99935C14.6673 8.51351 14.6673 9.77058 13.8863 10.5516C13.1052 11.3327 11.8481 11.3327 9.33398 11.3327H6.66732C4.15316 11.3327 2.89608 11.3327 2.11503 10.5516C1.33398 9.77058 1.33398 8.51351 1.33398 5.99935Z'
                  stroke='black'
                />
                <path
                  d='M4 3.33398L5.43926 4.53337C6.66369 5.55372 7.2759 6.0639 8 6.0639C8.7241 6.0639 9.33631 5.55372 10.5607 4.53337L12 3.33398'
                  stroke='black'
                  stroke-linecap='round'
                />
              </svg>

              {t('email')}
            </a>
          </div>
        )}
        {onView && (
          <div className='menu-item px-3'>
            <a style={{display: 'flex', gap: '5px'}} className='menu-link px-3' onClick={onView}>
              <img
                src={toAbsoluteUrl('/media/avatars/eye.png')}
                alt='SVG Image'
                style={{width: '15px', marginRight: '5px'}}
              />

              {t('view')}
            </a>
          </div>
        )}
        {onEdit && (
          <div className='menu-item px-3'>
            <a style={{display: 'flex', gap: '5px'}} className='menu-link px-3' onClick={onEdit}>
              <img
                src={toAbsoluteUrl('/media/avatars/edit.png')}
                alt='SVG Image'
                style={{width: '15px', marginRight: '5px'}}
              />
              {t('edit')}
            </a>
          </div>
        )}

        {/* end::Menu item */}

        {/* begin::Menu item */}
        {onDelete && (
          <>
            <div className='menu-item px-3'>
              <a
                style={{display: 'flex', gap: '5px'}}
                className='menu-link px-3'
                data-kt-users-table-filter='delete_row'
                onClick={() => {
                  setOpen(true)
                }}
              >
                <img
                  src={toAbsoluteUrl('/media/avatars/delete.png')}
                  alt='SVG Image'
                  style={{width: '15px', marginRight: '5px'}}
                />
                {t('delete')}
              </a>
            </div>
            <DeletedMsg onClick={onDelete} open={open} setOpen={setOpen} />
          </>
        )}

        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
    </>
  )
}

export {ActionsCell}
