import {Stack} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {RootState} from '../../store'

export default function TextErea({
  width,
  name,
  data,
  setData,
  placeHolder,
  disabled,
  isError,
}: any) {
  const [isFocused, setIsFocused] = useState(false)
  const {t} = useTranslation()
  const store = useSelector((state: RootState) => state.vender)

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleBlur = () => {
    setIsFocused(false)
  }

  return (
    <Stack
      bgcolor={disabled ? '#fafafa' : store.user && store.user.isConfirmProfile ? '#F6F7F9' : ''}
      maxWidth={width}
      direction={'row'}
      alignItems={'center'}
      gap='0px'
      position={'relative'}
      borderColor={'#DBDEE0'}
    >
      <textarea
        rows={5}
        style={{
          border: isError
            ? '1px solid rgb(239, 71, 111)'
            : isFocused
            ? '1px solid var(--link-color)'
            : '1px solid #dbdee0',
          width: '100%',
          borderRadius: '4px',
          padding: '16px 12px 16px 12px',
          background: 'rgb(246 247 249)',
        }}
        placeholder={`${t(placeHolder)}`}
        onChange={(e) => setData({...data, [name]: e.target.value})}
        value={data[name]}
        onFocus={handleFocus}
        onBlur={handleBlur}
        disabled={disabled}
      />
    </Stack>
  )
}
