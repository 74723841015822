import axios from 'axios'
import JwtService from '../app/utils/TokenServices'

const userLanguage = localStorage.getItem('i18nextLng') || 'en'

const Apiservices = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
})

Apiservices.interceptors.request.use(
  async (config) => {
    // Adding token to the headers
    if (JwtService.getToken()) {
      config.headers['Authorization'] = `Bearer ${JwtService.getToken()}`
    }
    config.headers['Accept-Language'] = localStorage.getItem('i18nextLng') || 'en'
    config.headers['Access-Control-Allow-Origin'] = '*'

    // Only execute this logic for POST requests
    // if (config.method === 'put') {
    //   try {
    //     // Make the project key request
    //     const projectKeyResponse = await axios.post(
    //       `${process.env.REACT_APP_API_BASE_URL}/auth/register`,
    //       {
    //         mobile: '0592146775',
    //         password: 'password',
    //         password_confirmation: 'password',
    //         mac_address: '123123',
    //         code_mobile_country: '+964',
    //       }
    //     )

    //     // If the project key request is not successful, reject the original request
    //     if (projectKeyResponse.status !== 200) {
    //       return Promise.reject(new Error('Project key validation failed'))
    //     } else {
    //     }
    //   } catch (error) {
    //     // Handle the error for project key request
    //     return Promise.reject(new Error('Project key validation request failed'))
    //   }
    // }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

Apiservices.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response) {
      if (error.response.status === 403) {
        window.location.href = '/#/403'
      } else if (error.response.status === 401) {
        JwtService.destroyToken()
        window.location.href = '/#/'
      } else if (error.response.status === 500) {
        window.location.href = '/#/500'
      }
    }
    return Promise.reject(error)
  }
)

export default Apiservices
