import {Box, Stack} from '@mui/material'
import React, {useState} from 'react'
import Title from '../../../components/texts/Title'
import UploadImg from '../../../components/inputs/UploadImg'
import Label from '../../../components/texts/Label'
import InputWithLabel from '../../../parts/InputWithLabel'
import InputDateWithLabel from '../../../parts/InputDateWithLabel'
import RadioButtonsGroup from '../../../components/inputs/RadioGroup'
import MainBtn from '../../../components/buttons/MainBtn'
import {useDispatch} from 'react-redux'
import TextEreaWithLabel from '../../../parts/TextEreaWithLabel'
import InputImgWithLabel from '../../../parts/InputImgWithLabel'
import SecondaryBtn from '../../../components/buttons/SacandaryBtn'
import {ChangePasswordSchema} from '../../../yup/SettingSchema'
import Post from '../../../fubcations/mastars/Post'
import {ChangePasswordObj} from '../../../modules/modules/post/SettingsModule'

export default function ChangePassword() {
  const [data, setData] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [msgErr, setMsgErr] = useState('')
  const [edit, setEdit] = useState(false)
  const [errors, setErrors] = useState({})
  const submit = () => {
    Post(
      ChangePasswordSchema,
      data,
      ChangePasswordObj,
      setErrors,
      () => {
        setEdit(false)
      },
      setIsLoading,
      'profile/change-password'
    )
  }
  return (
    <Stack>
      <Stack gap={'32px'}>
        <InputWithLabel
          fw='400'
          name='oldPassword'
          placeHolder='oldPassword'
          msgErr={msgErr}
          setData={setData}
          data={data}
          width='100%'
          disabled={!edit}
          type={'password'}
          errors={errors}
        />
        <InputWithLabel
          fw='400'
          name='newPassword'
          placeHolder='newPassword'
          type={'password'}
          msgErr={msgErr}
          setData={setData}
          data={data}
          width='100%'
          disabled={!edit}
          errors={errors}
        />
        <InputWithLabel
          fw='400'
          name='confirmPassword'
          placeHolder='confirmPassword'
          type={'password'}
          msgErr={msgErr}
          setData={setData}
          data={data}
          width='100%'
          disabled={!edit}
          errors={errors}
        />

        <Stack gap='24px' direction={'row'}>
          {' '}
          {edit && (
            <SecondaryBtn
              width='auto'
              text='cancel'
              handelSubmit={() => {
                setEdit(!edit)
              }}
            />
          )}
          <MainBtn
            isLoading={isLoading}
            width='auto'
            text={edit ? 'save' : 'edit'}
            handelSubmit={() => {
              if (edit) {
                submit()
              } else {
                setEdit(!edit)
              }
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  )
}
