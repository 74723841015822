import {Box, Stack, Typography} from '@mui/material'
import React, {useState} from 'react'

import {useDispatch} from 'react-redux'
import InputWithLabel from '../../../../parts/InputWithLabel'
import MainBtn from '../../../../components/buttons/MainBtn'
import TextLink from '../../../../components/texts/TextLink'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import TextEreaWithLabel from '../../../../parts/TextEreaWithLabel'
import PopUp from '../../../../parts/PopUp'
import ConfirmForm from './ConfirmForm'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../store'
import GoBack from '../../../../parts/GoBack'
import Post from '../../../../fubcations/mastars/Post'
import {DepositeSchema} from '../../../../yup/DepositeSchema'
import {DepositObj} from '../../../../modules/modules/post/DepositModule'
import {
  balanceDepositObj,
  SettingTransfersObj,
} from '../../../../modules/modules/get/SettingTransfersModule'
import Get from '../../../../fubcations/mastars/Get'

export default function FormTransferToUser({
  setIsActive,
  setOpenSuccess,
  data,
  setData,
  setting,
  setSetting,
}: any) {
  const [msgErr, setMsgErr] = useState('')

  const [open, setOpen] = useState(false)
  const store = useSelector((state: RootState) => state.vender)
  const navigate = useNavigate()
  const [errors, setErrors] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const {id, name} = useParams()
  const [loading, setLoading] = useState(false)

  Get([], setSetting, `deposit/settings/${id}`, setLoading, balanceDepositObj)

  return (
    <Stack gap='40px' p={{xs: '0 30px'}}>
      <Stack gap='32px'>
        <GoBack onClick={() => navigate('/wallet')} text='goBack' />

        <Stack
          gap='32px'
          // justifyContent={'space-between'}
          flexWrap={'wrap'}
          direction={'row'}
          width={{md: '836px'}}
        >
          <Stack width={{xs: '100%', md: '395px'}} position={'relative'}>
            <InputWithLabel
              fw='400'
              name='balance'
              placeHolder='yourBalance'
              value={
                setting.isFixed && +data['amount'] - +setting.fee_value
                  ? +data.amount - +setting.fee_value
                  : !setting.isFixed && +data.amount - +data.amount * (+setting.fee_value / 100)
                  ? +data.amount - +data.amount * (+setting.fee_value / 100)
                  : '0'
              }
              type='number'
              msgErr={msgErr}
              setData={setData}
              data={data}
              width={{xs: '100%', md: '395px'}}
              disabled
            />
            <Typography
              sx={{
                right: store.isRtl === 0 ? '14px' : 'auto',
                left: store.isRtl !== 0 ? '14px' : 'auto',
                top: '31px',
                fontSize: '18px',
                fontWeight: '600',
              }}
              position={'absolute'}
            >
              {setting.balance}
            </Typography>
          </Stack>

          <InputWithLabel
            fw='400'
            name='amount'
            placeHolder='enterYourAmount'
            type='number'
            msgErr={msgErr}
            setData={setData}
            data={data}
            width={{xs: '100%', md: '395px'}}
            errors={errors}
          />
        </Stack>
        <Box>
          <MainBtn
            width='auto'
            text='continue'
            handelSubmit={() => {
              Post(DepositeSchema, {...data, id: id}, DepositObj, setErrors, () => {
                setOpen(true)
              })
            }}
          />
        </Box>
      </Stack>
      <PopUp open={open} setOpen={setOpen} width='md'>
        <ConfirmForm
          setting={setting}
          data={{...data, name: name}}
          isLoading={isLoading}
          total={
            setting.isFixed && +data['amount'] - +setting.fee_value
              ? +data.amount - +setting.fee_value
              : !setting.isFixed && +data.amount - +data.amount * (+setting.fee_value / 100)
              ? +data.amount - +data.amount * (+setting.fee_value / 100)
              : '0'
          }
          submit={() => {
            Post(
              DepositeSchema,
              {...data, id: id},
              DepositObj,
              setErrors,
              () => {
                setIsActive(1)
                setOpenSuccess(true)
                setOpen(false)
              },
              setIsLoading,
              'deposit/charge'
            )
          }}
          setOpenSuccess={setOpenSuccess}
          setIsActive={setIsActive}
          setOpen={setOpen}
        />
      </PopUp>
    </Stack>
  )
}
