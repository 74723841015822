export function paymentRefundRejectObj(data: any) {
  return {
    refund_request_id: data.id,
  }
}
export function paymentRefundAcceptObj(data: any) {
  return {
    refund_request_id: data.id,
    amount_refund: +data.newAmount,
  }
}
