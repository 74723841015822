import {Box, Stack} from '@mui/material'
import React, {useState} from 'react'
import Title from '../../components/texts/Title'
import UploadImg from '../../components/inputs/UploadImg'
import Label from '../../components/texts/Label'
import InputWithLabel from '../../parts/InputWithLabel'
import InputDateWithLabel from '../../parts/InputDateWithLabel'
import RadioButtonsGroup from '../../components/inputs/RadioGroup'
import MainBtn from '../../components/buttons/MainBtn'
import {useDispatch} from 'react-redux'
import {PersonalInfoSchema} from '../../yup/SignSchema'
import * as Yup from 'yup'
import InputDateMui from '../../components/inputs/InputDateMui'
import InputDateMuiWithLabel from '../../parts/InputDateMuiWithLabel'
import {PersonalInfoObj} from '../../modules/modules/post/SignsModule'
import SelectWithLabel from '../../parts/SelectWithLabel'
import Post from '../../fubcations/mastars/Post'
import Get from '../../fubcations/mastars/Get'
import {actions} from '../../store/admin/auth.slice'
import {RootState} from '../../store'
import {useSelector} from 'react-redux'
import CityModule from '../../modules/modules/get/CityModule'

export default function FormPersonalInformation() {
  const [data, setData] = useState({gender: 'M'})
  const [base64Image, setBase64Image] = useState('')
  const [errors, setErrors] = useState({})
  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const store = useSelector((state: RootState) => state.vender)

  const submit = async () => {
    Post(
      PersonalInfoSchema,
      {...data, isFormData: true},
      PersonalInfoObj,
      setErrors,
      () => {
        dispatch(actions.login({...store.user, isConfirmProfile: true}))
      },
      setLoading,
      'auth/profile'
    )
  }
  Get([], setOptions, 'auth/cities', setLoading)
  return (
    <Stack gap='40px' p={{xs: '0 30px', md: '0 135px'}}>
      <Title fs='18px' text='enterPersonalInformation' />
      <Stack gap={'32px'}>
        <Stack gap='8px'>
          <UploadImg
            setBase64Image={setBase64Image}
            base64Image={base64Image}
            name='avatar'
            setData={setData}
            data={data}
          />
          <Label fw='400' text='pickPicture' />
        </Stack>
        <Stack gap='32px' flexWrap={'wrap'} direction={'row'} width={{lg: '836px'}}>
          <InputWithLabel
            fw='400'
            name='name'
            placeHolder='firstName'
            type='text'
            errors={errors}
            setData={setData}
            data={data}
            width={{xs: '100%', sm: '395px'}}
          />
          <SelectWithLabel
            fw='400'
            name='country'
            placeHolder='country'
            type='text'
            errors={errors}
            setData={setData}
            data={data}
            width={{xs: '100%', sm: '395px'}}
            options={options}
            Module={CityModule}
          />
          <InputWithLabel
            fw='400'
            name='email'
            placeHolder='email'
            type='email'
            errors={errors}
            setData={setData}
            data={data}
            width={{xs: '100%', sm: '395px'}}
          />
          <InputDateMuiWithLabel
            fw='400'
            name='dateBirth'
            placeHolder='dateBirth'
            type='text'
            errors={errors}
            setData={setData}
            data={data}
            width={{xs: '100%', sm: '395px'}}
          />

          <Stack width={'100%'}>
            <Label fw='400' text='chooseGender' />
            <RadioButtonsGroup
              info={data}
              setData={setData}
              value={data['gender'] || 'M'}
              data={['M', 'F']}
            />
          </Stack>
          <Box display={'flex'}>
            <MainBtn isLoading={loading} text='continue' handelSubmit={submit} />
          </Box>
        </Stack>
      </Stack>
    </Stack>
  )
}
