import {Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

export default function Status({text}: any) {
  const {t} = useTranslation()
  return (
    <Typography
      sx={{
        color:
          text === 'pending'
            ? '#FF881B'
            : text === 'open'
            ? '#48b04d'
            : text === 'completed'
            ? '#74adeb'
            : '#e15259',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '20px',
        letterSpacing: '-0.5px',
      }}
    >
      {t(text)}
    </Typography>
  )
}
