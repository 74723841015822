import {CircularProgress} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

export default function MainBtn({
  isLoading,
  handelSubmit,
  text,
  disabled,
  width,
  p,
  fs,
  isAmount,
  bgcolor,
}: any) {
  const {t} = useTranslation()
  function capitalizeFirstLetter(text: any) {
    if (!text) return text
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
  }
  const translatedText = t(text)
  const capitalizedText = capitalizeFirstLetter(translatedText)
  return (
    <button
      disabled={disabled || isLoading}
      style={{
        fontSize: fs || '14px',
        color: 'white',
        fontWeight: '600',
        background:
          disabled || isLoading
            ? '#92e6fc'
            : bgcolor
            ? bgcolor
            : ' linear-gradient(180deg, #6775E2 -46.29%, #44B9EA 79.49%)',
        borderRadius: '4px',
        padding: p || '10px 24px',
        border: 'none',
        width: width || '100%',
        minWidth: '120px',
      }}
      onClick={handelSubmit}
    >
      {isLoading ? (
        <CircularProgress size={'20px'} color='info' />
      ) : isAmount ? (
        translatedText
      ) : (
        capitalizedText
      )}
    </button>
  )
}
