import {Stack, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

export default function LabelWithValue({text, answer}: any) {
  const {t} = useTranslation()
  return (
    <Stack gap='5px' direction={'row'}>
      <Typography sx={{color: '#636B6F', fontSize: '16px', fontWeight: '400', lineHeight: '15px'}}>
        {t(text)} :
      </Typography>
      <Typography
        sx={{
          color: '#cbcbcb',
          fontSize: '16px',
          fontWeight: '400',
          lineHeight: '20px',
          letterSpacing: '-0.5px',
        }}
      >
        {answer}
      </Typography>
    </Stack>
  )
}
