import {Stack} from '@mui/material'
import React from 'react'
import Nike from '../../../svg/Nike'
import Title from '../../../components/texts/Title'
import Label from '../../../components/texts/Label'
import LabelWithValue from '../pages/transferToUser/LabelWithValue'
import MainBtn from '../../../components/buttons/MainBtn'
import {useNavigate} from 'react-router-dom'
import SecondaryBtn from '../../../components/buttons/SacandaryBtn'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'

export default function Success({data}: any) {
  const navigate = useNavigate()
  const store = useSelector((state: RootState) => state.vender)

  return (
    <Stack gap='24px' alignItems={'center'} justifyContent={'center'} p='60px 40px'>
      <Nike />
      <Title fs='20px' text='successfullyTopUp' />
      <Label text='thanksServise' fw='400' color='#9D97B1' />
      <Stack gap='24px' width={'100%'}>
        <LabelWithValue color='#9D97B1' value={data && data.name} label='beneficiary' />
        <LabelWithValue
          color='#9D97B1'
          value={data && data.amount}
          text3={` ${store.user.currencyCode}`}
          label='transferAmount'
        />
      </Stack>
      <Stack width={'90%'} direction={'row'} gap='15px'>
        <MainBtn
          text='backToHome'
          handelSubmit={() => {
            navigate('/dashboard')
          }}
        />
        {/* <SecondaryBtn
          text='viewDetails'
          handelSubmit={() => {
            navigate('/dashboard')
          }}
        /> */}
      </Stack>
    </Stack>
  )
}
