import {FC, lazy, Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'

import Error403 from '../modules/errors/components/Error403'
import CustomPage from '../modules/CustomPage'

import Error401 from '../modules/errors/components/Error401'
import {Error500} from '../modules/errors/components/Error500'
import NewCustomPage from '../CustomPage'
import HomePage from './routs/Home'
import TransactionsPage from './routs/Transactions'
import Wallet from './routs/Wallet'
import LatestOrder from '../pages/latestOrder'
import AccountStatemant from '../pages/accountStatemant'
import Purchases from '../pages/purchases'
import Refund from '../pages/purchases/refund'
import TicketPage from './routs/Ticket'
import PaymentLog from '../pages/paymentLog'
import PaymentRefund from '../pages/paymentRefund'
import RequestRefund from '../pages/requestRefund'
import Profile from '../pages/profile'
import SettingsPage from './routs/Settings'
import QRCode from '../pages/QRCode'
import {useSelector} from 'react-redux'
import {RootState} from '../store'

const PrivateRoutes = () => {
  const store = useSelector((state: RootState) => state.vender)

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='custom' element={<CustomPage />} />
        <Route
          path='latestOrder'
          element={store.user && store.user.type === 'Vendor' ? <LatestOrder /> : <Error403 />}
        />
        <Route path='accountStatemant' element={<AccountStatemant />} />
        <Route
          path='paymentLog'
          element={store.user && store.user.type === 'Vendor' ? <PaymentLog /> : <Error403 />}
        />
        <Route
          path='requestRefund'
          element={store.user && store.user.type === 'Vendor' ? <RequestRefund /> : <Error403 />}
        />
        <Route
          path='paymentRefund'
          element={store.user && store.user.type === 'Vendor' ? <PaymentRefund /> : <Error403 />}
        />
        {/* <Route path='settings' element={} /> */}
        <Route path='profile' element={<Profile />} />
        <Route path='purchases' element={<Purchases />} />
        <Route path='purchases/refund/:id' element={<Refund />} />
        <Route path='customPage' element={<NewCustomPage />} />

        <Route
          path='QRCode'
          element={store.user && store.user.type === 'Vendor' ? <QRCode /> : <Error403 />}
        />
        <Route path='403' element={<Error403 />} />
        <Route path='401' element={<Error401 />} />
        <Route path='500' element={<Error500 />} />
        <Route
          path='dashboard/*'
          element={
            <SuspensedView>
              <HomePage />
            </SuspensedView>
          }
        />
        <Route
          path='settings/*'
          element={
            <SuspensedView>
              <SettingsPage />{' '}
            </SuspensedView>
          }
        />
        <Route
          path='transactions/*'
          element={
            <SuspensedView>
              <TransactionsPage />
            </SuspensedView>
          }
        />
        <Route
          path='wallet/*'
          element={
            <SuspensedView>
              <Wallet />
            </SuspensedView>
          }
        />
        <Route
          path='ticket/*'
          element={
            <SuspensedView>
              <TicketPage />
            </SuspensedView>
          }
        />
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
