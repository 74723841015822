import React from 'react'

export default function OpenEye() {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10.0001 8.00065C10.0001 9.10522 9.10465 10.0007 8.00008 10.0007C6.89551 10.0007 6.00008 9.10522 6.00008 8.00065C6.00008 6.89608 6.89551 6.00065 8.00008 6.00065C9.10465 6.00065 10.0001 6.89608 10.0001 8.00065Z'
        stroke='#636B6F'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1.63892 8.00063C2.48843 5.2959 5.01529 3.33398 8.00037 3.33398C10.9855 3.33398 13.5123 5.29593 14.3618 8.00068C13.5123 10.7054 10.9855 12.6673 8.00039 12.6673C5.01529 12.6673 2.48842 10.7054 1.63892 8.00063Z'
        stroke='#636B6F'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}
