import {Box, Stack} from '@mui/material'
import React, {useState} from 'react'

import {useDispatch} from 'react-redux'

import {Link, useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import InputPhoneWithLabel from '../../../parts/InputPhoneWithLabel'
import InputWithLabel from '../../../parts/InputWithLabel'
import TextEreaWithLabel from '../../../parts/TextEreaWithLabel'
import MainBtn from '../../../components/buttons/MainBtn'
import Post from '../../../fubcations/mastars/Post'
import {ContactUsSchema} from '../../../yup/SettingSchema'
import {ContactUsObj} from '../../../modules/modules/post/SettingsModule'
import Get from '../../../fubcations/mastars/Get'
import SelectWithLabel from '../../../parts/SelectWithLabel'
import ReasonsObj from '../../../modules/modules/get/ReasonsModule'

export default function FormTransferToUser({setIsActive, setOpenSuccess}: any) {
  const [data, setData] = useState({code: '+964'})
  const [msgErr, setMsgErr] = useState('')
  const {t} = useTranslation()
  const [open, setOpen] = useState(false)
  const [errors, setErrors] = useState({})
  const [reasons, setReasons] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  Get([], setReasons, 'contact/reason')
  const submit = () => {
    Post(ContactUsSchema, data, ContactUsObj, setErrors, () => {}, setIsLoading, 'contact/send')
  }
  return (
    <Stack gap='40px' p={{xs: '0 30px'}}>
      <Stack gap='32px'>
        <Stack
          gap='32px'
          justifyContent={'space-between'}
          flexWrap={'wrap'}
          direction={'row'}
          width={{lg: '100%'}}
        >
          <InputWithLabel
            fw='400'
            name='name'
            placeHolder='name'
            type='text'
            msgErr={msgErr}
            setData={setData}
            data={data}
            width={{xs: '100%', md: '395px'}}
            errors={errors}
          />
          <InputWithLabel
            fw='400'
            name='email'
            placeHolder='email'
            type='email'
            msgErr={msgErr}
            setData={setData}
            data={data}
            width={{xs: '100%'}}
            errors={errors}
          />
          <InputPhoneWithLabel
            fw='400'
            name='phone'
            placeHolder='MobileNumber'
            msgErr={msgErr}
            setData={setData}
            data={data}
            width={{xs: '100%', md: '395px'}}
            errors={errors}
            codeName='code'
          />
          <SelectWithLabel
            fw='400'
            name='reason'
            placeHolder='reason'
            type='text'
            errors={errors}
            setData={setData}
            data={data}
            width={{xs: '100%', sm: '395px'}}
            options={reasons}
            Module={ReasonsObj}
          />

          <Stack width={'100%'}>
            <TextEreaWithLabel
              fw='400'
              name='content'
              placeHolder='yourMessage'
              msgErr={msgErr}
              setData={setData}
              data={data}
              width='100%'
              errors={errors}
            />
          </Stack>

          <Box display={'flex'}>
            <MainBtn
              isLoading={isLoading}
              text='contactNow'
              handelSubmit={() => {
                submit()
                setOpen(true)
              }}
            />
          </Box>
        </Stack>
      </Stack>
    </Stack>
  )
}
