import {Stack} from '@mui/material'
import React, {useState} from 'react'
import TitlePage from '../../modules/customTable/TitlePage'
import {useTranslation} from 'react-i18next'
import SideBar from './sideBar'
import Contant from './contant'
import {useSelector} from 'react-redux'
import {RootState} from '../../store'

export default function Settings() {
  const {t} = useTranslation()
  const bannerInfo = {
    title: t('settings'),
    linkHome: '/',
  }
  const store = useSelector((state: RootState) => state.vender)

  const [contant, setContant] = useState(
    store.user && store.user.type === 'Vendor' ? 'businessAccountDetails' : 'convertToBusiness'
  )

  const [SelectLang, setSelectLang] = useState({
    code: store.user.language_code,
    name: store.user.language_name,
    flag: 'https://www.shutterstock.com/image-vector/jordan-country-flag-vector-illustration-260nw-2279320689.jpg',
  })

  return (
    <Stack gap='32px'>
      <Stack flexWrap={'wrap'} justifyContent={'space-between'} direction={'row'}>
        <TitlePage info={bannerInfo} />
      </Stack>
      <Stack direction={{xs: 'column', md: 'row'}} gap={{xs: '20px', md: '100px'}}>
        <SideBar SelectLang={SelectLang} setContant={setContant} contant={contant} />
        <Contant setSelectLang={setSelectLang} SelectLang={SelectLang} contant={contant} />
      </Stack>
    </Stack>
  )
}
