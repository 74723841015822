import {Avatar, SxProps} from '@mui/material'

type IProps = {
  src?: string
  alt?: string
  width?: string
  height?: string
  borderRadius?: string
  mx?: string
  sx?: SxProps
}

export default function AvatarBox({sx, height, width, borderRadius, src, alt}: IProps) {
  return (
    <Avatar
      alt={alt}
      src={src || `/media/images/avatarImg.svg`}
      sx={{width, height, borderRadius, ...sx}}
    />
  )
}
