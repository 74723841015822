import {Stack} from '@mui/material'
import React, {useState} from 'react'
import Title from '../../../../components/texts/Title'
import FormTransferToUser from './Form'
import VerificationPage from '../../../dashboard/parts/VerificationPage'
import PopUp from '../../../../parts/PopUp'
import Success from './Success'

export default function BalanceDeposit() {
  const [isActive, setIsActive] = useState(0)
  const [openSuccess, setOpenSuccess] = useState(false)
  const [data, setData] = useState<any>({})
  const [setting, setSetting] = useState<any>({})

  return (
    <>
      <Stack gap='32px'>
        <Title text='balanceDeposit' />
        <FormTransferToUser
          setData={setData}
          setting={setting}
          setSetting={setSetting}
          data={data}
          setOpenSuccess={setOpenSuccess}
          setIsActive={setIsActive}
        />
      </Stack>

      <PopUp width='xs' setOpen={setOpenSuccess} open={openSuccess}>
        <Success
          total={
            setting.isFixed && +data['amount'] - +setting.fee_value
              ? +data.amount - +setting.fee_value
              : !setting.isFixed && +data.amount - +data.amount * (+setting.fee_value / 100)
              ? +data.amount - +data.amount * (+setting.fee_value / 100)
              : '0'
          }
        />
      </PopUp>
    </>
  )
}
