import {Container, Stack} from '@mui/material'
import React, {useState} from 'react'
import VerificationCode from '../../../modules/login/VerificationCode'
import {VereficationSchema} from '../../../yup/SignSchema'
import Post from '../../../fubcations/mastars/Post'
import {VereficationObj} from '../../../modules/modules/post/TransferModule'

export default function VerificationPage({setIsActive, setOpenSuccess, data, path, Module}: any) {
  const handelSubmit = () => {}
  const [codeValue, setCodeValue] = useState<any>({})
  const [msgErr, setMsgErr] = React.useState<string>('')
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({})
  const handelSubmitSendCode = async () => {
    Post(
      VereficationSchema,
      {...data, ...codeValue},
      Module,
      setErrors,
      () => {
        setIsActive(0)
        setOpenSuccess(true)
      },
      setLoading,
      path
    )
  }
  return (
    <Stack height={'60vh'}>
      <Container
        sx={{
          background: '#ffff',
          boxShadow: ' rgba(0, 0, 0, 0.24) 0px 3px 8px',
          borderRadius: '16px',
          padding: '40px',
          margin: 'auto',
        }}
        maxWidth={'xs'}
      >
        <VerificationCode
          msgErr={msgErr}
          setCodeValue={setCodeValue}
          codeValue={codeValue}
          handelSubmit={handelSubmitSendCode}
          setIsActive={setIsActive}
          errors={errors}
          isLoading={loading}
        />
      </Container>
    </Stack>
  )
}
