import {FormControlLabel, Radio, Stack} from '@mui/material'
import React, {useState} from 'react'
import Label from '../../../../components/texts/Label'
import ActionBtns from './ActionBtns'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../store'
import PopUp from '../../../../parts/PopUp'
import DeleteMsg from './DeleteMsg'
import Bank from '../../../../svg/Bank'

export default function WithDrawItem({
  item,
  isActive,
  setIsActive,
  isSitting,
  data,
  setData,
  setItem,
}: any) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setIsActive) {
      setIsActive(item.id)
      setItem(item)
    }
  }

  const store = useSelector((state: RootState) => state.vender)
  const [open, setOpen] = useState(false)

  return (
    <Stack
      onClick={() => {
        setIsActive(item.id)
        setItem(item)
      }}
      bgcolor={'#e7edf7'}
      width={'397px'}
      height={'256px'}
      borderRadius={'16px'}
      border={isActive ? '1px solid #467DE1' : ''}
      p='24px'
      justifyContent={'space-between'}
    >
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Stack gap='24px' direction={'row'}>
          <Bank />
          <Stack justifyContent={'space-between'} gap='16px'>
            <Label fw='400' answer={item.name} text={item.type} />
            {item.bank_adress && <Label fw='400' answer={item.bank_adress} text={'bankAddress'} />}
            {item.owner && <Label fw='400' answer={item.owner} text={'owner'} />}
            {item.swift_code && <Label fw='400' answer={item.swift_code} text={'swiftCode'} />}
            {item.iban && <Label fw='400' answer={item.iban} text={'IBAN'} />}
            {(item.wallet_mobile || item.exchange_mobile) && (
              <Label fw='400' answer={item.wallet_mobile || item.exchange_mobile} text={'mobile'} />
            )}
          </Stack>
        </Stack>
        <ActionBtns openDeleteMsg={open} setOpenDeleteMsg={setOpen} item={item} />
      </Stack>
      {!isSitting && (
        <Stack
          // bgcolor={'red'}
          // position={'relative'}
          className='radio-btn'
          alignItems={'flex-end'}
          width={'100%'}
        >
          <Radio
            sx={{
              // position: 'absolute',
              right: store.isRtl === 0 ? '-6px' : 'auto',
              left: store.isRtl !== 0 ? '1-6px4px' : 'auto',
              bottom: '-0',
            }}
            checked={isActive}
            onChange={handleChange}
            value={item.id}
            name='radio-buttons'
            inputProps={{'aria-label': 'A'}}
          />{' '}
        </Stack>
      )}{' '}
      {open && (
        <PopUp width='xs' setOpen={setOpen} open={open}>
          <DeleteMsg setData={setData} data={data} setOpen={setOpen} item={item} />
        </PopUp>
      )}
    </Stack>
  )
}
