import React from 'react'

export default function TransferToNoneUser() {
  return (
    <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='16' cy='16' r='16' fill='#B47AFF' fill-opacity='0.1' />
      <g clip-path='url(#clip0_1_29809)'>
        <path
          d='M19.1334 9.11954H22.0873L15.3342 15.876C15.1155 16.0947 15.1155 16.4504 15.3342 16.6691C15.4419 16.7768 15.5855 16.8323 15.7291 16.8323C15.8727 16.8323 16.0164 16.7768 16.1241 16.6691L22.8805 9.91269V12.8666C22.8805 13.1767 23.1318 13.428 23.4386 13.428C23.7487 13.428 24 13.1767 24 12.8666V8.5614C24 8.25133 23.7487 8 23.4386 8H19.1334C18.8234 8 18.572 8.25133 18.572 8.5614C18.5753 8.86822 18.8234 9.11954 19.1334 9.11954Z'
          fill='#B47AFF'
        />
        <path
          d='M11.3586 24H20.6381C22.492 24 23.9967 22.492 23.9967 20.6414V16.2709C23.9967 15.9608 23.7454 15.7128 23.4353 15.7128C23.1253 15.7128 22.8772 15.9641 22.8772 16.2709V20.6381C22.8772 21.8719 21.8719 22.8772 20.6381 22.8772H11.3586C10.1248 22.8772 9.11954 21.8719 9.11954 20.6381V13.2681V11.3619C9.11954 10.1281 10.1248 9.12281 11.3586 9.12281H15.6899C16 9.12281 16.2481 8.87148 16.2481 8.5614C16.2481 8.25133 15.9967 8 15.6899 8H11.3586C9.50469 8 8 9.50796 8 11.3586V13.2648V20.6381C8 22.492 9.50796 24 11.3586 24Z'
          fill='#B47AFF'
        />
      </g>
      <defs>
        <clipPath id='clip0_1_29809'>
          <rect width='16' height='16' fill='white' transform='translate(8 8)' />
        </clipPath>
      </defs>
    </svg>
  )
}
