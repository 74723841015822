import React, {useEffect, useState} from 'react'
import {Stack, Typography} from '@mui/material'
import Table from './Table'
import {useTranslation} from 'react-i18next'
import Get from '../../../../fubcations/mastars/Get'

export default function TransactionsTable() {
  const {t} = useTranslation()

  const [rows, setRows] = useState<any>([])
  const [added, setAdded] = useState(!false)
  Get([], setRows, 'transaction/all', setAdded)

  return <Table setAdded={setAdded} added={added} rows={rows} setRows={setRows} />
}
