import {CircularProgress, Stack, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import Arrow from '../../svg/Arrow'

export default function BtnWithIcon({name, icon, without, onClick}: any) {
  const {t} = useTranslation()
  function capitalizeFirstLetter(name: any) {
    if (!name) return name
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase()
  }
  const translatedText = t(name)
  const capitalizedText = capitalizeFirstLetter(translatedText)
  return (
    <button
      style={{
        fontSize: '16px',
        color: without ? '#467DE1' : 'white',
        fontWeight: '400',
        background: without ? '#fff' : ' linear-gradient(180deg, #6775E2 -46.29%, #44B9EA 79.49%)',
        borderRadius: '4px',
        padding: '11px 24px',
        border: without ? '1px solid #467DE1' : 'none',
      }}
      onClick={() => {
        onClick()
      }}
    >
      <Stack gap='8px' alignItems={'normal'} direction={'row'}>
        {icon}
        <Typography>{capitalizedText}</Typography>
      </Stack>
    </button>
  )
}
