import {Box, Stack} from '@mui/material'
import React from 'react'

import LabelWithValue from '../wallet/pages/withdraw/pages/bank/LabelWithValue'
import {useNavigate} from 'react-router-dom'
import TextLink from '../../components/texts/TextLink'
import Logo from '../../svg/Logo'
import Title from '../../components/texts/Title'
import Label from '../../components/texts/Label'
import MainBtn from '../../components/buttons/MainBtn'
import Status from '../../components/texts/Status'
import GoBack from '../../parts/GoBack'

export default function ConfirmForm({setOpen, item}: any) {
  const navigate = useNavigate()
  return (
    <Stack p='24px'>
      <Stack alignItems={'center'} justifyContent={'space-between'} direction={'row'}>
        <GoBack onClick={() => setOpen(false)} text='goBack' />

        <Logo />
      </Stack>
      <Stack p='24px 0' borderBottom='1px dashed #9D97B1' gap='24px'>
        <Title text='invoiceDetails' />
        <>
          {item.statusRefund && (
            <>
              <Stack gap='24px'>
                <Stack alignItems={'center'} direction={'row'} gap='5px'>
                  <Label text='refundStatus' /> :
                  <Status text={item.statusRefund} />
                </Stack>
                {item.statusRefund === 'accept' && (
                  <>
                    {' '}
                    <Stack alignItems={'center'} direction={'row'} gap='5px'>
                      <Label fw='600' text='principalAmount' /> :
                      <Label fw='600' isAmount text={item.amountRequest} />
                    </Stack>
                    <Stack alignItems={'center'} direction={'row'} gap='5px'>
                      <Label fw='600' text='refundAmount' /> :
                      <Label fw='600' isAmount text={item.amountRefund} />
                    </Stack>
                    <Stack alignItems={'center'} direction={'row'} gap='5px'>
                      <Label fw='600' text='vendorName' /> :
                      <Label fw='600' text={item.vendorName} />
                    </Stack>
                  </>
                )}
              </Stack>
            </>
          )}
        </>
      </Stack>
      <Stack borderBottom='1px dashed #9D97B1' gap='24px' p='24px 0'>
        <LabelWithValue value={item.title} label='type' />
        <LabelWithValue value={item.id} label='invoiceId' />
        <LabelWithValue value={item.orderDate} label='paymentDate' />
        <LabelWithValue value={item.payFor} label='payFor' />
      </Stack>

      <Stack borderBottom='1px dashed #9D97B1' gap='24px' p='24px 0'>
        <LabelWithValue isAmount value={item.amount} label='amount' />
        <LabelWithValue isAmount value={item.balance} label='balance' />
      </Stack>
      <Stack
        direction={'row'}
        justifyContent={'flex-end'}
        alignItems={'center'}
        borderBottom='1px dashed #9D97B1'
        gap='5px'
        p='24px 0'
      >
        <Label text='total' />
        <Label text=':' />
        <Title isAmount fs='18px' text={item.total} />
      </Stack>
      <Stack width={'120px'} pt={'24px'} direction={'row'} justifyContent={'flex-start'}>
        {!item.statusRefund && (
          <MainBtn
            text='refund'
            handelSubmit={() => {
              navigate(`refund/${item.id}`)
              // navigate('/dashboard')
            }}
          />
        )}
      </Stack>
    </Stack>
  )
}
