export function TicketObj(data: any) {
  return {
    ticket_type_id: data.ticketType,
    subject: data.subject,
    description: data.description,
    file: data.file,
  }
}
export function MassageObj(data: any) {
  return {
    message: data.message,
    file: data.file,
    ticket_user_id: data.id,
  }
}
