import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import {RootState} from '../../store'
import {Stack} from '@mui/material'
import {useTranslation} from 'react-i18next'
import Arrow from '../../svg/Arrow'

export default function CustomSelect({
  name,
  values,
  option,
  disabled,
  value,
  setValues,
  border,
  defaultValue,
  color,
  width,
  isError,
  Module,
}: any) {
  const store = useSelector((state: RootState) => state.vender)
  const {t} = useTranslation()
  const handleChange = (event: any) => {
    setValues({...values, [`${name}`]: +event.target.value ? +event.target.value : 0})
    // values[`${name}`] = +event.target.value
  }
  const [isOpenEye, setIsOpenEye] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleBlur = () => {
    setIsFocused(false)
  }

  return (
    <Stack position={'relative'}>
      <Stack
        sx={{
          right: store.isRtl === 0 ? '14px' : 'auto',
          left: store.isRtl !== 0 ? '14px' : 'auto',
          top: '11px',
        }}
        position={'absolute'}
      >
        <Arrow />
      </Stack>
      <select
        className={` form-select inputTypeContainer ${isFocused ? 'focused' : ''}`}
        disabled={disabled}
        style={{
          border: isError ? '1px solid rgb(239, 71, 111)' : border ? border : '1px solid #dbdee0',
          // borderRadius: '6.18px',
          padding: '7px 12px',
          color: color ? color : 'auto',
          width: width || '100% ',
          height: '40px',
          backgroundPosition: store.isRtl === 0 ? 'right 1rem center' : 'left 1rem center',
          background: 'rgb(246 247 249)',
        }}
        value={(values && values[name]) || 0}
        onChange={handleChange}
      >
        <option
          style={{color: color ? color : 'auto', textTransform: 'capitalize'}}
          value={0}
          disabled
          hidden
        >
          {defaultValue || t('select')}
        </option>
        {option.map((e: any) => {
          const item = Module(e)
          return (
            <option
              style={{color: color ? color : 'auto', textTransform: 'capitalize'}}
              key={item.id}
              value={item.id}
            >
              {t(item.name)}
            </option>
          )
        })}
      </select>
    </Stack>
  )
}
