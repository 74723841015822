import {Radio, Stack} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import Title from '../../../../components/texts/Title'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../store'

export default function WithDrawMethod({item, isActive, setIsActive}: any) {
  const store = useSelector((state: RootState) => state.vender)
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsActive(item.name)
  }
  return (
    <Stack
      onClick={() => setIsActive(item.name)}
      direction={'row'}
      justifyContent={'space-between'}
      alignItems={'center'}
      sx={{bgcolor: '#ecf2fc', borderRadius: '16px', padding: '12px', width: '100%'}}
    >
      <Stack alignItems={'center'} gap='24px' direction={'row'}>
        {item.icon}
        <Title fw='400' fs='16px' text={item.name} />
      </Stack>
      <Stack position={'relative'}>
        <Radio
          sx={{
            position: 'absolute',
            right: store.isRtl === 0 ? '-6px' : 'auto',
            left: store.isRtl !== 0 ? '-6px' : 'auto',
            bottom: '-020px',
          }}
          checked={item.name === isActive}
          onChange={handleChange}
          value={item.id}
          name='radio-buttons'
          inputProps={{'aria-label': 'A'}}
        />
      </Stack>
    </Stack>
  )
}
