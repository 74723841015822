import {Stack, Typography} from '@mui/material'
import React from 'react'
import {useSelector} from 'react-redux'
import {RootState} from '../../store'

export default function TabsLangWithFlag({setActiveLang, activeLang, languages}: any) {
  const store = useSelector((state: RootState) => state.vender)

  return (
    <Stack
      position={'absolute'}
      top='-22px'
      left={store.isRtl !== 0 ? '0' : 'auto'}
      right={store.isRtl === 0 ? '0' : 'auto'}
      gap='16px'
      direction={'row'} zIndex={5}
    >
      {languages.map((e: any, i: number) => (
        <Typography
          fontSize={10}
          fontWeight={500}
          onClick={() => {
            setActiveLang(e.code)
          }}
          key={i}
          sx={{
            textTransform: 'capitalize',
            color: activeLang === e.code ? '#D81923' : 'var(--bs-body-color)',
            borderBottom: activeLang === e.code ? '1px solid #D81923' : 'auto',
            cursor: 'pointer',
            paddingBottom: '7px',
          }}
        >
          {e.lang}
        </Typography>
      ))}
    </Stack>
  )
}
