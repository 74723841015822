import React from 'react'

export default function GetIp(
  method: any,
  schema: any,
  data: any,
  ModuleObj: any,
  setErrors: any,
  successFun?: any,
  setLoading?: any,
  path?: any
) {
  const fetchIpWithRetry = (retries: number = 3) => {
    setLoading(true)
    fetch('https://api64.ipify.org?format=json')
      .then((response) => response.json())
      .then((e: any) => {
        method(schema, {...data, ip: e.ip}, ModuleObj, setErrors, successFun, setLoading, path)
      })
      .catch((error) => {
        if (retries > 0) {
          fetchIpWithRetry(retries - 1)
        } else {
          setLoading(false)
        }
      })
  }

  fetchIpWithRetry()
}
