import clsx from 'clsx'
import {useLayout} from '../../core'
import {Footer} from './Footer'
import {Typography} from '@mui/material'

const FooterWrapper = () => {
  const {config} = useLayout()
  if (!config.app?.footer?.display) {
    return null
  }

  return (
    <div
      style={{
        marginTop: '50px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        borderRadius: '8px',
        // position: 'absolute',
        bottom: '20px',
        background: '#FFF',
      }}
      className='app-footer app-container container-fluid'
      id='kt_app_content_container'
      // id='kt_app_footer'
    >
      <Typography sx={{textAlign: 'center', fontSize: '12px', fontWeight: '400', color: '6F6F6F'}}>
        2024 &#9400; <span style={{color: '#0D6EFD'}}>KONIN Pay</span> All Rights Reserved
      </Typography>
      {/* {config.app.footer.containerClass ? (
        <div className={clsx('app-container', config.app.footer.containerClass)}>
          <Footer />
        </div>
      ) : (
        <Footer />
      )} */}
    </div>
  )
}

export {FooterWrapper}
