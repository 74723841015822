/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import {useEffect, useState} from 'react'

import {useLayout} from '../../core'
import {Navbar} from './Navbar'
import {Stack} from '@mui/material'
import Logo from '../../../../app/svg/Logo'
import MainItems from './MainItems'
import DrawerComponent from '../../DrowerSideBar'
import Business from '../../../../app/svg/Business'
import Payment from '../../../../app/svg/Payment'
import Order from '../../../../app/svg/Order'
import PaymentRefund from '../../../../app/svg/PaymentRefund'
import Request from '../../../../app/svg/Request'
import {useLocation} from 'react-router-dom'
import {checkIsActive} from '../../../helpers'
export function SubNavbar({activeMenu}: any) {
  const {config, classes} = useLayout()
  const {pathname} = useLocation()

  if (!config.app?.header?.display) {
    return null
  }

  const links = [
    // {
    //   name: 'myBusiness',
    //   to: '/myBusiness',
    //   icon: <Business color={checkIsActive(pathname, '/myBusiness') ? '#ffff' : ''} />,
    //   isSubMenu: true,
    // },
    {
      name: 'paymentLog',
      to: '/paymentLog',
      icon: <Payment color={checkIsActive(pathname, '/paymentLog') ? '#ffff' : ''} />,
      isSubMenu: true,
    },
    {
      name: 'latestOrder',
      to: '/latestOrder',
      icon: <Order color={checkIsActive(pathname, '/latestOrder') ? '#ffff' : ''} />,
      isSubMenu: true,
    },
    {
      name: 'paymentRefund',
      to: '/paymentRefund',
      icon: <PaymentRefund color={checkIsActive(pathname, '/paymentRefund') ? '#ffff' : ''} />,
      isSubMenu: true,
    },
    {
      name: 'requestRefund',
      to: '/requestRefund',
      icon: <Request color={checkIsActive(pathname, '/requestRefund') ? '#ffff' : ''} />,
      isSubMenu: true,
    },
  ]
  return (
    <Stack
      width={'100%'}
      display={{xs: 'none', lg: 'block'}}
      p={{xs: '30px 30px', lg: '24px 0 32px 0'}}
    >
      <Stack
        alignItems={'center'}
        direction={'row'}
        justifyContent={'space-between'}
        borderBottom={{md: '1px solid #E7E7E7'}}
        p={'0 8px 24px 8px'}
        width={'100%'}
      >
        <Stack width={'100%'} display={{xs: 'none', md: 'block'}}>
          <MainItems links={links} />
        </Stack>
      </Stack>
    </Stack>
  )
}
