import {Box, Stack} from '@mui/material'
import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {Data} from '@react-google-maps/api'
import GoBack from '../../parts/GoBack'
import Logo from '../../svg/Logo'
import Title from '../../components/texts/Title'
import Label from '../../components/texts/Label'
import LabelWithValue from '../dashboard/parts/LabelWithValue'
import MainBtn from '../../components/buttons/MainBtn'
import InputWithLabel from '../../parts/InputWithLabel'

export default function ConfirmForm({
  setOpen,
  isLoading,
  submit,
  data,
  setData,
  status,
  errors,
}: any) {
  return (
    <Stack p='24px'>
      <Stack alignItems={'center'} justifyContent={'space-between'} direction={'row'}>
        <GoBack onClick={() => setOpen(false)} text='goBack' />
        <Logo />
      </Stack>
      <Stack p='24px 0' borderBottom='1px dashed #9D97B1' gap='24px'>
        <Title text='paymentRefund' />
      </Stack>
      <Stack borderBottom='1px dashed #9D97B1' gap='24px' p='24px 0'>
        <LabelWithValue value={data.client} text='type' />
        <LabelWithValue value={data.code} text='invoiceId' />
        <LabelWithValue value={data.amountRefund} text='amountRefund' />
      </Stack>
      {status === 'accept' && (
        <Stack mt='40px'>
          <InputWithLabel
            fw='400'
            name='newAmount'
            placeHolder='enterYourAmount'
            type='number'
            setData={setData}
            data={data}
            width={{xs: '100%', md: '395px'}}
            errors={errors}
          />{' '}
        </Stack>
      )}

      <Stack width={'120px'} pt={'24px'} direction={'row'} justifyContent={'flex-start'}>
        <MainBtn
          text={status === 'accept' ? 'refund' : 'reject'}
          isLoading={isLoading}
          handelSubmit={() => {
            submit()
          }}
        />
      </Stack>
    </Stack>
  )
}
