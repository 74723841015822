import React from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {useTranslation} from 'react-i18next'
import {Stack} from '@mui/material'

export default function Search({search, handleSearch}: any) {
  const {t} = useTranslation()

  return (
    <div className='position-relative'>
      <Stack m='0 10px'>
        <KTSVG
          path='/media/icons/duotune/general/gen021.svg'
          className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50  translate-middle-y'
        />
      </Stack>
      <input
        value={search}
        onChange={handleSearch}
        style={{
          textIndent: '2rem',height:'100%'
        }}
        //    value={search}
        type='text'
        className='form-control'
        name='search'
        //    onChange={handleSearch}
        placeholder={t('search') || 'search'}
      />
    </div>
  )
}
