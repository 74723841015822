import {Stack} from '@mui/material'
import React from 'react'
import Ticket from './Ticket'
import {TiketObj} from '../../modules/modules/get/Tiket'
import EmptyTickets from './EmptyTickets'

export default function Tickets({tickets, isLoading}: any) {
  return (
    <Stack
      flexWrap={'wrap'}
      justifyContent={{xs: 'center', sm: 'flex-start'}}
      direction={'row'}
      p='24px'
      gap='24px'
    >
      {!isLoading && tickets.length === 0 ? (
        <EmptyTickets />
      ) : (
        tickets.map((e: any) => e && <Ticket item={TiketObj(e)} />)
      )}
    </Stack>
  )
}
