import {Stack, Typography} from '@mui/material'
import React from 'react'
import GoBackArrow from '../svg/GoBackArrow'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {RootState} from '../store'

export default function GoBack({text, onClick}: any) {
  const {t} = useTranslation()
  const store = useSelector((state: RootState) => state.vender)

  return (
    <Stack
      sx={{cursor: 'pointer'}}
      gap='4px'
      alignItems={'center'}
      direction={'row'}
      onClick={onClick}
      maxWidth={'117px'}
    >
      <Stack
        sx={{
          transform: store.isRtl !== 0 ? 'rotate(180deg)' : '',
        }}
      >
        <GoBackArrow />
      </Stack>
      <Typography
        sx={{
          color: '#467DE1',
          fontSize: '14px',
          fontWeight: '600',
          lineHeight: '18px',
          // textTransform: 'capitalize',
        }}
      >
        {t(text)}
      </Typography>
    </Stack>
  )
}
