import React, {useState, useEffect} from 'react'
import ReactApexChart from 'react-apexcharts'
import {ApexOptions} from 'apexcharts'
import {Stack} from '@mui/material'
import {useSelector} from 'react-redux'
import {RootState} from '../../store'
import Title from '../texts/Title'
import ChartSelect from '../selects/ChartSelect'

const Appearance = ({categories, series, setDate, date}: any) => {
  const [maxYValue, setMaxYValue] = useState<number | undefined>(undefined)
  const store = useSelector((state: RootState) => state.vender)

  useEffect(() => {
    if (series && series.length > 0) {
      const maxValues = series.map((s: any) => Math.max(...s.data))
      const maxValue = Math.max(...maxValues)
      setMaxYValue(maxValue)
    }
  }, [series])

  const [options] = useState<ApexOptions>(() => {
    const defaultOptions: ApexOptions = {
      chart: {
        height: 350,
        type: 'area',
      },
      colors: ['#F94144', '#37BD6B'],
      legend: {
        position: 'top', // Set the legend position to 'top'
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        type: 'category',
        categories: categories,
      },
      tooltip: {
        x: {
          formatter: (value) => value.toString(),
        },
      },
      yaxis: {
        max: maxYValue, // Set the maximum value dynamically
        labels: {
          formatter: (value: number) => value.toFixed(0),
        },
      },
      annotations: {
        // position: 'front', // Ensure annotations are rendered in front of series
        yaxis: [
          {
            y: 10, // Adjust the y-coordinate to position the annotation vertically
            y2: 30, // Adjust the y2-coordinate to control the height of the annotation
            borderColor: 'transparent',
            label: {
              borderColor: 'transparent',
              text: series[0]?.name,
              style: {
                background: '#F94144', // Background color of the annotation
                color: '#fff', // Text color of the annotation
              },
            },
          },
          {
            y: 40, // Adjust the y-coordinate to position the annotation vertically
            y2: 60, // Adjust the y2-coordinate to control the height of the annotation
            borderColor: 'transparent',
            label: {
              borderColor: 'transparent',
              text: series[1]?.name,
              style: {
                background: '#37BD6B', // Background color of the annotation
                color: '#fff', // Text color of the annotation
              },
            },
          },
        ],
      },
    }

    return defaultOptions
  })

  return (
    <Stack position={'relative'}>
      <Stack
        zIndex={15}
        bgcolor={'#fff'}
        // width={'200px'}
        width={'100%'}
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        flexWrap={'wrap'}
        height={'28px'}
        position={'absolute'}
        sx={{right: store.isRtl !== 0 ? '0' : 'auto', left: store.isRtl === 0 ? '0' : 'auto'}}
      >
        <Title fs='20px' text='balanceOverview' />
        <ChartSelect date={date} setDate={setDate} option={['yearly', 'weekly', 'monthly']} />
      </Stack>
      <Stack
        width={{xs: 'calc(100% + 16px)', lg: 'calc(100% + 24px)'}}
        sx={{
          right: store.isRtl === 0 ? '0' : 'auto',
          left: store.isRtl !== 0 ? '0' : 'auto',
        }}
        position={'absolute'}
      >
        <div dir='ltr' id='chart'>
          <ReactApexChart options={options} series={series} type='area' height={350} />
        </div>
        <div id='html-dist'></div>
      </Stack>
    </Stack>
  )
}

export default Appearance
