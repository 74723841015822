import React from 'react'
import {useSelector} from 'react-redux'
import {RootState} from '../store'

export default function K({style}: any) {
  const store = useSelector((state: RootState) => state.vender)

  return (
    <svg viewBox='0 0 904 866' style={style} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M407.899 1371.31L111.758 1254.4C161.898 1127.39 159.557 985.671 105.25 860.387C50.9439 735.103 -50.8845 636.508 -177.855 586.27L-60.9474 290.13C66.1257 340.294 207.922 337.925 333.248 283.542C458.574 229.16 557.164 127.219 607.328 0.14567L903.469 117.053C855.239 239.464 778.676 348.697 680.061 435.792C581.445 522.887 463.587 585.362 336.153 618.092C406.772 729.043 450.093 855.149 462.564 986.075C475.035 1117 456.301 1249.02 407.899 1371.31Z'
        fill='url(#paint0_linear_1_30821)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_1_30821'
          x1='315.02'
          y1='383.235'
          x2='210.181'
          y2='743.85'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#467DE1' />
          <stop offset='1' stop-color='#45D4FF' />
        </linearGradient>
      </defs>
    </svg>
  )
}
