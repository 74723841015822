import React from 'react'
import {ActionsCell} from './Actions'

export default function Row() {
  return (
    <tr className='fw-bold text-muted '>
      <td>
        <a className='text-dark fw-bold text-hover-primary fs-6'>{1}</a>
      </td>
      <td>2</td>
      <td>3</td>
      <td style={{textAlign: 'start'}}>4 </td>

      <td style={{textAlign: 'start'}} className='text-start'>
        <ActionsCell />
      </td>
    </tr>
  )
}
