import {Box, Stack} from '@mui/material'
import React from 'react'
import TextLink from '../../../../components/texts/TextLink'
import Logo from '../../../../svg/Logo'
import Title from '../../../../components/texts/Title'
import Label from '../../../../components/texts/Label'
import LabelWithValue from '../balanceDeposit/LabelWithValue'
import MainBtn from '../../../../components/buttons/MainBtn'
import {useNavigate} from 'react-router-dom'
import GoBack from '../../../../parts/GoBack'
import Status from '../../../../components/texts/Status'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../store'

export default function ConfirmForm({setOpen, setIsActive, setOpenSuccess, item}: any) {
  const navigate = useNavigate()
  const store = useSelector((state: RootState) => state.vender)

  return (
    <Stack p='24px'>
      <Stack alignItems={'center'} justifyContent={'space-between'} direction={'row'}>
        <GoBack onClick={() => setOpen(false)} text='goBack' />
        {/* <Logo /> */}
      </Stack>
      <Stack p='24px 0' borderBottom='1px dashed #9D97B1' gap='24px'>
        <Title text='depositDetails' />
        {store.user && store.user.type === 'Vendor' && (
          <Stack alignItems={'center'} direction={'row'} gap='5px'>
            <Label text='status' /> :
            <Status text={item.status} />
          </Stack>
        )}
      </Stack>
      <Stack borderBottom='1px dashed #9D97B1' gap='24px' p='24px 0'>
        <LabelWithValue isAmount value={item.balance} label='balance' />
        {store.user && store.user.type === 'Vendor' && (
          <LabelWithValue value={item.depositId} label='invoiceId' />
        )}
        <LabelWithValue value={item.depositMethod} label='depositWith' />
        <LabelWithValue isAmount value={item.amount} label='amount' />
        <LabelWithValue isAmount value={item.fee} label='fee' />
      </Stack>

      <Stack
        direction={'row'}
        justifyContent={'flex-end'}
        alignItems={'center'}
        // borderBottom='1px dashed #9D97B1'
        gap='5px'
        p='24px 0'
      >
        <Label text='total' />
        <Label text=':' />
        <Title isAmount fs='18px' text={item.reciveAmount} />
      </Stack>
    </Stack>
  )
}
