import React from 'react'

export default function PartK({style}: any) {
  return (
    <svg style={style} viewBox='0 0 641 587' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M88.2747 1031.4L0.271547 725.427C131.499 687.685 242.372 599.382 308.522 479.928C374.672 360.473 390.686 219.641 353.045 88.3846L659.022 0.381513C696.784 131.675 785.155 242.591 904.696 308.728C1024.24 374.865 1165.15 390.806 1296.45 353.044L1384.45 659.021C1258.03 695.473 1124.77 701.435 995.602 676.417C866.433 651.4 745.035 596.116 641.367 515.101C596.525 638.739 523.022 749.99 426.882 839.735C330.742 929.481 214.702 995.165 88.2747 1031.4Z'
        fill='url(#paint0_linear_1_29021)'
        fill-opacity='0.64'
      />
      <defs>
        <linearGradient
          id='paint0_linear_1_29021'
          x1='814.58'
          y1='355.095'
          x2='464.873'
          y2='491.99'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#467DE1' />
          <stop offset='1' stop-color='#45D4FF' />
        </linearGradient>
      </defs>
    </svg>
  )
}
