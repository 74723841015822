import React from 'react'

export default function Wallet() {
  return (
    <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='20' cy='20' r='20' fill='white' />
      <path
        d='M15 23L15 17'
        stroke='#2E81FF'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M28.8333 17H26.2308C24.4465 17 23 18.3431 23 20C23 21.6569 24.4465 23 26.2308 23H28.8333C28.9167 23 28.9583 23 28.9935 22.9979C29.5328 22.965 29.9623 22.5662 29.9977 22.0654C30 22.0327 30 21.994 30 21.9167V18.0833C30 18.006 30 17.9673 29.9977 17.9346C29.9623 17.4338 29.5328 17.035 28.9935 17.0021C28.9583 17 28.9167 17 28.8333 17Z'
        stroke='#2E81FF'
        stroke-width='1.5'
      />
      <circle cx='26' cy='20' r='1' fill='#2E81FF' />
      <path
        d='M28.965 17C28.8873 15.1277 28.6366 13.9797 27.8284 13.1716C26.6569 12 24.7712 12 21 12L18 12C14.2288 12 12.3431 12 11.1716 13.1716C10 14.3431 10 16.2288 10 20C10 23.7712 10 25.6569 11.1716 26.8284C12.3431 28 14.2288 28 18 28H21C24.7712 28 26.6569 28 27.8284 26.8284C28.6366 26.0203 28.8873 24.8723 28.965 23'
        stroke='#2E81FF'
        stroke-width='1.5'
      />
    </svg>
  )
}
