import {Button, IconButton, Stack, Typography} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import AttachFileIcon from '@mui/icons-material/AttachFile'

const UploadFileMsg = ({selectedFile, setSelectedFile}: any) => {
  const {t} = useTranslation()

  const handleFileChange = (event: any) => {
    const file = event.target.files[0]
    setSelectedFile(file)
  }

  return (
    <IconButton
      sx={{background: '#ff000000', width: '24px', height: '24px', transform: 'rotate(45deg)'}}
      component='label'
    >
      <AttachFileIcon
        sx={{color: !selectedFile ? 'var(--bs-body-color)' : 'red', fontSize: '24px'}}
      />
      <input hidden type='file' name='file' onChange={handleFileChange} />
    </IconButton>
  )
}

export default UploadFileMsg
