import {Stack, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import Label from '../../../components/texts/Label'
import Down from '../../../svg/Down'
import Top from '../../../svg/Top'

export default function SmallCard({num, isIncome}: any) {
  return (
    <Stack
      bgcolor={isIncome ? '#EBF8F0' : '#FFECE6'}
      width={{xs: '100%', sm: '200px'}}
      height={'138px'}
      borderRadius={'8px'}
      p='20px 24px'
      direction={'row'}
      justifyContent={'space-between'}
      alignItems={'center'}
    >
      <Stack gap='12px' justifyContent={'space-between'}>
        <Label isAmount fs='24px' fw='700' color={isIncome ? '#37BD6B' : '#FF1A1A'} text={num} />
        <Label text={isIncome ? 'income' : 'expanse'} />
      </Stack>
      {isIncome ? <Down /> : <Top />}
    </Stack>
  )
}
