import {Suspense, useState} from 'react'
import {Outlet, useLocation, useNavigate} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import ScrollToTop from './modules/customTable/scrollTop'
import {useEffect} from 'react'
import './App.css'
import {useDispatch} from 'react-redux'
import {actions} from './store/admin/auth.slice'
import {useSelector} from 'react-redux'
import {RootState} from './store'
import {Stack} from '@mui/material'
import Login from './modules/login'
import JwtService from './utils/TokenServices'
import Loading from './modules/customTable/LoadingScreen'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {useTranslation} from 'react-i18next'
import PersonalInformation from './pages/personalInformation/PersonalInformation'

import Get from './fubcations/mastars/Get'
const App = () => {
  const location = useLocation()
  const [languages, setlanguages] = useState([])

  Get([], setlanguages, 'general/languages')
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(actions.setLangs(languages))
  }, [languages])

  const store = useSelector((state: RootState) => state.vender)
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const {i18n} = useTranslation()

  useEffect(() => {
    if (!store.user) {
      JwtService.destroyToken()
      i18n.changeLanguage(localStorage.getItem('myLang') || 'en')
    } else {
      i18n.changeLanguage(store.user.language_code)
    }
  }, [store.user])
  useEffect(() => {
    if (
      location.pathname.includes('/catalog/sub-category') &&
      location.pathname.includes('/edit-category')
    ) {
      navigate(`/catalog/sub-category/${location.pathname.split('/')[3]}`)
    } else if (location.pathname.includes('/add-category/add-sub-category')) {
      navigate(`/catalog/add-sub-category`)
    }
  }, [location])

  return (
    <>
      <ToastContainer />
      <Stack
        overflow={isLoading ? 'hidden' : 'revert'}
        position={'relative'}
        dir={store.isRtl === 0 ? 'ltr' : 'rtl'}
      >
        {isLoading && (
          <Stack width={'100vw'} zIndex={'200'} height={'100vh'} top='0' position={'absolute'}>
            <Loading />
          </Stack>
        )}
        <Suspense fallback={<LayoutSplashScreen />}>
          <I18nProvider>
            <LayoutProvider>
              {store.user && !store.user.is2F && !store.user.isConfirmProfile ? (
                <PersonalInformation />
              ) : store.user && !store.user.is2F ? (
                <>
                  <Outlet />
                </>
              ) : (
                <Login />
              )}
              <MasterInit />
              <ScrollToTop />
            </LayoutProvider>
          </I18nProvider>
        </Suspense>
      </Stack>
    </>
  )
}

export {App}
