import {useEffect, useState} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import {reInitMenu} from '../helpers'
import {
  InviteUsers,
  ThemeModeProvider,
  // UpgradePlan
} from '../partials'
import {Content} from './components/content'
import {FooterWrapper} from './components/footer'
import {HeaderWrapper} from './components/header'
import {ScrollTop} from './components/scroll-top'
import {Sidebar} from './components/sidebar'
import {ToolbarWrapper} from './components/toolbar'
import {PageDataProvider} from './core'
import {Box, Stack} from '@mui/material'
import {SidebarLogo} from './components/sidebar/SidebarLogo'
import {SidebarMenu} from './components/sidebar/sidebar-menu/SidebarMenu'
import {useSelector} from 'react-redux'
import {RootState} from '../../app/store'
import SidBarItems from './SidBarItems'
import DrawerSideMenu from './DrowerSideBar'
import {SubNavbar} from './components/header/SubNavbar'
import NoNetWork from '../../app/parts/NoNetWork'

const MasterLayout = () => {
  const location = useLocation()
  useEffect(() => {
    reInitMenu()
  }, [location.key])
  const screenWidth = window.innerWidth
  const store = useSelector((state: RootState) => state.vender)
  const [activeMenu, setActiveMenu] = useState(true)

  return (
    //     <RequireVendOrAuth>
    <PageDataProvider>
      <ThemeModeProvider>
        <Stack
          sx={{minHeight: '100vh'}}
          className='d-flex flex-column flex-root app-root'
          id='kt_app_root'
          alignItems={'center'}
        >
          <Stack width='100%' maxWidth={'1170px'}>
            <Stack width='100%' className='app-page flex-column flex-column-fluid' id='kt_app_page'>
              <HeaderWrapper activeMenu={activeMenu} />
              {store.user && store.user.type === 'Vendor' && <SubNavbar activeMenu={activeMenu} />}
              <Stack sx={{display: screenWidth >= 992 ? 'block' : 'none'}}></Stack>
              <Stack
                maxWidth={'1170px'}
                m={{
                  xs: '24px 10px',
                  lg: store.user && store.user.type === 'Vendor' ? '0 0 24px 0' : '32px 0 24px 0',
                }}
                minHeight={'75vh'}
              >
                {store.errNetWork && <NoNetWork />}
                <Stack
                  sx={{
                    opacity: store.errNetWork ? 0 : 1,
                    position: store.errNetWork ? 'relative' : 'static',
                    top: 0,
                  }}
                >
                  <Outlet />
                </Stack>
              </Stack>
              <FooterWrapper />
            </Stack>
          </Stack>
        </Stack>

        {/* begin:: Drawers */}
        {/* <ActivityDrawer />
        <RightToolbar />
        <DrawerMessenger /> */}
        {/* end:: Drawers */}

        {/* begin:: Modals */}
        <InviteUsers />
        {/* <UpgradePlan /> */}
        {/* end:: Modals */}
        <ScrollTop />
      </ThemeModeProvider>
    </PageDataProvider>
    //     </RequireVendOrAuth>
  )
}

export {MasterLayout}
