import {CircularProgress, Stack} from '@mui/material'
import React, {useEffect, useRef, useState} from 'react'
import Label from '../../../components/texts/Label'
import Massages from './Massages'
import ActionBtns from './ActionBtns'
import ReactDOM from 'react-dom'
import Post from '../../../fubcations/mastars/Post'
import {NotifySchema} from '../../../yup/SettingSchema'
import {MassageObj} from '../../../modules/modules/post/TicketsModule'
import {useParams} from 'react-router-dom'
import Loading from '../../../modules/customTable/LoadingForm'

export default function Chat({data, setData, id, isLoadingMassages}: any) {
  const [msgContant, setMsgContant] = useState({message: ''})
  const [selectedFile, setSelectedFile] = useState(null)
  const [errors, setErrors] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const messagesRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    scrollToBottom()
  }, [data])

  const scrollToBottom = () => {
    const messagesContainer = ReactDOM.findDOMNode(messagesRef.current) as HTMLDivElement
    if (messagesContainer) {
      messagesContainer.scrollTop = messagesContainer.scrollHeight
    }
  }
  const send = async (e: any) => {
    e.preventDefault()
    if (!msgContant.message && !selectedFile) {
      return
    }
    Post(
      NotifySchema,
      {...msgContant, file: selectedFile, id: id},
      MassageObj,
      setErrors,
      (e: any) => {
        scrollToBottom()
        setMsgContant({message: ''})
        setSelectedFile(null)
        setData([...data, e.data.data])
      },
      setIsLoading,
      'tickets/send-message'
    )
  }
  return (
    <Stack gap='34px'>
      {/* <Label color='#858A8C' text='kakashi ticket' /> */}
      <Stack justifyContent={'space-between'} position={'relative'}>
        <Stack
          position={'relative'}
          height={'400px'}
          sx={{overflowY: 'scroll'}}
          gap='24px'
          ref={messagesRef}
        >
          {' '}
          {isLoadingMassages ? <Loading /> : <Massages data={data} />}
        </Stack>

        <ActionBtns
          isLoading={isLoading}
          selectedFile={selectedFile}
          msgContant={msgContant}
          setMsgs={setData}
          msgs={data}
          send={send}
          setMsgContant={setMsgContant}
          setSelectedFile={setSelectedFile}
        />
      </Stack>{' '}
    </Stack>
  )
}
