import {Stack} from '@mui/material'
import React, {useState} from 'react'
import Nike from '../../../../svg/Nike'
import Title from '../../../../components/texts/Title'
import Label from '../../../../components/texts/Label'
import LabelWithValue from '../../../dashboard/pages/transferToUser/LabelWithValue'
import MainBtn from '../../../../components/buttons/MainBtn'
import {useNavigate} from 'react-router-dom'
import SecondaryBtn from '../../../../components/buttons/SacandaryBtn'
import Nile from '../../../../svg/Nile'
import Delete from '../../../../fubcations/mastars/Delete'

export default function DeleteMsg({item, setOpen, data, setData}: any) {
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  return (
    <Stack gap='24px' alignItems={'center'} justifyContent={'center'} p='60px 40px'>
      <Nile />
      <Label
        color='#2C2B2B'
        lineHeight='24px'
        align='center'
        text='deleteMsgNote'
        fw='400'
        fs='20px'
      />

      <Stack justifyContent={'center'} width={'90%'} direction={'row'} gap='40px'>
        <MainBtn
          isLoading={isLoading}
          text='remove'
          width={isLoading ? '120px' : '87px'}
          p='12px 16px'
          handelSubmit={() => {
            Delete(
              {type: item.type},
              () => {
                setData(data.filter((e: any) => e.id !== item.id))
                setOpen(false)
              },
              setIsLoading,
              `vendor/withdraw/saved/${item.id}/delete`
            )
          }}
        />
        {!isLoading && (
          <SecondaryBtn
            text='cancel'
            width='87px'
            p='12px 16px'
            handelSubmit={() => {
              setOpen(false)
            }}
          />
        )}
      </Stack>
    </Stack>
  )
}
