import React from 'react'

export default function TopChart() {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='24' height='24' rx='4' fill='#E6FBF5' />
      <path
        d='M8.66602 11.3333L11.9993 8M11.9993 8L15.3327 11.3333M11.9993 8V16'
        stroke='#35D391'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}
