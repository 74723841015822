import React from 'react'

export default function TransferToUser() {
  return (
    <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='16' cy='16' r='16' fill='#FF9637' fill-opacity='0.1' />
      <g clip-path='url(#clip0_1_29801)'>
        <path
          d='M16.0002 11.2207C13.3645 11.2207 11.2202 13.365 11.2202 16.0007C11.2202 18.6364 13.3645 20.7807 16.0002 20.7807C18.6359 20.7807 20.7802 18.6364 20.7802 16.0007C20.7802 13.365 18.6359 11.2207 16.0002 11.2207ZM16.0002 19.6557C13.9849 19.6557 12.3452 18.0161 12.3452 16.0007C12.3452 13.9854 13.9849 12.3457 16.0002 12.3457C18.0156 12.3457 19.6552 13.9854 19.6552 16.0007C19.6552 18.0161 18.0156 19.6557 16.0002 19.6557Z'
          fill='#FF9637'
        />
        <path
          d='M16.1512 7.6073L16.3979 7.36062C16.6176 7.14098 16.6176 6.78481 16.3979 6.56513C16.1783 6.34546 15.8221 6.3455 15.6024 6.56513L14.3974 7.77016C14.292 7.87565 14.2327 8.01871 14.2327 8.16788C14.2327 8.31706 14.2919 8.46012 14.3974 8.56561L15.6025 9.77063C15.7123 9.88047 15.8563 9.93537 16.0002 9.93537C16.1441 9.93537 16.2881 9.88047 16.3979 9.7706C16.6176 9.55092 16.6176 9.19478 16.3979 8.97511L16.1551 8.73233C20.0925 8.81513 23.2702 12.0435 23.2702 16.0004C23.2702 16.311 23.522 16.5629 23.8327 16.5629C24.1433 16.5629 24.3952 16.311 24.3952 16.0004C24.3952 11.4219 20.7108 7.68826 16.1512 7.6073Z'
          fill='#FF9637'
        />
        <path
          d='M16.3014 15.4373H15.711C15.5162 15.4373 15.3577 15.2783 15.3577 15.0822C15.3577 14.9017 15.504 14.7548 15.6838 14.7548H16.6027C16.9133 14.7548 17.1652 14.503 17.1652 14.1923C17.1652 13.8817 16.9133 13.6298 16.6027 13.6298H16.5627V13.5898C16.5627 13.2792 16.3108 13.0273 16.0002 13.0273C15.6895 13.0273 15.4377 13.2792 15.4377 13.5898V13.6512C14.7544 13.7686 14.2327 14.3655 14.2327 15.0828C14.2327 15.8986 14.8959 16.5624 15.711 16.5624H16.3014C16.4896 16.5624 16.6427 16.7155 16.6427 16.9036C16.6427 17.0917 16.4896 17.2449 16.3014 17.2449H15.3977C15.087 17.2449 14.8352 17.4967 14.8352 17.8074C14.8352 18.118 15.087 18.3699 15.3977 18.3699H15.4377V18.4098C15.4377 18.7205 15.6895 18.9723 16.0002 18.9723C16.3108 18.9723 16.5627 18.7205 16.5627 18.4098V18.3461C17.2469 18.2224 17.7677 17.6229 17.7677 16.9036C17.7677 16.0951 17.11 15.4373 16.3014 15.4373Z'
          fill='#FF9637'
        />
        <path
          d='M16.3979 22.2297C16.1783 22.0101 15.8221 22.0101 15.6024 22.2297C15.3828 22.4494 15.3828 22.8055 15.6024 23.0252L15.8453 23.268C11.9078 23.1852 8.73022 19.9569 8.73022 16C8.73022 15.6894 8.47837 15.4375 8.16772 15.4375C7.85707 15.4375 7.60522 15.6894 7.60522 16C7.60522 20.5785 11.2896 24.3121 15.8491 24.3931L15.6025 24.6398C15.3828 24.8594 15.3828 25.2156 15.6025 25.4353C15.7123 25.5451 15.8562 25.6 16.0002 25.6C16.1442 25.6 16.2881 25.5451 16.3979 25.4353L17.6029 24.2302C17.7084 24.1248 17.7677 23.9817 17.7677 23.8325C17.7677 23.6833 17.7085 23.5403 17.6029 23.4348L16.3979 22.2297Z'
          fill='#FF9637'
        />
      </g>
      <defs>
        <clipPath id='clip0_1_29801'>
          <rect width='19.2' height='19.2' fill='white' transform='translate(6.40015 6.40039)' />
        </clipPath>
      </defs>
    </svg>
  )
}
