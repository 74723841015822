import * as React from 'react'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker'
import {useState} from 'react'
import dayjs, {Dayjs} from 'dayjs'
import {Stack} from '@mui/material'
import Calender from '../../svg/Calender'
import {useSelector} from 'react-redux'
import {RootState} from '../../store'

export default function InputDateMui({disabled, isError, data, setData, name}: any) {
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null)

  const handleDateChange = (date: Dayjs | null) => {
    if (date) {
      const formattedDate = date.format('DD/MM/YYYY')
      setData({...data, [name]: formattedDate})
      setSelectedDate(date)
    } else {
      setData({...data, [name]: null})
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack position={'relative'}>
        <DesktopDatePicker
          disabled={disabled}
          className={`custom-datepicker ${disabled ? 'disabled' : ''}`}
          value={data && data[name] ? dayjs(data[name], 'DD/MM/YYYY') : null}
          onChange={handleDateChange}
          format='DD/MM/YYYY'
          sx={{
            '& input': {
              padding: '10.70px 16px',
            },
            '& fieldset': {
              display: 'none',
            },

            border: isError ? '1px solid rgb(239, 71, 111)' : '1px solid var(--secondary-color)',
            borderRadius: '4px',
            '&:focus-within': {
              borderColor: 'var(--link-color)',
            },
            '.css-i4bv87-MuiSvgIcon-root': {
              opacity: '0 !important',
            },
            '.css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root': {
              // opacity: '0 !important',
              bgcolor: '#ff000000',
              position: 'relative',
              zIndex: '2',
            },
          }}
        />
      </Stack>
    </LocalizationProvider>
  )
}
