export default function BusinessAccountDetailsForUserObj(data: any) {
  return {
    avarage: +data.average_earning.split(' ')[0],
    type: data.business_type_id,
    businessName: data.business_name,
    businessManager: data.manger_name,
    nameOfBusinessManager: data.manger_name,
    domainName: data.domain_web,
    content: data.business_about,
    imageOne: data.commercial_record,
    imageTwo: data.passport_or_accommodation,
    file: data.id_card_face_one,
    isCitizen: data.user_type === 'citizen' ? 1 : 0,
    status: data.status,
    noteReject: data.note_reject,
    imageThree: data.id_card_face_two,
    imageFour: data.id_card_face_one,
  }
}
