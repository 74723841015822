import React from 'react'

export default function CardBtn({
  text,
  submit,
  disabled,
  width,
}: {
  text: string
  submit: any
  disabled: boolean
  width: string
}) {
  return (
    <button
      style={{
        width: width,
        height: '40px',
        color: 'white',
        borderRadius: '8px',
        border: 'none',
        background: disabled ? '#979797' : '#D71B22',
        cursor: disabled ? 'no-drop' : 'pointer',
      }}
      onClick={submit}
    >
      {text}
    </button>
  )
}
