import React from 'react'

export default function PurchasesIcon({color}: any) {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3.55466 14.2572C2.83619 10.9043 2.47696 9.22793 3.37751 8.11397C4.27806 7 5.99253 7 9.42147 7H14.5776C18.0066 7 19.721 7 20.6216 8.11397C21.5221 9.22793 21.1629 10.9043 20.4444 14.2572L20.0159 16.2572C19.5289 18.5297 19.2854 19.666 18.4603 20.333C17.6353 21 16.4732 21 14.1491 21H9.85004C7.5259 21 6.36383 21 5.53876 20.333C4.71369 19.666 4.4702 18.5297 3.98323 16.2572L3.55466 14.2572Z'
        stroke={color || '#6D6D6D'}
        stroke-width='1.5'
      />
      <path
        d='M8 12H16'
        stroke={color || '#6D6D6D'}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M10 15H14'
        stroke={color || '#6D6D6D'}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M18 9L15 3'
        stroke={color || '#6D6D6D'}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M6 9L9 3'
        stroke={color || '#6D6D6D'}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}
