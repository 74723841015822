import {Box, Stack} from '@mui/material'
import React, {useState} from 'react'
import Bank from '../../../svg/Bank'
import WithDrawItems from '../../wallet/pages/withdraw/WithDrawItems'
import WithDrawMethod from '../../wallet/pages/withdraw/WithDrawMethod'
import Wallet from '../../../svg/Wallet'
import ExchangeOfficeIcon from '../../../svg/ExchangeOfficeIcon'
import MainBtn from '../../../components/buttons/MainBtn'
import {useNavigate} from 'react-router-dom'
import SecondaryBtn from '../../../components/buttons/SacandaryBtn'
import Get from '../../../fubcations/mastars/Get'
import Loading from '../../../modules/customTable/LoadingForm'

export default function SavedWithdrawMethods() {
  const [isMethod, setIsMethod] = useState(1)
  const [isAdd, setIsAdd] = useState(false)
  const [isActiveMethod, setIsActiveMethod] = useState('bank')
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [MethodItems, setMethodItems] = useState([])
  Get([], setMethodItems, 'vendor/withdraw/saved', setIsLoading)

  const methods = [
    {
      icon: <Bank />,
      name: 'bank',
      id: 1,
    },
    {
      icon: <Wallet />,
      name: 'EWallet',
      id: 2,
    },
    {
      icon: <ExchangeOfficeIcon />,
      name: 'exchangeOffice',
      id: 3,
    },
  ]
  return (
    <Stack minHeight={'100%'} position={'relative'}>
      {isLoading ? (
        <Loading />
      ) : (
        <Stack>
          {' '}
          {!isAdd && MethodItems.length !== 0 && (
            <WithDrawItems
              isMethod={isMethod}
              setIsMethod={setIsMethod}
              isSitting
              data={MethodItems}
              setIsAdd={setIsAdd}
            />
          )}
          <Stack gap='20px'>
            {(isAdd || MethodItems.length === 0) && (
              <>
                {' '}
                {methods.map((e: any) => (
                  <WithDrawMethod
                    setIsActive={setIsActiveMethod}
                    isActive={isActiveMethod}
                    item={e}
                  />
                ))}
                <Box display={'flex'}>
                  <SecondaryBtn
                    width='170px'
                    isLink
                    text='cancel'
                    handelSubmit={() => {
                      setIsAdd(false)
                    }}
                  />
                  <MainBtn
                    width='auto'
                    text='continue'
                    handelSubmit={() => {
                      navigate(`methods/${isActiveMethod}`)
                    }}
                  />
                </Box>
              </>
            )}
          </Stack>
        </Stack>
      )}
    </Stack>
  )
}
