import React, {useEffect, useState} from 'react'
import {Stack, Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'
import TitlePage from '../../modules/customTable/TitlePage'
import MainBtn from '../../components/buttons/MainBtn'
import CustomSelect from '../../components/selects/CustomSelect'
import Tickets from './Tickets'
import {useNavigate} from 'react-router-dom'
import CityModule from '../../modules/modules/get/CityModule'
import Get from '../../fubcations/mastars/Get'
import Loading from '../../modules/customTable/LoadingForm'

export default function Ticket() {
  const {t} = useTranslation()
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState({id: 1})
  const bannerInfo = {
    title: t('tickets'),
    home: t('home'),
    linkHome: '/',
  }
  const [tickets, setTickets] = useState([])
  const [filter, setFilter] = useState([])
  Get([], setTickets, 'tickets', setIsLoading)
  const navigate = useNavigate()

  return (
    <Stack position={'relative'} minHeight={'400px'} width={'100%'}>
      {isLoading ? (
        <Loading />
      ) : (
        <Stack>
          <Stack
            flexWrap={{xs: 'wrap', sm: 'nowrap'}}
            justifyContent={'space-between'}
            direction={'row'}
          >
            <TitlePage info={bannerInfo} />
            <Stack direction={'row'} gap='24px'>
              <CustomSelect
                border='1px solid #467de1 '
                color={'#467de1'}
                values={data}
                width={'130px'}
                option={[
                  {id: 1, name: 'all'},
                  {id: 2, name: 'pending'},
                  {id: 3, name: 'rejected'},
                  {id: 4, name: 'completed'},
                ]}
                name={'id'}
                setValues={setData}
                value={{id: data.id}}
                Module={CityModule}

                // width='120px'
              />
              <MainBtn width='auto' handelSubmit={() => navigate('addTicket')} text='addTicket' />
            </Stack>
          </Stack>
          <Tickets
            isLoading={isLoading}
            tickets={tickets.map((e: any) => {
              if (data.id === 1) {
                return e
              } else if (data.id === 2 && e.status === 'pending') {
                return e
              } else if (data.id === 3 && e.status === 'rejected') {
                return e
              } else if (data.id === 4 && e.status === 'completed') {
                return e
              } else {
              }
            })}
          />
        </Stack>
      )}
    </Stack>
  )
}
