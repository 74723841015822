import {Stack} from '@mui/material'
import React, {useRef, useEffect} from 'react'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'

export default function DropDawn({open, setOpen, selectItems, btn, isLeft}: any) {
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const store = useSelector((state: RootState) => state.vender)

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        setOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef, setOpen])

  return (
    <Stack position={'relative'}>
      {btn}
      {open && (
        <Stack
          bgcolor={'#fff'}
          sx={{
            position: 'absolute',
            top: '42px',
            right: store.isRtl === 0 && isLeft ? '0' : 'auto',
            left: store.isRtl !== 0 && isLeft ? '0' : 'auto',
            border: ' 1px solid #E8E8E8',
            zIndex: '10',
          }}
          ref={wrapperRef}
          onClick={() => {
            setOpen(false)
          }}
        >
          {selectItems}
        </Stack>
      )}
    </Stack>
  )
}
