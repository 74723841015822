import {Button, Stack, Tooltip} from '@mui/material'
import React, {useEffect} from 'react'
import {useState} from 'react'
import ImgMsg from './ImgMsg'
import AvatarBox from './AvatarBox'

export default function UploadAvatarImg({base64Image, setBase64Image, disable}: any) {
  const [image, setImage] = useState(base64Image || '')

  const handleImageChange = (event: any) => {
    const file = event.target.files[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      setBase64Image(reader.result)
      setImage(reader.result as string)
    }
    reader.onerror = (error) => {}
  }

  return (
    <>
      <Tooltip title='Only jpeg , jpg , png and svg image files are accepted'>
        <Stack width='100%' height='100%'>
          <Button
            disableRipple
            sx={{
              width: '100%',
              height: '100%',
              padding: '10px',
              borderRadius: '50%',
              cursor: disable ? 'auto !important' : 'pointer !important',
            }}
            component='label'
          >
            <Stack
              // border={'7px solid #fbe8e9'}
              alignItems={'center'}
              justifyContent={'center'}
              width={'100px'}
              height={'100px'}
              borderRadius={'50%'}
            >
              <AvatarBox
                src={image}
                alt='UploadAvatarImg'
                width='93px'
                height='93px'
                borderRadius='50%'
              />
            </Stack>
            {!disable && (
              <input
                hidden
                accept='.jpg, .jpeg, .png, .svg'
                onChange={handleImageChange}
                multiple
                type='file'
              />
            )}
          </Button>
        </Stack>
      </Tooltip>
    </>
  )
}
