import {Stack} from '@mui/material'
import React, {useState} from 'react'
import Title from '../../../../components/texts/Title'
import WithDrawItem from './WithDrawItem'
import MainBtn from '../../../../components/buttons/MainBtn'
import SecondaryBtn from '../../../../components/buttons/SacandaryBtn'
import PopUp from '../../../../parts/PopUp'
import Success from './Success'
import NoWithDraw from '../../../../svg/NoWithDraw'
import Label from '../../../../components/texts/Label'
import WithDrowItemObj from '../../../../modules/modules/get/WithDrowItemModule'

export default function WithDrawItems({
  data,
  setIsMethod,
  isMethod,
  setIsAdd,
  isSitting,
  submit,
  open,
  setOpen,
  setErrors,
  setMethodItems,
  loading,
  amount,
  setItem,
}: any) {
  return (
    <Stack gap={'24px'}>
      {data.length !== 0 && !isSitting && <Title fs='16px' fw='600' text='chooseWithdraw' />}
      {data.length === 0 && (
        <Stack gap='32px' alignItems={'center'}>
          <NoWithDraw />
          <Label fs='16px' fw='600' text='NoWithDraw' />
          <MainBtn
            width='auto'
            text='addNewMethod'
            handelSubmit={() => {
              if (setErrors) {
                setErrors({})
              }
              setIsAdd(true)
            }}
          />
        </Stack>
      )}

      <Stack
        justifyContent={{xs: 'center', md: 'flex-start'}}
        flexWrap={'wrap'}
        gap={'24px'}
        direction={'row'}
      >
        {data.map((e: any) => (
          <WithDrawItem
            setData={setMethodItems}
            data={data}
            setItem={setItem}
            setIsActive={setIsMethod}
            isActive={isMethod === e.id ? true : false}
            item={WithDrowItemObj(e)}
            isSitting={isSitting}
          />
        ))}
      </Stack>

      <Stack justifyContent={'flex-start'} width={'350px'} gap='24px' direction={'row'}>
        {data.length !== 0 && (
          <SecondaryBtn
            width='170px'
            isLink
            text='addNewMethod'
            handelSubmit={() => {
              if (setErrors) {
                setErrors({})
              }
              setIsAdd(true)
            }}
          />
        )}

        {data.length !== 0 && !isSitting && (
          <MainBtn
            isLoading={loading}
            width='120px'
            text='continue'
            handelSubmit={() => {
              submit()
            }}
          />
        )}
      </Stack>

      <PopUp open={open} setOpen={setOpen}>
        <Success amount={amount} />
      </PopUp>
    </Stack>
  )
}
