import {Stack} from '@mui/material'
import React from 'react'
import Label from '../../components/texts/Label'

export default function Item({item}: any) {
  return (
    <Stack p='24px'>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Stack gap='12px'>
          <Label lineHeight='20px' color='#484848' text={item.title} />
          <Stack maxHeight={'100px'} overflow={'auto'}>
            <Label lineHeight='20px' color='rgba(34, 34, 34, 0.65)' text={item.body} />
          </Stack>
          <Label lineHeight='20px' color='#484848' text={item.date} />
        </Stack>
        {/* <Stack width={'8px'} height={'8px'} borderRadius={'100%'} bgcolor={'#0688E6'}></Stack> */}
      </Stack>
    </Stack>
  )
}
