import React from 'react'

export default function PDF() {
  return (
    <svg
      width='121'
      height='121'
      viewBox='0 0 121 121'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='1'
        y='1'
        width='119'
        height='119'
        rx='8'
        fill='white'
        stroke='#979797'
        stroke-width='2'
        stroke-linecap='round'
      />
      <path
        d='M40.5 21C37.8187 21 35.625 23.1937 35.625 25.875V94.125C35.625 96.8063 37.8187 99 40.5 99H89.25C91.9313 99 94.125 96.8063 94.125 94.125V40.5L74.625 21H40.5Z'
        fill='#E2E5E7'
      />
      <path
        d='M79.5 40.5H94.125L74.625 21V35.625C74.625 38.3062 76.8187 40.5 79.5 40.5Z'
        fill='#B0B7BD'
      />
      <path d='M94.125 55.125L79.5 40.5H94.125V55.125Z' fill='#CAD1D8' />
      <path
        d='M84.375 84.375C84.375 85.7156 83.2781 86.8125 81.9375 86.8125H28.3125C26.9719 86.8125 25.875 85.7156 25.875 84.375V60C25.875 58.6594 26.9719 57.5625 28.3125 57.5625H81.9375C83.2781 57.5625 84.375 58.6594 84.375 60V84.375Z'
        fill='#F15642'
      />
      <path
        d='M36.5 67.1834C36.5 66.5399 37.007 65.8379 37.8236 65.8379H42.3256C44.8606 65.8379 47.1421 67.5344 47.1421 70.786C47.1421 73.867 44.8606 75.583 42.3256 75.583H39.0716V78.157C39.0716 79.015 38.5256 79.5001 37.8236 79.5001C37.1801 79.5001 36.5 79.015 36.5 78.157V67.1834ZM39.0716 68.2925V73.1479H42.3256C43.6321 73.1479 44.6656 71.995 44.6656 70.786C44.6656 69.4234 43.6321 68.2925 42.3256 68.2925H39.0716ZM50.9593 79.5001C50.3158 79.5001 49.6138 79.1491 49.6138 78.2935V67.2224C49.6138 66.5228 50.3158 66.0134 50.9593 66.0134H55.4223C64.3289 66.0134 64.1339 79.5001 55.5978 79.5001H50.9593ZM52.1877 68.3924V77.1235H55.4223C60.6849 77.1235 60.9189 68.3924 55.4223 68.3924H52.1877ZM67.2929 68.5484V71.6465H72.263C72.965 71.6465 73.667 72.3485 73.667 73.0285C73.667 73.672 72.965 74.1985 72.263 74.1985H67.2929V78.2911C67.2929 78.9736 66.8079 79.4976 66.1254 79.4976C65.2674 79.4976 64.7433 78.9736 64.7433 78.2911V67.22C64.7433 66.5204 65.2698 66.011 66.1254 66.011H72.9674C73.8254 66.011 74.3324 66.5204 74.3324 67.22C74.3324 67.844 73.8254 68.546 72.9674 68.546H67.2929V68.5484Z'
        fill='white'
      />
      <path
        d='M81.9375 86.8125H35.625V89.25H81.9375C83.2781 89.25 84.375 88.1531 84.375 86.8125V84.375C84.375 85.7156 83.2781 86.8125 81.9375 86.8125Z'
        fill='#CAD1D8'
      />
    </svg>
  )
}
