import {Stack} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import TitlePage from '../../../modules/customTable/TitlePage'
import Title from '../../../components/texts/Title'
import Label from '../../../components/texts/Label'
import InputWithLabelWithCopyBtn from '../../../parts/InputWithLabelWithCopyBtn'
import Loading from '../../../modules/customTable/LoadingForm'
import Get from '../../../fubcations/mastars/Get'

export default function Keys() {
  const [data, setData] = useState({secretKey: '123456', clientKey: '456789'})
  const [isLoading, setIsLoading] = useState(false)
  Get([], setData, 'vendor/settings/keys', setIsLoading)

  return (
    <Stack minHeight={'100%'} position={'relative'}>
      {isLoading ? (
        <Loading />
      ) : (
        <Stack gap='24px'>
          <Title fs='18px' text='keys' />
          <Stack gap='16px'>
            <InputWithLabelWithCopyBtn
              fw='400'
              name='secret_key'
              placeHolder='secretKey'
              type='text'
              setData={setData}
              data={data}
              width={{xs: '100%'}}
              disabled
            />
            <InputWithLabelWithCopyBtn
              fw='400'
              name='client_key'
              placeHolder='clientKey'
              type='text'
              setData={setData}
              data={data}
              width={{xs: '100%'}}
              disabled
            />
          </Stack>
        </Stack>
      )}
    </Stack>
  )
}
