import {Avatar, Stack} from '@mui/material'
import React from 'react'

export default function AvatarImg() {
  return (
    <Stack
      border={'7px solid #fbe8e9'}
      alignItems={'center'}
      justifyContent={'center'}
      width={'120px'}
      height={'120px'}
      borderRadius={'50%'}
    >
      <Avatar sx={{width: '93px', height: '93px', padding: '10px'}} />
    </Stack>
  )
}
