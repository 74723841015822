import {Stack} from '@mui/material'
import React from 'react'
import Label from '../../../components/texts/Label'
import PlasBtn from '../../../svg/PlasBtn'
import LabelWithValue from './LabelWithValue'
import DepositeHome from '../../../svg/DepositeHome'
import TransactionsHome from '../../../svg/TransactionsHome'

export default function BigCard({data}: any) {
  return (
    <Stack
      gap='38px'
      width={{xs: '100%', sm: '397px'}}
      p='24px'
      bgcolor={'#F1F6FF'}
      borderRadius={'8px'}
      height={'308px'}
    >
      <Stack top='5px' gap='60px'>
        <Stack direction={'row'} gap='123px'>
          <Stack gap='5px'>
            <Label text='balance' />
            {/* <Label fs='12px' color='#636B6F' text='Today, 21 Feb' /> */}
          </Stack>
        </Stack>
        <Label lineHight='28px' fs='28px' fw='600' isAmount text={data.balance} />
      </Stack>
      <Stack width='100%' height={'1px'} bgcolor={'#DBDBDB'}></Stack>
      <Stack gap='20px'>
        <LabelWithValue text='deposit' value={data.deposits} icon={<DepositeHome />} />
        <LabelWithValue text='transactions' value={data.transactions} icon={<TransactionsHome />} />
      </Stack>
    </Stack>
  )
}
