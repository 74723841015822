import React from 'react'

export default function PaymentLogObj(data: any) {
  return {
    code: data.payment_code,
    client: data.user_fullName,
    city: data.city_name,
    amount: data.amount,
    accountNo: data.user_account_number,
    date: data.date,
    status: data.status,
  }
}
