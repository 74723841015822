import {Stack} from '@mui/material'
import React from 'react'
import Label from '../components/texts/Label'
import InputType from '../components/inputs/InputType'
import ErrorMsg from '../components/texts/ErrorMsg'

export default function InputWithLabel({
  data,
  setData,
  type,
  placeHolder,
  name,
  isNumberPhone,
  fw,
  width,
  disabled,
  errors,
  label,
  value,
}: any) {
  return (
    <Stack width={width || 'auto'} gap='8px'>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Label fw={fw} text={placeHolder} />

        {label}
      </Stack>
      <InputType
        type={type}
        placeHolder={placeHolder}
        data={data}
        setData={setData}
        name={name}
        width='100%'
        isNumberPhone={isNumberPhone}
        disabled={disabled}
        isError={errors && errors[name]}
        value={value}
      />

      {errors && errors[name] && errors && typeof errors[name] === 'string' && (
        <ErrorMsg msgErr={errors[name]} />
      )}
      {errors && errors[name] && errors && typeof errors[name] !== 'string' && errors[name]}
    </Stack>
  )
}
