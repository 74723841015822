import {Stack} from '@mui/material'
import React, {useState, useRef, useEffect} from 'react'
import {SidebarMenu} from './components/sidebar/sidebar-menu/SidebarMenu'
import {KTSVG} from '../helpers'
import {useSelector} from 'react-redux'
import {RootState} from '../../app/store'
import Logo from '../../app/svg/Logo'

const DrawerComponent: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [isTransitioned, setIsTransitioned] = useState(false)
  const drawerRef = useRef<HTMLDivElement>(null)

  const handleOpen = () => {
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsTransitioned(true)
    setTimeout(() => {
      setIsOpen(false)
      setIsTransitioned(false)
    }, 1300)
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (drawerRef.current && !drawerRef.current.contains(event.target as Node)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
  const store = useSelector((state: RootState) => state.vender)

  return (
    <Stack>
      <div
        onClick={handleOpen}
        // style={{marginTop: '7px'}}
        className='btn btn-icon btn-active-color-primary w-35px h-35px'
        id='kt_app_sidebar_mobile_toggle'
      >
        <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className=' svg-icon-1' />
      </div>
      <div className={`drawer ${isOpen ? 'open' : ''}`} ref={drawerRef}>
        <Stack
          sx={{
            position: 'fixed',
            top: 0,
            left: store.isRtl === 0 ? 0 : 'auto',
            right: store.isRtl === 1 ? 0 : 'auto',
            height: '100vh',
            width: isOpen ? '260px' : '0',
            backgroundColor: '#fff',
            transition: 'width 0.3s linear',
            overflow: 'hidden',
            boxShadow: ' rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
          className='drawer-content'
        >
          <Stack padding='26px 0 26px 28px' alignItems={'flex-start'}>
            <Logo />
          </Stack>
          <SidebarMenu setIsOpen={setIsOpen} activeMenu={true} />
        </Stack>
        <div className='backdrop' />
      </div>
    </Stack>
  )
}

export default DrawerComponent
