import React, {useEffect} from 'react'
import Apiservices from '../../../lib/ApiServices'
import {actions} from '../../store/admin/auth.slice'
import {useDispatch} from 'react-redux'
import {toast} from 'react-toastify'

export default function Get(arr: any, setData: any, path: any, setIsLoading?: any, Module?: any) {
  const dispatch = useDispatch()

  const getData = async () => {
    try {
      if (setIsLoading) {
        setIsLoading(true)
      }
      const data = await Apiservices.get(path)

      if (setIsLoading) {
        setIsLoading(false)
      }

      if (Module && data.data.data) {
        const newData = Module(data.data.data)

        setData(newData)
      } else {
        setData(data.data.data)
      }
      dispatch(actions.setErrNetWork(false))
      // toast.success('Data fetched successfully!') // Success toast
    } catch (err: any) {
      if (setIsLoading) {
        setIsLoading(false)
      }

      if (!err.response) {
        dispatch(actions.setErrNetWork(true))
        toast.error('Network error, please check your internet connection.') // Network error toast
      } else {
        // API error
        dispatch(actions.setErrNetWork(false))
        if (err.response.status == 401) {
          dispatch(actions.login(null))
        }
        toast.error(`API error: ${err.response.status} - ${err.response.data.message}`) // API error toast
      }
    }
  }

  useEffect(
    () => {
      getData()
    },
    arr && path !== 'general/languages' ? [...arr, localStorage.getItem('i18nextLng')] : []
  )

  return null
}
