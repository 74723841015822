import {Stack} from '@mui/material'
import React from 'react'
import Header from '../../modules/login/Header'
import K from '../../svg/K'
import {useSelector} from 'react-redux'
import {RootState} from '../../store'
import PartK from '../../svg/PartK'
import FormPersonalInformation from './FormPersonalInformation'

export default function PersonalInformation() {
  const store = useSelector((state: RootState) => state.vender)

  return (
    <Stack width={'100vw'} height={'100vh'} position={'relative'}>
      <Header />
      <PartK
        style={{
          position: 'absolute',
          right: store.isRtl === 0 ? '0' : 'auto',
          left: store.isRtl !== 0 ? '0' : 'auto',
          // zIndex: '-1',
          bottom: '0',
          width: '35vw',
          // height: '50vh',
          transform: store.isRtl !== 0 ? 'rotateY(-180deg)' : 'rotateY(0)',
        }}
      />
      <FormPersonalInformation />
    </Stack>
  )
}
