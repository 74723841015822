import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {useTranslation} from 'react-i18next'

import {useSelector} from 'react-redux'
import {RootState} from '../../store'
import ExchangeOffice from '../../pages/wallet/pages/withdraw/pages/ExchangeOffice'
import Bank from '../../pages/wallet/pages/withdraw/pages/bank'
import Ewallet from '../../pages/wallet/pages/withdraw/pages/EWallet/index'
import Settings from '../../pages/settings'

const SettingsPage = () => {
  const {t} = useTranslation()
  const store = useSelector((state: RootState) => state.vender)

  return (
    <Routes>
      <Route
        element={
          <>
            {/* <ProfileHeader /> */}
            <Outlet />
          </>
        }
      >
        {/* <Route index element={<Transactions />} /> */}
        <Route index element={<Settings />} />
        <Route path='methods/bank' element={<Bank isSetting />} />
        <Route path='methods/bank/:id' element={<Bank isSetting />} />
        <Route path='methods/EWallet' element={<Ewallet isSetting />} />
        <Route path='methods/exchangeOffice' element={<ExchangeOffice isSetting />} />

        <Route index element={<Navigate to='/crafted/pages/profile/overview' />} />
      </Route>
    </Routes>
  )
}

export default SettingsPage
