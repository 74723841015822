import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {useTranslation} from 'react-i18next'

import {useSelector} from 'react-redux'

import Ticket from '../../pages/ticket'
import AddTicket from '../../pages/ticket/AddTicket'
import TicketDetails from '../../pages/ticket/chat'

const TicketPage = () => {
  return (
    <Routes>
      <Route
        element={
          <>
            {/* <ProfileHeader /> */}
            <Outlet />
          </>
        }
      >
        <Route index element={<Ticket />} />
        <Route path='addTicket' element={<AddTicket />} />
        <Route path='/:id' element={<TicketDetails />} />

        <Route index element={<Navigate to='/crafted/pages/profile/overview' />} />
      </Route>
    </Routes>
  )
}

export default TicketPage
