import {Stack} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

export default function Status({text, p, width}: any) {
  const {t} = useTranslation()
  return (
    <Stack
      bgcolor={
        text === 'pending' || text === 'wait'
          ? 'rgba(255, 168, 0, 0.10)'
          : text === 'inProgruss'
          ? 'rgba(0, 162, 248, 0.10)'
          : text === 'complate' || text === 'accept' || text === 'success' || text === 'refund'
          ? 'rgba(70, 203, 155, 0.10)'
          : 'rgba(216, 25, 35, 0.10)'
      }
      sx={{
        color:
          text === 'pending' || text === 'wait'
            ? '#FFA800'
            : text === 'inProgruss'
            ? '#00A2F8'
            : text === 'complate' || text === 'accept' || text === 'success' || text === 'refund'
            ? '#46CB9B'
            : '#D81923',
        textTransform: 'capitalize',
        fontSize: '12px',
        fontWeight: '400',
        lineHeight: '16px',
      }}
      p={'6px 6px'}
      // borderRadius={'4px'}
      width={'86px'}
      textAlign={'center'}
    >
      {t(text)}
    </Stack>
  )
}
