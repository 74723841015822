import {Stack} from '@mui/material'
import React, {useState} from 'react'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import {useSelector} from 'react-redux'
import {RootState} from '../../store'
export default function InputDate({disabled}: {disabled?: boolean}) {
  const [startDate, setStartDate] = useState(new Date())
  const store = useSelector((state: RootState) => state.vender)

  return (
    <Stack sx={{position: 'relative', width: '100%'}}>
      <CalendarMonthIcon
        sx={{
          position: 'absolute',
          zIndex: '10',
          right: store.isRtl === 0 ? '14px' : 'auto',
          left: store.isRtl !== 0 ? '14px' : 'auto',
          top: '12px',
          fontSize: '24px',
          color: '#707074',
        }}
      />
      <ReactDatePicker
        disabled={disabled}
        className={`custom-datepicker ${disabled ? 'disabled' : ''}`}
        selected={startDate}
        onChange={(date: any) => setStartDate(date)}
      />
    </Stack>
  )
}
